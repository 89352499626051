import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { dniValidator } from 'src/app/helpers/validators/dniValidator';
import { RequestData } from 'src/app/model/shared/request-data';
import { SelectOptions } from 'src/app/model/shared/select-options.model';
import { CommonService } from 'src/app/service/common/common.service';
import { DropdownService } from 'src/app/service/shared/dropdown.service';
import { UIService } from 'src/app/service/shared/ui.service';
import {emailPattern} from '../../../helpers/validators/emailPattern';

@Component({
  selector: 'app-add-new-worker',
  templateUrl: './add-new-worker.component.html',
  styleUrls: ['./add-new-worker.component.scss'],
})
export class AddNewWorkerComponent implements OnInit {
  public isLoading = false;
  submitted: boolean = false;
  companyList: SelectOptions[] = [];
  workcenterList: SelectOptions[] = [];
  languageList: SelectOptions[] = [];
  genderList = [
    { gid: "H", name: 'Hombre' },
    { gid: "M", name: 'Mujer' }
  ];
  public savedEmployeeData: any = {};
  public companyName: number;
  selectedCompany: any;  
  requestAll: any;
  @Input() public presonaData;
  @Input() public selectedCompanyRec;
  @Input() public selectedCompanyRecName;
  isLangSelected: boolean = true;
  isCompanyDissable: boolean = false;
  isLableFound: boolean = false;
  companyNameVal: string;

  companyRequestData = {
    page_number: 1,
    page_size: 10,
    incl: null,
    type: null,
    relatedId: null,
  };
  workCentersRequestData = {
    page_number: 1,
    page_size: 25,
    type: null,
    relatedId: null,
  };
  personaForm = new FormGroup({
    nombre: new FormControl('', [Validators.required]),
    apellidos: new FormControl('', [Validators.required]),
    nip: new FormControl('', [dniValidator()]),
    tel1: new FormControl('', [
      Validators.minLength(9),
      Validators.maxLength(12),
    ]),
    fecha_nacimiento: new FormControl('', Validators.required),
    email1: new FormControl('', [Validators.pattern(emailPattern())]),
    email: new FormControl(''),
    genero: new FormControl('', Validators.required),
    language_id: new FormControl(2),
    comentarios: new FormControl(''),
    empresa_id: new FormControl(null, [Validators.required]),
    id_centro_trabajo: new FormControl(null, [Validators.required]),
    id: new FormControl(0),
    id_trabajado: new FormControl(''),
  });

  chkPassport = false;
  isDisabledPassport: boolean = true;
  chkPassportBox = false;
  constructor(
    public modal: NgbActiveModal,
    private _commonService: CommonService,
    private dropdownService: DropdownService,
    public uiService: UIService
  ) {}

  ngOnInit(): void {
    this.isCompanyDissable = false;
    this.bindEditData(this.presonaData);
    this.getLanguageList().subscribe();
    this.getCompanyList();

    if(this.selectedCompanyRecName) {
      this.companyName = this.selectedCompanyRecName;
      this.loadTopMatchingCompanies(this.companyName);      
      this.getRelatedWorkCentersList(Number(this.companyName));
    }
      
    if (this.selectedCompanyRec) {
      this.personaForm.patchValue({
        empresa_id: this.selectedCompanyRec,
      });
    }
  }

  bindEditData(presonaData) {
    if (presonaData) {
      this.isLoading = true;
      this.getRelatedWorkCentersList(presonaData.empresa_id, false);
      
      setTimeout(() => {
        this.personaForm.patchValue({
          id: presonaData.id,
          nombre: presonaData.nombre?.trim(),
          apellidos: presonaData.apellidos?.trim(),
          nip: presonaData.nip.trim(),
          tel1: presonaData.tel1?.trim(),
          fecha_nacimiento: moment(
            presonaData.fecha_nacimiento,
            'DD-MM-YYYY'
          ).format('YYYY-MM-DD'),
          email1: presonaData.email1?.toLowerCase().trim(),
          genero: presonaData.genero,
          language_id: presonaData.language_id,
          comentarios: presonaData.comentarios?.trim(),
          empresa_id: presonaData.empresa_id,
          id_centro_trabajo: presonaData.id_centro_trabajo,
          id_trabajado: presonaData.id_trabajador,
        });
      }, 1000);
      this.isLoading = false;
    }
  }
  langChange(event) {
    if (!event) {
      this.isLangSelected = false;
      this.personaForm.updateValueAndValidity();
    } else {
      this.isLangSelected = true;
      this.personaForm.updateValueAndValidity();
    }
  }
  prepareEmployeePostData(personData) {
    if (personData != null) {
        personData.nip = personData.nip.trim();
        personData.nombre = personData.nombre?.trim();
        personData.tel1 = personData.tel1?.trim();
        personData.email1 = personData.email1?.trim();
        personData.apellidos = personData.apellidos?.trim();
        personData.comentarios = personData.comentarios?.trim();

      if (this.presonaData?.is_edit && this.presonaData.is_edit == 1) {
        let dDate = new Date(personData.fecha_nacimiento);
        let month = dDate.getMonth() + 1;
        let dDay = dDate.getDate();
        let nDate = dDate.getFullYear() + '-' + month + '-' + dDay;
        personData.fecha_nacimiento = moment(nDate).format('yyyyMMDD');
        
        personData['empresas'] = [
          {
            id: personData.empresa_id,
            id_empresa: personData.id_trabajado,
            sedes: [
              {
                empresa_sede_id: personData.id_centro_trabajo,
              },
            ],
          },
        ];
      } else {
        if (personData?.fecha_nacimiento._isAMomentObject) {
          personData.fecha_nacimiento = personData?.fecha_nacimiento
            .format('yyyyMMDD')
            .toString();
        }

        personData['empresas'] = [
          {
            id: personData.empresa_id,
            sedes: [
              {
                empresa_sede_id: personData.id_centro_trabajo,
              },
            ],
          },
        ];
      }
    }
    return personData;
  }

  omitSpecialChar(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  preparePersonaBindingData(personData) {
    if (personData != null) {
      if (personData.fecha_nacimiento?.length == 8) {
        let day = personData.fecha_nacimiento?.substr(6, 8);
        let month = personData.fecha_nacimiento?.substr(4, 2);
        let year = personData.fecha_nacimiento?.substr(0, 4);
        if (day != undefined && month != undefined && year != undefined) {
          let date = day + '/' + month + '/' + year;
          personData.fecha_nacimiento = date;
        }
        this.personaForm.controls.fecha_nacimiento.setValue(
          personData.fecha_nacimiento
        );
      }
      if (personData?.empresas[0]?.sedes?.length > 0) {
        this.personaForm.controls.id_centro_trabajo.setValue(
          personData?.empresas[0]?.sedes[0].empresa_sede_txt
        );
      }
    }
  }
  saveEmployee() {    
    this.submitted = true;
    if (this.personaForm.valid) {
      if (this.personaForm.get('id')?.value == 0) {
        this.personaForm.removeControl('id');
      }
      this.isLoading = true;
      let postData = this.personaForm.value;      
      postData = this.prepareEmployeePostData(postData);
      if (postData.email1 == '') {
        postData.email = '';
      }
      if (postData.tel1 == '') {
        postData.email = '';
      }
      this._commonService.updatePerson(postData).subscribe(
        (res) => {
          if (res && res?.webError?.number == 0) {
            this.uiService.showFailToast('Error', res?.webError?.messageToUser);
          } else if (res && res?.data != null) {
            this.personaForm.patchValue(res.data);
            this.preparePersonaBindingData(res.data);
            this.uiService.showSuccessToast('Guardado con éxito');
            this.savedEmployeeData = res.data;
            this.savedEmployeeData['companyName'] = this.companyName;
            this.modal.close();
          }
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
        }
      );
    } else {
      this.personaForm.updateValueAndValidity();
    }
  }

  get personaFormControl() {
    return this.personaForm.controls;
  }

  getCompanyList(incl?: string, isScroll = false) {
    this.isLoading = true;
    this.isCompanyDissable = false;
    this.companyRequestData.incl = incl ? incl : null;
    this.companyRequestData.page_number = isScroll ? this.companyRequestData.page_number + 1 : 1;

    if (this.presonaData?.is_edit && this.presonaData.is_edit == 1) {
      this.companyRequestData.relatedId = this.selectedCompanyRec;
      this.isCompanyDissable = true;
    }
    this._commonService.getCompanyList(this.companyRequestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        if (isScroll) {
          this.companyList = this.companyList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id'));
        } else {
          this.companyList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');           
        }
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  loadTopMatchingCompanies(companyNameTarget: any) {
    if (companyNameTarget.length >= 4) {
      this.getCompanyList(companyNameTarget);
    } else {
      if (companyNameTarget.length == 0) {
        this.getCompanyList();
      }
    }
  }
  onKeyUpCompany(companyNameTarget) {
    this.companyNameVal = companyNameTarget?.target?.value;
    this.loadTopMatchingCompanies(companyNameTarget?.target?.value);
  }

  onChangeCompany(company: any) {
    if (company) {
      this.companyName = company.label;
      this.personaFormControl.id_centro_trabajo.setValue(null);
      this.getRelatedWorkCentersList(Number(company.value));

      if (company.value != 1 && company.value != null) {
        this.chkPassportBox = false;
        this.chkPassport = false;
        this.isDisabledPassport = true;
        this.personaForm.controls.nip.setValidators(dniValidator());
        this.personaForm.controls.nip.updateValueAndValidity();
      } else if (company.value == 1) {
        this.chkPassportBox = false;
        this.isDisabledPassport = false;
      }

      if (this.chkPassport) {
        if (this.personaForm.controls.nip.value != '') {
          this.personaForm.controls.nip.clearValidators();
          this.personaForm.controls.nip.updateValueAndValidity();
        }
      }
    } else {
      this.getCompanyList()
      this._commonService.companyIdSubject.next(null);
    }
  }
  onScrollCompany() {
    this.getCompanyList(this.companyNameVal, true);
  }

  onScrollCentro() {
    this.getRelatedWorkCentersList(
      this.personaFormControl.empresa_id.value,
      true
    );
  }
  getRelatedWorkCentersList(companyId?: number, isScroll = false) {
    this.isLoading = true;
    this.workCentersRequestData.page_number = isScroll ? this.workCentersRequestData.page_number + 1 : 1;
    this.workCentersRequestData.relatedId = companyId > 0 ? Number(companyId) : null;

    this._commonService.getWorkCenterList(this.workCentersRequestData).subscribe(
      (res) => {
        this.isLoading = false;
        if (res) {
          if (isScroll) {
            this.workcenterList = this.workcenterList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'empresa_sede', 'id', false, false));
          }
          else {
            this.workcenterList = this.dropdownService.WrappedToDropDownList(res.data, 'empresa_sede', 'id');
          }
        }
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  getLanguageList() {
    this.isLoading = true;
    const requestData = new RequestData();
    requestData.type = null;
    return this._commonService.getLanguages(requestData).pipe(
      map((res) => {
        this.isLoading = false;
        if (res) {
          this.languageList = this.dropdownService.WrappedToDropDownList(
            res.data,
            'nombre',
            'id'
          );
        }
      }),
      catchError((_err) => {
        this.isLoading = false;
        return EMPTY;
      })
    );
  }

  onClearCompany() {
    this.personaFormControl.id_centro_trabajo.setValue(null);
    this.chkPassportBox = false;
    this.chkPassport = false;
    this.isDisabledPassport = true;
    this.personaForm.controls.nip.setValidators(dniValidator());
    this.personaForm.controls.nip.updateValueAndValidity();
  }

  onChangeChkPassport($event) {
    this.chkPassport = $event.target.checked;
    if (this.chkPassport) {
      if (this.personaForm.controls.nip.value != '') {
        this.personaForm.controls.nip.clearValidators();
        this.personaForm.controls.nip.updateValueAndValidity();
      } else {
        this.personaForm.controls['nip'].setErrors({ invalid: true });
      }
    } else if (!this.chkPassport) {
      this.personaForm.controls.nip.setValidators(dniValidator());
      this.personaForm.controls.nip.updateValueAndValidity();
    }
  }
}
