export class Authentication {

	public static clientId = 'erp';
	public static clientId_scope = 'usr_read valimed_access valimed_upd erp_data_access usr_auth_read vigilancia_upd vigilancia_access'; //'openid profile usr_read valimed_access valimed_upd';
	public static clientId_response_type = 'code';				//PKCE 

	// if any of these two values are present for a user, they overrides any other permission
	public static readonly AUTH_NO_ACCESS = -1;					// DENIED ACCESS for any app
	public static readonly AUTH_ADMIN = 0;						// ADMIN for all apps

}
