<app-spinner *ngIf="isLoading"></app-spinner>
<div class="container-fluid pl-0 pr-0">

  <div class="row" *ngIf="!isShowWorkCenter;  else workCenterBlock">
    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
      <div class="form-group">
        <label class="mb-0">Empresa</label>
        <ng-select
          [clearAllText]="'limpiar todo'"
          #select
          [virtualScroll]="true"
          (close)="select.blur()"
          (clear)="onCloseCompanies()"
          loadingText="Cargando..."
          notFoundText="No hay resultados"
          id="activity-type"
          name="activity-type"
          bindLabel="label"
          bindValue="value"
          [items]="companyList"
          placeholder="Seleccione"
          (keyup)="onKeyUpCompany($event)"
          (change)="onChangeCompany($event)"
          [(ngModel)]="companyId"
          (scrollToEnd)="onScrollCompany()"
          [class.ng-invalid]="this.validateCompany && companyId == null"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.label }}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
      <div class="form-group">
        <label class="mb-0">Persona</label>
        <ng-select
          [clearAllText]="'limpiar todo'"
          #select
          [virtualScroll]="true"
          (close)="select.blur(); onClosePersons()"
          loadingText="Cargando..."
          notFoundText="No hay resultados"
          id="activity-type"
          name="activity-type"
          bindLabel="label"
          bindValue="value"
          [items]="personsList"
          placeholder="Seleccione"
          (keyup)="onKeyUpPerson($event)"
          (change)="onChange($event)"
          [(ngModel)]="personId"
          (scrollToEnd)="onScrollPerson()"
          [class.ng-invalid]="this.validateEmployee && personId == null"
        >
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.label }}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
  <ng-template #workCenterBlock>
    <div class="row" *ngIf="!isShowEmpresa;  else empresaBlock">
      <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Empresa</label>
          <ng-select
            [clearAllText]="'limpiar todo'"
            #select
            [virtualScroll]="true"
            (close)="select.blur()"
            (clear)="onCloseCompanies()"
            loadingText="Cargando..."
            notFoundText="No hay resultados"
            id="activity-type"
            name="activity-type"
            bindLabel="label"
            bindValue="value"
            [items]="companyList"
            placeholder="Seleccione"
            (keyup)="onKeyUpCompany($event)"
            (change)="onChangeCompany($event)"
            [(ngModel)]="companyId"
            (scrollToEnd)="onScrollCompany()"
            [class.ng-invalid]="this.validateCompany && companyId == null"
          >
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Centro de trabajo</label>
          <ng-select
            [clearAllText]="'limpiar todo'"
            #select
            [virtualScroll]="true"
            (close)="select.blur()"
            (clear)="onCloseWorkCenters()"
            loadingText="Cargando..."
            notFoundText="No hay resultados"
            id="activity-type"
            name="activity-type"
            bindLabel="label"
            bindValue="value"
            [items]="workcenterList"
            placeholder="Seleccione"
            [(ngModel)]="workCenterId"
            [disabled]="workCenterDisable"
            (change)="onChangeWorkCenter($event)"
            (scrollToEnd)="onScrollCentro()"
          >
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Persona</label>
          <ng-select
            [clearAllText]="'limpiar todo'"
            #select
            [virtualScroll]="true"
            (close)="select.blur(); onClosePersons()"
            loadingText="Cargando..."
            notFoundText="No hay resultados"
            id="activity-type"
            name="activity-type"
            bindLabel="label"
            bindValue="value"
            [items]="personsList"
            placeholder="Seleccione"
            (keyup)="onKeyUpPerson($event)"
            (change)="onChange($event)"
            [(ngModel)]="personId"
            (scrollToEnd)="onScrollPerson()"
            [class.ng-invalid]="this.validateEmployee && personId == null"
          >
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #empresaBlock>
    <div class="row">
      <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Empresa</label>
          <ng-select
            [clearAllText]="'limpiar todo'"
            #select
            [virtualScroll]="true"
            (close)="select.blur()"
            (clear)="onCloseCompanies()"
            loadingText="Cargando..."
            notFoundText="No hay resultados"
            id="activity-type"
            name="activity-type"
            bindLabel="label"
            bindValue="value"
            [items]="companyList"
            placeholder="Seleccione"
            (keyup)="onKeyUpCompany($event)"
            (change)="onChangeCompany($event)"
            [(ngModel)]="companyId"
            (scrollToEnd)="onScrollCompany()"
            [class.ng-invalid]="this.validateCompany && companyId == null"
          >
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>  
    </div>
  </ng-template>
</div>
