<div class="modal-body text-center">
    <h1 class="font-weight-bold popup-title-f-s">{{confirmationMessage}}</h1>
</div>
<div class="modal-footer pt-0">
    <div class="col-md-12 d-flex">
        <div class="col-md-6 text-right pr-0">
            <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal" (click)="modal.dismiss()"
                ngbAutofocus>No</button>
        </div>
        <div class="col-md-6">
            <button type="button" class="btn btn-primary" (click)="modal.close(true)">Sí</button>
        </div>
    </div>
</div>