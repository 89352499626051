import { AgendaAttribute } from "./agenda-attribute";
import { AgendaOption } from "./agenda-options";
import { AgendaPersona } from "./agenda-persona";

export class Agenda {
       persona:AgendaPersona;
     
       attribs: AgendaAttribute;

       options: AgendaOption[];

       protocolos:[];
       pruebas:[];
       sections: any;
       
       constructor() {

this.persona = new AgendaPersona();
this.attribs = new AgendaAttribute();
this.options = [];
       }
}
