import { Constants } from 'src/app/helpers/constant/constant';
import { Subject } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import esLocale from '@fullcalendar/core/locales/es';
import { Agenda } from 'src/app/model/agendar/agenda';
import { RequestData } from 'src/app/model/shared/request-data';
import { SelectOptions } from 'src/app/model/shared/select-options.model';
import { CommonService } from 'src/app/service/common/common.service';
import { DropdownService } from 'src/app/service/shared/dropdown.service';
import { UIService } from 'src/app/service/shared/ui.service';
import { WeekJumpType } from '../../../../helpers/enums/week-jump-type';
import moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AgendaRequestData } from 'src/app/model/agendar/agenda-request-data'
@Component({
  selector: 'app-reusable-calendar',
  templateUrl: './reusable-calendar.component.html',
  styleUrls: ['./reusable-calendar.component.scss'],
})
export class ReusableCalendarComponent implements OnChanges, OnInit {
  @Input() clearForm: boolean = false;
  @Input() agendaId: number;
  @Input() centroId: number;
  @Input() timeAppointment: string;
  @Input() dateAppointment: string;
  @Input() validateProp: any = null;
  @Output() editedAgenda: EventEmitter<Agenda>;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @Input() isEdit: number;
  agendalist: SelectOptions[] = [];
  comments:'';
  events = [];
  _weekJumpType = WeekJumpType;
  centerList: SelectOptions[] = [];
  fecha: Date;
  isCentroDissable:boolean =false;
  timeSlotTotalElement = [];
  firstTimeLoad:boolean=true;
  agendaTimeSlotForm = new FormGroup({
    centro_Id: new FormControl(null),
    dateAppointment: new FormControl('', [Validators.required]),
    timeAppointment: new FormControl('', [Validators.required]),
  });
  isLoading: boolean = false;
  isCentorReadonly = false;
  storedCentroid:any;
  storedCentreName:any;
  selectedValue:number;
  isCancelAppointment:boolean;
  agendaFilteredData: AgendaRequestData;
  private centerSubject: Subject<string> = new Subject();
  private agendaSubject: Subject<string> = new Subject();
  //calendar 
  centerRequestData = {
    page_number: 1,
    page_size: 25,
    incl: null,
    type: 'filter'
  }

  agendaRequestData = {
    page_number: 1,
    page_size: 25,
    incl: null,
    type: 'filter'
  }
  monthIdentity:number=0;
  weekIdentity:number=0;
  calendarOptions: CalendarOptions = {
    dayMaxEventRows: false,
    initialView: 'dayGridWeek',
    locale: esLocale,
    weekNumbers: false,
    //navLinks: true, // can click day/week names to navigate views
    editable: false,
    headerToolbar: {
      left: '',
      center: 'title',
      right: '',
    },
    //events: [{ title: '', date: '', color: '' }] // #f8b63c orange color
    eventClick: (arg) => {
      if (arg.event.extendedProps.isClickable) {
        let allEvents = this.calendarComponent
          .getApi()
          .getOption('events') as Array<any>;
        allEvents.forEach((event) => {
          event.isSelected = false;
          event.color = event.isClickable ? '#5bc2ee' : '#e4e2de';
          if (event.id == arg.event.id) {
            event.color = '#FDAF3B';
            event.isSelected = true;
            this.agendaTimeSlotForm.controls['dateAppointment'].setValue(
              event.date
            );
            this.agendaTimeSlotForm.controls['timeAppointment'].setValue(
              event.title.split('(')[0]
            );
            const agenda: Agenda = new Agenda();
            agenda.attribs.hora = event.title.split('(')[0];
            agenda.attribs.fecha = event.date;
            agenda.attribs.centro_id =
              this.agendaTimeSlotForm.controls.centro_Id.value;
            this.editedAgenda.emit(agenda);
          }
        });
      }
    },
    dayHeaderDidMount: (args) => {        
      this.timeSlotTotalElement =
      this.timeSlotTotalElement.length >= 7 ? [] : this.timeSlotTotalElement;
      this.timeSlotTotalElement.push(args.el);
    setTimeout(() => 
    {
      this.timeSlotTotalElement =
      this.timeSlotTotalElement.length >= 7 ? [] : this.timeSlotTotalElement;
      this.timeSlotTotalElement.push(args.el);
    },
    3000);
    },
};
  calenderData: any;
  constructor(
    private _visualizerCitarService: CommonService,
    private dropdownService: DropdownService,
    private uiService: UIService,
    private changeDetection: ChangeDetectorRef,
    private _commonService: CommonService
  ) {
    this.fecha = new Date();
    this.editedAgenda = new EventEmitter<Agenda>();

    this.agendaTimeSlotForm.valueChanges.subscribe((data) => {
      const agenda: Agenda = new Agenda();
      agenda.attribs.hora = data.timeAppointment;
      agenda.attribs.fecha = data.dateAppointment;
      agenda.attribs.centro_id = data.centro_Id;
      this.editedAgenda.emit(agenda);
    });
  }
  ngOnInit(): void {
    this.storedCentroid= Number(localStorage.getItem("centro_id"));
    if(this.storedCentroid!=null)
    {
      this.selectedValue=this.storedCentroid;
    }else
    {
      this.selectedValue=this.centroId;
    }
    this.centerList = [];
    
    this.agendaSubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe(agendaName =>  this.loadTopMatchingAgenda(agendaName));

    this.centerSubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe(centerName =>  this.loadTopMatchingCenters(centerName));

    this.calenderData= JSON.parse(localStorage.getItem('calendarioData'))
    if(this.calenderData != null && this.calenderData.clicked){
      let fecha = moment(this.calenderData.fecha).format('YYYY-MM-DD');
      this.agendaTimeSlotForm.controls['dateAppointment'].setValue(fecha);
      this.agendaTimeSlotForm.controls['timeAppointment'].setValue(this.calenderData.hora);
      this.getCenterList(this.calenderData.agendaId);
      this.getcenter(this.calenderData.agendaId);
    } else {
      this.agendaTimeSlotForm.controls['dateAppointment'].setValue('');
      this.agendaTimeSlotForm.controls['timeAppointment'].setValue('');
    }
    if(this.isEdit>0)
    {
      this.getCenterList();
    }

    this.isCancelAppointment=Boolean(localStorage.getItem('cancelStatus'))

    if(this.isCancelAppointment)
    {
      this.selectedValue=null;
      this.getCenterList(this.agendaId)
    }
  }

  loadTopMatchingAgenda(agendaName: any) {
    // Trigger the loading only if string length is >= 4
    if (agendaName && agendaName.length >= 4) { 
      this.getAgendaList(agendaName);
    } else {
      if (agendaName.length == 0) {
        this.getAgendaList();
      }
    }
  }
  getAgendaList(incl?: string, isScroll = false) {
    this.isLoading = true;
    this.agendaRequestData.incl = incl ? incl : null;
    this.agendaRequestData.page_number = isScroll ? (this.agendaRequestData.page_number + 1) : 1;
    this._commonService.getAgendaList(this.agendaRequestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        if (isScroll) {
          this.agendalist = this.agendalist.concat(this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id'));
        } else {
          this.agendalist = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');
        }
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  loadTopMatchingCenters(centerName: any) {
    // Trigger the loading only if string length is >= 4
    if (centerName && centerName.length >= 4) { 
      
      this.getCenterList(this.agendaId, centerName);
    } else {
      if (centerName.length == 0) {
        const agenda_id = this.agendaId > 0 ? this.agendaId : undefined;
        this.getCenterList(agenda_id);
      }
    }
  }

   getCenterList(agendaId?: number, incl?: string, isScroll = false) {
    this.isLoading = true;
    this.centerRequestData.incl = incl ? incl : null;
    this.centerRequestData.page_number = isScroll ? (this.centerRequestData.page_number + 1) : 1;
  
    this.storedCentreName = localStorage.getItem("centro_text");
    if (!this.storedCentreName) {
      this.storedCentreName = null;
    }  
    if (!this.centerRequestData.incl && !this.storedCentreName) {
      this.storedCentreName = null;
    } else if (this.centerRequestData.incl) {
      this.storedCentreName = this.centerRequestData.incl;
    }
  
    const requestData = {
      relatedId: agendaId,
      page_number: this.centerRequestData.page_number,
      page_size: 10,
      incl: null,
      type: null //'filter'
    };
  
    let request: any;
    let requestAll: any;
  
    if (agendaId > 0) {
      const requestDataObj = new RequestData();
      requestDataObj.relatedId = agendaId;
      requestDataObj.incl = this.storedCentreName;
      requestDataObj.page_number = this.centerRequestData.page_number;
      requestDataObj.type = null; //'filter';
      request = requestDataObj;
    } else {
      request = requestData;
    }
  
    this._commonService.getCenterList(request).subscribe(
      (res) => {
        this.isLoading = false;
        if (res) {
          if (isScroll) {
            this.centerList = this.centerList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id'));
          } else {
            if (this.isEdit == 1) {
              this.isCentroDissable = true;
              const requestAllData = {
                page_number: this.centerRequestData.page_number,
                page_size: res.links.rowcount,
                incl: null,// this.storedCentreName,
                type: null, //'filter',
                relatedId: agendaId,
              };
              requestAll = requestAllData;
              this._commonService.getCenterList(requestAll).subscribe(
                (resall) => {
                  this.centerList = this.dropdownService.WrappedToDropDownList(resall.data, 'nombre', 'id', false, false);
                },
                (error) => {
                  this.isLoading = false;
                  console.log(error);
                });
            } else {
              this.isCentroDissable = false;
              this.centerList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id', false, false);
              if (this.isCancelAppointment) {
                this.selectedValue = res.data[1].id;
              } else {
                this.selectedValue = this.storedCentroid != null ? this.storedCentroid : res.data[1].id;
              }
            }
          }
        }
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }
  

  changeLeagueOwner(event) {
    localStorage.setItem('centro_text', event.label);
  }

  getcenter(agendaId :any){
    this.storedCentreName= localStorage.getItem("centro_text");
    if(this.storedCentreName==null || '' || undefined)
    {
      this.storedCentreName=null
    }
    const requestData = {
      relatedId:agendaId,
      page_number: 1,
      page_size: 200,
      incl: null, //this.storedCentreName,
      type: null //'filter'
    }
    this._commonService.getCenterList(requestData).subscribe(res => {
      if (this.isCancelAppointment) {
      } else {
        if(this.storedCentroid!=null)
        {
          if(this.storedCentroid.toString() != "0")
          {
            this.selectedValue=this.storedCentroid;
          }
          else{
            setTimeout(() => {
              this.selectedValue=res.data[0].id;
            }, 2000);
          }
        }else
        {
          setTimeout(() => {
            this.selectedValue=res.data[0].id;
          }, 2000);
        }      
      }
    })
    this.isLoading = false;
  }

  ngAfterContentInit(): void {
    
    this.centroId = this.centroId == 0 ? null : this.centroId;
    if (this.timeAppointment && this.dateAppointment) {
      this.agendaTimeSlotForm.controls['timeAppointment'].setValue(
        this.timeAppointment
      );
      this.agendaTimeSlotForm.controls['dateAppointment'].setValue(
        moment(this.dateAppointment.toString(), 'DD/MM/YYYY')
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    
    if (changes.agendaId?.previousValue !== changes.agendaId?.currentValue) {
      this.getCenterList(this.agendaId,'', false);
      this.getAgendaTimeSlots(this.agendaId, true);
      this.getAgendaData(this.agendaId);
      this.getcenter(this.agendaId);
    }
   
    if (this.clearForm) {
      
      this.agendaTimeSlotForm.controls['centro_Id'].setValue(null);
      this.agendaTimeSlotForm.controls['timeAppointment'].setValue('');
      this.agendaTimeSlotForm.controls['dateAppointment'].setValue('');

      let allEvents = this.calendarComponent
        .getApi()
        .getOption('events') as Array<any>;
      allEvents.forEach((event) => {
        event.isSelected = false;
        event.color = '#5bc2ee';
      });

      this.agendaTimeSlotForm.markAsPristine();
      this.agendaTimeSlotForm.markAsUntouched();
      this.clearForm = false;
    }
  }

  onCloseCentro() {
    this.centroId = null;
  }

  onRefreshCentro() {
    
    this.centroId = null;
    this.centerList = [];
  }
  ngAfterViewInit(): void {
    this.agendaTimeSlotForm.markAsUntouched();
    this.agendaTimeSlotForm.markAsPristine();
    this.changeDetection.detectChanges();
  }


  onKeyUpCenter(centerNameTarget){
    this.centerSubject.next(centerNameTarget?.target?.value);
  }
  onScrollCenter() {
    this.getCenterList(this.agendaId,'', true);
  }
  
  onWeekChange(weekJumpType: number) {
    switch (weekJumpType) {
      case this._weekJumpType.PreviousWeek:
        this.weekIdentity=this.weekIdentity-1;
        if(this.weekIdentity < 4){
          this.monthIdentity = 0;
        }
        this.calendarComponent.getApi().prev();
       
        break;
      case this._weekJumpType.FourWeeksBack:
        if(this.weekIdentity < 4){
          this.monthIdentity = 0;
          break;
        }
        this.monthIdentity=this.monthIdentity-1;
        if(this.weekIdentity >= 4){
          this.weekIdentity = this.weekIdentity - 4;
        }

        this.calendarComponent.getApi().prev();
        this.calendarComponent.getApi().prev();
        this.calendarComponent.getApi().prev();
        this.calendarComponent.getApi().prev();
        break;
      case this._weekJumpType.NextWeek:
        this.weekIdentity=this.weekIdentity+1;
        if(this.weekIdentity >= 4){
          this.monthIdentity = this.weekIdentity/4;
          this.monthIdentity =Math.floor(this.monthIdentity );
        }
        this.calendarComponent.getApi().next();
        break;
      case this._weekJumpType.FourWeeksForward:
        this.weekIdentity=this.weekIdentity + 4;
        this.monthIdentity=this.monthIdentity+1;       
        this.calendarComponent.getApi().next();
        this.calendarComponent.getApi().next();
        this.calendarComponent.getApi().next();
        this.calendarComponent.getApi().next();
        break;
    }
    this.getAgendaTimeSlots(this.agendaId);
  }

  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  getAgendaTimeSlots(agendaId?: number, firstTime: boolean = false) {
    this.isLoading = true;
    this.events = [];
    const date = firstTime ? this.getMonday(new Date()) : this.getMonday(this.calendarComponent.getApi().getDate());
    const dateString = this.uiService.dateToString(date);
    const requestData = {
      agenda_id: agendaId,
      from: dateString,
      days: 7,
      slots: true,
    };

    this._visualizerCitarService.getAgendaTimeSlots(requestData).subscribe(
      (res) => {
        if (res && res.data) {
          const eventTotal = [];
          for (const timeslot of res.data) {
            const datereq = timeslot.date.slice(-4) + '-' + timeslot.date.slice(3, 5) + '-' + timeslot.date.slice(0, 2);
            let currentDayConcurrents = 0;
            let currentDayUsed = 0;
            for (const slot of timeslot.slots) {
              const event = {
                id: '_' + Math.random().toString(36).substring(2, 9),
                title: `${slot.hour_from}(${slot.used}/${slot.concurrents})`,
                date: datereq,
                color: Number(slot.used) < Number(slot.concurrents) ? '#5bc2ee' : '#e4e2de',
                isClickable: Number(slot.used) < Number(slot.concurrents),
                isSelected: false,
              };
              currentDayUsed += slot.used;
              currentDayConcurrents += slot.concurrents;
              this.events.push(event);
            }
            eventTotal.push({
              currentDayUsed: currentDayUsed,
              currentDayConcurrents: currentDayConcurrents,
            });
          }
  
          this.timeSlotTotalElement.forEach((el, i) => {
            const totalTimeSlots = document.querySelectorAll(`.totalTimeSlot-${i}`);
            totalTimeSlots.forEach(totalTimeSlot=> {
              totalTimeSlot.remove();
            });
            const span = document.createElement('span');
            span.className = `totalTimeSlot-${i}`;
            span.textContent = `(${eventTotal[i].currentDayUsed}/${eventTotal[i].currentDayConcurrents})`;
            span.style.color = 'dimgrey';
            el.append(span);
          });
  
          this.calendarOptions.events = this.events;
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getAgendaData(agendaId){
    this.isLoading = true;
    const requestData = {
      agenda_id: agendaId, 
    };
    this._visualizerCitarService.getAgendaData(requestData).subscribe(
      (res) => {
        if (res && res.data) {
          this.comments = res.data?.comments;
        }
        this.isLoading = false;
      },
      (error) => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }
}
