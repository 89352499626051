<div class="modal-body" *ngIf="showException">
    <div class="row pl-3">
        <div class="col-md-10 text-left pl-0"></div>
        <div class="col-md-2">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="closeExceptionModal($event)">
                <span aria-hidden="true">×</span>
            </button>
        </div>
    </div>
    <nav class="add-exception-tabs">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="nav-agregar-tab" data-toggle="tab" href="#nav-agregar" role="tab"
                aria-controls="nav-agregar" aria-selected="true">Agregar Excepciones</a>
            </li>
            <li class="nav-item">
                <a [ngClass]="hasException ? 'disabled': '' " class="nav-link ml-2" id="nav-copiar-tab" data-toggle="tab" href={{hrefvalue}} role="tab"
                aria-controls="nav-copiar" aria-selected="false">Copiar Excepciones </a>
            </li>
          </ul>
    </nav>

    <div class="card card-tabs">
        <div id="nav-tabContent" class="tab-content">
            <div
            id="nav-agregar"
            class="tab-pane p-3 fade show active"
            role="tabpanel"
            aria-labelledby="nav-agregar-tab"
            >
                <div class="row pl-3 pb-3">
                    <div class="col-md-10 text-left pl-0 pb-3">
                        <h6 id="activity-modal" class="modal-title font-weight-bold">
                            Agregar excepciones
                        </h6>
                    </div>
                    <div class="col-md-2"> </div>
                </div>

                <form [formGroup]="exceptionForm">

                    <div class="row">
                        <div class="col-lg-2 col-xl-2 col-md-3 col-sm-12">
                            <div class="form-group d-grid">
                                <label class="mb-0">Fecha Incio</label>
                                <input [owlDateTime]="filterFromDatePicker1" [owlDateTimeTrigger]="filterFromDatePicker1"
                                    type="text" class="form-control form-control-sm date-field"
                                    formControlName="fecha_desde" [(ngModel)]="angendaExcep.fecha_desde">
                                <owl-date-time #filterFromDatePicker1 [pickerType]="'calendar'" [firstDayOfWeek]="1">
                                </owl-date-time>

                                <span *ngIf="( exceptionForm.get('fecha_hasta').touched &&
                            exceptionForm.get('fecha_hasta').errors?.required &&
                            exceptionForm.get('fecha_hasta').errors != null   )" class="is-invalid red-clr">
                                    requerida </span>
                            </div>
                        </div>
                        <div class="col-lg-2 col-xl-2 col-md-3 col-sm-12">
                            <div class="form-group d-grid">
                                <label class="mb-0">Fecha Fin</label>
                                <input [owlDateTime]="filterFromDatePicker2" [owlDateTimeTrigger]="filterFromDatePicker2"
                                    type="text" class="form-control form-control-sm date-field"
                                    formControlName="fecha_hasta" [(ngModel)]="angendaExcep.fecha_hasta">
                                <owl-date-time #filterFromDatePicker2 [pickerType]="'calendar'" [firstDayOfWeek]="1">
                                </owl-date-time>
                                <span *ngIf="( exceptionForm.get('fecha_desde').touched &&
                            exceptionForm.get('fecha_desde').errors?.required &&
                            exceptionForm.get('fecha_desde').errors != null   )" class="validation-span red-clr">
                                    requerida </span>
                            </div>
                        </div>

                        <div class="col-lg-2 col-xl-2 col-md-3 col-sm-12">
                            <div class="form-group d-grid">
                                <label class="mb-0">Hora Incio</label>
                                <input class="time-input" type="time" name="appt" formControlName="hora_desde"
                                    [(ngModel)]="angendaExcep.hora_desde">
                                <span *ngIf="( exceptionForm.get('hora_hasta').touched &&
                            exceptionForm.get('hora_hasta').errors?.required &&
                            exceptionForm.get('hora_hasta').errors != null   )" class="validation-span red-clr">
                                    requerida </span>
                            </div>
                        </div>
                        <div class="col-lg-2 col-xl-2 col-md-3 col-sm-12">
                            <div class="form-group d-grid">
                                <label class="mb-0">Hora Fin</label>
                                <input class="time-input" type="time" name="appt" formControlName="hora_hasta"
                                    [(ngModel)]="angendaExcep.hora_hasta">
                                <span *ngIf="( exceptionForm.get('hora_desde').touched &&
                            exceptionForm.get('hora_desde').errors?.required &&
                            exceptionForm.get('hora_desde').errors != null   )" class="validation-span red-clr">
                                    requerida </span>
                            </div>
                        </div>
                        <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12">
                            <div class="form-group d-grid">
                                <label class="mb-0">{{concurrentText}}</label>
                                <input class="form-control w-75" type="number" name="appt" formControlName="concurrentes"
                                    [(ngModel)]="angendaExcep.concurrentes">
                                <span *ngIf="( exceptionForm.get('concurrentes').touched &&               
                            exceptionForm.get('concurrentes').errors != null   )" class="validation-span red-clr">
                                    requerida </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 col-xl-12 col-lg-12 col-sm-12">
                            <div class="form-group">
                                <label class="mb-0">Notas :</label>
                                <textarea class="form-control" formControlName="notas"
                                    [(ngModel)]="angendaExcep.notas"></textarea>
                                <span *ngIf="( exceptionForm.get('notas').touched &&
                                exceptionForm.get('notas').errors?.required &&
                                exceptionForm.get('notas').errors != null   )" class="is-invalid red-clr">
                                    requerida </span>
                            </div>
                        </div>
                    </div>

                </form>

                <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 text-right mt-2">
                    <button class="btn btn-secondary mr-3" (click)="closeExceptionModal($event)">Cancelar</button>
                    <button class="btn btn-primary" (click)="appceptException($event ,exceptionForm)">Aceptar</button>
                </div>
            </div>

            <div
            id="nav-copiar"
            class="tab-pane p-3 fade"
            role="tabpanel"
            aria-labelledby="nav-copiar-tab"
            >
                <div class="row pl-3 pb-3">
                    <div class="col-md-10 text-left pl-0 pb-3">
                        <h6 id="activity-modal" class="modal-title font-weight-bold">
                            Copiar excepciones desde otro día
                        </h6>
                    </div>
                    <div class="col-md-2"></div>
                </div>
                <form [formGroup]="copyExcepForm">

                    <div class="row">
                        <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12">
                            <div class="form-group d-grid">
                                <label class="mb-0">Día origen</label>
                                <input [owlDateTime]="filterFromDatePicker4" [owlDateTimeTrigger]="filterFromDatePicker4"
                                    type="text" class="form-control form-control-sm date-field"
                                    formControlName="día_origen">
                                <owl-date-time #filterFromDatePicker4 [pickerType]="'calendar'" [firstDayOfWeek]="1">
                                </owl-date-time>
                            </div>
                        </div>
                        <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12">
                            <div class="form-group d-grid">
                                <label class="mb-0">Día destino</label>
                                <input type="text" [formControl]="dia_destino" [readonly]="true" class="form-control-plaintext form-control-sm">
                            </div> 
                        </div>
                        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 text-right mt-2">
                            <button class="btn btn-secondary mr-3" (click)="closeCopyExceptionModal($event)">Cancelar</button>
                            <button class="btn btn-primary" (click)="appceptCopyException($event ,copyExcepForm)">Aceptar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>