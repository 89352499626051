import { Camera,Plus,Minus,ChevronRight,Calendar,Github } from 'angular-feather/icons';

export class FeatherIcons {
    public static icons = {
        Camera,
        Plus,
        Minus,
        ChevronRight,
        Calendar,
        Github

    };
}