import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CommonService } from 'src/app/service/common/common.service';
import { DropdownService } from '../../../service/shared/dropdown.service';
import { SelectOptions } from 'src/app/model/shared/select-options.model';
import { UIService } from 'src/app/service/shared/ui.service';
import { EmpresaTrabajadorSerchComponent } from '../../shared/reusable-component/empresa-trabajador-serch/empresa-trabajador-serch.component';
import { AgendaRequestData } from 'src/app/model/agendar/agenda-request-data';
import { AgendaList } from '../../../model/agendar/agenda-list'
import { FilterTypesRequest } from '../../../model/agendar/filter-request';
import { BookingTypes } from '../../../model/agendar/booking-types';
import { StatTypes } from '../../../model/agendar/stat-types'
import { CenterGroup } from '../../../model/agendar/center-groups'
import { Quarters } from '../../../model/agendar/quarters'
import { AgendaPost } from '../../../model/agendar/agenda-post';
import { AgendaService } from '../agenda-services/agenda-service'
import { AgendaException } from '../../../model/agendar/agenda-exception';
import { WeekSlots } from '../../../model/agendar/week_slots';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Constants } from 'src/app/helpers/constant/constant';

@Component({
  selector: 'app-edit-agendar',
  templateUrl: './edit-agendar.component.html',
  styleUrls: ['./edit-agendar.component.scss'],
})
export class EditAgendarComponent implements OnInit {
  @ViewChild(EmpresaTrabajadorSerchComponent) empresaTrabajadorSerchComponent: EmpresaTrabajadorSerchComponent;
  @Output() searchDataEmit: EventEmitter<any> = new EventEmitter();
  title: string;
  agendaFilteredData: AgendaRequestData;
  personsList: SelectOptions[] = [];
  companyList: SelectOptions[] = [];
  bookTypesList: SelectOptions[] = [];
  statTypesList: SelectOptions[] = [];
  partnerCenterList: SelectOptions[] = [];
  quaterList: SelectOptions[] = [];
  agendaExceptions: AgendaException[] = [];
  weekSlot: WeekSlots[] = [];
  companyId: number;
  personId: number;
  public agendaListItem: AgendaList = new AgendaList();
  isWebVisYes: boolean;
  isWebVisNo: boolean
  isAllCmpYes: boolean;
  isAllCmpNo: boolean;
  agendaListForm: FormGroup;
  public isLoading = false;
  bookingTypes: BookingTypes
  statType: StatTypes
  centerGroup: CenterGroup;
  quarters: Quarters
  agendaPost: AgendaPost;
  agendaID: number;
  centrotxt: string;
  public modelChangeCompy: any[]
  public existsCmpy: any[];
  selectedCompanies: Array<{ id: number, nombre: string }> = [];
  onChangeCompanies: any[];
  newSubcription: any;
  private ngUnsubscribe = new Subject();
  private ngNew: Subscription;
  public radioWebVisible: boolean;
  webCommentName: string;
  webCommentComment: string;
  isRefrehExitData: boolean;
  isWebAllow: boolean;
  isCompanyAllow: boolean;
  tempNombreInWeb: string;
  tempWebComment: string;
  isCompanyEnable: boolean;
  exceptionStatus: boolean;
  private companySubject: Subject<string> = new Subject();
  private centerSubject: Subject<string> = new Subject();

  personsRequestData = {
    page_number: 1,
    page_size: 10,
    incl: null,
    type: 'filter'
  }

  companyRequestData = {
    page_number: 1,
    page_size: 10,
    incl: null,
    type: 'filter'
  }

  partnerGroupData = {
    incl: "",
    page_number: 1,
    page_size: 10,
    type: "filter",
    id_legacy: ""
  }

  constructor(
    private router: Router,
    private commonService: CommonService,
    private dropdownService: DropdownService,
    public _formBuilder: FormBuilder,
    private uiService: UIService,
    public agendaService: AgendaService
  ) {

    this.bookingTypes = new BookingTypes();
    this.statType = new StatTypes()
    this.centerGroup = new CenterGroup();
    this.quarters = new Quarters();
    this.agendaPost = new AgendaPost();
    this.onLoadReservas();
    this.onLoadStatTypes();
    this.onLoadQuarters();

    this.router.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (
          event.id === 1 &&
          event.url === event.urlAfterRedirects
        ) {
          this.getRefershDetail();
        }
      });
  }

  ngOnInit(): void {

    this.setIntialFrom();
    this.ngNew = this.agendaService.agendaDisplayDetail.subscribe((display) => {
      if (display) {
        if (display.id != undefined) {
          if (display.id == 0) {
            this.weekSlot = this.setNewWeekSlot();
            this.agendaExceptions = [];
            this.agendaListForm.patchValue({ web_visibility: false, companies_all: false });
          }
          this.agendaID = display.id;
          this.centrotxt = display.centro_txt;
          this.setLocalStorage("agendaID", this.agendaID);
          this.setLocalStorage("centrotxt", this.centrotxt)
        }
        else {
          this.getRefershDetail();
        }
      }
      else {
        this.agendaID = 0;
        this.centrotxt = "";
        this.agendaListItem.id = this.agendaID;
        this.setLocalStorage("agendaID", this.agendaID);
      }
      this.getRefershDetail();
    });

    this.agendaService.agendaExceptions.pipe(takeUntil(this.ngUnsubscribe)).subscribe((aException) => {
      if (aException) {

        if (aException.exception_slots != undefined && aException.exception_slots.length > 0) {
          this.agendaExceptions = aException.exception_slots;
        }
        if (aException.week_slots != undefined && aException.week_slots.length > 0) {
          this.weekSlot = aException.week_slots;
        }

      }
    });

    this.newSubcription = this.agendaService.agendaNewItem.subscribe((display) => {
      if (display) {
        this.agendaListItem = display;
        this.centrotxt = this.getCashCentro();
        this.getPartnerCenter(this.centrotxt);
        let isWebVisibilty = (this.agendaListItem.web_visibility == undefined || !this.agendaListItem.web_visibility) ? false : this.agendaListItem.web_visibility;
        let isCompanyAll = (this.agendaListItem.companies_all == undefined || !this.agendaListItem.companies_all) ? false : this.agendaListItem.companies_all;

        this.webCommentComment = this.agendaListItem.web_comments;
        this.webCommentName = this.agendaListItem.web_name;
        this.isCompanyAllow = isCompanyAll

        this.agendaListForm.patchValue({ web_visibility: isWebVisibilty, companies_all: isCompanyAll });
        this.agendaListItem.web_comments = this.webCommentComment;
        this.agendaListItem.web_name = this.webCommentName;
      }
    });
    this.agendaService.existsDataRevSubcrib.subscribe((agenda) => {
      if (agenda?.nombre != undefined) {

        this.centrotxt = this.getCashCentro();
        this.getPartnerCenter(this.centrotxt);

        this.agendaListItem = agenda;
        this.webCommentName = this.agendaListItem.web_name;
        this.webCommentComment = this.agendaListItem.web_comments;

        this.isCompanyAllow = this.agendaListItem.companies_all;
        this.existsCmpy = this.loadExistCompany(this.agendaListItem.companies_allowed)
        this.isRefrehExitData = true;
        this.isWebAllow = this.agendaListItem.web_visibility;
        this.isLoading = true;
        this.getAgendaData();
      }
      else {
        this.isRefrehExitData = false;
        this.isLoading = true;
        this.getAgendaData();
      }

    });

    this.setFormWebVisibility();
    this.setFromCompany();
    this.setFromCompanyDetails();
    this.getPartnerCenter();


    this.companySubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe(companyName => this.loadTopMatchingCompanies(companyName));


    this.centerSubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe(centerName => this.loadTopMatchingPartner(centerName));

  }

  getRefershDetail() {
    let jAgendaid = this.getLocalStorage("agendaID");
    let jCentrotxt = this.getLocalStorage("centrotxt");
    if (jAgendaid != undefined) {
      this.agendaID = jAgendaid;
      if (jCentrotxt != undefined && jCentrotxt != "") {
        this.centrotxt = jCentrotxt;
      }
      this.agendaListItem.id = jAgendaid;
      this.getAgendaData();
    }
  }

  setIntialFrom() {
    this.agendaListForm = this._formBuilder.group({
      nombre: ["", [Validators.required]],
      active: [""],
      tipo_accion_id: ["", [Validators.required]],
      center_group_id: ["", Validators.required],
      delegacion_id: ["", [Validators.required]],
      tipo_estadistico_id: ["", [Validators.required]],
      web_visibility: [""],
      web_name: [""],
      web_comments: [""],
      minutes: [""],
      comments: [""],
      companies_all: [""],
      companies_allowed: [""]

    });
    this.agendaListForm.get('web_name').disable();
    this.agendaListForm.get('web_comments').disable();
    this.agendaListForm.get('companies_allowed').enable();
    this.agendaListItem = new AgendaList();
  }

  setFormWebVisibility() {
    this.agendaListForm.get("web_visibility").valueChanges.subscribe(value => {
      if (value) {
        this.tempNombreInWeb = this.agendaListForm.get('web_name').value;
        this.tempWebComment = this.agendaListForm.get('web_comments').value;
        this.agendaListForm.get('web_name').reset();
        this.agendaListForm.get('web_comments').reset();
        this.agendaListForm.get('web_name').setValidators(Validators.required);
        this.agendaListForm.get('web_name').updateValueAndValidity();
        this.agendaListForm.get('web_comments').setValidators(Validators.required);
        this.agendaListForm.get('web_comments').updateValueAndValidity();
        this.agendaListItem.web_comments = this.tempWebComment;
        this.agendaListItem.web_name = this.tempNombreInWeb;
        this.agendaListForm.get('web_name').setValue(this.tempNombreInWeb);
        this.agendaListForm.get('web_comments').setValue(this.tempWebComment);
        this.agendaListForm.get('web_name').enable();
        this.agendaListForm.get('web_comments').enable();
      }
      else {
        this.tempNombreInWeb = this.agendaListForm.get('web_name').value;
        this.tempWebComment = this.agendaListForm.get('web_comments').value;
        this.agendaListForm.get('web_name').reset();
        this.agendaListForm.get('web_comments').reset();
        this.agendaListForm.get('web_name').clearValidators();
        this.agendaListForm.get('web_name').updateValueAndValidity();
        this.agendaListForm.get('web_comments').clearValidators();
        this.agendaListForm.get('web_comments').updateValueAndValidity();
        this.agendaListItem.web_comments = this.tempWebComment;
        this.agendaListItem.web_name = this.tempNombreInWeb;
        this.agendaListForm.get('web_name').setValue(this.tempNombreInWeb);
        this.agendaListForm.get('web_comments').setValue(this.tempWebComment);
        this.agendaListForm.get('web_name').disable();
        this.agendaListForm.get('web_comments').disable();
      }
    });
  }
  setFromCompany() {
    this.agendaListForm.get("companies_all").valueChanges.subscribe(value => {
      this.agendaListItem.companies_all = value;
      let exitItem = this.agendaListForm.get('companies_allowed').value;
      if (value) {        
        let tempCompanies = this.setCompanyFormReset();
        this.agendaListForm.get('companies_allowed').reset();
        this.agendaListForm.get('companies_allowed').clearValidators();
        this.agendaListForm.get('companies_allowed').updateValueAndValidity();
        this.agendaListForm.get('companies_allowed').disable();
        if ((exitItem == null || exitItem == undefined) || exitItem.length > 0) {
          this.existsCmpy = this.loadExistCompany(tempCompanies)
          this.companyList = this.dropdownService.WrappedToDropDownList(this.existsCmpy, 'nombre', 'id');
          this.modelChangeCompy = this.loadExitCompanyID(this.existsCmpy);
        }
      }
      else {
        let tempCompanies = this.setCompanyFormReset();
        this.agendaListForm.get('companies_allowed').reset();
        this.agendaListForm.get('companies_allowed').setValidators(Validators.required);
        this.agendaListForm.get('companies_allowed').updateValueAndValidity();
        this.agendaListForm.get('companies_allowed').enable();

        if ((exitItem == null || exitItem == undefined) || exitItem.length > 0) {
          this.existsCmpy = this.loadExistCompany(tempCompanies)
          this.companyList = this.dropdownService.WrappedToDropDownList(this.existsCmpy, 'nombre', 'id');
          this.modelChangeCompy = this.loadExitCompanyID(this.existsCmpy);
        }
      }
    });
  }

  setFromCompanyDetails() {
    if (this.webCommentName != undefined && this.webCommentName != "") {
      this.agendaListItem.web_name = this.webCommentName;
    }
    if (this.webCommentComment != undefined && this.webCommentComment != "") {
      this.agendaListItem.web_comments = this.webCommentComment;
    }
  }

  getCashCentro(): string {
    if (this.centrotxt == undefined || this.centrotxt == "") {
      return this.getLocalStorage("centrotxt")
    }
    else {
      return this.centrotxt;
    }
  }

  getAgendaData() {
    if (this.agendaID > 0) {
      this.title = "EDITAR AGENDA";

      const agendaData = {
        agenda_id: this.agendaID
      }

      if (this.isRefrehExitData != undefined && this.isRefrehExitData) {
        const isCompanyAll = this.agendaListItem?.companies_all || false;
        const isWebComment = this.agendaListItem?.web_visibility || false;
        this.agendaListForm.patchValue({ web_visibility: isWebComment, companies_all: isCompanyAll });
        this.updateAgendaListItem();
        this.companyList = this.dropdownService.WrappedToDropDownList(this.existsCmpy, 'nombre', 'id');
        this.modelChangeCompy = this.loadExitCompanyID(this.existsCmpy);
        return;
      }

      this.commonService.getAgendaData(agendaData).subscribe(res => {
        this.isLoading = false;
        if (res) {
          this.agendaListItem = res.data;
          localStorage.setItem('listAgendaName', this.agendaListItem.nombre)
          this.webCommentComment = this.agendaListItem.web_comments;
          this.webCommentName = this.agendaListItem.web_name;
          this.isWebAllow = this.agendaListItem.web_visibility
          this.centrotxt = this.getCashCentro();
          this.getPartnerCenterEdit(res.data.center_group_id);
          this.isWebVisYes = this.agendaListItem.web_visibility;
          this.isWebVisNo = !this.agendaListItem.web_visibility;

          this.updateAgendaListForm();
          this.updateCompanyList();
          this.updateAgendaListItem();
        }
      }, _error => {
        this.isLoading = false;
      });
    }
    else {
      this.isLoading = false;
      this.title = "Editar Nueva Agenda";
      this.agendaListForm.patchValue({ web_visibility: false, companies_all: false });
    }
  }

  private updateAgendaListForm() {
    const isCompanyAll = this.agendaListItem?.companies_all || false;
    this.agendaListForm.patchValue({ web_visibility: this.isWebVisYes, companies_all: isCompanyAll });
  }
  
  private updateCompanyList() {
    if (this.agendaListItem.companies_allowed && this.agendaListItem.companies_allowed.length > 0) {
      this.existsCmpy = this.loadExistCompany(this.agendaListItem.companies_allowed)
      this.companyList = this.dropdownService.WrappedToDropDownList(this.existsCmpy, 'nombre', 'id');
      this.modelChangeCompy = this.loadExitCompanyID(this.existsCmpy);
    }
  }

  private updateAgendaListItem() {
    this.agendaListItem.web_comments = this.webCommentComment;
    this.agendaListItem.web_name = this.webCommentName;
    this.agendaListItem.web_visibility = this.isWebAllow;
    this.agendaListItem.companies_all = this.isCompanyAllow;
  }

  setLocalStorage(storName: string, storObect: any) {
    let data = localStorage.getItem(storName);
    if (data != undefined) {
      localStorage.removeItem(storName);
    }
    localStorage.setItem(storName, storObect);
  }

  getLocalStorage(storName: string): any {
    return localStorage.getItem(storName);
  }

  loadExitCompanyID(item: any): any[] {
    let outputArray = [];

    for (let element in item) {
      outputArray.push(
        item[element].id
      );
    }
    return outputArray;
  }
  loadExistCompany(item: any): any[] {
    let outputArray = [];
    for (let element in item) {
      outputArray.push({
        id: item[element].id,
        nombre: item[element].nombre
      });
    }
    return outputArray;
  }

  setCompanyFormReset(): any[] {
    let setCompanies = [];
    const companiesToCheck = this.onChangeCompanies || this.existsCmpy;

    if (companiesToCheck && companiesToCheck.length > 0) {
      for (let element of companiesToCheck) {
        let itemIndex = setCompanies.findIndex(item => item.id == Number(element.value));
        if (itemIndex == -1 || itemIndex == undefined) {
          setCompanies.push({ id: Number(element.value), nombre: element.label });
        }
      }
    }
    return setCompanies;
  }


  lastWord(words): string {
    let n = words.replace(/[\[\]?.,\/#!$%\^&\*;:{}=\\|_~()]/g, "").split(" ");
    return n[n.length - 1];
  }
  onCancel() {
    this.agendaService.updateExceptionStatus(false);
    this.agendaService.removeAgendaException();
    this.removeLocalStorage("agendaID");
    this.removeLocalStorage("centrotxt")
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.agendaListForm.reset();
    this.weekSlot = this.setNewWeekSlot();
    this.agendaExceptions = [];
    this.router.navigate(['/agendar-list/view']);
  }
  onSelectSchedule() {
    if (this.centrotxt != "") {
      this.setLocalStorage("centrotxt", this.centrotxt);
    }
    if (this.isWebVisYes) {
      this.agendaListItem.web_visibility = true;
    }
    else {
      this.agendaListItem.web_visibility = false;
    }
    if (this.agendaID == 0) {
      this.weekSlot = this.setNewWeekSlot();
      this.agendaExceptions = [];
      this.agendaListItem.week_slots = this.weekSlot;
      this.agendaListItem.exception_slots = this.agendaExceptions;
      this.agendaService.UpdateAgendaSheduleDetail(this.agendaListItem);
      this.agendaService.setNewAgendaDetail(this.agendaListItem);
    }
    else {
      this.agendaService.setNewAgendaDetail(null);
      this.agendaService.UpdateAgendaSheduleDetail(this.agendaListItem);
      this.agendaService.updateExistsData(this.agendaListItem);
    }
    this.router.navigate(['/agendar-list/schedule']);
  }

  setCompanySearchData(data: any) {
    this.agendaFilteredData.empresa_id = Number(data.companyId);
    this.agendaFilteredData.persona_id = Number(data.personId);
  }

  
  onCloseCompanies() {

    this.getCompanyList();
    this.companyId = null;
    this.personId = null;
    this.searchDataEmit.emit({
      companyId: this.companyId,
      personId: this.personId
    });
    this.commonService.companyIdSubject.next(null);
  }
  onChangeCompany(company: any) {

    let x = this.existsCmpy;
    this.personId = null;
    this.searchDataEmit.emit({
      companyId: this.companyId,
      personId: this.personId
    });
    if (company) {
      this.commonService.companyIdSubject.next(this.companyId);
      this.onChangeCompanies = company;
    } else {
      this.commonService.companyIdSubject.next(null);
    }

  }
  loadTopMatchingCompanies(companyName: any) {
    if (companyName.length >= 4) {
      this.getCompanyList(companyName);
    } else {
      if (companyName.length == 0) {
        this.getCompanyList();
      }
    }
  }
  onKeyUpCompany(companyNameTarget) {
    this.companySubject.next(companyNameTarget?.target?.value);
  }
  onScrollCompany() {
    this.getCompanyList('', true);
  }

  getCompanyList(incl?: string, isScroll = false) {
    this.isLoading = true;
    this.companyRequestData.incl = incl ? incl : null;
    this.companyRequestData.page_number = isScroll ? (this.companyRequestData.page_number + 1) : 1;
    this.commonService.getCompanyList(this.companyRequestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        if (isScroll) {
          this.companyList = this.companyList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id'));
        } else {
          this.companyList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');
        }
      }
    }, _error => {
      this.isLoading = false;
    });
  }

  onChangeWebVisiYes(value: any) {

    if (value.target.checked) {
      this.agendaListItem.web_visibility = true;
      this.isWebVisNo = false;
      this.isWebVisYes = true;

    }
    else {
      this.agendaListItem.web_visibility = false;
      this.isWebVisNo = true;
      this.isWebVisYes = false;

    }
  }
  onChangeWebVisiNo(value: any) {

    if (value.target.checked) {
      this.agendaListItem.web_visibility = false;
      this.isWebVisNo = true;
      this.isWebVisYes = false;
    }
    else {
      this.agendaListItem.web_visibility = true;
      this.isWebVisNo = false;
      this.isWebVisYes = true;
    }
  }

  onChangeEmpresasYes(value: any) {

    if (value.target.checked) {
      this.agendaListItem.companies_all = true;
    }
    else {
      this.agendaListItem.companies_all = false;
    }
  }

  onChangeEmpresasNo(value: any) {

    if (value.target.checked) {
      this.agendaListItem.companies_all = false;
    }
    else {
      this.agendaListItem.companies_all = true;
    }
  }


  // Loading Reservas (booking types)
  onLoadReservas() {
    let bookRequest = new FilterTypesRequest();
    bookRequest.type = null;
    this.commonService.getBookingType(bookRequest).subscribe(res => {
      if (res) {
        this.bookTypesList = this.dropdownService.WrappedToDropDownList(res.data, 'abreviatura', 'id');
      }
    });
  }

  onChangeBookTypes(bookTypes: any) {
    if (bookTypes) {
      this.bookingTypes.id = Number(bookTypes.value);
    } else {
      this.bookingTypes.id = 0;
    }

  }

  onLoadStatTypes() {
    let bookRequest = new FilterTypesRequest();
    bookRequest.type = "filter";
    this.commonService.getStatType(bookRequest).subscribe(res => {
      if (res) {
        this.statTypesList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');
      }
    });
  }

  onChangeStatTypes(statVale: any) {
    if (statVale) {
      this.statType.id = Number(statVale.value);
    } else {
      this.statType.id = 0;
    }
  }
  
  getPartnerCenter(incl?: string, isScroll = false) {
    this.partnerGroupData.id_legacy = "";
    this.isLoading = true;
    this.partnerGroupData.incl = incl ? incl : null;
    this.partnerGroupData.page_number = isScroll ? (this.partnerGroupData.page_number + 1) : 1;
    this.commonService.getPartnerGroup(this.partnerGroupData).subscribe(res => {
      this.isLoading = false;
      this.setEnablityCompany();
      if (res) {
        if (isScroll) {
          this.partnerCenterList = this.partnerCenterList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id'));
        } else {
          this.partnerCenterList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');
        }
      }
    }, _error => {
      this.isLoading = false;
      this.setEnablityCompany();
    });
    this.setEnablityCompany();
  }
  getPartnerCenterEdit(id_legacy?: string, isScroll = false) {

    this.isLoading = true;
    this.partnerGroupData.incl = null;
    this.partnerGroupData.page_number = isScroll ? (this.partnerGroupData.page_number + 1) : 1;
    this.partnerGroupData.id_legacy = id_legacy;
    this.commonService.getPartnerGroup(this.partnerGroupData).subscribe(result => {
      this.isLoading = false;
      this.setEnablityCompany();
      if (result) {
        if (isScroll) {
          this.partnerCenterList = this.partnerCenterList.concat(this.dropdownService.WrappedToDropDownList(result.data, 'nombre', 'id'));
        } else {
          this.partnerCenterList = this.dropdownService.WrappedToDropDownList(result.data, 'nombre', 'id');
        }
      }
    }, _error => {
      this.isLoading = false;
      this.setEnablityCompany();
    });
    this.setEnablityCompany();
  }

  setEnablityCompany() {
    this.agendaListForm.get("companies_all").valueChanges.subscribe(value => {
      if (value) {
        this.agendaListForm.get('companies_allowed').disable();
      }
      else {
        this.agendaListForm.get('companies_allowed').enable();
      }
      this.isCompanyEnable = value;
    });
  }
  onChangePartnerCenter(centeChg: any) {
    if (centeChg) {
      this.centerGroup.id = centeChg.value;
    } else {
      this.centerGroup.id = "";
    }
  }
  onScrollPartnerCenter() {
    this.getPartnerCenter(this.centerGroup.id, true);
  }
  loadTopMatchingPartner(partnerTarget: any) {
    if (partnerTarget && partnerTarget.length >= 4) {
      this.getPartnerCenter(partnerTarget);
    } else {
      if (partnerTarget.length == 0) {
        this.getPartnerCenter();
      }
    }
  }

  onKeyUpCenter(centerTarget) {
    this.centerSubject.next(centerTarget?.target?.value);
  }
  onClosePartnerCenter() {
    this.getPartnerCenter();
  }

  onLoadQuarters() {
    let bookRequest = new FilterTypesRequest();
    bookRequest.type = null;
    this.commonService.getQuarters(bookRequest).subscribe(res => {
      if (res) {
        this.quaterList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');
      }
    });
  }

  onChangeQuarters(quaType: any) {
    if (quaType) {
      this.quarters.id = Number(quaType.value);
    } else {
      this.quarters.id = 0;
    }
  }
 
  
  onSaveEditDetail(event: Event, form) {
    if (event || form) {
      if (this.agendaListForm.invalid) {
        Object.keys(this.agendaListForm.controls).forEach(field => {
          const control = this.agendaListForm.get(field);
          control.markAsDirty({ onlySelf: true });
          control.markAsTouched({ onlySelf: true });
        });
        return;
      }
    }

    this.exceptionStatus = this.agendaService.getExceptionStatus();
    this.isLoading = true;
    this.agendaPost.exception_slots = this.agendaListItem.exception_slots;

    if (!this.exceptionStatus) {
      this.agendaService.updateAgendaException(this.agendaPost);
    } else {
      this.agendaListItem.exception_slots = [];
    }

    if (this.agendaExceptions && this.agendaExceptions.length > 0) {
      this.agendaListItem.exception_slots = this.getExceptionArray(this.agendaExceptions);
    }

    if (this.weekSlot && this.weekSlot.length > 0) {
      this.agendaListItem.week_slots = this.getWeekSlot(this.weekSlot, this.agendaListItem.week_slots);
    }

    this.selectedCompanies = [];

    if (this.onChangeCompanies != undefined && this.onChangeCompanies.length > 0) {
      for (let element of this.onChangeCompanies) {
        let itemIndex = this.selectedCompanies.findIndex(item => item.id == Number(element.value));
        if (itemIndex == -1 || itemIndex == undefined) {
          this.selectedCompanies.push({ id: Number(element.value), nombre: element.label });
        }
      }
    }

    if (this.existsCmpy != undefined && this.onChangeCompanies == undefined) {
      for (let element of this.existsCmpy) {
        let itemIndex = this.selectedCompanies.findIndex(item => item.id == Number(element.id));
        if (itemIndex == -1 || itemIndex == undefined) {
          this.selectedCompanies.push({ id: Number(element.id), nombre: element.nombre });
        }
      }
    }

    this.agendaListItem.eliminado = false;
    this.agendaListItem.companies_allowed = this.selectedCompanies;

    if (this.agendaID == 0) {
      this.agendaListItem.id = null;
    }
    this.commonService.postAgenda(this.agendaListItem).subscribe(res => {
      if (res && res?.webError?.number == 0) {
        this.uiService.showFailToast("Error", res?.webError?.messageToUser);
      }
      else if (res && res?.data != null) {
        this.uiService.showSuccessToast("Guardado con éxito");
        this.agendaListItem = null;
        this.agendaService.updateExceptionStatus(false);
        this.agendaService.removeAgendaException();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.agendaListForm.reset();
        this.router.navigate(['/agendar-list/view']);
      }
      this.isLoading = false;
    }, _error => {
      console.log(_error);
      this.uiService.showFailToast("Error", _error);
      this.isLoading = false;
    });
  }

  setNewWeekSlot(): any[] {
    let outputArray = [];
    for (let i = 1; i < 8; i++) {
      outputArray.push({
        num_dia: i,
        slot_desde: "00.00",
        slot_hasta: "23:59",
        concurrentes: 0,
        total_concurrentes: 0,
        notas: ""
      });
    }
    return outputArray
  }

  getWeekSlot(slot: WeekSlots[], _oldSlot: WeekSlots[]): any[] {
    let outputArray = [];

    for (let element in slot) {
      if (slot[element].concurrentes > 0) {
        outputArray.push({
          num_dia: slot[element].num_dia,
          slot_desde: slot[element].slot_desde,
          slot_hasta: slot[element].slot_hasta,
          concurrentes: slot[element].concurrentes,
          total_concurrentes: slot[element].concurrentes,
          notas: slot[element].notas
        });
      }
    }
    return outputArray;
  }

  getExceptionArray(exceptionsValue: AgendaException[]): any[] {

    let outputArray = [];
    for (let element in exceptionsValue) {
      outputArray.push({
        fecha_desde: this.uiService.dateToSpanishCustome(exceptionsValue[element].fecha_desde),
        hora_desde: exceptionsValue[element].hora_desde,
        fecha_hasta: this.uiService.dateToSpanishCustome(exceptionsValue[element].fecha_hasta),
        hora_hasta: exceptionsValue[element].hora_hasta,
        concurrentes: exceptionsValue[element].concurrentes,
        notas: exceptionsValue[element].notas
      });
    }
    return outputArray;
  }

  removeLocalStorage(storName: string) {
    let data = localStorage.getItem(storName);
    if (data != undefined) {
      localStorage.removeItem(storName);
    }
  }

}
