<div class="modal-body">
    <div class="row pl-3 pb-3">
        <div class="col-md-10 text-left pl-0"></div>
        <div class="col-md-2">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeTimeFrameModal($event)">
                <span aria-hidden="true">×</span>
            </button>
        </div>
    </div>
    <form [formGroup]="timeSlotForm">
    <div class="row">
        <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12">
            <div class="form-group d-grid">
                <label class="mb-0">Hora Incio</label>
                <input class="time-input" type="time" name="appt"  formControlName ="slot_desde" [(ngModel)]="weekTimeSlot.slot_desde">

                <span *ngIf="( timeSlotForm.get('slot_desde').touched &&
                timeSlotForm.get('slot_desde').errors?.required &&
                timeSlotForm.get('slot_desde').errors != null   )" class="validation-span red-clr">
                        requerida </span>
            </div>
        </div>
        <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12">
            <div class="form-group d-grid">
                <label class="mb-0">Hora Fin</label>
                <input class="time-input" type="time"  name="appt" formControlName ="slot_hasta" [(ngModel)]="weekTimeSlot.slot_hasta">
                <span *ngIf="( timeSlotForm.get('slot_hasta').touched &&
                timeSlotForm.get('slot_hasta').errors?.required &&
                timeSlotForm.get('slot_hasta').errors != null   )" class="c-span red-clr">
                        requerida </span>
            </div>
        </div>
        <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
            <div class="form-group d-grid">
                <label class="mb-0">{{concurrentText }}</label>
                <input class="form-control w-50" type="number" name="appt"  formControlName ="concurrentes" [(ngModel)]="weekTimeSlot.concurrentes">
                <span *ngIf="( timeSlotForm.get('concurrentes').touched &&               
                timeSlotForm.get('concurrentes').errors != null   )" class="validation-span red-clr">
                        requerida </span>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-xl-12 col-lg-12 col-sm-12">
            <div class="form-group">
                <label class="mb-0">Notas :</label>
                <textarea class="form-control" formControlName ="notas" [(ngModel)]="weekTimeSlot.notas"></textarea>
                <span *ngIf="( timeSlotForm.get('notas').touched &&
                timeSlotForm.get('notas').errors?.required &&
                timeSlotForm.get('notas').errors != null   )" class="validation-span red-clr">
                        requerida </span>
            </div>
        </div>
    </div>
</form>
    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 text-right mt-2">
        <button class="btn btn-secondary mr-3"  (click)="closeTimeFrameModal($event)">Cancelar</button>
        <button class="btn btn-danger mr-3" (click)="removeTimeFrame($event)">Eliminar</button>
        <button class="btn btn-primary" (click)="aceptTimeFame($event ,timeSlotForm)">Aceptor</button>
    </div>

</div>