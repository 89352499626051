import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as Response from "src/app/model/agendar/agenda";
import { SelectOptions } from 'src/app/model/shared/select-options.model';
import { CommonUiService, Delay } from 'src/app/service/common/common-ui.service';
import { CommonService } from 'src/app/service/common/common.service';
import { DropdownService } from 'src/app/service/shared/dropdown.service';
import { UIService } from 'src/app/service/shared/ui.service';

@Component({
  selector: 'app-covid-screen',
  templateUrl: './covid-screen.component.html',
  styleUrls: ['./covid-screen.component.scss']
})
export class CovidScreenComponent implements OnInit {
  pcr: boolean = false;
  trag: boolean = false;
  sero: boolean = false;
  otro: boolean = false;
  testType: string = '';
  appointmentEditData: Response.Agenda;
  isLoading = false;
  PCRProveedorList: SelectOptions[] = [];
  TRAGProveedorList: SelectOptions[] = [];
  SEROProveedorList: SelectOptions[] = [];
  PCRDiagnosticsList: SelectOptions[] = [];
  TRAGDiagnosticsList: SelectOptions[] = [];
  SERODiagnosticsList: SelectOptions[] = [];
  fecha: string = "";
  tragPrintObj = { isPrint: false, buttonText: '', url: '' };
  covidForm = new FormGroup({
    pcr_proveedor_id: new FormControl(null),
    pcr_resultado: new FormControl(null),
    pcr_diagnostico_id: new FormControl(null),
    pcr_lote: new FormControl(null),
    sero_diagnostico_id: new FormControl(null),
    sero_proveedor_id: new FormControl(null),
    sero_lote: new FormControl(null),
    igG: new FormControl(null),
    igM: new FormControl(null),
    igA: new FormControl(null),
    igS1: new FormControl(null),
    trag_proveedor_id: new FormControl(null),
    trag_lote: new FormControl(null),
    trag_diagnostico_id: new FormControl(null),
    description: new FormControl(null),
    observaciones: new FormControl(null),
  });

  submitted = false;
  constructor(
    private _route: ActivatedRoute,
    private _visualizerCitarService: CommonService,
    private dropdownService: DropdownService,
    public uiService: UIService,
    public commonUiService: CommonUiService
  ) {
    this.appointmentEditData = new Response.Agenda();
    this.getProveedors('PCR');
    this.getProveedors('TRAG');
    this.getProveedors('SERO');
    this.getDiagnostics('PCR');
    this.getDiagnostics('TRAG');
    this.getDiagnostics('SERO');
  }

  ngOnInit(): void {
    this._route.queryParams.subscribe(async params => {
      if (params['id'] && params['accion_tipo_id']) {
        this.getAppointments(Number(params['id']), Number(params['accion_tipo_id']));
      }
    });
  }

  getAppointments(agendaId: number, accion_tipo_id: number) {
    this.isLoading = true;
    const requestData = {
      id: agendaId,
      accion_tipo_id: accion_tipo_id
    }
    this._visualizerCitarService.getAppointments(requestData).subscribe(res => {
      if (res) {
        this.appointmentEditData = res.data;
        this.PrepareBindingData();
        this.isLoading = false;
      }
    }, error => {
      console.log(error);
      this.isLoading = false;
    });
  }

  PrepareBindingData() {
    const attribs = this.appointmentEditData.attribs;
    const persona = this.appointmentEditData.persona;    
  
    if (attribs && attribs.fecha.length === 8) {
      const fecha = attribs.fecha;
      const day = fecha.substring(6, 8);
      const month = fecha.substring(4, 6);
      const year = fecha.substring(0, 4);
  
      if (day && month && year) {
        this.fecha = `${day}/${month}/${year}`;
      }
    }
  
    if (attribs && attribs.hora.trim().length === 4 || attribs.hora.trim().length === 6) {
      const hora = attribs.hora;
      const h = hora.substring(0, 2);
      const m = hora.substring(2, 2);
    }
  
    if (persona && persona.birth_date.length === 8) {
      const birth_date = persona.birth_date;
      const day = birth_date.substring(6, 8);
      const month = birth_date.substring(4, 6);
      const year = birth_date.substring(0, 4);
  
      if (day && month && year) {
        persona.birth_date = `${day}/${month}/${year}`;
      }
    }
  
    for (const obj of this.appointmentEditData.sections) {
      if (obj.type === "PCR") {
        const data = obj.data;
        this.covidForm.controls.pcr_resultado.setValidators(Validators.required);
        this.covidForm.controls.pcr_diagnostico_id.setValidators(Validators.required);
  
        this.covidForm.patchValue({
          pcr_proveedor_id: data.pcr_proveedor_id,
          pcr_resultado: data.pcr_resultado,
          pcr_diagnostico_id: data.pcr_diagnostico_id,
          pcr_lote: data.lote
        });
  
        this.pcr = true;
      } else if (obj.type === "SERO") {
        const data = obj.data;
        this.covidForm.controls.sero_diagnostico_id.setValidators(Validators.required);
        this.covidForm.controls.sero_proveedor_id.setValidators(Validators.required);
        this.covidForm.controls.igG.setValidators(Validators.required);
        this.covidForm.controls.igM.setValidators(Validators.required);
        this.covidForm.controls.igA.setValidators(Validators.required);
        this.covidForm.controls.igS1.setValidators(Validators.required);
  
        this.covidForm.patchValue({
          sero_diagnostico_id: data.sero_diagnostico_id,
          sero_proveedor_id: data.sero_proveedor_id,
          sero_lote: data.lote,
          igG: data.igG,
          igM: data.igM,
          igA: data.igA,
          igS1: data.igS1
        });
  
        this.sero = true;
      }  else if (obj.type == "TRAG") {
        const data = obj.data;
        this.covidForm.controls.trag_proveedor_id.setValidators(Validators.required);
        this.covidForm.controls.trag_diagnostico_id.setValidators(Validators.required);
  
        this.covidForm.patchValue({
          trag_proveedor_id: data.trag_proveedor_id,
          trag_diagnostico_id: data.trag_diagnostico_id,
          trag_lote: data.lote
        });

        if (data.trag_print_external_caption_button != null && data.trag_print_external_url != null) {
          this.tragPrintObj.isPrint = true;
          this.tragPrintObj.url = data.trag_print_external_url;
          this.tragPrintObj.buttonText = data.trag_print_external_caption_button;
        }
        this.trag = true;
      }
    }
    this.covidForm.controls.observaciones.setValue(this.appointmentEditData.attribs.observaciones);
    this.covidForm.updateValueAndValidity();
  }

  getProveedors(incl) {
    const requestData = { incl: incl, type: null };
    this._visualizerCitarService.getProviders(requestData).subscribe(res => {
      if (res) {

        switch (incl) {
          case 'PCR':
            this.PCRProveedorList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');
            break;
          case 'TRAG':
            this.TRAGProveedorList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');
            break;
          case 'SERO':
            this.SEROProveedorList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');
            break;
          default:
            break;
        }
      }
    }, error => {
      console.log(error);
    });
  }

  getDiagnostics(incl) {
    const requestData = { incl: incl, type: null };
    this._visualizerCitarService.getDiagnostics(requestData).subscribe(res => {
      if (res) {
        switch (incl) {
          case 'PCR':
            this.PCRDiagnosticsList = this.dropdownService.WrappedToDropDownList(res.data, 'descripcion', 'id');
            break;
          case 'TRAG':
            this.TRAGDiagnosticsList = this.dropdownService.WrappedToDropDownList(res.data, 'descripcion', 'id');
            break;
          case 'SERO':
            this.SERODiagnosticsList = this.dropdownService.WrappedToDropDownList(res.data, 'descripcion', 'id');
            break;
          default:
            break;
        }
      }
    }, error => {
      console.log(error);
    });
  }

  preparePostData() {
    const postData: any = this.appointmentEditData;
    if (postData?.sections?.length > 0) {
      const formValues = this.covidForm.value;
      for (let i = 0; i < postData?.sections.length; i++) {
        const obj: any = postData.sections[i];
        if (obj?.type == "PCR") {
          postData.sections[i].data.pcr_proveedor_id = formValues.pcr_proveedor_id;
          postData.sections[i].data.pcr_resultado = formValues.pcr_resultado;
          postData.sections[i].data.pcr_diagnostico_id = formValues.pcr_diagnostico_id;
          postData.sections[i].data.lote = formValues.pcr_lote;
        }
        else if (obj?.type == "SERO") {
          postData.sections[i].data.sero_diagnostico_id = formValues.sero_diagnostico_id;
          postData.sections[i].data.sero_proveedor_id = formValues.sero_proveedor_id;
          postData.sections[i].data.lote = formValues.sero_lote;
          postData.sections[i].data.igG = formValues.igG;
          postData.sections[i].data.igM = formValues.igM;
          postData.sections[i].data.igA = formValues.igA;
          postData.sections[i].data.igS1 = formValues.igS1;

        }
        else if (obj?.type == "TRAG") {
          postData.sections[i].data.trag_proveedor_id = formValues.trag_proveedor_id;
          postData.sections[i].data.trag_diagnostico_id = formValues.trag_diagnostico_id;
          postData.sections[i].data.lote = formValues.trag_lote;
        }
      }
    }
    postData.attribs.observaciones = this.covidForm.controls.observaciones.value;
    return postData;
  }

  saveCovidDetails(close = false) {
    this.submitted = true;
    const postData = this.preparePostData();
    if (this.covidForm.valid) {
      this.isLoading = true;
      postData['intention_id'] = 'btnSave';
      this._visualizerCitarService.postAppointment(postData).subscribe(res => {
        if (res && res?.webError?.number == 0) {
          this.uiService.showFailToast("Error", res?.webError?.messageToUser);
        }
        else if (res && res?.data != null) {
          this.uiService.showSuccessToast("Guardado con éxito");
          if (close) {
            setTimeout(function () {
              localStorage.setItem('CovidScreenUpdated', "1");
              window.close();
            }, 1500)
          }
        }
        this.submitted = false;
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
        if (error && error?.webError?.number == 0) {
          this.uiService.showFailToast("Error", error?.webError?.messageToUser);
        }
        this.submitted = false;
      });
    }
  }

  close() {
    window.close();
  }
  async onPrint(section, type) {
    this.testType = type;
    await Delay(300);
    this.commonUiService.PR05A57_RAPID_ANT_CAS(section, type);
  }

  tragExternalPrint() {
    if (this.tragPrintObj.isPrint) {
      window.open(this.tragPrintObj.url);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(_event) {
    localStorage.setItem('CovidScreenUpdated', "1");
  }

}
