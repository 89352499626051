<div id="visualizer-zitar-report-all" class="m-t-20 m-l-15" [hidden]="true">
  <div id="" *ngFor="let item of allAppointmentData" class="m-t-20 m-l-15">
    <div class="row p-l-30 m-b-10">

      <div class="col-md-3">
        <label class="mb-0 ml-1 font-weight-bold">Hora de llegada : </label>
      </div>
      <div class="col-md-3">
        <label class="mb-0 font-weight-bold">Hora de Salida : </label>
      </div>
      <div class="col-md-1">
        <label class="mb-0 font-weight-bold">I : </label>
      </div>
      <div class="col-md-1">
        <label class="mb-0 font-weight-bold">M : </label>
      </div>
      <div class="col-md-4"></div>
    </div>
    <!-- header table -->
    <table class="table vis-cita-rep-header-tbl">
      <tbody>
        <tr>
          <td class="txt-bold text-center">GESEME</td>
          <td class="txt-bold text-center">
            Recogida datos Reconocimientos médicos
          </td>
          <td class="txt-bold text-center">
            <span>PR 05 -A7</span>
            <span>Rev. 2</span>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div class="row p-l-10">
              <div class="col-md-6">
                <label class="txt-bold">EMPRESA : {{ item.data.attribs?.empresa_txt }}</label>
              </div>
              <div class="col-md-6">
                <label class="txt-bold">CT : {{ item.data.attribs?.centro_txt }}</label>
              </div>
            </div>
            <div class="row p-l-10">
              <div class="col-md-6">
                <label class="txt-bold">NOMBRE : {{ item.data.persona?.nombre }} {{ item.data.persona?.apellidos
                  }}</label>
              </div>
              <div class="col-md-6">
                <label class="txt-bold">PT : {{ item.data.attribs?.puestovs_txt }}</label>
              </div>
            </div>
            <div class="row p-l-10">
              <div class="col-md-6">
                <label class="txt-bold">FECHA VISITA :
                  {{
                  convertDateToReadableFormat(item.data.attribs?.fecha)
                  }}</label>
              </div>
              <div class="col-md-6">
                <label class="txt-bold">NIF : {{ item.data.persona?.dni }}</label>
              </div>
            </div>
            <div class="row p-l-10">
              <div class="col-md-6">
                <label class="txt-bold">TELF : {{ item.data.persona?.telefono }}</label>
              </div>
              <div class="col-md-6">
                <label class="txt-bold">EDAD :
                  {{ calculateAge(item.data.persona?.birth_date) }}</label>
              </div>
            </div>
            <div class="row p-l-10">
              <div class="col-md-6">
                <label class="txt-bold">FECHA NAC :
                  {{
                  convertDateToReadableFormat(
                  item.data.persona?.birth_date
                  )
                  }}</label>
              </div>
              <div class="col-md-6">
                <label class="txt-bold">SEXO :
                  {{
                  item.data.persona?.gender == "H"
                  ? "Hombre"
                  : item.data.persona?.gender == "M"
                  ? "Mujer"
                  : ""
                  }}</label>
              </div>
            </div>
            <div class="row p-l-10">
              <div class="col-md-6">
                <label class="txt-bold">EMAIL : {{ item.data.persona?.email }}</label>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table vis-cita-rep-select">
      <tbody>
        <!-- AF row -->
        <tr>
          <td class="p-l-10">AF</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Antecedentes Familiares No significativos
              </li>
              <li>
                <div class="check-box"></div>
                Neoplasia...........................
              </li>
              <li>
                <div class="check-box"></div>
                Cardiopatía.........................
              </li>
              <li>
                <div class="check-box"></div>
                Ictus/derrame cerebral........
              </li>
              <li>
                <div class="check-box"></div>
                Respiratorio........................
              </li>
              <li>
                <div class="check-box"></div>
                HTA....................................
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Endocrino................................
              </li>
              <li>
                <div class="check-box"></div>
                Diabetes..................................
              </li>
              <li>
                <div class="check-box"></div>
                Glaucoma................................
              </li>
              <li>
                <div class="check-box"></div>
                Hipoacusia...............................
              </li>
              <li>
                <div class="check-box"></div>
                Psiquiátrica..............................
              </li>
              <li>
                <div class="check-box"></div>
                Otros........................................
              </li>
            </ul>
          </td>
        </tr>
        <!-- Ap row -->
        <tr>
          <td class="p-l-10">AP</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Antecedentes Personales No significativos
              </li>
              <li>
                <div class="check-box"></div>
                Enf cutáneas.........................
              </li>
              <li>
                <div class="check-box"></div>
                HTA............................
              </li>
              <li>
                <div class="check-box"></div>
                Arritmia................................
              </li>
              <li>
                <div class="check-box"></div>
                Asma, EPOC...................................
              </li>
              <li>
                <div class="check-box"></div>
                Ictus/IAM/angor-pectoris.......................................
              </li>
              <li>
                <div class="check-box"></div>
                Trastorno-próstata............................
              </li>
              <li>
                <div class="check-box"></div>
                Trastorno-mama............................
              </li>
              <li>
                <div class="check-box"></div>
                Migraña/cefalea....................................
              </li>
              <li>
                <div class="check-box"></div>
                Acúfenos, tr_oido, hipoacusia............................
              </li>
              <li>
                <div class="check-box"></div>
                Vértigo-mareo................................
              </li>
              <li>
                <div class="check-box"></div>
                Diabetes...................................
              </li>
              <li>
                <div class="check-box"></div>
                Trast. tiroides-parat.......................................
              </li>
              <li>
                <div class="check-box"></div>
                Hipercolest./hipertriglic....................................
              </li>
              <li>
                <div class="check-box"></div>
                Hepatitis...............................
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Alteraciones visuales:
              </li>
              <li>
                <div class="check-box"></div>
                COVID-19:
              </li>
              <li>
                <div class="check-box"></div>
                Neoplaia:
              </li>
              <li>
                <div class="check-box"></div>
                Conjuntivitis:
              </li>
              <li>
                <div class="check-box"></div>
                Rinitis:
              </li>
              <li>
                <div class="check-box"></div>
                Ansiedad/depresión/pat, Psiquiátrica:
              </li>
              <li class="d-content">
                <div class="d-flex">
                  <div class="check-box"></div>
                  Otras patologias:
                </div>
                <div class="AP-otras-div">Sens.Subjetiva: (REC.ANT. )</div>
              </li>
            </ul>
          </td>
        </tr>
        <!-- QUIRD row -->
        <tr>
          <td class="p-l-10">QUIRD</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Apendicectomía.........................................
              </li>
              <li>
                <div class="check-box"></div>
                Amigdalectomía.........................................
              </li>
              <li>
                <div class="check-box"></div>
                Adenoidectomía.........................................
              </li>
              <li>
                <div class="check-box"></div>
                Cesárea................................................
              </li>
              <li>
                <div class="check-box"></div>
                Cirugía cardíaca.......................................
              </li>
              <li>
                <div class="check-box"></div>
                Colecistectomía........................................
              </li>
              <li>
                <div class="check-box"></div>
                Trauma.................................................
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Herniorrafía inguinal........................
              </li>
              <li>
                <div class="check-box"></div>
                Herniorrafía umbilical......................
              </li>
              <li>
                <div class="check-box"></div>
                Fimosis.....................................
              </li>
              <li>
                <div class="check-box"></div>
                Cirugía hernia discal.......................
              </li>
              <li>
                <div class="check-box"></div>
                Cirugía fisura anal..........................
              </li>
              <li>
                <div class="check-box"></div>
                Cir. síndrome del túnel carpiano....
              </li>
              <li>
                <div class="check-box"></div>
                Refractaria (lente intraocular)...................
              </li>
              <li>
                <div class="check-box"></div>
                Otros
              </li>
            </ul>
          </td>
        </tr>
        <!-- TRAUMA row -->
        <tr>
          <td class="p-l-10">TRAUMA</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Cabeza..........................................
              </li>
              <li>
                <div class="check-box"></div>
                Cintura escapular.........................................
              </li>
              <li>
                <div class="check-box"></div>
                Enf. Sist Musculoesquelético y ti conectivo.....
              </li>
              <li>
                <div class="check-box"></div>
                Hombro..........................................
              </li>
              <li>
                <div class="check-box"></div>
                Codo..............
              </li>
              <li>
                <div class="check-box"></div>
                Muñeca/carpo................................
              </li>
              <li>
                <div class="check-box"></div>
                Mano......................................
              </li>
              <li>
                <div class="check-box"></div>
                Síndrome túnel carpiano................
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Cervicalgia.....................................
              </li>
              <li>
                <div class="check-box"></div>
                Dorsalgia...........................................
              </li>
              <li>
                <div class="check-box"></div>
                Lumbalgia....................................
              </li>
              <li>
                <div class="check-box"></div>
                Escoliosis......................
              </li>
              <li>
                <div class="check-box"></div>
                Hernia discal (no I.Q.).....................
              </li>
              <li>
                <div class="check-box"></div>
                Cadera..........................…...............
              </li>
              <li>
                <div class="check-box"></div>
                Rodilla..........................…................
              </li>
              <li>
                <div class="check-box"></div>
                Tobillo/pie...................................
              </li>
              <li>
                <div class="check-box"></div>
                Pie..............................................
              </li>
            </ul>
          </td>
        </tr>
        <!-- Historia laboral row -->
        <tr>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Historia laboral (riesgos mportantes)
              </li>
              <li>
                <div class="check-box"></div>
                Discapacidad rec.
              </li>
              <li>
                <div class="check-box"></div>
                Acc. Laboral
                <div class="check-box m-l-20"></div>
                Enf. profesional
              </li>
            </ul>
          </td>
          <td colspan="2">
            <ul>
              <li>
                Horario........................................................Año Inicia actividad
              </li>
              <li class="d-flex">
                Conducción en misión: &nbsp;<div class="check-box" style="margin-top: 2px;"></div> si &nbsp;<div
                  class="check-box" style="margin-top: 2px;"></div> no..............Alturas>2m: &nbsp;<div
                  class="check-box" style="margin-top: 2px;"></div> si &nbsp;<div class="check-box"
                  style="margin-top: 2px;"></div> no..............Teletrabajo: <div class="check-box"
                  style="margin-top: 2px;"></div> si &nbsp; <div class="check-box" style="margin-top: 2px;"></div> no
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <span class="m-b-5" style="visibility:hidden">make space</span>
    <table class="table vis-cita-rep-select-1">
      <tbody>
        <!-- Alergias row -->
        <tr>
          <td colspan="2">Alergias</td>
          <td colspan="2">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                No conocidas
              </li>
            </ul>
          </td>
          <td colspan="3">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                ...............................................................vacunoterapia
                efectiva? Si/No (REC. ANT. ;MEDIC:
              </li>
            </ul>
          </td>
        </tr>
        <!-- Vacunación row -->
        <tr>
          <td colspan="2">Vacunación</td>
          <td colspan="2">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Vac Tétanos……………actualizada? Si/No
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                SARS-CoV-2
              </li>
              <li style="font-size: small;">
                <div class="check-box"></div>1-dosis&nbsp;<div class="check-box"></div>2-dosis&nbsp;<div
                  class="check-box"></div>3-dosis
              </li>
              <li>
                Tipo:
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Hepatitis B
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Otras
              </li>
            </ul>
          </td>
        </tr>
        <!-- Medicación habitual row -->
        <tr>
          <td rowspan="2" colspan="2">Medicación habitual(REC. ANT.</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                No consume
              </li>
              <li>
                <div class="check-box"></div>
                Analgésicos- AINES
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Antihipertensivos
              </li>
              <li>
                <div class="check-box"></div>
                Hipolipemiante
              </li>
              <li>
                <div class="check-box"></div>
                Antidiabético
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Anticonceptivo
              </li>
              <li>
                <div class="check-box"></div>
                Antihistamínico
              </li>
              <li>
                <div class="check-box"></div>
                Psicotrópico
              </li>
            </ul>
          </td>
          <td colspan="2">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Protector digestivo
              </li>
              <li>
                <div class="check-box"></div>
                Disfunción tiroidea
              </li>
              <li>
                <div class="check-box"></div>
                Otros
              </li>
            </ul>
          </td>
        </tr>
        <!-- no text row -->
        <tr>
          <td colspan="7">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
              </li>
            </ul>
          </td>
        </tr>

        <!-- Tabaco row -->
        <tr>
          <td>Tabaco</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                No fumador
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Ex
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Esporádico
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                -10 cig./día
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                10-20 cig./día
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                +20cig./día
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table vis-cita-rep-select-1 BreakPage">
      <tbody>
        <!-- Alcohol row -->
        <tr>
          <td colspan="2">Alcohol</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                No consume
              </li>
            </ul>
          </td>
          <td colspan="2">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Consumo ocasional
              </li>
            </ul>
          </td>
          <td colspan="2">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Consumo habitual
              </li>
            </ul>
          </td>
        </tr>
        <!-- Drogas row -->
        <tr>
          <td colspan="2">Drogas</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                No consume
              </li>
            </ul>
          </td>
          <td colspan="4">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Consumo. Tipo
              </li>
            </ul>
          </td>
        </tr>

        <!-- Alimentación row -->
        <tr>
          <td colspan="2">Alimentación</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Variada sin dieta
              </li>
              <li>
                <div class="check-box"></div>
                No equilibrada
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Hipocalórica
              </li>
              <li>
                <div class="check-box"></div>
                Hiposódica
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Hipolipemiante
              </li>
              <li>
                <div class="check-box"></div>
                Hipoglucemiante
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Sin lactosa
              </li>
              <li>
                <div class="check-box"></div>
                Sin gluten
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Vegetariana
              </li>
              <li>
                <div class="check-box"></div>
                Sin cerdo
              </li>
            </ul>
          </td>
        </tr>
        <!-- Ritmo row -->
        <tr>
          <td colspan="3">
            Ritmo intestinal
            <ul class="check-diseases-list m-t-10">
              <li>
                <div class="check-circle"></div>
                Normal
              </li>
              <li>
                <div class="check-circle"></div>
                Alterado
              </li>
            </ul>
          </td>
          <td colspan="2">
            Ritmo urinario
            <ul class="check-diseases-list m-t-10">
              <li>
                <div class="check-circle"></div>
                Normal
              </li>
              <li>
                <div class="check-circle"></div>
                Alterado
              </li>
            </ul>
          </td>
          <td colspan="2">
            Ritmo sueño
            <ul class="check-diseases-list m-t-10">
              <li>
                <div class="check-circle"></div>
                Normal
              </li>
              <li>
                <div class="check-circle"></div>
                Alterado
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colspan="1">
            Act.física/ocio
          </td>
          <td colspan="6">
            <ul class="check-diseases-list m-t-10">
              <li>
                <div class="check-circle"></div>
                No habitual &nbsp;
                <div class="check-circle"></div>
                < 4h/sem. &nbsp; <div class="check-circle">
  </div>
  > 4h/sem. &nbsp;
  <div class="check-circle"></div>
  Caza &nbsp;
  <div class="check-circle"></div>
  Música
  </li>
  <li>
    <div class="check-circle"></div>
    Tipo deporte /ocio (ANT.REC.):.....
    <div class="check-circle"></div>
    Gimnasio &nbsp;
    <div class="check-circle"></div>
    Musculación &nbsp;
    <div class="check-circle"></div>
    Casminar &nbsp;
    <div class="check-circle"></div>
    Footing &nbsp;
    <div class="check-circle"></div>
    Estiramientos
  </li>
  </ul>
  </td>
  </tr>
  </tbody>
  </table>
  <!-- 2 nd page start -->
  <div class="m-t-20 text-center p-20">
    <label class="txt-bold"> EXPLORACIÓN CLÍNICA</label>
  </div>
  <table class="issue-check-tbl">
    <tbody>
      <tr>
        <td>Normal</td>
        <td>Alterado</td>
        <td></td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Piel y mucosas............</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Cuello/Faringe/amígdalas/laringe................</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          Dentición.........................…...............................
        </td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Oídos. Otoscopia.........................…..................</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Ojos. Examen ocular/ Tonometría................</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Naríz/lengua/mandíbula.........................….....</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Auscultación pulmonar/cardíaca..................</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          Abdomen.........................…...............................
        </td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Aparato geniturinario.........................…..........</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Columna vertebral.........................…................</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Extremidades superiories/inferiores.............</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Sistema nervioso.........................…...................</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Sistema circularorio.........................…..............</td>
      </tr>
    </tbody>
  </table>
  <div class="issue-check-comentarios m-t-10 ">
    <label>Comentarios :</label>
  </div>
  <table class="detail-tbl-vis-cita-report">
    <tbody>
      <tr>
        <td>Peso: <span class="p-l-30">kg</span></td>
        <td>Talla: <span class="p-l-30">m</span></td>
        <td>T.a. máx: <span class="p-l-30">MmHg</span></td>
        <td>2ª toma max.</td>
        <td>T.a.mín: <span class="p-l-30">MmHg</span></td>
        <td>2ª toma min.</td>
        <td>FC: <span class="p-l-30">x´</span></td>
      </tr>
    </tbody>
  </table>
  <table class="detail-tbl-vis-cita-report">
    <tbody>
      <tr>
        <td class="grey-column">Test visión</td>
        <td>Corrección</td>
        <td class="grey-column">Función respiratoria</td>
        <td>Valoración</td>
      </tr>
      <tr>
        <td>
          <table class="detail-tbl-vis-cita-report">
            <tbody>
              <tr>
                <td>Agud.Visual</td>
                <td>Cerca</td>
                <td>Lejos</td>
              </tr>
              <tr>
                <td>O.Der.</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>O.Izq.</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Binocular</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <label class="m-t-10">Cromatismo : normal / alterado</label>
        </td>
        <td>
          <ul class="check-diseases-list">
            <li>
              <div class="check-box"></div>
              No utiliza
            </li>
            <li>
              <div class="check-box"></div>
              No ha traído lejana
            </li>
            <li>
              <div class="check-box"></div>
              No ha traído cercana
            </li>
            <li>
              <div class="check-box"></div>
              Lejana
            </li>
            <li>
              <div class="check-box"></div>
              Cercana
            </li>
            <li>
              <div class="check-box"></div>
              Progresiva/bifocal
            </li>
          </ul>
        </td>
        <td>
          <table class="detail-tbl-vis-cita-report">
            <tbody>
              <tr>
                <td></td>
                <td>Valor obtenido</td>
              </tr>
              <tr>
                <td>CVF</td>
                <td></td>
              </tr>
              <tr>
                <td>FEV1</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td>
          <ul class="check-diseases-list">
            <li>
              <div class="check-box"></div>
              Déficit obstructivo
            </li>
            <li>
              <div class="check-box"></div>
              Déficit restrictivo
            </li>
            <li>
              <div class="check-box"></div>
              Déficit mixto
            </li>
            <li>
              <div class="check-box"></div>
              Déficit mixto
            </li>
            <li>
              <div class="check-box"></div>
              No valorable
            </li>
            <li>
              <div class="check-box"></div>
              No realizado
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="detail-tbl-vis-cita-report">
    <tbody>
      <tr>
        <td class="grey-column">AUDIOMETRIA tipo: Reposo auditivo ? Si/No</td>
        <td class="grey-column">E.C.G.</td>
      </tr>
      <tr>
        <td>
          <table class="detail-tbl-vis-cita-report">
            <tbody>
              <tr>
                <td></td>
                <td>500</td>
                <td>1000</td>
                <td>2000</td>
                <td>3000</td>
                <td>4000</td>
                <td>6000</td>
                <td>8000</td>
              </tr>
              <tr>
                <td>OD</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>OI</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td>
          <ul class="check-diseases-list">
            <li>
              <div class="check-box"></div>
              Normal
            </li>
            <li>
              <div class="check-box"></div>
              &nbsp;
            </li>
            <li>
              <div class="check-box"></div>
              Alterado. Impresión diagnóstica:
            </li>
            <li>
              <div class="check-box"></div>
              No realizado
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="detail-tbl-vis-cita-report">
    <tbody>
      <tr>
        <td class="grey-column">COMENTARIOS:</td>
      </tr>
      <td>
        <ul class="check-diseases-list">

          <li class="m-b-10 m-t-10 d-content">
            <div class="d-flex">
              <div class="check-box"></div>
              <b>PROTOCOLOS APLICADOS:</b>
            </div>

            <span *ngFor="
                let protocol of item.data?.protocolos;
                let i = index
              ">
              <span>{{ protocol.protocolo_txt }},</span>

            </span>
          </li>
          <li class="m-b-10 d-content p-t-2">
            <div class="d-flex">
              <div class="check-box"></div>
              <b>PRUEBAS ESPECIALES:</b>
            </div>
            <div class="newspaper">
              <div class="d-flex" *ngFor="
                  let prueba of item.data?.pruebas;
                  let i = index
                ">
                <div class="check-box"></div>
                {{ prueba.prueba_txt }}
              </div>
            </div>
          </li>
        </ul>
      </td>
    </tbody>
  </table>
  <div style="margin-top:700px" class="m-t-20 m-l-15"></div>
</div>
<div>
  <div id="visualizer-zitar-report" class="m-t-20 m-l-15" [hidden]="true">
    <div class="row p-l-30 m-b-10">
      <div class="col-md-3">
        <label class="mb-0 ml-1 font-weight-bold">Hora de llegada : </label>
      </div>
      <div class="col-md-3">
        <label class="mb-0 font-weight-bold">Hora de Salida : </label>
      </div>
      <div class="col-md-1">
        <label class="mb-0 font-weight-bold">I : </label>
      </div>
      <div class="col-md-1">
        <label class="mb-0 font-weight-bold">M : </label>
      </div>
      <div class="col-md-4"></div>
    </div>
    <!-- header table -->
    <table class="table vis-cita-rep-header-tbl">
      <tbody>
        <tr>
          <td class="txt-bold text-center">GESEME</td>
          <td class="txt-bold text-center">
            Recogida datos Reconocimientos médicos
          </td>
          <td class="txt-bold text-center">
            <span>PR 05 -A7</span>
            <span>Rev. 2</span>
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <div class="row p-l-10">
              <div class="col-md-6">
                <label class="txt-bold">EMPRESA : {{ appointmentData?.attribs?.empresa_txt }}</label>
              </div>
              <div class="col-md-6">
                <label class="txt-bold">CT : {{ appointmentData?.attribs?.centro_txt }}</label>
              </div>
            </div>
            <div class="row p-l-10">
              <div class="col-md-6">
                <label class="txt-bold">NOMBRE : {{ appointmentData?.persona?.nombre }} {{
                  appointmentData?.persona?.apellidos }}</label>
              </div>
              <div class="col-md-6">
                <label class="txt-bold">PT : {{ appointmentData?.attribs?.puestovs_txt }}</label>
              </div>
            </div>
            <div class="row p-l-10">
              <div class="col-md-6">
                <label class="txt-bold">FECHA VISITA :
                  {{
                  convertDateToReadableFormat(appointmentData?.attribs?.fecha)
                  }}</label>
              </div>
              <div class="col-md-6">
                <label class="txt-bold">NIF : {{ appointmentData?.persona?.dni }}</label>
              </div>
            </div>
            <div class="row p-l-10">
              <div class="col-md-6">
                <label class="txt-bold">TELF :{{ appointmentData?.persona?.telefono }}</label>
              </div>
              <div class="col-md-6">
                <label class="txt-bold">EDAD :
                  {{ calculateAge(appointmentData?.persona?.birth_date) }}</label>
              </div>
            </div>
            <div class="row p-l-10">
              <div class="col-md-6">
                <label class="txt-bold">FECHA NAC :
                  {{
                  convertDateToReadableFormat(
                  appointmentData?.persona?.birth_date
                  )
                  }}</label>
              </div>
              <div class="col-md-6">
                <label class="txt-bold">SEXO :
                  {{
                  appointmentData?.persona?.gender == "H"
                  ? "Hombre"
                  : appointmentData?.persona?.gender == "M"
                  ? "Mujer"
                  : ""
                  }}</label>
              </div>
            </div>
            <div class="row p-l-10">
              <div class="col-md-6">
                <label class="txt-bold">EMAIL : {{ appointmentData?.persona?.email }}</label>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table class="table vis-cita-rep-select">
      <tbody>
        <!-- AF row -->
        <tr>
          <td class="p-l-10">AF</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Antecedentes Familiares No significativos
              </li>
              <li>
                <div class="check-box"></div>
                Neoplasia...........................
              </li>
              <li>
                <div class="check-box"></div>
                Cardiopatía.........................
              </li>
              <li>
                <div class="check-box"></div>
                Ictus/derrame cerebral........
              </li>
              <li>
                <div class="check-box"></div>
                Respiratorio........................
              </li>
              <li>
                <div class="check-box"></div>
                HTA....................................
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Endocrino................................
              </li>
              <li>
                <div class="check-box"></div>
                Diabetes..................................
              </li>
              <li>
                <div class="check-box"></div>
                Glaucoma................................
              </li>
              <li>
                <div class="check-box"></div>
                Hipoacusia...............................
              </li>
              <li>
                <div class="check-box"></div>
                Psiquiátrica..............................
              </li>
              <li>
                <div class="check-box"></div>
                Otros........................................
              </li>
            </ul>
          </td>
        </tr>
        <!-- Ap row -->
        <tr>
          <td class="p-l-10">AP</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Antecedentes Personales No significativos
              </li>
              <li>
                <div class="check-box"></div>
                Enf cutáneas.........................
              </li>
              <li>
                <div class="check-box"></div>
                HTA............................
              </li>
              <li>
                <div class="check-box"></div>
                Arritmia................................
              </li>
              <li>
                <div class="check-box"></div>
                Asma, EPOC...................................
              </li>
              <li>
                <div class="check-box"></div>
                Ictus/IAM/angor-pectoris.......................................
              </li>
              <li>
                <div class="check-box"></div>
                Trastorno-próstata............................
              </li>
              <li>
                <div class="check-box"></div>
                Trastorno-mama............................
              </li>
              <li>
                <div class="check-box"></div>
                Migraña/cefalea....................................
              </li>
              <li>
                <div class="check-box"></div>
                Acúfenos, tr_oido, hipoacusia............................
              </li>
              <li>
                <div class="check-box"></div>
                Vértigo-mareo................................
              </li>
              <li>
                <div class="check-box"></div>
                Diabetes...................................
              </li>
              <li>
                <div class="check-box"></div>
                Trast. tiroides-parat.......................................
              </li>
              <li>
                <div class="check-box"></div>
                Hipercolest./hipertriglic....................................
              </li>
              <li>
                <div class="check-box"></div>
                Hepatitis...............................
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Alteraciones visuales:
              </li>
              <li>
                <div class="check-box"></div>
                COVID-19:
              </li>
              <li>
                <div class="check-box"></div>
                Neoplaia:
              </li>
              <li>
                <div class="check-box"></div>
                Conjuntivitis:
              </li>
              <li>
                <div class="check-box"></div>
                Rinitis:
              </li>
              <li>
                <div class="check-box"></div>
                Ansiedad/depresión/pat, Psiquiátrica:
              </li>
              <li class="d-content">
                <div class="d-flex">
                  <div class="check-box"></div>
                  Otras patologias:
                </div>
                <div class="AP-otras-div">Sens.Subjetiva: (REC.ANT. )</div>
              </li>
            </ul>
          </td>
        </tr>
        <!-- QUIRD row -->
        <tr>
          <td class="p-l-10">QUIRD</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Apendicectomía.........................................
              </li>
              <li>
                <div class="check-box"></div>
                Amigdalectomía.........................................
              </li>
              <li>
                <div class="check-box"></div>
                Adenoidectomía.........................................
              </li>
              <li>
                <div class="check-box"></div>
                Cesárea................................................
              </li>
              <li>
                <div class="check-box"></div>
                Cirugía cardíaca.......................................
              </li>
              <li>
                <div class="check-box"></div>
                Colecistectomía........................................
              </li>
              <li>
                <div class="check-box"></div>
                Trauma.................................................
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Herniorrafía inguinal........................
              </li>
              <li>
                <div class="check-box"></div>
                Herniorrafía umbilical......................
              </li>
              <li>
                <div class="check-box"></div>
                Fimosis.....................................
              </li>
              <li>
                <div class="check-box"></div>
                Cirugía hernia discal.......................
              </li>
              <li>
                <div class="check-box"></div>
                Cirugía fisura anal..........................
              </li>
              <li>
                <div class="check-box"></div>
                Cir. síndrome del túnel carpiano....
              </li>
              <li>
                <div class="check-box"></div>
                Refractaria (lente intraocular)...................
              </li>
              <li>
                <div class="check-box"></div>
                Otros
              </li>
            </ul>
          </td>
        </tr>
        <!-- TRAUMA row -->
        <tr>
          <td class="p-l-10">TRAUMA</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Cabeza..........................................
              </li>
              <li>
                <div class="check-box"></div>
                Cintura escapular.........................................
              </li>
              <li>
                <div class="check-box"></div>
                Enf. Sist Musculoesquelético y ti conectivo.....
              </li>
              <li>
                <div class="check-box"></div>
                Hombro..........................................
              </li>
              <li>
                <div class="check-box"></div>
                Codo..............
              </li>
              <li>
                <div class="check-box"></div>
                Muñeca/carpo................................
              </li>
              <li>
                <div class="check-box"></div>
                Mano......................................
              </li>
              <li>
                <div class="check-box"></div>
                Síndrome túnel carpiano................
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Cervicalgia.....................................
              </li>
              <li>
                <div class="check-box"></div>
                Dorsalgia...........................................
              </li>
              <li>
                <div class="check-box"></div>
                Lumbalgia....................................
              </li>
              <li>
                <div class="check-box"></div>
                Escoliosis......................
              </li>
              <li>
                <div class="check-box"></div>
                Hernia discal (no I.Q.).....................
              </li>
              <li>
                <div class="check-box"></div>
                Cadera..........................…...............
              </li>
              <li>
                <div class="check-box"></div>
                Rodilla..........................…................
              </li>
              <li>
                <div class="check-box"></div>
                Tobillo/pie...................................
              </li>
              <li>
                <div class="check-box"></div>
                Pie..............................................
              </li>
            </ul>
          </td>
        </tr>
        <!-- Historia laboral row -->
        <tr>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Historia laboral (riesgos mportantes)
              </li>
              <li>
                <div class="check-box"></div>
                Discapacidad rec.
              </li>
              <li>
                <div class="check-box"></div>
                Acc. Laboral
                <div class="check-box m-l-20"></div>
                Enf. profesional
              </li>
            </ul>
          </td>
          <td colspan="2">
            <ul>
              <li>
                Horario........................................................Año Inicia actividad
              </li>
              <li class="d-flex">
                Conducción en misión: &nbsp;<div class="check-box" style="margin-top: 2px;"></div> si &nbsp;<div
                  class="check-box" style="margin-top: 2px;"></div> no..............Alturas>2m: &nbsp;<div
                  class="check-box" style="margin-top: 2px;"></div> si &nbsp;<div class="check-box"
                  style="margin-top: 2px;"></div> no..............Teletrabajo: <div class="check-box"
                  style="margin-top: 2px;"></div> si &nbsp; <div class="check-box" style="margin-top: 2px;"></div> no
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <span class="m-b-5" style="visibility:hidden">make space</span>
    <table class="table vis-cita-rep-select-1">
      <tbody>
        <!-- Alergias row -->
        <tr>
          <td colspan="2">Alergias</td>
          <td colspan="2">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                No conocidas
              </li>
            </ul>
          </td>
          <td colspan="3">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                ...............................................................vacunoterapia
                efectiva? Si/No (REC. ANT. ;MEDIC:
              </li>
            </ul>
          </td>
        </tr>
        <!-- Vacunación row -->
        <tr>
          <td colspan="2">Vacunación</td>
          <td colspan="2">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Vac Tétanos……………actualizada? Si/No
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                SARS-CoV-2
              </li>
              <li style="font-size: small;">
                <div class="check-box"></div>1-dosis&nbsp;<div class="check-box"></div>2-dosis&nbsp;<div
                  class="check-box"></div>3-dosis
              </li>
              <li>
                Tipo:
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Hepatitis B
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Otras
              </li>
            </ul>
          </td>
        </tr>
        <!-- Medicación habitual row -->
        <tr>
          <td rowspan="2" colspan="2">Medicación habitual(REC. ANT.</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                No consume
              </li>
              <li>
                <div class="check-box"></div>
                Analgésicos- AINES
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Antihipertensivos
              </li>
              <li>
                <div class="check-box"></div>
                Hipolipemiante
              </li>
              <li>
                <div class="check-box"></div>
                Antidiabético
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Anticonceptivo
              </li>
              <li>
                <div class="check-box"></div>
                Antihistamínico
              </li>
              <li>
                <div class="check-box"></div>
                Psicotrópico
              </li>
            </ul>
          </td>
          <td colspan="2">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Protector digestivo
              </li>
              <li>
                <div class="check-box"></div>
                Disfunción tiroidea
              </li>
              <li>
                <div class="check-box"></div>
                Otros
              </li>
            </ul>
          </td>
        </tr>
        <!-- no text row -->
        <tr>
          <td colspan="7">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
              </li>
            </ul>
          </td>
        </tr>
        <!-- Tabaco row -->
        <tr>
          <td>Tabaco</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                No fumador
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Ex
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Esporádico
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                -10 cig./día
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                10-20 cig./día
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                +20cig./día
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
    <table class="table vis-cita-rep-select-1 BreakPage">
      <tbody>
        <!-- Alcohol row -->
        <tr>
          <td colspan="2">Alcohol</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                No consume
              </li>
            </ul>
          </td>
          <td colspan="2">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Consumo ocasional
              </li>
            </ul>
          </td>
          <td colspan="2">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Consumo habitual
              </li>
            </ul>
          </td>
        </tr>
        <!-- Drogas row -->
        <tr>
          <td colspan="2">Drogas</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                No consume
              </li>
            </ul>
          </td>
          <td colspan="4">
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Consumo. Tipo
              </li>
            </ul>
          </td>
        </tr>
        <!-- Alimentación row -->
        <tr>
          <td colspan="2">Alimentación</td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Variada sin dieta
              </li>
              <li>
                <div class="check-box"></div>
                No equilibrada
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Hipocalórica
              </li>
              <li>
                <div class="check-box"></div>
                Hiposódica
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Hipolipemiante
              </li>
              <li>
                <div class="check-box"></div>
                Hipoglucemiante
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Sin lactosa
              </li>
              <li>
                <div class="check-box"></div>
                Sin gluten
              </li>
            </ul>
          </td>
          <td>
            <ul class="check-diseases-list">
              <li>
                <div class="check-box"></div>
                Vegetariana
              </li>
              <li>
                <div class="check-box"></div>
                Sin cerdo
              </li>
            </ul>
          </td>
        </tr>
        <!-- Ritmo row -->
        <tr>
          <td colspan="3">
            Ritmo intestinal
            <ul class="check-diseases-list m-t-10">
              <li>
                <div class="check-circle"></div>
                Normal
              </li>
              <li>
                <div class="check-circle"></div>
                Alterado
              </li>
            </ul>
          </td>
          <td colspan="2">
            Ritmo urinario
            <ul class="check-diseases-list m-t-10">
              <li>
                <div class="check-circle"></div>
                Normal
              </li>
              <li>
                <div class="check-circle"></div>
                Alterado
              </li>
            </ul>
          </td>
          <td colspan="2">
            Ritmo sueño
            <ul class="check-diseases-list m-t-10">
              <li>
                <div class="check-circle"></div>
                Normal
              </li>
              <li>
                <div class="check-circle"></div>
                Alterado
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td colspan="1">
            Act.física/ocio
          </td>
          <td colspan="6">
            <ul class="check-diseases-list m-t-10">
              <li>
                <div class="check-circle"></div>
                No habitual &nbsp;
                <div class="check-circle"></div>
                < 4h/sem. &nbsp; <div class="check-circle">
  </div>
  > 4h/sem. &nbsp;
  <div class="check-circle"></div>
  Caza &nbsp;
  <div class="check-circle"></div>
  Música
  </li>
  <li>
    <div class="check-circle"></div>
    Tipo deporte /ocio (ANT.REC.):.....
    <div class="check-circle"></div>
    Gimnasio &nbsp;
    <div class="check-circle"></div>
    Musculación &nbsp;
    <div class="check-circle"></div>
    Casminar &nbsp;
    <div class="check-circle"></div>
    Footing &nbsp;
    <div class="check-circle"></div>
    Estiramientos
  </li>
  </ul>
  </td>
  </tr>
  </tbody>
  </table>
  <!-- 2 nd page start -->
  <div class="m-t-20 text-center p-20">
    <label class="txt-bold"> EXPLORACIÓN CLÍNICA</label>
  </div>
  <table class="issue-check-tbl">
    <tbody>
      <tr>
        <td>Normal</td>
        <td>Alterado</td>
        <td></td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Piel y mucosas............</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Cuello/Faringe/amígdalas/laringe................</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          Dentición.........................…...............................
        </td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Oídos. Otoscopia.........................…..................</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Ojos. Examen ocular/ Tonometría................</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Naríz/lengua/mandíbula.........................….....</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Auscultación pulmonar/cardíaca..................</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          Abdomen.........................…...............................
        </td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Aparato geniturinario.........................…..........</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Columna vertebral.........................…................</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Extremidades superiories/inferiores.............</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Sistema nervioso.........................…...................</td>
      </tr>
      <tr>
        <td>
          <div class="check-box"></div>
        </td>
        <td>
          <div class="check-box"></div>
        </td>
        <td>Sistema circularorio.........................…..............</td>
      </tr>
    </tbody>
  </table>
  <!--<span class="m-b-10" style="visibility:hidden">make space</span>-->
  <div class="issue-check-comentarios m-t-10 p-10">
    <label class="m-t-5 p-5">Comentarios :</label>
  </div>
  <table class="detail-tbl-vis-cita-report">
    <tbody>
      <tr>
        <td>Peso: <span class="p-l-30">kg</span></td>
        <td>Talla: <span class="p-l-30">m</span></td>
        <td>T.a. máx: <span class="p-l-30">MmHg</span></td>
        <td>2ª toma max.</td>
        <td>T.a.mín: <span class="p-l-30">MmHg</span></td>
        <td>2ª toma min.</td>
        <td>FC: <span class="p-l-30">x´</span></td>
      </tr>
    </tbody>
  </table>
  <table class="detail-tbl-vis-cita-report">
    <tbody>
      <tr>
        <td class="grey-column">Test visión</td>
        <td>Corrección</td>
        <td class="grey-column">Función respiratoria</td>
        <td>Valoración</td>
      </tr>
      <tr>
        <td>
          <table class="detail-tbl-vis-cita-report">
            <tbody>
              <tr>
                <td>Agud.Visual</td>
                <td>Cerca</td>
                <td>Lejos</td>
              </tr>
              <tr>
                <td>O.Der.</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>O.Izq.</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Binocular</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <label class="m-t-10">Cromatismo : normal / alterado</label>
        </td>
        <td>
          <ul class="check-diseases-list">
            <li>
              <div class="check-box"></div>
              No utiliza
            </li>
            <li>
              <div class="check-box"></div>
              No ha traído lejana
            </li>
            <li>
              <div class="check-box"></div>
              No ha traído cercana
            </li>
            <li>
              <div class="check-box"></div>
              Lejana
            </li>
            <li>
              <div class="check-box"></div>
              Cercana
            </li>
            <li>
              <div class="check-box"></div>
              Progresiva/bifocal
            </li>
          </ul>
        </td>
        <td>
          <table class="detail-tbl-vis-cita-report">
            <tbody>
              <tr>
                <td></td>
                <td>Valor obtenido</td>
              </tr>
              <tr>
                <td>CVF</td>
                <td></td>
              </tr>
              <tr>
                <td>FEV1</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td>
          <ul class="check-diseases-list">
            <li>
              <div class="check-box"></div>
              Déficit obstructivo
            </li>
            <li>
              <div class="check-box"></div>
              Déficit restrictivo
            </li>
            <li>
              <div class="check-box"></div>
              Déficit mixto
            </li>
            <li>
              <div class="check-box"></div>
              Déficit mixto
            </li>
            <li>
              <div class="check-box"></div>
              No valorable
            </li>
            <li>
              <div class="check-box"></div>
              No realizado
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="detail-tbl-vis-cita-report">
    <tbody>
      <tr>
        <td class="grey-column">AUDIOMETRIA tipo: Reposo auditivo ? Si/No</td>
        <td class="grey-column">E.C.G.</td>
      </tr>
      <tr>
        <td>
          <table class="detail-tbl-vis-cita-report">
            <tbody>
              <tr>
                <td></td>
                <td>500</td>
                <td>1000</td>
                <td>2000</td>
                <td>3000</td>
                <td>4000</td>
                <td>6000</td>
                <td>8000</td>
              </tr>
              <tr>
                <td>OD</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>OI</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </td>
        <td>
          <ul class="check-diseases-list">
            <li>
              <div class="check-box"></div>
              Normal
            </li>
            <li>
              <div class="check-box"></div>
              &nbsp;
            </li>
            <li>
              <div class="check-box"></div>
              Alterado. Impresión diagnóstica:
            </li>
            <li>
              <div class="check-box"></div>
              No realizado
            </li>
          </ul>
        </td>
      </tr>
    </tbody>
  </table>
  <table class="detail-tbl-vis-cita-report">
    <tbody>
      <tr>
        <td class="grey-column">COMENTARIOS:</td>
      </tr>
      <td>


        <ul class="check-diseases-list">
          <li class="m-b-10 m-t-10 d-content">
            <div class="d-flex">
              <div class="check-box"></div>
              <b>PROTOCOLOS APLICADOS:</b>
            </div>

            <span *ngFor="
                let protocol of this.appointmentData?.protocolos;
                let i = index
              ">

              <span>{{ protocol.protocolo_txt }},</span>
            </span>
          </li>
          <li class="m-b-10 d-content p-t-2">
            <div class="d-flex">
              <div class="check-box"></div>
              <b>PRUEBAS ESPECIALES:</b>
            </div>
            <div class="newspaper">
              <div class="d-flex" *ngFor="
                let prueba of this.appointmentData?.pruebas;
                let i = index
              ">
                <div class="check-box"></div>
                {{ prueba.prueba_txt }}
              </div>
            </div>
          </li>
        </ul>
      </td>
    </tbody>
  </table>
</div>

<div id="PrintCovidA57" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row covidRDT-print-doc d-block m-l-15">
    <!-- header section -->
    <div class="row">
      <div class="col-md-7">
        <div>
          <img alt="logo-RDT" src="assets\images\covid-logo.png" />
        </div>
      </div>
      <div class="col-md-5 right-header">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">PR05 A57</label>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">Rev :</label>
          <span>02</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">Fecha :</label>
          <span> 23/09/2021</span>
        </div>
      </div>
    </div>
    <!--/ header section -->
    <div class="row d-block m-t-20">
      <h3 class="title-rapid">
        Prueba rápida de diagnóstico para la detección cualitativa de antígeno
      </h3>
      <h3 class="title-rapid">SARS-CoV-2</h3>
      <h6 class="subtitle-rapid m-t-5">Name Lorem Ipsum</h6>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">DNI :</label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">Fecha de Nacimiento :</label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">Solicitado por :</label>
        <span>Dr. Miguel González</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">Fecha emisión informe :
        </label>
        <span>Dr. Miguel González</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">Hora : </label>
        <span>09:34 H</span>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="col-md-3">
        <label class="text-underline">Tipo de Muestra :</label>
      </div>
      <div class="col-md-9 d-grid">
        <label>Hisopo nasofaríngeo</label>
        <label>24/09/2019</label>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="col-md-3">
        <label class="text-underline">SARS-CoV-2 :</label>
      </div>
      <div class="col-md-9 d-grid">
        <label>Hisopo nasofaríngeo</label>
        <label>24/09/2019</label>
      </div>
    </div>
    <div class="row observ-div">
      <label class="text-underline">Observaciones:</label>
      <div class="d-grid m-t-10">
        <span>El resultado está condicionado a la toma de la muestra. </span>
        <span>Este análisis no excluye la presencia de otras infecciones víricas o
          bacterianas.
        </span>
        <span>Un resultado negativo en una muestra obtenida con ausencia de
          sintomatología, no descarta completamente la presencia de virus.</span>
        <span>Con un resultado negativo con síntomas clínicos, es recomendable
          realizar una prueba PCR.
        </span>
      </div>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area">
      <div class="col-md-6 d-grid">
        <span>Dr. Miguel González Ruiz</span>
        <span>Nº Col: 08/28300</span>
        <span>Médico</span>
        <span>GESEME 1996 SL</span>
      </div>
      <div class="col-md-6"></div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>Geseme 1996 SL. – SP-007-B </label>
    </div>
    <!--/ footer section -->
  </div>
</div>

<div id="PrintCovidA31Cas" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row covid-print-doc d-block m-l-15">
    <!-- header section -->
    <div class="row">
      <img alt="logo" src="assets\images\covid-logo.png" />
    </div>
    <!--/ header section -->
    <div class="row d-block">
      <h3 class="title-rapid">
        DECLARACIÓN DE CONSENTIMIENTO PARA LA REALIZACIÓN DE PRUEBAS
        DIAGNÓSTICAS DE LA COVID-19
      </h3>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Apellidos Y Nombre:
        </label>
        <span>lorem ipsum</span>
      </div>
      <div class="d-flex">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">Fecha Nacimiento:</label>
          <span>lorem ipsum</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">D.N.I. :</label>
          <span>lorem ipsum</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">Telf : </label>
          <span>0000000000</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Email : </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Municipio Domicilio :
        </label>
        <span>{{ appointmentData?.attribs?.poblacion_txt }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Nº Tarjeta Sanitaria :
        </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">A Rellenar Únicamente Por Trabajadores De Empresas Clientes De
          Geseme
          :
        </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Nombre Empresa : </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Municipio Empresa :
        </label>
        <span>lorem ipsum</span>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="form-group row">
        <label class="col-form-label">AUTORIZO me sean extraídas muestras para la siguiente prueba :
        </label>
        <span>lorem ipsum</span>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">En el caso del Test Rápido Antígenos, AUTORIZO el envío de los
          resultados o enlace de descarga mediante correo electrónico.</label>
      </div>
    </div>
    <div class="row para-div">
      <p>
        Con la firma del presente documento, AUTORIZO de forma voluntaria al
        equipo de GESEME para la gestión del análisis del “frotis orofaríngeo
        y/o nasofaríngeo” y/o extracción de sangre, dirigida a la detección de
        SARSCoV-2 y manifiesto mi plena capacidad para otorgar el presente
        consentimiento. A la par, quiero dejar constancia de que se me ha
        informado en qué consisten ambas técnicas de toma de muestras, como se
        realizan, y cuáles son las dificultades que pueden presentarse durante
        la extracción de estas. Asimismo, he sido informado de que todos los
        procesos de ensayo tienen un riesgo, reducido, de falso positivo por
        contaminación cruzada.
      </p>
      <p>
        DESCARGO DE RESPONSABILIDAD: En nombre propio y en el de mis herederos,
        cesionarios y/o representantes legales, por la presente libero y exonero
        a GESEME 1996, SL, sus socios, administradores, agentes y representantes
        DE TODA RESPONSABILIDAD LEGAL, derivada del resultado del análisis de la
        muestra. He leído detenidamente este acuerdo y comprendo completamente
        su contenido. Tengo conocimiento de que este acuerdo es una exoneración
        de responsabilidad hacia GESEME.
      </p>
      <p>
        GESEME declina toda responsabilidad relacionada con la veracidad y
        exactitud de la información facilitada por el interesado para la
        realización de la prueba; así como de los resultados que este pudiera
        ofrecer, dado que, por múltiples factores de método, ajenos a ella, no
        se puede asegurar el resultado íntegramente.
      </p>
    </div>
    <div class="row protection-div">
      <label class="text-underline">CONFIDENCIALIDAD Y PROTECCIÓN DE DATOS
      </label>
      <p>
        Todos los datos facilitados en este formulario serán tratados con
        estricta confidencialidad atendiendo a la normativa legal vigente en
        materia de protección de datos, Reglamento Europeo (UE) 2016/679 y Ley
        Orgánica 3/2018. La finalidad del tratamiento de los datos es la gestión
        del análisis dirigida a la detección de SARS-CoV-2 y poder prestar el
        servicio solicitado. Responsable: Geseme. Base jurídica: Prestación del
        servicio contratado. Destinatarios: Geseme. Transferencia internacional:
        No. Conservación: El tiempo necesario para cumplir con la finalidad
        recogida y determinar las posibles responsabilidades derivadas de la
        misma. Derechos: Acceso, rectificación, supresión y oposición,
        portabilidad y limitación del tratamiento, por escrito a: Att. Delegado
        de Protección de Datos. Geseme, c/ Aragón, 25 (08015) Barcelona; o por
        correo electrónico a:
        <span>dpo@geseme.com</span>. Para reclamaciones, también puede dirigirse
        a la Autoridad de Control (Agencia Española de Protección de Datos
        www.aepd.es). Más información:<span>
          https://geseme.com/politica-de-privacidad</span>
      </p>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area">
      <div class="col-md-6">
        <label>Firma : ...............................</label>
      </div>
      <div class="col-md-6 d-grid text-end">
        <label>Fecha : <span></span> de</label>
        <label> <span></span> de <span></span></label>
      </div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>www.geseme.com</label>
    </div>
    <!--/ footer section -->
  </div>
  <div class="row covid-form-doc page-break d-block m-t-20">
    <div class="row">
      <div class="col-md-7 d-block">
        <img alt="logo-RDT" src="assets\images\covid-logo.png" />
        <div class="left-header">
          <div class="form-group row m-t-10">
            <label class="col-form-label font-weight-bold">PR05 A57</label>
          </div>
          <div class="form-group row">
            <label class="col-form-label font-weight-bold">Rev :</label>
            <span>02</span>
          </div>
          <div class="form-group row">
            <label class="col-form-label font-weight-bold">Fecha :</label>
            <span> 23/09/2021</span>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="label-square"></div>
      </div>
    </div>
    <div class="row d-block">
      <h3 class="title-rapid">TOMA DE DATOS PACIENTE PARA PRUEBA COVID</h3>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">TIPO PRUEBA : </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">NOMBRE : </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">DNI : </label>
        <label class="col-form-label font-weight-bold m-l-40">FECHA NACIMIENTO :
        </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">DIRECCIÓN EMAIL :
        </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">CIP (TARJETA SANITARIA) :
        </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">RESULTADO TRAG : POSITIVO / NEGATIVO / NO VÁLIDO</label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">MARCA COMERCIAL TRAG:
        </label>
        <span class="detail-line"></span>
      </div>
    </div>
  </div>
</div>
<div id="PrintCovidA31Cat" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row covid-print-doc d-block m-l-15">
    <!-- header section -->
    <div class="row">
      <img alt="logo" src="assets\images\covid-logo.png" />
    </div>
    <!--/ header section -->
    <div class="row d-block">
      <h3 class="title-rapid">
        DECLARACIÓ DE CONSENTIMENT PER A LA REALITZACIO DE PROVES DIAGNÓSTIQUES
        DE LA COVID-19
      </h3>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Cognoms I Nom: </label>
        <span>lorem ipsum</span>
      </div>
      <div class="d-flex">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">Data Naixement:</label>
          <span>lorem ipsum</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">D.N.I. :</label>
          <span>lorem ipsum</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">Telf : </label>
          <span>0000000000</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Email : </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Municipi Domicili :
        </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Nº Targeta Sanitaria :
        </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">A Omplir Únicament Per Treballadors D’empreses Clients De Geseme
          :
        </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Nom Empresa : </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Municipi Empresa :
        </label>
        <span>lorem ipsum</span>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="form-group row">
        <label class="col-form-label">AUTORITZO em siguin extretes motres per a la següent prova :
        </label>
        <span>lorem ipsum</span>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">En el cas del Test Ràpid Antígens, AUTORIZO l’enviament dels
          resultats o descàrrega d'enllaç per correu electrònic.</label>
      </div>
    </div>
    <div class="row para-div">
      <p>
        Amb la signatura d'aquest document, AUTORITZO de forma voluntària a
        l'equip de GESEME per a la gestió de l'anàlisi del "frotis orofaringi
        i/o nasofaringi" i/o extracció sanguínia dirigida a la detecció de
        SARSCoV-2 i manifest la meva plena capacitat per atorgar el present
        consentiment. A l'una, vull deixar constància que se m'ha informat en
        què consisteix les dues tècniques de presa de mostres, com es realitza,
        i quines són les dificultats que poden presentar-se durant la
        realització de la tècnica. Així mateix, he estat informat que tots els
        processos d'assaig tenen un risc, reduït, de fals positiu per
        contaminació creuada.
      </p>
      <p>
        DESCÀRREC DE RESPONSABILITAT: En nom propi i en el dels meus hereus,
        cessionaris i / o representants legals, per la present allibero i
        exonero a GESEME 1996, SL, els seus socis, administradors, agents i
        representants DE TOTA RESPONSABILITAT LEGAL, derivada del resultat de
        l'anàlisi de la mostra. He llegit detingudament aquest acord i comprenc
        completament el seu contingut. Tinc coneixement que aquest acord és una
        exoneració de responsabilitat cap GESEME.
      </p>
      <p>
        GESEME declina tota responsabilitat relacionada amb la veracitat i
        exactitud de la informació facilitada per l'interessat per a la
        realització del test; així com dels resultats que aquest pogués oferir,
        atès que, per múltiples factors de mètode, aliens a ella, no es pot
        assegurar el resultat íntegrament.
      </p>
    </div>
    <div class="row protection-div">
      <label class="text-underline">CONFIDENCIALITAT I PROTECCIÓ DE DADES</label>
      <p>
        Totes les dades facilitades en aquest formulari seran tractades amb
        estricta confidencialitat atenent a la normativa legal vigent en matèria
        de protecció de dades, Reglament Europeu (UE) 2016/679 i Llei Orgànica
        3/2018. La finalitat del tractament de les dades és la gestió de
        l'anàlisi dirigida a la detecció de SARS-CoV-2 i poder prestar el servei
        sol·licitat. Responsable: GESEME. Base jurídica: Prestació del servei
        contractat. Destinataris: GESEME. Transferència internacional: No.
        Conservació: El temps necessari per complir amb la finalitat recollida i
        determinar les possibles responsabilitats derivades de la mateixa.
        Drets: Accés, rectificació, supressió i oposició, portabilitat i
        limitació del tractament, per escrit a: Att. Delegat de Protecció de
        Dades. GESEME, c / Aragó, 25 (08015) Barcelona; o per correu electrònic
        a:
        <span>dpo@geseme.com</span>. Per reclamacions, també pot dirigir-se a
        l'Autoritat de Control (Agència Espanyola de Protecció de Dades
        www.aepd.es). Més informació:
        <span> https://geseme.com/politica-de-privacidad</span>
      </p>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area">
      <div class="col-md-6">
        <label>Signatura: ...............................</label>
      </div>
      <div class="col-md-6 d-grid text-end">
        <label>Data : <span></span> de</label>
        <label> <span></span> de <span></span></label>
      </div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>www.geseme.com</label>
    </div>
    <!--/ footer section -->
  </div>
  <div class="row covid-form-doc page-break d-block m-t-20">
    <div class="row">
      <div class="col-md-7 d-block">
        <img alt="logo-RDT" src="assets\images\covid-logo.png" />
        <div class="left-header">
          <div class="form-group row m-t-10">
            <label class="col-form-label font-weight-bold">PR05 A57</label>
          </div>
          <div class="form-group row">
            <label class="col-form-label font-weight-bold">Rev :</label>
            <span>02</span>
          </div>
          <div class="form-group row">
            <label class="col-form-label font-weight-bold">Fecha :</label>
            <span> 23/09/2021</span>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="label-square"></div>
      </div>
    </div>
    <div class="row d-block">
      <h3 class="title-rapid">TOMA DE DATOS PACIENTE PARA PRUEBA COVID</h3>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">TIPO PRUEBA : </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">NOMBRE : </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">DNI : </label>
        <label class="col-form-label font-weight-bold m-l-40">FECHA NACIMIENTO :
        </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">DIRECCIÓN EMAIL :
        </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">CIP (TARJETA SANITARIA) :
        </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">RESULTADO TRAG : POSITIVO / NEGATIVO / NO VÁLIDO</label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">MARCA COMERCIAL TRAG:
        </label>
        <span class="detail-line"></span>
      </div>
    </div>
  </div>
</div>
<div id="PrintCovidA31Fra" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row covid-print-doc d-block m-l-15">
    <!-- header section -->
    <div class="row">
      <img alt="logo" src="assets\images\covid-logo.png" />
    </div>
    <!--/ header section -->
    <div class="row d-block">
      <h3 class="title-rapid">
        DÉCLARATION DE CONSENTEMENT POUR LA RÉALISATION DES TESTS DE DÉPISTAGE
        ET DIAGNOSTIC DE LA COVID-19
      </h3>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Nom Et Prénom : </label>
        <span>lorem ipsum</span>
      </div>
      <div class="d-flex">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">Date De Naissance :</label>
          <span>lorem ipsum</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">Carte D´Identité :</label>
          <span>lorem ipsum</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">Telf : </label>
          <span>0000000000</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Email : </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Adresse De La Municipalité :
        </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Carte Sanitaire Nº :
        </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">A Remplir Uniquement Par Les Travailleurs Des Enterprises
          Clientes De
          Geseme :
        </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Nom Entreprise : </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Municipalité D´Enterprise :
        </label>
        <span>lorem ipsum</span>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="form-group row">
        <label class="col-form-label">J´AUTORISE que des échantillons soient prélevés pour le test suivant
          :
        </label>
        <span>lorem ipsum</span>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">Dans le cas du Test Antigène Rapide, J'AUTORISE l'envoi des résultats
          ou le lien de téléchargement par email.</label>
      </div>
    </div>
    <div class="row para-div">
      <p>
        En signant le présent document, J’AUTORISE volontairement l’équipe de
        GESEME pour la gestion de l’analyse du "frottis oropharyngé et/ou
        nasopharyngé" et/ou du prélèvement de sang, destiné à la détection de
        SARSCoV-2 et déclare que je suis pleinement habilité à donner le présent
        consentement. En même temps, je tiens à signalar que jái été informé de
        la nature des deux techniques d´échantillonnage, telles qu´elles sont
        appliquées, et des difficultés qui preuvent se présenter lors de leur
        extraction. J´ai également été infotmé que tous les processus d’essai
        ont un risque, réduit, de faussement positif par contamination croisée.
      </p>
      <p>
        DÉCHARGE DE RESPONSABILITÉ: En son nom propre et au nom de mes
        héritiers, cessionnaries et/ou représentants légaux, libérer et exonérer
        GESEME 1996, SL, dérivée du résultat de l´analyse de l´echantillon. J’ai
        lu attentivement cet accord et j’en comprends parfaitement le contenu.
        Je sais que cet accord est une décharge de responsabilité envers GESEME.
      </p>
      <p>
        GESEME décline toute responsabilité quant à la véracité et à
        l’exactitude des informations fournies par la personne concernée aux
        fins de l’exécution du test, ainsi qu’aux résultats que celui-ci
        pourrait fournir, étant donné que, pour des facteurs de méthode
        multiples, le résultat intégral ne peut être assuré.
      </p>
    </div>
    <div class="row protection-div">
      <label class="text-underline">CONFIDENTIALITÉ ET PROTECTION DES DONNÉES</label>
      <p>
        Toutes les données fournies dans ce formulaire seront traitées avec une
        stricte confidentialité en conformité avec la réglementation légale en
        vigueur en matière de protection des données, Règlement européen (UE)
        2016/679 et Loi organique 3/2018. Le but du traitement des données est
        la gestion de l’analyse du "frottis oropharyngé et nasopharyngé" destiné
        à la détection du SRAS-CoV-2 et capable de fournir le service demandé.
        Responsable: Geseme. Base juridique : Prestation du service contractuel.
        Destinataires : Geseme. Transfert international : No. Conservation : Le
        temps nécessaire pour atteindre l’objectif de collecte et de déterminer
        les responsabilités éventuelles qui en découlent. Droits : Accès,
        rectification, suppression et opposition, portabilité et limitation du
        traitement, par écrit à : Att. Délégué à la protection des données.
        Geseme, c/ Aragón, 25 (08015) Barcelona; ou par courrier électronique à
        :
        <span>dpo@geseme.com</span>. Pour les réclamations, vous pouvez
        également vous adresser à l’Autorité de Contrôle (Agence Espagnole de
        Protection des Données www.aepd.fr). Informations complémentaires :
        <span>https://geseme.com/politica-de-privacidad</span>
      </p>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area">
      <div class="col-md-6">
        <label>Signature : ...............................</label>
      </div>
      <div class="col-md-6 d-grid text-end">
        <label>Date : <span></span> de</label>
        <label> <span></span> de <span></span></label>
      </div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>www.geseme.com</label>
    </div>
    <!--/ footer section -->
  </div>
  <div class="row covid-form-doc page-break d-block m-t-20">
    <div class="row">
      <div class="col-md-7 d-block">
        <img alt="logo-RDT" src="assets\images\covid-logo.png" />
        <div class="left-header">
          <div class="form-group row m-t-10">
            <label class="col-form-label font-weight-bold">PR05 A57</label>
          </div>
          <div class="form-group row">
            <label class="col-form-label font-weight-bold">Rev :</label>
            <span>02</span>
          </div>
          <div class="form-group row">
            <label class="col-form-label font-weight-bold">Fecha :</label>
            <span> 23/09/2021</span>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="label-square"></div>
      </div>
    </div>
    <div class="row d-block">
      <h3 class="title-rapid">TOMA DE DATOS PACIENTE PARA PRUEBA COVID</h3>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">TIPO PRUEBA : </label>
        <label class="col-form-label font-weight-bold m-l-40">FECHA : </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">NOMBRE : </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">DNI : </label>
        <label class="col-form-label font-weight-bold m-l-40">FECHA NACIMIENTO :
        </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">DIRECCIÓN EMAIL :
        </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">CIP (TARJETA SANITARIA) :
        </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">RESULTADO TRAG : POSITIVO / NEGATIVO / NO VÁLIDO</label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">MARCA COMERCIAL TRAG:
        </label>
        <span class="detail-line"></span>
      </div>
    </div>
  </div>
</div>
<div id="PrintCovidA31Ing" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row covid-print-doc d-block m-l-15">
    <!-- header section -->
    <div class="row">
      <img alt="logo" src="assets\images\covid-logo.png" />
    </div>
    <!--/ header section -->
    <div class="row d-block">
      <h3 class="title-rapid">
        DECLARATION OF CONSENT FOR THE PERFORMANCE OF DIAGNOSTIC TESTS OF
        COVID-19
      </h3>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Surname And Name :
        </label>
        <span>lorem ipsum</span>
      </div>
      <div class="d-flex">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">Date :</label>
          <span>lorem ipsum</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">ID :</label>
          <span>lorem ipsum</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">Phone Nº :
          </label>
          <span>0000000000</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Email : </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Municipality Domicile :
        </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Sanitary Card Nº :
        </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">To Be Fulfilled Just By Workers Of Geseme Client Companies :
        </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Company Name : </label>
        <span>lorem ipsum</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Company Municipality :
        </label>
        <span>lorem ipsum</span>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="form-group row">
        <label class="col-form-label">I AUTORIZE the collection of the required sample(s) for the
          performance of the following test :
        </label>
        <span>lorem ipsum</span>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">In the case of the Rapid Antigen Test, I AUTHORIZE the sending of the
          results or download link by email.</label>
      </div>
    </div>
    <div class="row para-div">
      <p>
        By signing this document, I voluntarily AUTHORIZE GESEME’s team to
        manage the analysis of the oropharyngeal and nasopharyngeal smear and
        blood extraction aimed at detecting SARS-CoV-2 and I express my full
        capacity to grant this consent. I have been informed what these sampling
        techniques consist of, how they are performed, and what are the
        difficulties that may arise during the performance of such techniques.
        In addition, I have been informed that all assay processes have a
        reduced risk of false positive cross contamination.
      </p>
      <p>
        DISCLAIMER: In my own name and in the name of my heirs, assigns and / or
        legal representatives, I hereby release and exonerate GESEME 1996, SL,
        its partners, administrators, agents and representatives OF ALL LEGAL
        RESPONSIBILITY, derived from the extraction performance and test result.
        I have carefully read this agreement and fully understand its content. I
        am aware that this agreement is an exemption from liability towards
        GESEME.
      </p>
      <p>
        GESEME declines all responsibility related to the veracity and accuracy
        of the information provided by the interested party to carry out the
        test; as well as the results that this could offer, given that, due to
        multiple method’s factors, unrelated to it, the result cannot be fully
        guaranteed.
      </p>
    </div>
    <div class="row protection-div">
      <label class="text-underline">DATA PRIVACY AND CONFIDENTIALITY</label>
      <p>
        All the data provided in this form will be treated with strict
        confidentiality according to current legal regulations on data
        protection, European’s Regulation (EU) 2016/679 and Organic Law’s
        03/2018. The purpose of the data processing is the management of the
        analysis aimed at detecting SARS-CoV-2 and being able to provide the
        request service. Responsible: Geseme. Legal basis: The responsible’s
        legitimate interest to control the risk’s factors against the
        Coronavirus of all the staff accessing the facilities. Recipients:
        Geseme. International transfer: No. Conservation: The necessary time in
        order to fulfil the purpose and determine the possible responsibilities
        derived from it. Rights: Access, rectification, deletion and opposition,
        portability and limitation of the treatment; in writing to: Att. Data
        Protection’s Officer. Geseme, 25,c/ Aragó (08015) Barcelona; or by email
        to:
        <span>dpo@geseme.com</span>. For any claim, you can also contact
        Autoridad de Control (Authority Control) (Agencia Española de Protección
        de Datos (Spanish Data Protection’s Agency) / www.aepd.es). For further
        information, visit:
        <span> https://geseme.com/politica-de-privacidad</span>
      </p>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area">
      <div class="col-md-6">
        <label>Signature : ...............................</label>
      </div>
      <div class="col-md-6 d-grid text-end">
        <label>Date : <span></span> th of<span></span></label>
        <label> <span></span></label>
      </div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>www.geseme.com</label>
    </div>
    <!--/ footer section -->
  </div>
  <div class="row covid-form-doc page-break d-block m-t-20">
    <div class="row">
      <div class="col-md-7 d-block">
        <img alt="logo-RDT" src="assets\images\covid-logo.png" />
        <div class="left-header">
          <div class="form-group row m-t-10">
            <label class="col-form-label font-weight-bold">PR05 A57</label>
          </div>
          <div class="form-group row">
            <label class="col-form-label font-weight-bold">Rev :</label>
            <span>02</span>
          </div>
          <div class="form-group row">
            <label class="col-form-label font-weight-bold">Date :</label>
            <span> 23/09/2021</span>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="label-square"></div>
      </div>
    </div>
    <div class="row d-block">
      <h3 class="title-rapid">COLLECTING PATIENT DATA FOR COVID TEST</h3>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">TYPE TEST : </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">NAME : </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">ID : </label>
        <label class="col-form-label font-weight-bold m-l-40">BIRTH DATE :
        </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">EMAIL ADDRESS : </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">CIP (SANITARY CARD) :
        </label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">TRAG RESULT: POSITIVE / NEGATIVE / INVALID</label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">TRADEMARK TRAG: </label>
        <span class="detail-line"></span>
      </div>
    </div>
  </div>
</div>

<div id="PR05A57_RAPID_ANT_CAS" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row PR05A57-rapidant-cas d-block m-l-15" style="page-break-after: always">
    <!-- header section -->
    <div class="row">
      <div class="col-md-3">
        <div class="left-box">
          <span>ETIQUETA</span>
        </div>
      </div>
      <div class="col-md-4">
        <img class="middle-logo" alt="logo-PR05A57-rapidant-cas" src="assets\images\valimed-print-logo.png" />
      </div>
      <div class="col-md-5 right-header">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">PR05 A57</label>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">Rev :</label>
          <span>02</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">Fecha :</label>
          <span>{{ this.getFormattedTodayDate() }}</span>
        </div>
      </div>
    </div>
    <!--/ header section -->
    <div class="row d-block m-t-20">
      <h3 class="title-rapid">
        Prueba rápida de diagnóstico para la detección cualitativa de antígeno
      </h3>
      <h3 class="title-rapid">SARS-CoV-2</h3>
      <h6 class="subtitle-rapid m-t-5">
        {{ this.appointmentData?.persona.apellidos }} ,{{
        this.appointmentData?.persona.nombre
        }}
      </h6>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">DNI :</label>
        <span>{{ this.appointmentData?.persona.dni }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">Fecha de Nacimiento :</label>
        <span>{{ this.appointmentData?.persona.birth_date }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">Solicitado por :</label>
        <span>Dr. Miguel González</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">Fecha emisión informe :
        </label>
        <span>{{ this.appointmentData?.attribs.fecha?.substr(6, 2) }} de
          {{ this.appointmentData?.attribs.fecha?.substr(4, 2) }} de
          {{ this.appointmentData?.attribs.fecha?.substr(0, 4) }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">Hora : </label>
        <span>{{ this.getHora(this.appointmentData?.attribs.hora) }}H</span>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="col-md-3">
        <label class="text-underline">Tipo de Muestra :</label>
      </div>
      <div class="col-md-9 d-grid">
        <label>Hisopo nasofaríngeo</label>
        <label>{{
          this.convertDateToReadableFormat(this.appointmentData?.attribs?.fecha)
          }}</label>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="col-md-3">
        <label class="text-underline">SARS-CoV-2 :</label>
      </div>
      <div class="col-md-9 d-grid" *ngFor="let section of this.appointmentData?.sections; index as i">
        <div *ngIf="section.type == this.testType?.toUpperCase()">
          <label>{{ section.data[this.testType + "_diagnostico_txt"] }}</label>
          <br />
          <label>{{ section.data[this.testType + "_proveedor_txt"] }}</label>
        </div>
      </div>
    </div>
    <div class="row observ-div">
      <label class="text-underline">Observaciones:</label>
      <div class="d-grid m-t-10">
        <span>El resultado está condicionado a la toma de la muestra. </span>
        <span>Este análisis no excluye la presencia de otras infecciones víricas o
          bacterianas.
        </span>
        <span>Un resultado negativo en una muestra obtenida con ausencia de
          sintomatología, no descarta completamente la presencia de virus.</span>
        <span>Con un resultado negativo con síntomas clínicos, es recomendable
          realizar una prueba PCR.
        </span>
      </div>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area">
      <div class="col-md-6 d-grid">
        <span>Dr. Miguel González Ruiz</span>
        <span>Nº Col: 08/28300</span>
        <span>Médico</span>
        <span>GESEME 1996 SL</span>
      </div>
      <div class="col-md-6"></div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>Geseme 1996 SL. – SP-007-B </label>
    </div>
    <!--/ footer section -->
  </div>

  <div class="row PR05A57-rapidant-cas d-block m-l-15">
    <!-- header section -->
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-4">
        <img class="middle-logo" alt="logo-PR05A57-rapidant-cas" src="assets\images\valimed-print-logo.png" />
      </div>
      <div class="col-md-5 right-header">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">PR05 A57</label>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">Rev :</label>
          <span>02</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">Fecha :</label>
          <span>{{ this.getFormattedTodayDate() }}</span>
        </div>
      </div>
    </div>
    <!--/ header section -->
    <div class="row d-block m-t-20">
      <h3 class="title-rapid">
        SARS-CoV-2 Antigen Detecting Rapid Diagnostic Test (RDT)
      </h3>
      <h6 class="subtitle-rapid m-t-5">
        {{ this.appointmentData?.persona.apellidos }} ,{{
        this.appointmentData?.persona.nombre
        }}
      </h6>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">ID Card :</label>
        <span>{{ this.appointmentData?.persona.dni }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">Date of Birth :</label>
        <span>{{ this.appointmentData?.persona.birth_date }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">Request by :</label>
        <span>Dr. Miguel González</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">Report issue date :
        </label>
        <span>{{ this.appointmentData?.attribs.fecha?.substr(6, 2) }} de
          {{ this.appointmentData?.attribs.fecha?.substr(4, 2) }} de
          {{ this.appointmentData?.attribs.fecha?.substr(0, 4) }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label f-s-13 font-weight-bold">Time : </label>
        <span>{{ this.getHora(this.appointmentData?.attribs.hora) }} H</span>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="col-md-3">
        <label class="text-underline">Sample Type :</label>
      </div>
      <div class="col-md-9 d-grid">
        <label>Nasopharyngeal swab</label>
        <label>{{
          this.convertDateToReadableFormat(this.appointmentData?.attribs?.fecha)
          }}</label>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="col-md-3">
        <label class="text-underline">SARS-CoV-2 :</label>
      </div>
      <div class="col-md-9 d-grid" *ngFor="let section of this.appointmentData?.sections; index as i">
        <div *ngIf="section.type == this.testType?.toUpperCase()">
          <label>{{ section.data[this.testType + "_diagnostico_txt"] }}</label>
          <br />
          <label>{{ section.data[this.testType + "_proveedor_txt"] }}</label>
        </div>
      </div>
    </div>
    <div class="row observ-div">
      <label class="text-underline">Observations:</label>
      <div class="d-grid m-t-10">
        <span>Result depends on sample quality. </span>
        <span>This analysis does not exclude the presence of other viral or
          bacterial infections
        </span>
        <span>A negative result from a sample obtained in the absence of symptoms
          does not completely discard the presence of the virus.</span>
        <span>A Negative RDT with clinical symptoms should be confirmed with a PCR.
        </span>
      </div>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area">
      <div class="col-md-6 d-grid">
        <span>Dr. Miguel González Ruiz</span>
        <span>Nº Col: 08/28300</span>
        <span>Médico</span>
        <span>GESEME 1996 SL</span>
      </div>
      <div class="col-md-6"></div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>Geseme 1996 SL. – SP-007-B </label>
    </div>
    <!--/ footer section -->
  </div>
</div>

<!-- Justificante de asistencia doc-->
<div id="justificant_edeasistencia" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row justificant_edeasistencia-doc d-block m-l-15">
    <!-- header section -->
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-4">
        <img class="middle-logo" alt="logo-medical" src="/assets/images/covid-print-logo.png" />
      </div>
      <div class="col-md-5 right-header m-t-20">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">PR05 A57</label>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">Rev :</label>
          <span>02</span>
        </div>
      </div>
    </div>
    <!--/ header section -->
    <div class="row d-block m-10-top">
      <h3 class="title-rapid text-center">JUSTIFICANTE DE ASISTENCIA</h3>
    </div>
    <div class="row m-10-top">
      <div class="col-md-2"></div>
      <div class="col-md-10 d-grid text-end">
        <label>Barcelona,<span class="m-l-5">{{
            convertDate(convertDate(covidAppoimnet?.fecha))
            }}</span>
        </label>
      </div>
    </div>
    <div class="row">
      <p>
        En el día de la fecha, ha estado en este consultorio el/la Sr/a
        <span>{{ covidAppoimnet?.apellidos_nombre_persona }}</span>, con DNI/NIE <span>{{ appointmentData?.persona?.dni
          }}</span>, habiendo salido del centro a las
        <span>{{ covidAppoimnet?.hora }}</span> h, para realizarse la prueba:
      </p>
      <p class="m-l-20">
        Prueba rápida de diagnóstico para la detección cualitativa de antígeno
        SARS-CoV-2 de tipo
        <span>{{ covidAppoimnet?.accion_tipo }}</span>
      </p>
      <p>Lo que se hace constar a los efectos oportunos.</p>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area m-10-top">
      <div class="col-md-6 d-grid">
        <span>Dep. Medicina del Trabajo</span>
        <span>GESEME 1996, SL</span>
        <span>C/Aragó 25</span>
        <span>08015 Barcelona</span>
      </div>
      <div class="col-md-6"></div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>Geseme 1996 SL.</label>
    </div>
    <!--/ footer section -->
  </div>
</div>

<!-- Recibo de Pago -->
<div id="recibode_pago_Report" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row recibode-pago-doc d-block m-l-15">
    <!-- header section -->
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-4">
        <img class="middle-logo" alt="logo-medical" src="/assets/images/covid-print-logo.png" />
      </div>
      <div class="col-md-5 right-header m-10-top">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">RECIBO DE PAGO</label>
          <label class="col-form-label font-weight-bold">CENTRO MÉDICO C/ARAGÓ 25</label>
        </div>
      </div>
    </div>
    <!--/ header section -->
    <div class="row m-10-top">
      <div class="col-md-2"></div>
      <div class="col-md-10 d-grid text-end m-10-top">
        <label>Barcelona,<span class="m-l-5">{{
            convertDate(covidAppoimnet?.fecha)
            }}</span>
        </label>
      </div>
    </div>
    <div class="row m-10-top">
      <p>
        En el día de la fecha el/la Sr/a
        <span>{{ covidAppoimnet?.apellidos_nombre_persona }}</span>, con DNI/NIE <span>{{ covidAppoimnet?.nip }}</span>,
        ha abonado la cantidad de «PACIENTEPAGO» € mediante pago
        <span></span>«PACIENTEFORMAPAGO» en concepto de:
      </p>
      <p class="m-l-20">
        Prueba rápida de diagnóstico para la detección cualitativa de antígeno
        SARS-CoV-2 de tipo
        <span>{{ covidAppoimnet?.accion_tipo }}</span>
      </p>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area m-10-top">
      <div class="col-md-6 d-grid">
        <span>Dep. Medicina del Trabajo</span>
        <span>GESEME 1996, SL</span>
        <span>C/Aragó 25</span>
        <span>08015 Barcelona</span>
      </div>
      <div class="col-md-6"></div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>Geseme 1996 SL.</label>
    </div>
    <!--/ footer section -->
  </div>
</div>

<!-- TOMA DE DATOS pruebas COVID-19 -->
<div id="tomaDeDATOS_Pruebas_Report" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row covid-form-doc d-block m-t-20">
    <div class="row">
      <div class="col-md-4 d-block">
        <div class="left-header">
          <div class="form-group row m-t-10">
            <label class="col-form-label font-weight-bold">PR05 A57</label>
          </div>
          <div class="form-group row">
            <label class="col-form-label font-weight-bold">Rev :</label>
            <span>02</span>
          </div>
          <div class="form-group row">
            <label class="col-form-label font-weight-bold">Date :</label>
            <span>
              {{
              convertDate(covidAppoimnet?.fecha)
              }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <img class="middle-logo" alt="logo-medical" src="/assets/images/covid-print-logo.png" />
      </div>
      <div class="col-md-3">
        <div class="label-square">
          <span>ETIQUETA</span>
        </div>
      </div>
    </div>
    <div class="row d-block m-b-30">
      <h3 class="title-rapid">TOMA DE DATOS PACIENTE PARA PRUEBA COVID</h3>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">TIPO PRUEBA : {{ covidAppoimnet?.accion_tipo }}
        </label>
        <label class="col-form-label font-weight-bold m-l-40">FECHA : </label>
        <span class="detail-line">
          {{ convertDate(covidAppoimnet?.fecha) }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">NOMBRE : </label>
        <span class="detail-line">{{
          covidAppoimnet?.apellidos_nombre_persona
          }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">DNI : {{ covidAppoimnet?.nip }}</label>
        <label class="col-form-label font-weight-bold m-l-40">FECHA NACIMIENTO :</label>
        <span class="detail-line">
          {{
          convertDateToReadableFormat(appointmentData?.persona?.birth_date)
          }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">DIRECCIÓN EMAIL :
        </label>
        <span class="detail-line">{{ appointmentData?.persona?.email }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">CIP (TARJETA SANITARIA) :
        </label>
        <span class="detail-line">{{
          appointmentData?.attribs?.tarjeta_sanitaria
          }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">RESULTADO TRAg: POSITIVO / NEGATIVO / NO VÁLIDO</label>
        <span class="detail-line"></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">MARCA COMERCIAL TRAg :
        </label>
        <span class="detail-line"></span>
      </div>
    </div>
  </div>
</div>

<!-- CASTELLANO Consentimiento pruebas COVID-19 -->
<div id="castellano_Pruebas_Report" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row covid-print-doc d-block m-l-15">
    <!-- header section -->
    <div class="row m-b-10">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <img class="middle-logo" alt="logo-medical" src="/assets/images/covid-print-logo.png" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <!--/ header section -->
    <div class="row d-block m-t-30">
      <h3 class="title-rapid">
        DECLARACIÓN DE CONSENTIMIENTO PARA LA REALIZACIÓN DE PRUEBAS
        DIAGNÓSTICAS DE LA COVID-19
      </h3>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Apellidos Y Nombre:
        </label>
        <span>{{ covidAppoimnet?.apellidos_nombre_persona }}</span>
      </div>
      <div class="d-flex">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">Fecha Nacimiento:
          </label>
          <span>{{
            convertDateToReadableFormat(appointmentData?.persona?.birth_date)
            }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">D.N.I. :
          </label>
          <span>{{ covidAppoimnet?.nip }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">Telf :
          </label>
          <span>{{ appointmentData?.persona?.telefono }}</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Email : </label>
        <span>{{ appointmentData?.persona?.email }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Municipio Domicilio :
        </label>
        <span>{{ appointmentData?.attribs?.poblacion_txt }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Nº TARJETA SANITARIA :
        </label>
        <span>{{ appointmentData?.attribs?.tarjeta_sanitaria }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">A rellenar únicamente por trabajadores de empresas clientes de
          Geseme
          :
        </label>
        <span></span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Nombre Empresa : </label>
        <span>{{ covidAppoimnet?.empresa }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">MUNICIPIO EMPRESA :
        </label>
        <span></span>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="form-group row">
        <label class="col-form-label">AUTORIZO me sean extraídas muestras para la siguiente prueba :
        </label>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">{{ covidAppoimnet?.accion_tipo }}</label>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">En el caso del Test Rápido Antígenos, AUTORIZO el envío de los
          resultados o enlace de descarga mediante correo electrónico.</label>
      </div>
    </div>
    <div class="row para-div">
      <p>
        Con la firma del presente documento, AUTORIZO de forma voluntaria al
        equipo de GESEME para la gestión del análisis del “frotis orofaríngeo
        y/o nasofaríngeo” y/o extracción de sangre, dirigida a la detección de
        SARSCoV-2 y manifiesto mi plena capacidad para otorgar el presente
        consentimiento. A la par, quiero dejar constancia de que se me ha
        informado en qué consisten ambas técnicas de toma de muestras, como se
        realizan, y cuáles son las dificultades que pueden presentarse durante
        la extracción de estas. Asimismo, he sido informado de que todos los
        procesos de ensayo tienen un riesgo, reducido, de falso positivo por
        contaminación cruzada.
      </p>
      <p>
        DESCARGO DE RESPONSABILIDAD: En nombre propio y en el de mis herederos,
        cesionarios y/o representantes legales, por la presente libero y exonero
        a GESEME 1996, SL, sus socios, administradores, agentes y representantes
        DE TODA RESPONSABILIDAD LEGAL, derivada del resultado del análisis de la
        muestra. He leído detenidamente este acuerdo y comprendo completamente
        su contenido. Tengo conocimiento de que este acuerdo es una exoneración
        de responsabilidad hacia GESEME.
      </p>
      <p>
        GESEME declina toda responsabilidad relacionada con la veracidad y
        exactitud de la información facilitada por el interesado para la
        realización de la prueba; así como de los resultados que este pudiera
        ofrecer, dado que, por múltiples factores de método, ajenos a ella, no
        se puede asegurar el resultado íntegramente.
      </p>
    </div>
    <div class="row protection-div">
      <label class="text-underline">CONFIDENCIALIDAD Y PROTECCIÓN DE DATOS
      </label>
      <p>
        Todos los datos facilitados en este formulario serán tratados con
        estricta confidencialidad atendiendo a la normativa legal vigente en
        materia de protección de datos, Reglamento Europeo (UE) 2016/679 y Ley
        Orgánica 3/2018. La finalidad del tratamiento de los datos es la gestión
        del análisis dirigida a la detección de SARS-CoV-2 y poder prestar el
        servicio solicitado. Responsable: Geseme. Base jurídica: Prestación del
        servicio contratado. Destinatarios: Geseme. Transferencia internacional:
        No. Conservación: El tiempo necesario para cumplir con la finalidad
        recogida y determinar las posibles responsabilidades derivadas de la
        misma. Derechos: Acceso, rectificación, supresión y oposición,
        portabilidad y limitación del tratamiento, por escrito a: Att. Delegado
        de Protección de Datos. Geseme, c/ Aragón, 25 (08015) Barcelona; o por
        correo electrónico a:
        <span>dpo@geseme.com</span>. Para reclamaciones, también puede dirigirse
        a la Autoridad de Control (Agencia Española de Protección de Datos
        www.aepd.es). Más información:<span>
          https://geseme.com/politica-de-privacidad</span>
      </p>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area">
      <div class="col-md-6">
        <label>Firma : ...............................</label>
      </div>
      <div class="col-md-6 d-grid text-end">
        <label>Fecha :
          <span>{{ convertDate(covidAppoimnet?.fecha) }}</span></label>
      </div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>www.geseme.com</label>
    </div>
    <!--/ footer section -->
  </div>
</div>

<!-- CATALÁN Consentimiento pruebas COVID-19 -->
<div id="catalanCons_Pruebas_Report" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row covid-print-doc d-block m-l-15">
    <!-- header section -->
    <div class="row m-b-10">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <img class="middle-logo" alt="logo-medical" src="/assets/images/covid-print-logo.png" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <!--/ header section -->
    <div class="row d-block m-t-30">
      <h3 class="title-rapid">
        DECLARACIÓ DE CONSENTIMENT PER A LA REALITZACIO DE PROVES DIAGNÓSTIQUES
        DE LA COVID-19
      </h3>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">COGNOMS I NOM : </label>
        <span>{{ covidAppoimnet?.apellidos_nombre_persona }}</span>
      </div>
      <div class="d-flex">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">DATA NAIXEMENT :
          </label>
          <span>{{
            convertDateToReadableFormat(appointmentData?.persona?.birth_date)
            }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">D.N.I. :
          </label>
          <span> {{ covidAppoimnet?.nip }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">TELF : </label>
          <span>{{ appointmentData?.persona?.telefono }}</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">EMAIl : </label>
        <span>{{ appointmentData?.persona?.email }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">MUNICIPI DOMICILI :
        </label>
        <span>{{ appointmentData?.attribs?.poblacion_txt }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">Nº TARGETA SANITÀRIA :
        </label>
        <span> {{ appointmentData?.attribs?.tarjeta_sanitaria }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">A omplir únicament per treballadors d’empreses clients de Geseme
          :
        </label>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">NOM EMPRESA : </label>
        <span>{{ covidAppoimnet?.empresa }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">MUNICIPI EMPRESA :
        </label>
        <span></span>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="form-group row">
        <label class="col-form-label">AUTORIZO me sean extraídas muestras para la siguiente prueba :
        </label>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">{{ covidAppoimnet?.accion_tipo }}</label>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">En el cas del Test Ràpid Antígens, AUTORIZO l’enviament dels
          resultats o descàrrega d'enllaç per correu electrònic.</label>
      </div>
    </div>
    <div class="row para-div">
      <p>
        Amb la signatura d'aquest document, AUTORITZO de forma voluntària a
        l'equip de GESEME per a la gestió de l'anàlisi del "frotis orofaringi
        i/o nasofaringi" i/o extracció sanguínia dirigida a la detecció de
        SARSCoV-2 i manifest la meva plena capacitat per atorgar el present
        consentiment. A l'una, vull deixar constància que se m'ha informat en
        què consisteix les dues tècniques de presa de mostres, com es realitza,
        i quines són les dificultats que poden presentar-se durant la
        realització de la tècnica. Així mateix, he estat informat que tots els
        processos d'assaig tenen un risc, reduït, de fals positiu per
        contaminació creuada.
      </p>
      <p>
        DESCÀRREC DE RESPONSABILITAT: En nom propi i en el dels meus hereus,
        cessionaris i / o representants legals, per la present allibero i
        exonero a GESEME 1996, SL, els seus socis, administradors, agents i
        representants DE TOTA RESPONSABILITAT LEGAL, derivada del resultat de
        l'anàlisi de la mostra. He llegit detingudament aquest acord i comprenc
        completament el seu contingut. Tinc coneixement que aquest acord és una
        exoneració de responsabilitat cap GESEME.
      </p>
      <p>
        GESEME declina tota responsabilitat relacionada amb la veracitat i
        exactitud de la informació facilitada per l'interessat per a la
        realització del test; així com dels resultats que aquest pogués oferir,
        atès que, per múltiples factors de mètode, aliens a ella, no es pot
        assegurar el resultat íntegrament.
      </p>
    </div>
    <div class="row protection-div">
      <label class="text-underline">CONFIDENCIALITAT I PROTECCIÓ DE DADES</label>
      <p>
        Totes les dades facilitades en aquest formulari seran tractades amb
        estricta confidencialitat atenent a la normativa legal vigent en matèria
        de protecció de dades, Reglament Europeu (UE) 2016/679 i Llei Orgànica
        3/2018. La finalitat del tractament de les dades és la gestió de
        l'anàlisi dirigida a la detecció de SARS-CoV-2 i poder prestar el servei
        sol·licitat. Responsable: GESEME. Base jurídica: Prestació del servei
        contractat. Destinataris: GESEME. Transferència internacional: No.
        Conservació: El temps necessari per complir amb la finalitat recollida i
        determinar les possibles responsabilitats derivades de la mateixa.
        Drets: Accés, rectificació, supressió i oposició, portabilitat i
        limitació del tractament, per escrit a: Att. Delegat de Protecció de
        Dades. GESEME, c / Aragó, 25 (08015) Barcelona; o per correu electrònic
        a :
        <span>dpo@geseme.com</span>. Per reclamacions, també pot dirigir-se a
        l'Autoritat de Control (Agència Espanyola de Protecció de Dades
        www.aepd.es). Més informació :<span>
          https://geseme.com/politica-de-privacidad</span>
      </p>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area">
      <div class="col-md-6">
        <label>Signatura : ..............................</label>
      </div>
      <div class="col-md-6 d-grid text-end">
        <label>Fecha :
          <span>{{ convertDate(covidAppoimnet?.fecha) }}</span> </label>
      </div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>www.geseme.com</label>
    </div>
    <!--/ footer section -->
  </div>
</div>

<!-- INGLÉS Consentimiento pruebas COVID-19 -->
<div id="ingles_Report" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row covid-print-doc d-block m-l-15">
    <!-- header section -->
    <div class="row m-b-10">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <img class="middle-logo" alt="logo-medical" src="/assets/images/covid-print-logo.png" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <!--/ header section -->
    <div class="row d-block m-t-30">
      <h3 class="title-rapid">
        DECLARATION OF CONSENT FOR THE PERFORMANCE OF DIAGNOSTIC TESTS OF
        COVID-19
      </h3>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">SURNAME AND NAME :
        </label>
        <span>{{ covidAppoimnet?.apellidos_nombre_persona }}</span>
      </div>
      <div class="d-flex">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">DATE : </label>
          <span>{{
            convertDateToReadableFormat(appointmentData?.persona?.birth_date)
            }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">ID : </label>
          <span>{{ covidAppoimnet?.nip }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">PHONE Nº :
          </label>
          <span> {{ appointmentData?.persona?.telefono }}</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">E-MAIL : </label>
        <span>{{ appointmentData?.persona?.email }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">MUNICIPALITY DOMICILE :
        </label>
        <span>{{ appointmentData?.attribs?.poblacion_txt }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">SANITARY CARD Nº :
        </label>
        <span> {{ appointmentData?.attribs?.tarjeta_sanitaria }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">To be fulfilled just by workers of Geseme client companies :
        </label>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">COMPANY NAME : </label>
        <span>{{ covidAppoimnet?.empresa }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">COMPANY MUNICIPALITY :
        </label>
        <span></span>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="form-group row">
        <label class="col-form-label">I AUTORIZE the collection of the required sample(s) for the
          performance of the following test :
        </label>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">{{ covidAppoimnet?.accion_tipo }}</label>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">In the case of the Rapid Antigen Test, I AUTHORIZE the sending of the
          results or download link by email.</label>
      </div>
    </div>
    <div class="row para-div">
      <p>
        By signing this document, I voluntarily AUTHORIZE GESEME’s team to
        manage the analysis of the oropharyngeal and nasopharyngeal smear and
        blood extraction aimed at detecting SARS-CoV-2 and I express my full
        capacity to grant this consent. I have been informed what these sampling
        techniques consist of, how they are performed, and what are the
        difficulties that may arise during the performance of such techniques in
        minors due to the importance of the patient remaining immobile during
        the sampling. In addition, I have been informed that all assay processes
        have a reduced risk of false positive cross contamination.
      </p>
      <p>
        DISCLAIMER: In my own name and in the name of my heirs, assigns and / or
        legal representatives, I hereby release and exonerate GESEME 1996, SL,
        its partners, administrators, agents and representatives OF ALL LEGAL
        RESPONSIBILITY, derived from the extraction performance and test result.
        I have carefully read this agreement and fully understand its content. I
        am aware that this agreement is an exemption from liability towards
        GESEME.
      </p>
      <p>
        GESEME declines all responsibility related to the veracity and accuracy
        of the information provided by the interested party to carry out the
        test; as well as the results that this could offer, given that, due to
        multiple method’s factors, unrelated to it, the result cannot be fully
        guaranteed.
      </p>
    </div>
    <div class="row protection-div">
      <label class="text-underline">DATA PRIVACY AND CONFIDENTIALITY</label>
      <p>
        All the data provided in this form will be treated with strict
        confidentiality according to current legal regulations on data
        protection, European’s Regulation (EU) 2016/679 and Organic Law’s
        03/2018. The purpose of the data processing is the management of the
        analysis aimed at detecting SARS-CoV-2 and being able to provide the
        request service. Responsible: Geseme. Legal basis: The responsible’s
        legitimate interest to control the risk’s factors against the
        Coronavirus of all the staff accessing the facilities. Recipients:
        Geseme. International transfer: No. Conservation: The necessary time in
        order to fulfil the purpose and determine the possible responsibilities
        derived from it. Rights: Access, rectification, deletion and opposition,
        portability and limitation of the treatment; in writing to: Att. Data
        Protection’s Officer. Geseme, 25,c/ Aragó (08015) Barcelona; or by email
        to:
        <span>dpo@geseme.com</span>. For any claim, you can also contact
        Autoridad de Control (Authority Control) (Agencia Española de Protección
        de Datos (Spanish Data Protection’s Agency) / www.aepd.es). For further
        information, visit:
        <span> https://geseme.com/politica-de-privacidad</span>
      </p>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area">
      <div class="col-md-6">
        <label>Signature : ...............................</label>
      </div>
      <div class="col-md-6 d-grid text-end">
        <label>Date : <span>{{ convertDate(covidAppoimnet?.fecha) }}</span>
        </label>
      </div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>www.geseme.com</label>
    </div>
    <!--/ footer section -->
  </div>
</div>

<!-- FRANCÉS Consentimiento pruebas COVID-19 -->
<div id="france_Predas_Report" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row covid-print-doc d-block m-l-15">
    <!-- header section -->
    <div class="row m-b-10">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <img class="middle-logo" alt="logo-medical" src="/assets/images/covid-print-logo.png" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <!--/ header section -->
    <div class="row d-block m-t-30">
      <h3 class="title-rapid">
        DÉCLARATION DE CONSENTEMENT POUR LA RÉALISATION DES TESTS DE DÉPISTAGE
        ET DIAGNOSTIC DE LA COVID-19
      </h3>
    </div>
    <div class="row m-t-20 d-block">
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">NOM ET PRÉNOM : </label>
        <span>{{ covidAppoimnet?.apellidos_nombre_persona }}</span>
      </div>
      <div class="d-flex">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">DATE DE NAISSANCE :</label>
          <span>{{
            convertDateToReadableFormat(appointmentData?.persona?.birth_date)
            }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">CARTE D´IDENTITÉ :</label>
          <span>{{ covidAppoimnet?.nip }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">TELF : </label>
          <span> {{ appointmentData?.persona?.telefono }}</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">EMAIL : </label>
        <span>{{ appointmentData?.persona?.email }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">ADRESSE DE LA MUNICIPALITÉ :
        </label>
        <span>{{ appointmentData?.attribs?.poblacion_txt }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">CARTE SANITAIRE Nº :
        </label>
        <span>{{ appointmentData?.attribs?.tarjeta_sanitaria }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">A remplir uniquement par les travailleurs des enterprises
          clientes de
          Geseme :
        </label>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">NOM ENTREPRISE : </label>
        <span>{{ covidAppoimnet?.empresa }}</span>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">MUNICIPALITÉ D´ENTERPRISE :
        </label>
        <span></span>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="form-group row">
        <label class="col-form-label">J´AUTORISE que des échantillons soient prélevés pour le test suivant
          :
        </label>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">{{ covidAppoimnet?.accion_tipo }}</label>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">Dans le cas du Test Antigène Rapide, J'AUTORISE l'envoi des résultats
          ou le lien de téléchargement par email.</label>
      </div>
    </div>
    <div class="row para-div">
      <p>
        En signant le présent document, J’AUTORISE volontairement l’équipe de
        GESEME pour la gestion de l’analyse du "frottis oropharyngé et/ou
        nasopharyngé" et/ou du prélèvement de sang, destiné à la détection de
        SARSCoV-2 et déclare que je suis pleinement habilité à donner le présent
        consentement. En même temps, je tiens à signalar que jái été informé de
        la nature des deux techniques d´échantillonnage, telles qu´elles sont
        appliquées, et des difficultés qui preuvent se présenter lors de leur
        extraction. J´ai également été infotmé que tous les processus d’essai
        ont un risque, réduit, de faussement positif par contamination croisée.
      </p>
      <p>
        DÉCHARGE DE RESPONSABILITÉ: En son nom propre et au nom de mes
        héritiers, cessionnaries et/ou représentants légaux, libérer et exonérer
        GESEME 1996, SL, dérivée du résultat de l´analyse de l´echantillon. J’ai
        lu attentivement cet accord et j’en comprends parfaitement le contenu.
        Je sais que cet accord est une décharge de responsabilité envers GESEME.
      </p>
      <p>
        GESEME décline toute responsabilité quant à la véracité et à
        l’exactitude des informations fournies par la personne concernée aux
        fins de l’exécution du test, ainsi qu’aux résultats que celui-ci
        pourrait fournir, étant donné que, pour des facteurs de méthode
        multiples, le résultat intégral ne peut être assuré.
      </p>
    </div>
    <div class="row protection-div">
      <label class="text-underline">CONFIDENTIALITÉ ET PROTECTION DES DONNÉES</label>
      <p>
        Toutes les données fournies dans ce formulaire seront traitées avec une
        stricte confidentialité en conformité avec la réglementation légale en
        vigueur en matière de protection des données, Règlement européen (UE)
        2016/679 et Loi organique 3/2018. Le but du traitement des données est
        la gestion de l’analyse du "frottis oropharyngé et nasopharyngé" destiné
        à la détection du SRAS-CoV-2 et capable de fournir le service demandé.
        Responsable: Geseme. Base juridique : Prestation du service contractuel.
        Destinataires : Geseme. Transfert international : No. Conservation : Le
        temps nécessaire pour atteindre l’objectif de collecte et de déterminer
        les responsabilités éventuelles qui en découlent. Droits : Accès,
        rectification, suppression et opposition, portabilité et limitation du
        traitement, par écrit à : Att. Délégué à la protection des données.
        Geseme, c/ Aragón, 25 (08015) Barcelona; ou par courrier électronique à
        :
        <span>dpo@geseme.com</span>. Pour les réclamations, vous pouvez
        également vous adresser à l’Autorité de Contrôle (Agence Espagnole de
        Protection des Données www.aepd.fr). Informations complémentaires :
        <span> https://geseme.com/politica-de-privacidad</span>
      </p>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area">
      <div class="col-md-6">
        <label>Signature : ...............................</label>
      </div>
      <div class="col-md-6 d-grid text-end">
        <label>Fecha :
          <span>{{ convertDate(covidAppoimnet?.fecha) }}</span></label>
      </div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>www.geseme.com</label>
    </div>
    <!--/ footer section -->
  </div>
</div>

<!-- CASTELLANO Consentimiento MENORES pruebas COVID-19 -->
<div id="castellano_MenoresPrebas_Report" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row covid-print-doc d-block m-l-15">
    <!-- header section -->
    <div class="row m-b-10">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <img class="middle-logo" alt="logo-medical" src="/assets/images/covid-print-logo.png" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <!--/ header section -->
    <div class="row d-block m-t-30">
      <h3 class="title-rapid">
        DECLARACIÓN DE CONSENTIMIENTO PARA LA REALIZACIÓN DE PRUEBAS
        DIAGNÓSTICAS DE LA COVID-19
      </h3>
    </div>
    <div class="row m-t-20 d-block clr-blue">
      <label class="col-form-label font-weight-bold">REPRESENTANTE DEL MENOR</label>
      <div class="form-group row m-t-10">
        <label class="col-form-label">APELLIDOS Y NOMBRE REPRESENTANTE :
          ................................................................</label>
      </div>
      <div class="form-group row">
        <label class="col-form-label">con DNI : ..................................... en calidad de:</label>
      </div>
    </div>
    <div class="row">
      <ul class="check-diseases-list d-flex p-l-0">
        <li class="m-b-10 d-content">
          <div class="d-flex clr-blue">
            <div class="check-box"></div>
            Padre
          </div>
        </li>
        <li class="m-b-10 d-content">
          <div class="d-flex m-l-15 clr-blue">
            <div class="check-box"></div>
            Madre
          </div>
        </li>
        <li class="m-b-10 d-content">
          <div class="d-flex m-l-15 clr-blue">
            <div class="check-box"></div>
            Representante
          </div>
        </li>
        <li class="m-b-10 d-content">
          <div class="d-flex m-l-15 clr-blue">
            <div class="check-box"></div>
            Tutor legal
          </div>
        </li>
      </ul>
    </div>
    <div class="row m-t-20 d-block">
      <label class="col-form-label font-weight-bold">DATOS DEL MENOR</label>
      <div class="form-group row m-t-10">
        <label class="col-form-label font-weight-bold">APELLIDOS Y NOMBRE:
        </label>
        <span>{{ covidAppoimnet?.apellidos_nombre_persona }}</span>
      </div>
      <div class="d-flex">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">FECHA NACIMIENTO:</label>
          <span>{{
            convertDateToReadableFormat(appointmentData?.persona?.birth_date)
            }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">D.N.I. :</label>
          <span>{{ covidAppoimnet?.nip }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">TELF : </label>
          <span>{{ appointmentData?.persona?.telefono }}</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">EMAIL : </label>
        <span>{{ appointmentData?.persona?.email }}</span>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="form-group row">
        <label class="col-form-label">AUTORIZO sean extraídas muestras al menor para la siguiente prueba :
        </label>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">{{ covidAppoimnet?.accion_tipo }}</label>
      </div>
    </div>
    <div class="row para-div">
      <p>
        Con la firma del presente documento, AUTORIZO de forma voluntaria al
        equipo de GESEME para la gestión del análisis del “frotis orofaríngeo
        y/o nasofaríngeo” y/o extracción de sangre, dirigida a la detección de
        SARSCoV-2 y manifiesto mi plena capacidad para otorgar el presente
        consentimiento. A la par, quiero dejar constancia de que se me ha
        informado en qué consisten ambas técnicas de toma de muestras, como se
        realizan, y cuáles son las dificultades que pueden presentarse durante
        la extracción de estas en menores debido a la importancia de que el
        paciente permanezca inmóvil durante la toma de muestra. Asimismo, he
        sido informado de que todos los procesos de ensayo tienen un riesgo,
        reducido, de falso positivo por contaminación cruzada.
      </p>
      <p>
        DESCARGO DE RESPONSABILIDAD: En nombre propio y en el de mis herederos,
        cesionarios y/o representantes legales, por la presente libero y exonero
        a GESEME 1996, SL, sus socios, administradores, agentes y representantes
        DE TODA RESPONSABILIDAD LEGAL, derivada del resultado del análisis de la
        muestra. He leído detenidamente este acuerdo y comprendo completamente
        su contenido. Tengo conocimiento de que este acuerdo es una exoneración
        de responsabilidad hacia GESEME.
      </p>
      <p>
        GESEME declina toda responsabilidad relacionada con la veracidad y
        exactitud de la información facilitada por el interesado para la
        realización de la prueba; así como de los resultados que este pudiera
        ofrecer, dado que, por múltiples factores de método, ajenos a ella, no
        se puede asegurar el resultado íntegramente.
      </p>
    </div>
    <div class="row protection-div">
      <label class="text-underline">CONFIDENCIALIDAD Y PROTECCIÓN DE DATOS
      </label>
      <p>
        Todos los datos facilitados en este formulario serán tratados con
        estricta confidencialidad atendiendo a la normativa legal vigente en
        materia de protección de datos, Reglamento Europeo (UE) 2016/679 y Ley
        Orgánica 3/2018. La finalidad del tratamiento de los datos es la gestión
        del análisis dirigida a la detección de SARS-CoV-2 y poder prestar el
        servicio solicitado. Responsable: Geseme. Base jurídica: Prestación del
        servicio contratado. Destinatarios: Geseme. Transferencia internacional:
        No. Conservación: El tiempo necesario para cumplir con la finalidad
        recogida y determinar las posibles responsabilidades derivadas de la
        misma. Derechos: Acceso, rectificación, supresión y oposición,
        portabilidad y limitación del tratamiento, por escrito a: Att. Delegado
        de Protección de Datos. Geseme, c/ Aragón, 25 (08015) Barcelona; o por
        correo electrónico a:
        <span>dpo@geseme.com</span>. Para reclamaciones, también puede dirigirse
        a la Autoridad de Control (Agencia Española de Protección de Datos
        www.aepd.es). Más información:<span>
          https://geseme.com/politica-de-privacidad</span>
      </p>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area">
      <div class="col-md-6">
        <label>Firma : ...............................</label>
      </div>
      <div class="col-md-6 d-grid text-end">
        <label>Fecha :
          <span>{{ convertDate(covidAppoimnet?.fecha) }}</span></label>
      </div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>www.geseme.com</label>
    </div>
    <!--/ footer section -->
  </div>
</div>

<!-- CATALÀN Consentimiento MENORES pruebas COVID-19 -->
<div id="catalan_MenoresPruebas_Report" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row covid-print-doc d-block m-l-15">
    <!-- header section -->
    <div class="row m-b-10">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <img class="middle-logo" alt="logo-medical" src="/assets/images/covid-print-logo.png" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <!--/ header section -->
    <div class="row d-block m-t-30">
      <h3 class="title-rapid">
        DECLARACIÓ DE CONSENTIMENT PER A LA REALITZACIO DE PROVES DIAGNÒSTIQUES
        DE LA COVID-19 MENORS
      </h3>
    </div>
    <div class="row m-t-20 d-block clr-blue">
      <label class="col-form-label font-weight-bold">REPRESENTANTE DEL MENOR</label>
      <div class="form-group row m-t-10">
        <label class="col-form-label">COGNOMS I NOM REPRESENTANT :
          ................................................................</label>
      </div>
      <div class="form-group row">
        <label class="col-form-label">amb DNI: ..................................... En qualitat de:</label>
      </div>
    </div>
    <div class="row">
      <ul class="check-diseases-list d-flex p-l-0">
        <li class="m-b-10 d-content">
          <div class="d-flex clr-blue">
            <div class="check-box"></div>
            Padre
          </div>
        </li>
        <li class="m-b-10 d-content">
          <div class="d-flex m-l-15 clr-blue">
            <div class="check-box"></div>
            Madre
          </div>
        </li>
        <li class="m-b-10 d-content">
          <div class="d-flex m-l-15 clr-blue">
            <div class="check-box"></div>
            Representant
          </div>
        </li>
        <li class="m-b-10 d-content">
          <div class="d-flex m-l-15 clr-blue">
            <div class="check-box"></div>
            Tutor legal
          </div>
        </li>
      </ul>
    </div>
    <div class="row m-t-20 d-block">
      <label class="col-form-label font-weight-bold">DADES DEL MENOR</label>
      <div class="form-group row m-t-10">
        <label class="col-form-label font-weight-bold">COGNOMS I NOM : </label>
        <span>{{ covidAppoimnet?.apellidos_nombre_persona }}</span>
      </div>
      <div class="d-flex">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">DATA NAIXEMENT :</label>
          <span>{{
            convertDateToReadableFormat(appointmentData?.persona?.birth_date)
            }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">D.N.I. :</label>
          <span>{{ covidAppoimnet?.nip }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">TELF : </label>
          <span>{{ appointmentData?.persona?.telefono }}</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">EMAIL : </label>
        <span>{{ appointmentData?.persona?.email }}</span>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="form-group row">
        <label class="col-form-label">AUTORITZO siguin extretes motres al menor per a la següent prova :
        </label>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">{{ covidAppoimnet?.accion_tipo }}</label>
      </div>
    </div>
    <div class="row para-div">
      <p>
        Amb la signatura d'aquest document, AUTORITZO de forma voluntària a
        l'equip de GESEME per a la gestió de l'anàlisi del "frotis orofaringi
        i/o nasofaringi" i/o extracció sanguínia dirigida a la detecció de
        SARSCoV-2 i manifest la meva plena capacitat per atorgar el present
        consentiment.A l'una, vull deixar constància que se m'ha informat en què
        consisteix les dues tècniques de presa de mostres, com es realitza, i
        quines són les dificultats que poden presentar-se durant la realització
        de la tècnica en menors a cauda de la importancia que el pacient romagui
        immòbil durant la presa de mostra. Així mateix, he estat informat que
        tots els processos d'assaig tenen un risc, reduït, de fals positiu per
        contaminació creuada.
      </p>
      <p>
        DESCÀRREC DE RESPONSABILITAT: En nom propi i en el dels meus hereus,
        cessionaris i / o representants legals, per la present allibero i
        exonero a GESEME 1996, SL, els seus socis, administradors, agents i
        representants DE TOTA RESPONSABILITAT LEGAL, derivada del resultat de
        l'anàlisi de la mostra. He llegit detingudament aquest acord i comprenc
        completament el seu contingut. Tinc coneixement que aquest acord és una
        exoneració de responsabilitat cap GESEME.
      </p>
      <p>
        GESEME declina tota responsabilitat relacionada amb la veracitat i
        exactitud de la informació facilitada per l'interessat per a la
        realització del test; així com dels resultats que aquest pogués oferir,
        atès que, per múltiples factors de mètode, aliens a ella, no es pot
        assegurar el resultat íntegrament.
      </p>
    </div>
    <div class="row protection-div">
      <label class="text-underline">CONFIDENCIALITAT I PROTECCIÓ DE DADES</label>
      <p>
        Totes les dades facilitades en aquest formulari seran tractades amb
        estricta confidencialitat atenent a la normativa legal vigent en matèria
        de protecció de dades, Reglament Europeu (UE) 2016/679 i Llei Orgànica
        3/2018. La finalitat del tractament de les dades és la gestió de
        l'anàlisi dirigida a la detecció de SARS-CoV-2 i poder prestar el servei
        sol·licitat. Responsable: GESEME. Base jurídica: Prestació del servei
        contractat. Destinataris: GESEME. Transferència internacional: No.
        Conservació: El temps necessari per complir amb la finalitat recollida i
        determinar les possibles responsabilitats derivades de la mateixa.
        Drets: Accés, rectificació, supressió i oposició, portabilitat i
        limitació del tractament, per escrit a: Att. Delegat de Protecció de
        Dades. GESEME, c / Aragó, 25 (08015) Barcelona; o per correu electrònic
        a:
        <span>dpo@geseme.com</span>. Per reclamacions, també pot dirigir-se a
        l'Autoritat de Control (Agència Espanyola de Protecció de Dades
        www.aepd.es). Més informació:
        <span> https://geseme.com/politica-de-privacidad</span>
      </p>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area">
      <div class="col-md-6">
        <label>Signature : ...............................</label>
      </div>
      <div class="col-md-6 d-grid text-end">
        <label>Fecha :
          <span>{{ convertDate(covidAppoimnet?.fecha) }}</span></label>
      </div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>www.geseme.com</label>
    </div>
    <!--/ footer section -->
  </div>
</div>

<!-- INGLÉS Consentimiento MENORES pruebas COVID-19 -->
<div id="ingles_Menores_Pruebas" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row covid-print-doc d-block m-l-15">
    <!-- header section -->
    <div class="row m-b-10">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <img class="middle-logo" alt="logo-medical" src="/assets/images/covid-print-logo.png" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <!--/ header section -->
    <div class="row d-block m-t-30">
      <h3 class="title-rapid">
        DECLARATION OF CONSENT FOR THE PERFORMANCE OF DIAGNOSTIC TESTS OF
        COVID-19 MINORS
      </h3>
    </div>
    <div class="row m-t-20 d-block clr-blue">
      <label class="col-form-label font-weight-bold">REPRESENTANTE DEL MENOR</label>
      <div class="form-group row m-t-10">
        <label class="col-form-label">SURNAME AND NAME REPRESENTATIVE :
          ................................................................</label>
      </div>
      <div class="form-group row">
        <label class="col-form-label">with ID : ..................................... as:</label>
      </div>
    </div>
    <div class="row">
      <ul class="check-diseases-list d-flex p-l-0">
        <li class="m-b-10 d-content">
          <div class="d-flex clr-blue">
            <div class="check-box"></div>
            Father
          </div>
        </li>
        <li class="m-b-10 d-content">
          <div class="d-flex m-l-15 clr-blue">
            <div class="check-box"></div>
            Mother
          </div>
        </li>
        <li class="m-b-10 d-content">
          <div class="d-flex m-l-15 clr-blue">
            <div class="check-box"></div>
            Representative
          </div>
        </li>
        <li class="m-b-10 d-content">
          <div class="d-flex m-l-15 clr-blue">
            <div class="check-box"></div>
            Legal Guardian
          </div>
        </li>
      </ul>
    </div>
    <div class="row m-t-20 d-block">
      <label class="col-form-label font-weight-bold">MINOR INFORMATION</label>
      <div class="form-group row m-t-10">
        <label class="col-form-label font-weight-bold">SURNAME AND NAME :
        </label>
        <span>{{ covidAppoimnet?.apellidos_nombre_persona }}</span>
      </div>
      <div class="d-flex">
        <div class="form-group row">
          <label class="col-form-label font-weight-bold">DATE :</label>
          <span>{{
            convertDateToReadableFormat(appointmentData?.persona?.birth_date)
            }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">ID :</label>
          <span>{{ covidAppoimnet?.nip }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label font-weight-bold m-l-20">PHONE Nº :
          </label>
          <span>{{ appointmentData?.persona?.telefono }}</span>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label font-weight-bold">E-MAIL : </label>
        <span>{{ appointmentData?.persona?.email }}</span>
      </div>
    </div>
    <div class="row m-t-20">
      <div class="form-group row">
        <label class="col-form-label">I AUTORIZE the collection of the required sample(s) from the minor
          for the performance of the following test :
        </label>
      </div>
    </div>
    <div class="row m-t-10 m-b-20">
      <div class="form-group row">
        <label class="col-form-label">{{ covidAppoimnet?.accion_tipo }}</label>
      </div>
    </div>
    <div class="row para-div">
      <p>
        By signing this document, I voluntarily AUTHORIZE GESEME’s team to
        manage the analysis of the oropharyngeal and nasopharyngeal smear and
        blood extraction aimed at detecting SARS-CoV-2 and I express my full
        capacity to grant this consent. I have been informed what these sampling
        techniques consist of, how they are performed, and what are the
        difficulties that may arise during the performance of such techniques in
        minors due to the importance of the patient remaining immobile during
        the sampling. In addition, I have been informed that all assay processes
        have a reduced risk of false positive cross contamination.
      </p>
      <p>
        DISCLAIMER: In my own name and in the name of my heirs, assigns and / or
        legal representatives, I hereby release and exonerate GESEME 1996, SL,
        its partners, administrators, agents and representatives OF ALL LEGAL
        RESPONSIBILITY, derived from the extraction performance and test result.
        I have carefully read this agreement and fully understand its content. I
        am aware that this agreement is an exemption from liability towards
        GESEME.
      </p>
      <p>
        GESEME declines all responsibility related to the veracity and accuracy
        of the information provided by the interested party to carry out the
        test; as well as the results that this could offer, given that, due to
        multiple method’s factors, unrelated to it, the result cannot be fully
        guaranteed.
      </p>
    </div>
    <div class="row protection-div">
      <label class="text-underline">DATA PRIVACY AND CONFIDENTIALITY</label>
      <p>
        All the data provided in this form will be treated with strict
        confidentiality according to current legal regulations on data
        protection, European’s Regulation (EU) 2016/679 and Organic Law’s
        03/2018. The purpose of the data processing is the management of the
        analysis aimed at detecting SARS-CoV-2 and being able to provide the
        request service. Responsible: Geseme. Legal basis: The responsible’s
        legitimate interest to control the risk’s factors against the
        Coronavirus of all the staff accessing the facilities. Recipients:
        Geseme. International transfer: No. Conservation: The necessary time in
        order to fulfil the purpose and determine the possible responsibilities
        derived from it. Rights: Access, rectification, deletion and opposition,
        portability and limitation of the treatment; in writing to: Att. Data
        Protection’s Officer. Geseme, 25,c/ Aragó (08015) Barcelona; or by email
        to:
        <span>dpo@geseme.com</span>. For any claim, you can also contact
        Autoridad de Control (Authority Control) (Agencia Española de Protección
        de Datos (Spanish Data Protection’s Agency) / www.aepd.es). For further
        information, visit:
        <span> https://geseme.com/politica-de-privacidad</span>
      </p>
    </div>
    <!-- letter bottom section -->
    <div class="row sign-area">
      <div class="col-md-6">
        <label>Signature : ...............................</label>
      </div>
      <div class="col-md-6 d-grid text-end">
        <label>Date : <span>{{ convertDate(covidAppoimnet?.fecha) }}</span>
        </label>
      </div>
    </div>
    <!--/ letter bottom section -->

    <!-- footer section -->
    <div class="footer-covid">
      <label>www.geseme.com</label>
    </div>
    <!--/ footer section -->
  </div>
</div>

<!-- CASTELLANO RGPD -->
<div id="castellano_rgpd" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row rgpd-print-doc d-block m-l-15">
    <!-- header section -->
    <div style="height: 62%;">
      <div class="row m-b-10">
        <div class="col-md-4">
          <img alt="logo-medical" src="/assets/images/geseme-medical-experts-logo.png" width="220px" />
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-7">
          <h4 style="text-align: right; font-size: 12px; margin: 8px 0; color: #3b91d6;">AUTORIZACIÓN / RENUNCIA A
            REALIZAR EL EXAMEN DE SALUD</h4>
        </div>
      </div>


      <!--/ header section -->
      <div class="row m-t-20 d-block">
        <div class="form-group row m-t-10">
          <label class="col-form-label" style="color: #6c92bb;">NOMBRE Y APELLIDOS :
          </label>
          <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.persona?.apellidos}}&nbsp;{{
            appointmentData?.persona?.nombre }}</span>
        </div>
        <div class="d-flex">
          <div class="form-group row">
            <label class="col-form-label" style="color: #6c92bb;">AÑO DE NACIMIENTO :</label>
            <span
              style="font-weight: bold; color: #376daa;">{{convertDateToReadableFormat(appointmentData?.persona?.birth_date)}}</span>
          </div>
          <div class="form-group row">
            <label class="col-form-label m-l-20" style="color: #6c92bb;">ID :</label>
            <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.persona?.dni}}</span>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group row">
            <label class="col-form-label" style="color: #6c92bb;">NÚMERO DE TELEFONO :</label>
            <span style="font-weight: bold; color: #376daa;">{{personTelNo}}</span>
          </div>
          <div class="form-group row">
            <label class="col-form-label m-l-20" style="color: #6c92bb;">E-MAIL :</label>
            <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.persona?.email }}</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label" style="color: #6c92bb;">PUESTO DE TRABAJO : </label>
          <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.attribs?.puestovs_txt }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label" style="color: #6c92bb;">COMPAÑIA : </label>
          <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.attribs?.empresa_txt }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label" style="color: #6c92bb;">CENTRO DE TRABAJO : </label>
          <span style="font-weight: bold; color: #376daa;">{{ Sedes }}</span>
        </div>
      </div>

      <div class="row para-div">
        <p style="font-size: 13px; text-align: justify;">
          De acuerdo con los requisitos de información y protección de datos para el tratamiento de datos personales y
          el principio de transparencia contenidos en el Reglamento General de Protección de Datos 2016/679 / UE, así
          como la normativa estatal aplicable de la Ley Orgánica 3/2018 LOPDGDD, GESEME 1996, SL le informa que los
          datos obtenidos de la revisión médica, que usted autoriza voluntariamente, serán tratados por los
          profesionales sanitarios de GESEME 1996, SL. Los datos obtenidos a través de los cuestionarios específicos y
          derivados de las pruebas complementarias que se realicen por motivos de la revisión médica, pasan a formar
          parte de la historia clínica laboral. La información y los datos obtenidos se conservarán según los criterios
          establecidos por la normativa sobre autonomía del paciente y el tratamiento de su información médica.
          Asimismo, sus datos de contacto (teléfono y correo electrónico) serán utilizados para poder comunicarle
          información sobre sus reconocimientos médicos, como por ejemplo, recordarle su visita, comunicarle el estado
          de sus resultados o enviárselos a través de un enlace de descarga por correo electrónico. <span
            style="font-weight: bold;">Los reconocimientos médicos son voluntarios,</span> salvo en los casos recogidos
          en el artículo 22, punto 1, apartado 2 de la Ley 31/95 de Prevención de Riesgos Laborales (LPRL), que es,
          básicamente, cuando sea imprescindible para evaluar los efectos de las condiciones de trabajo sobre la salud,
          para comprobar si el estado de salud del trabajador puede constituir un riesgo para sí mismo o para los demás,
          o si está específicamente regulado por la ley (especialmente los del Anexo I del RD 39/97 de 17 de enero).
          <span style="font-weight: bold;">Los resultados del examen son reservados o confidenciales,</span> de forma
          que el personal sanitario (y en su caso, la autoridad sanitaria legitimada para conocer dicha información) no
          puede transmitir al empresario o a terceros, el resultado del control de salud, salvo que el trabajador lo
          autorice expresamente (art. 22.2, 3 y 4 de la LPRL), quedando, por tanto, constancia de la APTITUD para el
          desempeño de su trabajo. Si en la actualidad no realiza este reconocimiento médico específico, y si con
          posterioridad a esta fecha, desea realizarlo, la empresa no se negará y le ofrecerá la posibilidad, con las
          mismas condiciones y garantías que en la actualidad.
        </p>
      </div>
    </div>

    <!-- letter bottom section -->
    <div style="height: 10%;">
      <div class="row sign-area" style="margin: 30px 0;">
        <div class="col-md-8">
          <label class="col-form-label"><span style="font-weight: bold;">AUTORIZO la</span> realización del
            reconocimiento médico
          </label>
        </div>
        <div class="col-md-4" style="text-align: right;">
          <label>Firma : ...............................</label>
        </div>
      </div>
    </div>

    <!--/ letter bottom section -->
    <div style="height: 23%;">
      <div>
        <p style="font-size: 13px; color: #777; text-align: justify;">
          Puede ejercer los derechos de acceso, rectificación, supresión, limitación del tratamiento, portabilidad y
          oposición, reconocidos en la LOPDGDD 3/2018 y en el Reglamento General de Protección de Datos 2016/679,
          enviando una carta acompañada de una copia de su DNI dirigida a GESEME 1996, S.L., a la atención del Delegado
          de Protección de Datos, con domicilio en C/ Aragó, 25 (08015) Barcelona, o por correo electrónico a la
          dirección <span style="color: #3b91d6;">dpo@geseme.com</span>, o a través de los formularios disponibles en
          los centros Geseme. En cualquier caso de vulneración del derecho a la protección de datos de carácter
          personal, puede dirigirse a la Autoridad de Control correspondiente a nivel nacional (Agencia Española de
          Protección de Datos, <span style="color: #3b91d6;">www.agpd.es</span>) para iniciar los trámites oportunos de
          reclamación y/o defensa de sus derechos.
        </p>
      </div>

      <!-- footer section -->
      <div style="margin-top: 30px;">
        <label style="color: #ccc;">PR05PR05-A06_1 v7 20211019</label>
      </div>
      <!--/ footer section -->
    </div>

  </div>
</div>

<!-- CATALÀN RGPD -->
<div id="catalan_rgpd" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row rgpd-print-doc d-block m-l-15">
    <!-- header section -->
    <div style="height: 62%;">

      <div class="row m-b-10">
        <div class="col-md-4">
          <img alt="logo-medical" src="/assets/images/geseme-medical-experts-logo.png" width="220px" />
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-7">
          <h4 style="text-align: right; font-size: 12px; margin: 8px 0; color: #3b91d6;">AUTORITZACIÓ / RENÚNCIA A
            REALITZAR L'EXAMEN DE SALUT</h4>
        </div>
      </div>
      <!--/ header section -->
      <div class="row m-t-20 d-block">
        <div class="form-group row m-t-10">
          <label class="col-form-label" style="color: #6c92bb;">NOM I COGNOMS :
          </label>
          <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.persona?.apellidos}}&nbsp;{{
            appointmentData?.persona?.nombre }}</span>
        </div>
        <div class="d-flex">
          <div class="form-group row">
            <label class="col-form-label" style="color: #6c92bb;">ANY DE NAIXEMENT :</label>
            <span
              style="font-weight: bold; color: #376daa;">{{convertDateToReadableFormat(appointmentData?.persona?.birth_date)}}</span>
          </div>
          <div class="form-group row">
            <label class="col-form-label m-l-20" style="color: #6c92bb;">ID :</label>
            <span style="font-weight: bold; color: #376daa;">{{appointmentData?.persona?.dni}}</span>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group row">
            <label class="col-form-label" style="color: #6c92bb;">TELÈFON :</label>
            <span style="font-weight: bold; color: #376daa;">{{personTelNo}}</span>
          </div>
          <div class="form-group row">
            <label class="col-form-label m-l-20" style="color: #6c92bb;">E-MAIL :</label>
            <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.persona?.email }}</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label" style="color: #6c92bb;">LLOC DE TREBALL : </label>
          <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.attribs?.puestovs_txt }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label" style="color: #6c92bb;">COMPANYIA : </label>
          <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.attribs?.empresa_txt }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label" style="color: #6c92bb;">CENTRE DE TREBALL : </label>
          <span style="font-weight: bold; color: #376daa;">{{ Sedes }}</span>
        </div>
      </div>
      <div class="row para-div">
        <p style="font-size: 13px; text-align: justify;">
          D'acord amb els requisits d'informació i protecció de dades per al tractament de dades personals i el principi
          de transparència continguts en el Reglament General de Protecció de Dades 2016/679 / UE, així com la normativa
          estatal aplicable de la Llei Orgànica 3/2018 LOPDGDD, GESEME 1996, SL l'informa que les dades obtingudes de la
          revisió mèdica, que vostè autoritza voluntàriament, seran tractats pels professionals sanitaris de GESEME
          1996, SL. Les dades obtingudes a través dels qüestionaris específics i derivats de les proves complementàries
          que es realitzin per motius de la revisió mèdica, passen a formar part de la història clínica laboral. La
          informació i les dades obtingudes es conservaran segons els criteris establerts per la normativa sobre
          autonomia del pacient i el tractament de la seva informació mèdica. Així mateix, les seves dades de contacte
          (telèfon i correu electrònic) seran utilitzades per poder comunicar-li informació sobre els seus
          reconeixements mèdics, com per exemple, recordar-li la seva visita, comunicar-li l'estat dels seus resultats o
          enviar-les a través d'un enllaç de descàrrega per correu electrònic. <span style="font-weight: bold;">Els
            reconeixements mèdics són voluntaris,</span> excepte en els casos recollits a l'article 22, punt 1, apartat
          2 de la Llei 31/95 de Prevenció de Riscos Laborals (LPRL), que és, bàsicament, quan sigui imprescindible per
          avaluar els efectes de les condicions de treball sobre la salut, per comprovar si l'estat de salut del
          treballador pot constituir un risc per a si mateix o per als altres, o si està específicament regulat per la
          llei (especialment els de l'Annex I del RD 39/97 de 17 de gener). <span style="font-weight: bold;">Els
            resultats de l'examen són reservats o confidencials,</span> de manera que el personal sanitari (i en el seu
          cas, l'autoritat sanitària legitimada per conèixer aquesta informació) no pot transmetre a l'empresari o a
          tercers, el resultat del control de salut, llevat que el treballador ho autoritzi expressament (art. 22.2, 3 i
          4 de la LPRL), quedant, per tant, constància de l' APTITUD per a l' acompliment del seu treball. Si en l'
          actualitat no realitza aquest reconeixement mèdic específic, i si amb posterioritat a aquesta data, desitja
          realitzar-lo, l' empresa no es negarà i li oferirà la possibilitat, amb les mateixes condicions i garanties
          que en l' actualitat.
        </p>
      </div>
    </div>

    <!-- letter bottom section -->
    <div style="height: 10%;">
      <div class="row sign-area" style="margin: 30px 0;">
        <div class="col-md-8">
          <label class="col-form-label"><span style="font-weight: bold;">AUTORITZO la</span> realització del
            reconeixement mèdic
          </label>
        </div>
        <div class="col-md-4" style="text-align: right;">
          <label>Signatura : ...............................</label>
        </div>
      </div>
    </div>

    <!--/ letter bottom section -->
    <div style="height: 23%;">
      <div>
        <p style="font-size: 13px; color: #777; text-align: justify;">
          Pot exercir els drets d'accés, rectificació, supressió, limitació del tractament, portabilitat i oposició,
          reconeguts en la LOPDGDD 3/2018 i en el Reglament General de Protecció de Dades 2016/679, enviant una carta
          acompanyada d'una còpia del seu DNI dirigida a GESEME 1996, S.L., a l'atenció del Delegat de Protecció de
          Dades, amb domicili al C/ Aragó, 25 (08015) Barcelona, o per correu electrònic a l'adreça <span
            style="color: #3b91d6;">dpo@geseme.com</span>, o a través dels formularis disponibles als centres Geseme. En
          qualsevol cas de vulneració del dret a la protecció de dades de caràcter personal, pot dirigir-se a
          l'Autoritat de Control corresponent a nivell nacional (Agència Espanyola de Protecció de Dades, <span
            style="color: #3b91d6;">www.agpd.es</span>) per iniciar els tràmits oportuns de reclamació i/o defensa dels
          seus drets.
        </p>
      </div>

      <!-- footer section -->
      <div style="margin-top: 30px;">
        <label style="color: #ccc;">PR05PR05-A06_1 v7 20211019</label>
      </div>
      <!--/ footer section -->
    </div>

  </div>
</div>

<!-- ENGLISH RGPD -->
<div id="english_rgpd" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row rgpd-print-doc d-block m-l-15">
    <!-- header section -->
    <div style="height: 62%;">

      <div class="row m-b-10">
        <div class="col-md-4">
          <img alt="logo-medical" src="/assets/images/geseme-medical-experts-logo.png" width="220px" />
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-7">
          <h4 style="text-align: right; font-size: 12px; margin: 8px 0; color: #3b91d6;">AUTHORIZATION / WAIVER
            PERFORMING HEALTH EXAMINATION
          </h4>
        </div>
      </div>
      <!--/ header section -->
      <div class="row m-t-20 d-block">
        <div class="form-group row m-t-10">
          <label class="col-form-label" style="color: #6c92bb;">LAST NAME AND NAME :
          </label>
          <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.persona?.apellidos}}&nbsp;{{
            appointmentData?.persona?.nombre }}</span>
        </div>
        <div class="d-flex">
          <div class="form-group row">
            <label class="col-form-label" style="color: #6c92bb;">DATE OF BIRTH :</label>
            <span
              style="font-weight: bold; color: #376daa;">{{convertDateToReadableFormat(appointmentData?.persona?.birth_date)}}</span>
          </div>
          <div class="form-group row">
            <label class="col-form-label m-l-20" style="color: #6c92bb;">ID :</label>
            <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.persona?.dni }}</span>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group row">
            <label class="col-form-label" style="color: #6c92bb;">PHONE NUMBER :</label>
            <span style="font-weight: bold; color: #376daa;">{{personTelNo}}</span>
          </div>
          <div class="form-group row">
            <label class="col-form-label m-l-20" style="color: #6c92bb;">E-MAIL :</label>
            <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.persona?.email }}</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label" style="color: #6c92bb;">JOB POSITION : </label>
          <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.attribs?.puestovs_txt }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label" style="color: #6c92bb;">COMPANY : </label>
          <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.attribs?.empresa_txt }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label" style="color: #6c92bb;">WORK CENTRE : </label>
          <span style="font-weight: bold; color: #376daa;">{{ Sedes }}</span>
        </div>
      </div>
      <div class="row para-div">
        <p style="font-size: 13px; text-align: justify;">
          In accordance with the information and data protection’s requirements for the processing of personal data and
          the transparency principle contained in the General Data Protection Regulation 2016/679 / EU, as well as the
          Organic Law 3/2018 LOPDGDD’s applicable state regulations, GESEME 1996, SL informs you that the data obtained
          from the medical review, which you voluntarily authorize, will be processed by the healthcare professionals of
          GESEME 1996, SL. The data obtained through the specific questionnaires and derived from the complementary
          tests that are carried out for reasons of the medical review, become part of the occupational medical history.
          The information and data obtained will be kept according to the criteria established by the regulations on
          patient autonomy and the treatment of their medical information. Likewise, your contact information (telephone
          and email) will be used in order to be able to communicate information your medical examinations, such as, for
          example, reminding you of your visit, communicating the status of your results or sending them through of a
          download link via email. <span style="font-weight: bold;">Medical check-ups are voluntary,</span> except in
          those cases included in the 22nd article, point 1, paragraph 2 of the Occupational Risk Prevention Act 31/95
          (LPRL), which is, basically when it is essential to assess the effects of working conditions on health, to
          verify whether the worker's state of health may constitute a risk to himself or others, or if it is
          specifically regulated by law (especially those of Annex I of RD 39/97 of January 17). <span
            style="font-weight: bold;">The examination’s results are reserved or confidential,</span> in such a way that
          health personnel (and where appropriate, the health authority legitimized to know said information) is not
          allowed to transmit to the employer or third parties, the result of the health control, unless the worker
          expressly authorizes it (art. 22.2, 3 and 4 of the LPRL), leaving, therefore, proof only of the APTITUDE for
          the performance of their job. If you do not carry out this specific medical examination at present, and if
          after this date, you would like to do it, the company will not refuse and will offer you the possibility, with
          the same conditions and guarantees as at present.
        </p>
      </div>
    </div>

    <!-- letter bottom section -->
    <div style="height: 10%;">
      <div class="row sign-area" style="margin: 30px 0;">
        <div class="col-md-8">
          <label class="col-form-label"><span style="font-weight: bold;">I AUTHORIZE</span> the performance of the
            medical examination
          </label>
        </div>
        <div class="col-md-4" style="text-align: right;">
          <label>Signature : ...............................</label>
        </div>
      </div>
    </div>
    <!--/ letter bottom section -->

    <div style="height: 23%;">
      <div>
        <p style="font-size: 13px; color: #777; text-align: justify;">
          You can exercise the rights of access, rectification, deletion, limitation of treatment, portability and
          opposition, recognized in the LOPDGDD 3/2018 and General Data Protection Regulation 2016/679, by sending a
          letter accompanied by a copy of your ID addressed to GESEME 1996, SL, to the attention of the Data Protection
          Delegate, with address at C / Aragó, 25 (08015) Barcelona, ​​or by email to the address <span
            style="color: #3b91d6;">dpo@geseme.com</span>, or through the forms available at Geseme centers. In any case
          of violation of the right to the protection of personal data, you can contact the corresponding Control
          Authority at the national level (Spanish Agency for Data Protection, <span
            style="color: #3b91d6;">www.agpd.es</span>) to initiate the appropriate procedures for claiming and / or
          defending your rights.
        </p>
      </div>

      <!-- footer section -->
      <div style="margin-top: 30px;">
        <label style="color: #ccc;">PR05PR05-A06_1 v7 20211019</label>
      </div>
      <!--/ footer section -->
    </div>
  </div>
</div>

<!-- FRENCH RGPD -->
<div id="french_rgpd" class="m-t-20 m-l-15" [hidden]="true">
  <div class="row rgpd-print-doc d-block m-l-15">
    <!-- header section -->
    <div style="height: 62%;">

      <div class="row m-b-10">
        <div class="col-md-4">
          <img alt="logo-medical" src="/assets/images/geseme-medical-experts-logo.png" width="220px" />
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-7">
          <h4 style="text-align: right; font-size: 12px; margin: 8px 0; color: #3b91d6;">AUTORISATION / RENONCIATION À
            EFFECTUER L’EXAMEN DE SANTÉ</h4>
        </div>
      </div>
      <!--/ header section -->
      <div class="row m-t-20 d-block">
        <div class="form-group row m-t-10">
          <label class="col-form-label" style="color: #6c92bb;">NOM ET PRÉNOM :
          </label>
          <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.persona?.apellidos}}&nbsp;{{
            appointmentData?.persona?.nombre }}</span>
        </div>
        <div class="d-flex">
          <div class="form-group row">
            <label class="col-form-label" style="color: #6c92bb;">ANNÉE DE NAISSANCE :</label>
            <span
              style="font-weight: bold; color: #376daa;">{{convertDateToReadableFormat(appointmentData?.persona?.birth_date)}}</span>
          </div>
          <div class="form-group row">
            <label class="col-form-label m-l-20" style="color: #6c92bb;">ID :</label>
            <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.persona?.dni }}</span>
          </div>
        </div>
        <div class="d-flex">
          <div class="form-group row">
            <label class="col-form-label" style="color: #6c92bb;">NUMÉRO DE TÉLÉPHONE :</label>
            <span style="font-weight: bold; color: #376daa;">{{personTelNo}}</span>
          </div>
          <div class="form-group row">
            <label class="col-form-label m-l-20" style="color: #6c92bb;">E-MAIL :</label>
            <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.persona?.email }}</span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label" style="color: #6c92bb;">TITRE DU POSTE : </label>
          <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.attribs?.puestovs_txt }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label" style="color: #6c92bb;">ENTREPRISE : </label>
          <span style="font-weight: bold; color: #376daa;">{{ appointmentData?.attribs?.empresa_txt }}</span>
        </div>
        <div class="form-group row">
          <label class="col-form-label" style="color: #6c92bb;">LIEU DE TRAVAIL : </label>
          <span style="font-weight: bold; color: #376daa;">{{Sedes}}</span>
        </div>
      </div>
      <div class="row para-div">
        <p style="font-size: 13px; text-align: justify;">
          Conformément aux exigences d’information et de protection des données pour le traitement des données
          personnelles et au principe de transparence contenus dans le règlement général sur la protection des données
          2016/679 / UE, ainsi qu’aux réglementations nationales applicables de la loi organique 3/2018 LOPDGDD, GESEME
          1996, SL vous informe que les données obtenues à partir de l’examen médical, que vous autorisez
          volontairement, sera traité par les professionnels de la santé de GESEME 1996, SL. Les données obtenues à
          travers les questionnaires spécifiques et dérivées des tests complémentaires qui sont effectués pour des
          raisons de l’examen médical, font partie de l’histoire clinique professionnelle. Les informations et données
          obtenues seront conservées selon les critères établis par la réglementation sur l’autonomie des patients et le
          traitement de leurs informations médicales. De même, vos coordonnées (téléphone et e-mail) seront utilisées
          pour pouvoir communiquer des informations sur vos examens médicaux, telles que vous rappeler votre visite,
          communiquer l’état de vos résultats ou vous les envoyer via un lien de téléchargement par e-mail.<span
            style="font-weight: bold;"> Les examens médicaux sont volontaires</span>, sauf dans les cas prévus à
          l’article 22, point 1, alinéa 2, de la loi 31/95 sur la prévention des risques professionnels (LPRL), qui est,
          en substance, lorsqu’il est essentiel d’évaluer les effets des conditions de travail sur la santé, de vérifier
          si l’état de santé du travailleur peut constituer un risque pour lui-même ou pour autrui, ou s’il est
          spécifiquement réglementé par la loi (en particulier ceux de l’annexe I du RD 39/97 du 17 janvier). <span
            style="font-weight: bold;">Les résultats de l’examen sont réservés ou confidentiels</span> , de sorte que le
          personnel de santé (et le cas échéant, l’autorité sanitaire habilitée à connaître ces informations) ne peut
          transmettre à l’employeur ou à des tiers, le résultat du contrôle sanitaire, sauf autorisation expresse du
          travailleur (art. 22.2, 3 et 4 de la LPRL), étant, par conséquent, la preuve de l’APTITUDE pour l’exécution de
          leur travail. Si, à l’heure actuelle, vous n’effectuez pas cet examen médical spécifique et si, après cette
          date, vous souhaitez le faire, la société ne refusera pas et vous en offrira la possibilité, avec les mêmes
          conditions et garanties qu’actuellement.
        </p>
      </div>
    </div>
    <!-- letter bottom section -->
    <div style="height: 10%;">
      <div class="row sign-area" style="margin: 30px 0;">
        <div class="col-md-8">
          <label class="col-form-label"><span style="font-weight: bold;">J'AUTORISE</span> la réalisation de l'examen
            médical
          </label>
        </div>
        <div class="col-md-4" style="text-align: right;">
          <label>Signature : ...............................</label>
        </div>
      </div>
    </div>
    <!--/ letter bottom section -->

    <div style="height: 23%;">
      <div>
        <p style="font-size: 13px; color: #777; text-align: justify;">
          Vous pouvez exercer les droits d’accès, de rectification, de suppression, de limitation de traitement, de
          portabilité et d’opposition, reconnus dans le LOPDGDD 3/2018 et dans le Règlement général sur la protection
          des données 2016/679, en envoyant une lettre accompagnée d’une copie de votre pièce d’identité adressée à
          GESEME 1996, S.L., à l’attention du délégué à la protection des données, avec adresse à C / Aragó, 25 (08015)
          Barcelone, ou par e-mail à l’adresse <span style="color: #3b91d6;">dpo@geseme.com</span>, ou via les
          formulaires disponibles dans les centres Geseme. En cas de violation du droit à la protection des données
          personnelles, vous pouvez contacter l’autorité de contrôle correspondante au niveau national (Agence espagnole
          de protection des données, <span style="color: #3b91d6;">www.agpd.es</span>) pour initier les procédures
          appropriées de réclamation et / ou de défense de vos droits.
        </p>
      </div>

      <!-- footer section -->
      <div style="margin-top: 30px;">
        <label style="color: #ccc;">PR05PR05-A06_1 v7 20211019</label>
      </div>
      <!--/ footer section -->
    </div>
  </div>
</div>