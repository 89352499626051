import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'CustomeDatePipe'
})

export class CustomeDatePipeFormat extends
  DatePipe implements PipeTransform {
  isMoment: boolean;
  transform(value: any, _args: any): any {

    if (value._isAMomentObject) { // check if it's not a moment object
      this.isMoment = true;
    }
    else {
      this.isMoment = false;
    }
    let dDate = new Date(value);
    let dDay = dDate.getDate();
    
    if (Number.isNaN(dDay)) {
      let sSub = String(value);
      let sSplitStr = sSub.split("/");
      let nDate = sSplitStr[2] + "-" + sSplitStr[1] + "-" + sSplitStr[0];
      return super.transform(nDate, "dd/MM/yyyy");
    } else {
      let month = dDate.getMonth() + 1;
      if (this.isMoment) {
        let nDate = dDate.getFullYear() + "-" + month + "-" + dDay;
        return super.transform(nDate, "dd/MM/yyyy");
      } else {
        let nDate = +dDay + "-" + month + "-" + dDate.getFullYear();
        return super.transform(nDate, "dd/MM/yyyy");
      }
    }

  }
}