import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, AuthRedirect } from '../app/service/authentication/auth-guard';
import { HomeComponent } from '../app/modules/authentication/home/home.component';
import { SigninRedirectCallbackComponent } from './modules/authentication/signin-redirect-callback/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from './modules/authentication/signout-redirect-callback/signout-redirect-callback.component';
import { UnauthorizedComponent } from '../app/modules/authentication/unauthorized/unauthorized.component';
import { LoginLayoutComponent } from '../app/modules/shared/login-layout/login-layout.component';
import { MainLayoutComponent } from '../app/modules/shared/main-layout/main-layout.component';
import { MenuComponent } from './modules/shared/menu/menu.component';
import { CovidScreenComponent } from './modules/visualizer-citars/covid-screen/covid-screen.component';


const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'visualizer',
        loadChildren: () => import('./modules/visualizer-citars/visualizer-citars.module').then(m => m.VisualizerCitarsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'agendar-nuevas',
        loadChildren: () => import('./modules/agendar-nuevas-citas/agendar-nuevas-citas.module').then(m => m.AgendarNuevasCitasModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'reception',
        loadChildren: () => import('./modules/reception/reception.module').then(m => m.ReceptionModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'agendar-list',
        loadChildren: () => import('./modules/agendar-list/agendar-list.module').then(m => m.AgendarListModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'contactos',
        loadChildren: () => import('./modules/contactos/contactos.module').then(m => m.ContactosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'powerbi',
        loadChildren: () => import('./modules/powerbi/powerbi.module').then(m => m.PowerbiModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'memoria-vs',
        loadChildren: () => import('./modules/memoria-vs/memoria-vs.module').then(m => m.MemoriaVsModule),
            canActivate: [AuthGuard]
      },
      {
        path: 'puestosvs',
        loadChildren: () => import('./modules/puestos-vs/puestos-vs.module').then(m => m.PuestosVsModule),
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: '',
    component: MenuComponent,
    children: [
      {
        path: 'uploader',
        loadChildren: () => import('./modules/uploading/uploading.module').then(m => m.UploadingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'validator',
        loadChildren: () => import('./modules/validating/validating.module').then(m => m.ValidatingModule),
        canActivate: [AuthGuard]
      },
      { path: 'covid-screen', component: CovidScreenComponent, canActivate: [AuthGuard] },
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      { path: 'signin-callback', component: SigninRedirectCallbackComponent },
      { path: 'signout-callback', component: SignoutRedirectCallbackComponent },
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: 'signin', component: HomeComponent, canActivate: [AuthRedirect] }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
