import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AgendaCopyException } from 'src/app/model/agendar/agenda-copy-exception';


const excep = new Subject<AgendaCopyException>();
@Injectable({
  providedIn: 'root'
})
export class AgendaCopyExceptionService {
  exceptionAvailability:any=false;
  destino:any="";
  public excep = excep;
  public isClicked :boolean;

  setException(except :AgendaCopyException){
    this.excep.next(except);
  }
  public buttonClicked()
  {
    this.isClicked=true;
  }
  public buttonClickReset()
  {
    this.isClicked=false;
  }
}
