import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AgendarListComponent } from './agendar-list.component';
import { EditAgendarComponent } from './edit-agendar/edit-agendar.component';
import { ScheduleConfigurationComponent } from './schedule-configuration/schedule-configuration.component';
import { AuthGuard } from '../../../app/service/authentication/auth-guard';

const routes: Routes = [
  {
    path: 'view',
    component: AgendarListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit/:id/:id2',
    component: EditAgendarComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'save/:id/:id2',
    component: EditAgendarComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'schedule',
    component: ScheduleConfigurationComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AgendaListRoutingModule { }
