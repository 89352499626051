import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgendarListComponent } from './agendar-list.component';
import { AgendaListRoutingModule } from './agenda-list-routing.module';
import { SharedModule } from '../shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { OwlDateTimeModule } from '@busacca/ng-pick-datetime';
import { EditAgendarComponent } from './edit-agendar/edit-agendar.component';
import { ScheduleConfigurationComponent } from './schedule-configuration/schedule-configuration.component';
import { AddExceptionComponent } from './add-exception/add-exception.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomeDatePipeFormat } from './agenda-ui/custome-date-pipe'
import { AgendaService } from './agenda-services/agenda-service';
import { EditAppointmentTimeframesComponent } from './edit-appointment-timeframes/edit-appointment-timeframes.component';

@NgModule({
  declarations: [
    AgendarListComponent,
    EditAgendarComponent,
    ScheduleConfigurationComponent,
    AddExceptionComponent,
    CustomeDatePipeFormat,
    EditAppointmentTimeframesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgendaListRoutingModule,
    NgxPaginationModule,
    OwlDateTimeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers:[
    AgendaService
  ]
})
export class AgendarListModule { }
