import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/service/common/common.service';
import {AgendaDisplay } from '../../model/agendar/agenda-display';
import {AgendaService} from './agenda-services/agenda-service';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from 'src/app/service/shared/ui.service';

@Component({
  selector: 'app-agendar-list',
  templateUrl: './agendar-list.component.html',
  styleUrls: ['./agendar-list.component.scss']
})
export class AgendarListComponent implements OnInit {

  angendaDetails : AgendaDisplay[]=[];
  agendaItem : AgendaDisplay =new AgendaDisplay();
  public isLoading = false;
  constructor(private router: Router,
    private commonService :CommonService,
    public dataService :AgendaService,    
    private uiService : UIService
    ) {
  }

  ngOnInit(): void {
    this.getAgendaList();
    this.dataService.updateExceptionStatus(false);
    this.dataService.removeAgendaException();
  }

  angendaListData={
    incl: null,
    page_number: 1, 
    page_size: 200,
    type: null
  }

  getAgendaList(){
    
    this.isLoading = true;
    this.commonService.getAgendaList(this.angendaListData).subscribe(res => {
      if(res){
        this.angendaDetails = res.data;
        this.isLoading =false;             
      }
    });
  }

  onSaveAgenda(){
    this.agendaItem=new AgendaDisplay();
    this.agendaItem.id=0;
    this.agendaItem.centro_txt="";
   this.dataService.setNewAgendaDetail(null);
         this.dataService.updateExistsRevData(null);
        this.dataService.updateAgendaException(null);
    this.dataService.updateAgendaDisplay(this.agendaItem);
    this.router.navigate(['/agendar-list/save/','0' ,'']);
  }
  onEdit(item:AgendaDisplay) {
    this.agendaItem =item; 
  
    this.dataService.updateAgendaDisplay(this.agendaItem);
    this.router.navigate(['/agendar-list/edit/',item.id ,item.centro_txt]);
  }

  onDeleteAgenda(item : any){
    this.uiService.showConfirm().then((value)=>{
      if(value){
        this.isLoading = true;
        const appointmentRequestData = { "agenda_id": item.id};
        this.commonService.getAgendaData(appointmentRequestData).subscribe(res => {
          if (res && res?.webError?.number == 0) {
            this.uiService.showFailToast("Error",res?.webError?.messageToUser);
            this.isLoading = false;
          }
          else if(res && res?.data != null){     
            const appointmentData = res?.data;
            appointmentData.eliminado = true;
            this.commonService.postAgenda(appointmentData).subscribe(result => {
              if (result && result?.webError?.number == 0) {
                this.uiService.showFailToast("Error",result?.webError?.messageToUser);
                this.isLoading = false;           
              }
              else if(result && result?.data != null){     
                this.uiService.showSuccessToast("Guardado con éxito");
                this.getAgendaList();
              }  
            }, error => {
              console.log(error);
              this.uiService.showFailToast("Error",error);
              this.isLoading = false;          
            });
          }       
        }, errors => {
          console.log(errors);
          this.uiService.showFailToast("Error",errors);
          this.isLoading = false;      
        });
      }    
    }); 
  }

}
