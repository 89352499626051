import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class CommonUiService {
  commonStyle = `  @page { size: auto;  margin-top: 0mm; }
  @media print, screen{
  body{font-family:"Heebo", sans-serif; margin:20px; font-size:14px;}
  .row{display:flex;flex-wrap:wrap;}
  .container-fluid{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;}
  .col-md-12{flex:0 0 100%;max-width:100%;}
  .col-md-11 { flex: 0 0 91.6666666667%; max-width: 91.6666666667%; }
  .col-md-10{ flex: 0 0 83.3333333333%; max-width: 83.3333333333%; }
  .col-md-9 {flex:0 0 75%;max-width:75%;}
  .col-md-8 {flex: 0 0 66.66666667%;max-width: 66.66666667%;}
  .col-md-7 { flex: 0 0 58.3333333333%; max-width: 58.3333333333%; }
  .col-md-6 {flex:0 0 50%;max-width:50%;}
  .col-md-5 {-ms-flex: 0 0 41.66666667%;flex: 0 0 41.66666667%;max-width: 41.66666667%;}
  .col-md-4 {flex: 0 0 33.33333333%;max-width: 33.33333333%;}
  .col-md-3 {flex: 0 0 25%; max-width: 25%;}
  .col-md-2 {flex: 0 0 16.6666666667%;  max-width: 16.6666666667%; }
  .col-md-1 {flex: 0 0 8.3333333333%;max-width: 8.3333333333%;}
  .m-15{margin:15px;}
  .m-t-30{margin-top:30px;}
  .m-t-20 {margin-top:20px;}  
  .m-t-10 {margin-top:10px;} 
  .m-t-5{margin-top:5px;}
  .m-l-5 {margin-left:5px;}
  .m-l-15 {margin-left:10px;} 
  .m-l-20 {margin-left:20px;}
  .m-l-40{margin-left:40%;}
  .m-b-5{margin-bottom:5px;}
  .m-b-10{margin-bottom:10px;}
  .m-b-20{margin-bottom:20px;}
  .m-b-30{margin-bottom:30px;}
  .p-20{padding:0px;}
  .p-l-30{padding-left:30px;}
  .p-l-20 {padding-left:20px;}
  .p-l-10{padding-left:10px;}
  .p-l-0{padding-left:0px !important;}
  .p-r-0{padding-right:0px;}
  table {width:100%; border-collapse: collapse;}  
  .d-block{display:block;}
  .d-content{display:contents !important;}
  .d-flex{display:flex !important;}
  .d-grid{display:grid;}
  .text-underline{text-decoration: underline;}
  .text-center{text-align:center;}
  .text-end{text-align:end;}
  .font-weight-bold{font-weight:bold;}
  .page-break{page-break-after:auto;}
  .form-group {margin-bottom:5px;}
  div.m-t-20.m-l-15.ng-star-inserted {page-break-after:auto;}
  .newspaper  {columns: 300px 2;}
  `;


  PrintHtmlReport(section: string): void {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
            <html>
              <head>
                <title></title>
                <style>
                ${this.commonStyle}  
                .vis-cita-rep-header-tbl tbody tr td{border:2px solid black; padding:5px; margin:0px;}  
                .vis-cita-rep-header-tbl tbody tr:nth-child(2) td{border-top:unset;} 
                .txt-bold {font-weight:bold;}
                .check-diseases-list{padding-left:10px; margin: 0px;}
                .check-diseases-list li{list-style:unset; display:flex;}
                .check-box {border:1px solid black; width:10px; height:10px; margin-right:5px;}
                .vis-cita-rep-select {margin-bottom:0%;margin-top:0px}
                .vis-cita-rep-select tbody tr td{border:1px solid black; padding:2px 5px; margin-bottom:20px }
                .vis-cita-rep-select-1 {margin-bottom:0%}
                .vis-cita-rep-select-1 tbody tr td:nth-child(1){width:30px;}
                .vis-cita-rep-select-1 tbody tr td:nth-child(2){width:200px;}
                .vis-cita-rep-select-1 tbody tr td:nth-child(3){width:200px;}
                .vis-cita-rep-select-1 tbody tr td{border:1px solid black; padding:7px; 5px; }
                .check-circle {border:1px solid black; border-radius:50%; width:10px; height:10px; margin-right:5px;}
                .issue-check-tbl {width:60%;height:300px;margin-bottom:0%}
                .issue-check-comentarios{height:50px;}
                .detail-tbl-vis-cita-report tbody tr td{border:1px solid black; padding:5px;}
                .grey-column{background-color:#e9ecef;}
                .d-content{display:contents !important;}
                .d-flex{display:flex !important;}
                .AP-otras-div{padding-top:130px;}
                table{font-size:14px;}
                .BreakPage{page-break-after:auto;}           
              
           

              @media print and (width: 21cm) and (height: 29.7cm) {
                @page {
                   margin: 3cm;
                }
           }

           /* style sheet for "letter" printing */
           @media print and (width: 8.5in) and (height: 11in) {
               @page {
                   margin: 1in;
               }
           }
              /* A4 portrait*/
              @page {
                  size: A4 portrait;
                  margin: 10%;
              }

                </style>
              </head>
              <body>${html}</body>
            </html>`);

        // chrome browser fix
        popupWindow.setTimeout(() => {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();

        }, 1000);

      }
    }
  }

  PrintCovid(section: string): void {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
            <html>
              <head>
                <title></title>
                <style>
                ${this.commonStyle}  
                .covidRDT-print-doc label{font-size: 13px;}
                .covidRDT-print-doc span{ font-size: 13px;}
                .covidRDT-print-doc .row .right-header label{color: #5a96b3!important;float: right;margin-right: 0px; margin-left: auto;}
                .covidRDT-print-doc .row .right-header span{color:#5a96b3!important;}
                .covidRDT-print-doc .title-rapid{font-weight:bold; text-transform: uppercase; font-size:16px; text-align:center;}
                .covidRDT-print-doc .subtitle-rapid{font-size:16px!important; text-align:center;margin: auto; border: 2px solid #22a5de; width: fit-content;padding: 10px;border-radius: 5px;}
                .covidRDT-print-doc .observ-div {font-size:12px !important; margin-top:30px; margin-bottom:20px; display:grid;}
                .covidRDT-print-doc .footer-covid{color:#22a5de!important; text-align:center;position: fixed;left: 0;bottom: 0;width: 100%;}
                .covidRDT-print-doc .sign-area{margin-top:30px;}
              }

                </style>
              </head>
              <body>${html}</body>
            </html>`);


        // chrome browser fix
        popupWindow.setTimeout(() => {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000);
      }
    }
  }
  PrintReco(section: string): void {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
            <html>
              <head>
                <title></title>
                <style>
                ${this.commonStyle}  
                .covid-print-doc label,.covid-print-doc span{ font-size: 13px;}
                .covid-print-doc .title-rapid, .covid-form-doc .title-rapid{font-weight:bold; text-transform: uppercase; font-size:16px; text-align:center;color:#2d85d1 !important;}
                .covid-print-doc .subtitle-rapid{font-size:16px!important; text-align:center;margin: auto; border: 2px solid #22a5de; width: fit-content;padding: 10px;border-radius: 5px;}
                .covid-print-doc .observ-div {font-size:12px !important; margin-top:30px; margin-bottom:20px; display:grid;}
                .covid-print-doc .footer-covid{color:#2d85d1 !important; text-align:center;position: fixed;left: 0;bottom: 0;width: 100%;}
                .covid-print-doc .sign-area{margin-top:30px;}
                .covid-print-doc .sign-area span{padding-left: 80px;}
                .covid-print-doc .para-div p{margin:4px 0; font-size: 13px;text-align: justify;}
                .covid-print-doc .protection-div label{font-size: 12px; margin-top:10px;font-weight:bold; }
                .covid-print-doc .protection-div p{font-size: 13px; color:grey !important;text-align: justify;}
                .covid-print-doc .protection-div p span{color:#2d85d1 !important;}
                .covid-form-doc label{font-size:12px;}
                .covid-form-doc .row .left-header label{color:#22a5de!important;float: left;padding-bottom:10px;}
                .covid-form-doc .row .left-header span{color:#22a5de!important;font-size:12px;}
                .covid-form-doc .detail-line{ width: 100%; padding-top: 30px; margin-bottom:20px; border-bottom: 1px solid black;}
                .label-square{border:1px solid black;width:130px; height:140px;float: right; margin: auto;}
              }

                </style>
              </head>
              <body>${html}</body>
            </html>`);


        // chrome browser fix
        popupWindow.setTimeout(() => {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000);
      }
    }
  }
  justificantedeasistenciaReport(section: any) {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
            <html>
              <head>
                <title></title>
                <style>
                ${this.commonStyle}  
                .justificant_edeasistencia-doc .middle-logo{width:300px; opacity: 50%;}
                .m-10-top{margin-top:10%;}
                .justificant_edeasistencia-doc .row .right-header label{color: #5a96b3!important;font-size:14px;float: right;margin-right: 0px; margin-left: auto;}
                .justificant_edeasistencia-doc .row .right-header span{color: #5a96b3!important;font-size:14px;}
                .justificant_edeasistencia-doc p{line-height:26px;}
                .justificant_edeasistencia-doc .footer-covid{color:#2d85d1 !important; text-align:center;position: fixed;left: 0;bottom: 0;width: 100%;}
                </style>
              </head>
              <body>${html}</body>
            </html>`);


        // chrome browser fix
        popupWindow.setTimeout(() => {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000);
      }
    }
  }

  recibodepagoReport(section: string) {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
            <html>
              <head>
                <title></title>
                <style>
                ${this.commonStyle}  
                .recibode-pago-doc .middle-logo{width:300px; opacity: 50%;}
                .m-10-top{margin-top:10%;}
                .recibode-pago-doc .row .right-header label{color: #5a96b3!important;font-size:14px;float: right;margin-right: 0px; margin-left: auto;}
                .recibode-pago-doc .row .right-header span{color: #5a96b3!important;font-size:14px;}
                .recibode-pago-doc p{line-height:26px;}
                .recibode-pago-doc .footer-covid{color:#2d85d1 !important; text-align:center;position: fixed;left: 0;bottom: 0;width: 100%;}

                </style>
              </head>
              <body>${html}</body>
            </html>`);


        // chrome browser fix
        popupWindow.setTimeout(() => {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000);
      }
    }
  }

  tomaDeDATOSPruebasReport(section: string) {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
            <html>
              <head>
                <title></title>
                <style>
                ${this.commonStyle}  
                .covid-form-doc label{font-size:12px;}
                .covid-form-doc .middle-logo{width:300px;opacity: 50%;}
                .covid-form-doc .row .left-header label{color: #5a96b3!important;float: left;padding-bottom:10px;}
                .covid-form-doc .row .left-header span{color: #5a96b3!important;}
                .covid-form-doc .detail-line{ width: 100%; padding-top: 30px; margin-bottom:20px; border-bottom: 1px solid black;}
                .covid-form-doc label{font-size:12px;}
                .covid-form-doc .row .left-header label{color:#22a5de!important;float: left;padding-bottom:10px;}
                .covid-form-doc .row .left-header span{color:#22a5de!important;font-size:12px;}
                .covid-form-doc .detail-line{ width: 100%; padding-top: 25px; margin-bottom:20px; border-bottom: 1px solid black;}
                .covid-print-doc .title-rapid, .covid-form-doc .title-rapid{font-weight:bold; text-transform: uppercase; font-size:16px; text-align:center;color:#2d85d1 !important;}
                .label-square{border:1px solid black;width:130px; height:140px;float: right; margin: auto;padding-top:10px;}
                .label-square span{padding-left:15px;padding-top:10px;}
                </style>
              </head>
              <body>${html}</body>
            </html>`);


        // chrome browser fix
        popupWindow.setTimeout(() => {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000);
      }
    }
  }

  castellanoPruebasReport(section: string) {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
            <html>
              <head>
                <title></title>
                <style>
                ${this.commonStyle}  
                
                .covid-print-doc label,.covid-print-doc span{ font-size: 13px;}
                .covid-print-doc .title-rapid, .covid-form-doc .title-rapid{font-weight:bold; text-transform: uppercase; font-size:16px; text-align:center;color:#2d85d1 !important;}
                .covid-print-doc .subtitle-rapid{font-size:16px!important; text-align:center;margin: auto; border: 2px solid #22a5de; width: fit-content;padding: 10px;border-radius: 5px;}
                .covid-print-doc .observ-div {font-size:12px !important; margin-top:30px; margin-bottom:20px; display:grid;}
                .covid-print-doc .footer-covid{color:#2d85d1 !important; text-align:center;position: fixed;left: 0;bottom: 0;width: 100%;}
                .covid-print-doc .sign-area{margin-top:30px;}
                .covid-print-doc .para-div p{margin:4px 0; font-size: 13px;text-align: justify;}
                .covid-print-doc .protection-div label{font-size: 12px; margin-top:10px;font-weight:bold; }
                .covid-print-doc .protection-div p{font-size: 13px; color:grey !important;text-align: justify;}
                .covid-print-doc .protection-div p span{color:#2d85d1 !important;}
                .covid-form-doc label{font-size:12px;}
                .covid-form-doc .row .left-header label{color:#22a5de!important;float: left;padding-bottom:10px;}
                .covid-form-doc .row .left-header span{color:#22a5de!important;font-size:12px;}
                .covid-form-doc .detail-line{ width: 100%; padding-top: 30px; margin-bottom:20px; border-bottom: 1px solid black;}
                .check-diseases-list{padding-left:10px; margin: 0px;}
                .check-diseases-list li{list-style:unset; display:flex; font-size:14px;}
                .check-box {border:1px solid #2544b5; width:10px; height:10px; margin-right:5px;margin-top: 3px;}
                .clr-blue{color:#2544b5;}
                .middle-logo{width:200px;opacity: 50%;}
                </style>
              </head>
              <body>${html}</body>
            </html>`);


        // chrome browser fix
        popupWindow.setTimeout(() => {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000);
      }
    }
  }

  catalanConsPruebasReport(section: string) {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
            <html>
              <head>
                <title></title>
                <style>
                ${this.commonStyle}  
                
                .covid-print-doc label,.covid-print-doc span{ font-size: 13px;}
                .covid-print-doc .title-rapid, .covid-form-doc .title-rapid{font-weight:bold; text-transform: uppercase; font-size:16px; text-align:center;color:#2d85d1 !important;}
                .covid-print-doc .subtitle-rapid{font-size:16px!important; text-align:center;margin: auto; border: 2px solid #22a5de; width: fit-content;padding: 10px;border-radius: 5px;}
                .covid-print-doc .observ-div {font-size:12px !important; margin-top:30px; margin-bottom:20px; display:grid;}
                .covid-print-doc .footer-covid{color:#2d85d1 !important; text-align:center;position: fixed;left: 0;bottom: 0;width: 100%;}
                .covid-print-doc .sign-area{margin-top:30px;}
                .covid-print-doc .para-div p{margin:4px 0; font-size: 13px;text-align: justify;}
                .covid-print-doc .protection-div label{font-size: 12px; margin-top:10px;font-weight:bold; }
                .covid-print-doc .protection-div p{font-size: 13px; color:grey !important;text-align: justify;}
                .covid-print-doc .protection-div p span{color:#2d85d1 !important;}
                .covid-form-doc label{font-size:12px;}
                .covid-form-doc .row .left-header label{color:#22a5de!important;float: left;padding-bottom:10px;}
                .covid-form-doc .row .left-header span{color:#22a5de!important;font-size:12px;}
                .covid-form-doc .detail-line{ width: 100%; padding-top: 30px; margin-bottom:20px; border-bottom: 1px solid black;}
                .check-diseases-list{padding-left:10px; margin: 0px;}
                .check-diseases-list li{list-style:unset; display:flex; font-size:14px;}
                .check-box {border:1px solid #2544b5; width:10px; height:10px; margin-right:5px;margin-top: 3px;}
                .clr-blue{color:#2544b5;}
                .middle-logo{width:200px;opacity: 50%;}
                </style>
              </head>
              <body>${html}</body>
            </html>`);

        // chrome browser fix
        popupWindow.setTimeout(() => {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000);
      }
    }
  }
  inglesReport(section: string) {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
          <html>
            <head>
              <title></title>
              <style>
              ${this.commonStyle}  
              .covid-print-doc label,.covid-print-doc span{ font-size: 13px;}
              .covid-print-doc .title-rapid, .covid-form-doc .title-rapid{font-weight:bold; text-transform: uppercase; font-size:16px; text-align:center;color:#2d85d1 !important;}
              .covid-print-doc .subtitle-rapid{font-size:16px!important; text-align:center;margin: auto; border: 2px solid #22a5de; width: fit-content;padding: 10px;border-radius: 5px;}
              .covid-print-doc .observ-div {font-size:12px !important; margin-top:30px; margin-bottom:20px; display:grid;}
              .covid-print-doc .footer-covid{color:#2d85d1 !important; text-align:center;position: fixed;left: 0;bottom: 0;width: 100%;}
              .covid-print-doc .sign-area{margin-top:30px;}
              .covid-print-doc .para-div p{margin:4px 0; font-size: 13px;text-align: justify;}
              .covid-print-doc .protection-div label{font-size: 12px; margin-top:10px;font-weight:bold; }
              .covid-print-doc .protection-div p{font-size: 13px; color:grey !important;text-align: justify;}
              .covid-print-doc .protection-div p span{color:#2d85d1 !important;}
              .covid-form-doc label{font-size:12px;}
              .covid-form-doc .row .left-header label{color:#22a5de!important;float: left;padding-bottom:10px;}
              .covid-form-doc .row .left-header span{color:#22a5de!important;font-size:12px;}
              .clr-blue{color:#2544b5;}
              .middle-logo{width:200px;opacity: 50%;}

              </style>
            </head>
            <body>${html}</body>
          </html>`);


        // chrome browser fix
        popupWindow.setTimeout(() => {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000);
      }
    }
  }
  francePredasReport(section: string) {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
          <html>
            <head>
              <title></title>
              <style>
              ${this.commonStyle}  
              
              .covid-print-doc label,.covid-print-doc span{ font-size: 13px;}
              .covid-print-doc .title-rapid, .covid-form-doc .title-rapid{font-weight:bold; text-transform: uppercase; font-size:16px; text-align:center;color:#2d85d1 !important;}
              .covid-print-doc .subtitle-rapid{font-size:16px!important; text-align:center;margin: auto; border: 2px solid #22a5de; width: fit-content;padding: 10px;border-radius: 5px;}
              .covid-print-doc .observ-div {font-size:12px !important; margin-top:30px; margin-bottom:20px; display:grid;}
              .covid-print-doc .footer-covid{color:#2d85d1 !important; text-align:center;position: fixed;left: 0;bottom: 0;width: 100%;}
              .covid-print-doc .sign-area{margin-top:30px;}
              .covid-print-doc .para-div p{margin:4px 0; font-size: 13px;text-align: justify;}
              .covid-print-doc .protection-div label{font-size: 12px; margin-top:10px;font-weight:bold; }
              .covid-print-doc .protection-div p{font-size: 13px; color:grey !important;text-align: justify;}
              .covid-print-doc .protection-div p span{color:#2d85d1 !important;}
              .covid-form-doc label{font-size:12px;}
              .covid-form-doc .row .left-header label{color:#22a5de!important;float: left;padding-bottom:10px;}
              .covid-form-doc .row .left-header span{color:#22a5de!important;font-size:12px;}
              .clr-blue{color:#2544b5;}
              .middle-logo{width:200px;opacity: 50%;}

              </style>
            </head>
            <body>${html}</body>
          </html>`);


        // chrome browser fix
        popupWindow.setTimeout(() => {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000);
      }
    }
  }
  castellanoMenoresPrebasReport(section: string) {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
          <html>
            <head>
              <title></title>
              <style>
              ${this.commonStyle}  
              
              .covid-print-doc label,.covid-print-doc span{ font-size: 13px;}
              .covid-print-doc .title-rapid, .covid-form-doc .title-rapid{font-weight:bold; text-transform: uppercase; font-size:16px; text-align:center;color:#2d85d1 !important;}
              .covid-print-doc .subtitle-rapid{font-size:16px!important; text-align:center;margin: auto; border: 2px solid #22a5de; width: fit-content;padding: 10px;border-radius: 5px;}
              .covid-print-doc .observ-div {font-size:12px !important; margin-top:30px; margin-bottom:20px; display:grid;}
              .covid-print-doc .footer-covid{color:#2d85d1 !important; text-align:center;position: fixed;left: 0;bottom: 0;width: 100%;}
              .covid-print-doc .sign-area{margin-top:30px;}
              .covid-print-doc .para-div p{margin:4px 0; font-size: 13px;text-align: justify;}
              .covid-print-doc .protection-div label{font-size: 12px; margin-top:10px;font-weight:bold; }
              .covid-print-doc .protection-div p{font-size: 13px; color:grey !important;text-align: justify;}
              .covid-print-doc .protection-div p span{color:#2d85d1 !important;}
              .covid-form-doc label{font-size:12px;}
              .covid-form-doc .row .left-header label{color:#22a5de!important;float: left;padding-bottom:10px;}
              .covid-form-doc .row .left-header span{color:#22a5de!important;font-size:12px;}
              .covid-form-doc .detail-line{ width: 100%; padding-top: 30px; margin-bottom:20px; border-bottom: 1px solid black;}
              .check-diseases-list{padding-left:10px; margin: 0px;}
              .check-diseases-list li{list-style:unset; display:flex; font-size:14px;}
              .check-box {border:1px solid #03208b; width:10px; height:10px; margin-right:5px;margin-top: 3px;}
              .clr-blue{color:#03208b;}
              .middle-logo{width:200px;opacity: 50%;}

              </style>
            </head>
            <body>${html}</body>
          </html>`);


        // chrome browser fix
        popupWindow.setTimeout(() => {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000);
      }
    }
  }
  catalanMenoresPruebasReport(section: string) {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
          <html>
            <head>
              <title></title>
              <style>
              ${this.commonStyle}  
              
              .covid-print-doc label,.covid-print-doc span{ font-size: 13px;}
              .covid-print-doc .title-rapid, .covid-form-doc .title-rapid{font-weight:bold; text-transform: uppercase; font-size:16px; text-align:center;color:#2d85d1 !important;}
              .covid-print-doc .subtitle-rapid{font-size:16px!important; text-align:center;margin: auto; border: 2px solid #22a5de; width: fit-content;padding: 10px;border-radius: 5px;}
              .covid-print-doc .observ-div {font-size:12px !important; margin-top:30px; margin-bottom:20px; display:grid;}
              .covid-print-doc .footer-covid{color:#2d85d1 !important; text-align:center;position: fixed;left: 0;bottom: 0;width: 100%;}
              .covid-print-doc .sign-area{margin-top:30px;}
              .covid-print-doc .para-div p{margin:4px 0; font-size: 13px;text-align: justify;}
              .covid-print-doc .protection-div label{font-size: 12px; margin-top:10px;font-weight:bold; }
              .covid-print-doc .protection-div p{font-size: 13px; color:grey !important;text-align: justify;}
              .covid-print-doc .protection-div p span{color:#2d85d1 !important;}
              .covid-form-doc label{font-size:12px;}
              .covid-form-doc .row .left-header label{color:#22a5de!important;float: left;padding-bottom:10px;}
              .covid-form-doc .row .left-header span{color:#22a5de!important;font-size:12px;}
              .covid-form-doc .detail-line{ width: 100%; padding-top: 30px; margin-bottom:20px; border-bottom: 1px solid black;}
              .check-diseases-list{padding-left:10px; margin: 0px;}
              .check-diseases-list li{list-style:unset; display:flex; font-size:14px;}
              .check-box {border:1px solid #03208b; width:10px; height:10px; margin-right:5px;margin-top: 3px;}
              .clr-blue{color:#03208b;}
              .middle-logo{width:200px;opacity: 50%;}

              </style>
            </head>
            <body>${html}</body>
          </html>`);


        // chrome browser fix
        popupWindow.setTimeout(() => {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000);
      }
    }
  }
  inglesMenoresPruebas(section: string) {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
          <html>
            <head>
              <title></title>
              <style>
              ${this.commonStyle}  
              
              .covid-print-doc label,.covid-print-doc span{ font-size: 13px;}
              .covid-print-doc .title-rapid, .covid-form-doc .title-rapid{font-weight:bold; text-transform: uppercase; font-size:16px; text-align:center;color:#2d85d1 !important;}
              .covid-print-doc .subtitle-rapid{font-size:16px!important; text-align:center;margin: auto; border: 2px solid #22a5de; width: fit-content;padding: 10px;border-radius: 5px;}
              .covid-print-doc .observ-div {font-size:12px !important; margin-top:30px; margin-bottom:20px; display:grid;}
              .covid-print-doc .footer-covid{color:#2d85d1 !important; text-align:center;position: fixed;left: 0;bottom: 0;width: 100%;}
              .covid-print-doc .sign-area{margin-top:30px;}
              .covid-print-doc .para-div p{margin:4px 0; font-size: 13px;text-align: justify;}
              .covid-print-doc .protection-div label{font-size: 12px; margin-top:10px;font-weight:bold; }
              .covid-print-doc .protection-div p{font-size: 13px; color:grey !important;text-align: justify;}
              .covid-print-doc .protection-div p span{color:#2d85d1 !important;}
              .covid-form-doc label{font-size:12px;}
              .covid-form-doc .row .left-header label{color:#22a5de!important;float: left;padding-bottom:10px;}
              .covid-form-doc .row .left-header span{color:#22a5de!important;font-size:12px;}
              .covid-form-doc .detail-line{ width: 100%; padding-top: 30px; margin-bottom:20px; border-bottom: 1px solid black;}
              .check-diseases-list{padding-left:10px; margin: 0px;}
              .check-diseases-list li{list-style:unset; display:flex; font-size:14px;}
              .check-box {border:1px solid #03208b; width:10px; height:10px; margin-right:5px;margin-top: 3px;}
              .clr-blue{color:#03208b;}
              .middle-logo{width:200px;opacity: 50%;}

              </style>
            </head>
            <body>${html}</body>
          </html>`);


        // chrome browser fix
        popupWindow.setTimeout(() => {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000);
      }
    }
  }


  PR05A57_RAPID_ANT_CAS(section: string, type: string): void {
    let popupWindow: Window;
    const html: any = document.getElementById(section).innerHTML;
    if (html) {
      popupWindow = window.open(
        '',
        '_blank',
        'top=0,left=0,height=' + screen.height + ',width= ' + screen.width
      );
      if (popupWindow) {
        popupWindow.document.open();
        popupWindow.document.write(`
          <html>
            <head>
              <title></title>
              <style>
              ${this.commonStyle} 
              .PR05A57-rapidant-cas label{font-size: 13px;}
              .PR05A57-rapidant-cas span{ font-size: 13px;}
              .PR05A57-rapidant-cas .left-box{border: 1px solid black;width: 100px;height: 100px;}
              .PR05A57-rapidant-cas .left-box span{padding-left:12px;}
              .PR05A57-rapidant-cas .middle-logo{width:300px;}
              .PR05A57-rapidant-cas .row .right-header label{color:#22a5de!important;float: right;margin-right: 0px; margin-left: auto;}
              .PR05A57-rapidant-cas .row .right-header span{color:#22a5de!important;}
              .PR05A57-rapidant-cas .title-rapid{font-weight:bold; text-transform: uppercase; font-size:16px; text-align:center;}
              .PR05A57-rapidant-cas .subtitle-rapid{font-size:16px!important; text-align:center;margin: auto; border: 2px solid #22a5de; width: fit-content;padding: 10px;border-radius: 5px;}
              .PR05A57-rapidant-cas .observ-div {font-size:12px !important; margin-top:30px; margin-bottom:20px; display:grid;}
              .PR05A57-rapidant-cas .footer-covid{color:#22a5de!important; text-align:center;position: fixed;left: 0;bottom: 0;width: 100%;}
              .PR05A57-rapidant-cas .sign-area{margin-top:30px;}
            }
              </style>
            </head>
            <body>${html}</body>
          </html>`);


        // chrome browser fix
        popupWindow.setTimeout(() => {
          popupWindow.focus(); // necessary for IE >= 10
          popupWindow.stop();
          popupWindow.print();
        }, 1000);
      }
    }
  }

}

export function Delay(ms: number): Promise<any> {
  return new Promise(resolve => setTimeout(resolve, ms));
}
