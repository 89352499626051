export class WeekSlots{
    num_dia: number;
    slot_desde: any;
    slot_hasta: any;
    concurrentes: number;
    total_concurrentes : number;
    notas: string

    slotDate:any;
    isEdit :boolean;
    isRemove:boolean;
    aIndex :number;
}