<app-header></app-header>
<div class="row m-0 m-b-main mt-3">
  <!-- left menu -->
  <div class="col-md-2 col-xl-1 col-lg-1 col-sm-1 main-agendas-menu">
    <ul class="list-group inner-scroll">
      <li class="list-group-item" [ngClass]="
          _uiService.selectedMenu === menuListEnum.VisualizarCitas
            ? 'select'
            : ''
        " (click)="onSelectMenu(0)">
        <span><img alt="Visualizar Citas" src="assets\images\menu\Icon_VisCitas.png" /></span>
        <span>Visualizar Citas</span>
      </li>
      <li class="list-group-item" [ngClass]="
          _uiService.selectedMenu === menuListEnum.AgendarNuevasCitas
            ? 'select'
            : ''
        " (click)="onSelectMenu(1)">
        <span><img alt="Nueva Cita" src="assets\images\menu\Icon_AddCitas.png" /></span>
        <span>Nueva Cita</span>
      </li>
      <li class="list-group-item" [ngClass]="
          _uiService.selectedMenu === menuListEnum.Recepcion ? 'select' : ''
        " (click)="onSelectMenu(2)">
        <span><img alt="Recepción" src="assets\images\menu\Icon_Recepc.png" /></span>
        <span>Recepción</span>
      </li>
      <li class="list-group-item" [ngClass]="
          _uiService.selectedMenu === menuListEnum.ListadoDeAgendas
            ? 'select'
            : ''
        " (click)="onSelectMenu(3)">
        <span><img alt="Listado De Agendas" src="assets\images\menu\Icon_ListAgend.png" /></span>
        <span>Listado De Agendas</span>
      </li>
      <li class="list-group-item" (click)="onSelectMenu(4)">
        <span><img alt="Valimed" src="assets\images\menu\Icon_Valimed.png" /></span>
        <span>Valimed</span>
      </li>
      <li class="list-group-item" (click)="onSelectMenu(6)" [ngClass]="
        _uiService.selectedMenu === menuListEnum.Memoria ? 'select' : ''
      ">
        <span><img routerLink="memoria-vs" alt="Nueva Cita" src="assets\images\menu\Icon_Memoria.png" /></span>
        <span>Memoria VS</span>
      </li>
      <li class="list-group-item" (click)="onSelectMenu(5)" [ngClass]="
          _uiService.selectedMenu === menuListEnum.Contactos ? 'select' : ''
        ">
        <span><img alt="contact" src="assets\images\menu\Icon_Contact.png" /></span>
        <span>Contactos</span>
      </li>
      <li class="list-group-item" (click)="onSelectMenu(7)" [ngClass]="
          _uiService.selectedMenu === menuListEnum.PuestoVS ? 'select' : ''
        ">
        <span><img alt="contact" src="assets\images\menu\Icon_PuestosVS.png" /></span>
        <span>Puesto VS</span>
      </li>
    </ul>
  </div>
  <!-- / left menu -->
  <div class="col-md-10 col-xl-11 col-lg-11 col-sm-11">
    <router-outlet></router-outlet>
  </div>
</div>
<app-footer></app-footer>