import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class UIService {
  selectedMenu: number;
  isMoment: boolean;
  constructor(
    private toaster: ToastrService,
    private translateService: TranslateService,
    private datePipe: DatePipe) { }

  showToastByErrorCode(code, jsonPath): void {
    code = code.split(':');

    this.translateService.get(jsonPath + '.' + [code[1]]).subscribe((message) => {
      switch (code[0].toLowerCase()) {
        case 'i':
          this.toaster.success(message, this.translateService.instant('common.success'));
          break;
        case 'w':
          this.showWarningToast(this.translateService.instant('common.warning'), message);
          break;
        case 'e':
          this.showFailToast(this.translateService.instant('common.error'), message);
          break;
      }
    });
  }
  showWarningToast(title, message): void {
    this.toaster.warning(message, title);
  }

  showSuccessToast(key): void {
    const title = this.translateService.instant('common.success');
    const message = this.translateService.instant(key);
    this.toaster.success(message, title);
  }

  showCorrectoToast(key): void {
    const title = this.translateService.instant('common.Correcto');
    const message = this.translateService.instant(key);
    this.toaster.success(message, title);
  }

  showWarningToastInstant(key): void {
    const title = this.translateService.instant('common.warning');
    const message = this.translateService.instant(key);
    this.toaster.warning(message, title);
  }

  showWarningMessage(message): void {
    const title = this.translateService.instant('common.warning');
    this.toaster.warning(message, title, { timeOut: 7000 });
  }

  showFailToast(title, message): void {
    this.toaster.error(message, title, { timeOut: 5000 });
  }


  showInfoMessage(message): void {
    const title = 'AVISO: ';
    this.toaster.info(message, title, { timeOut: 7000, positionClass: 'toast-top-right', closeButton: true });
  }
  showConfirm(message?): Promise<boolean> {
    return new Promise((resolve) => {
      Swal.fire({
        title: '',
        text: message ? message : this.translateService.instant('common.deleteMessage'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translateService.instant('common.yes'),
        cancelButtonText: this.translateService.instant('common.no')
      }).then((result) => {
        if (result.value) {
          resolve(true);
          return;
        }
        resolve(false);
      });
    });
  }

  dateToSpanish(dateToFormat: string): string {
    return this.datePipe.transform(dateToFormat, 'dd/MM/yyyy');
  }

  dateToSpanishCustome(dateToFormat): string {
    if (dateToFormat._isAMomentObject) { // check if it's not a moment object
      this.isMoment = true;
    }
    else {
      this.isMoment = false;
    }


    let dDate = new Date(dateToFormat);
    let dDay = dDate.getDate()
    if (Number.isNaN(dDay)) {
      let sSub = String(dateToFormat);
      let sSplitStr = sSub.split("/");
      let nDate = sSplitStr[2] + "-" + sSplitStr[1] + "-" + sSplitStr[0];
      let tDate = new Date(nDate);
      return this.datePipe.transform(tDate, 'dd/MM/yyyy');

    }
    else {
      let month = dDate.getMonth() + 1;
      if (this.isMoment) {
        let nDate = +month + "-" + dDay + "-" + dDate.getFullYear()
        return this.datePipe.transform(nDate, 'dd/MM/yyyy');
      } else {
        let nDate = +dDay + "-" + month + "-" + dDate.getFullYear()
        return this.datePipe.transform(nDate, 'dd/MM/yyyy');
      }

    }

  }

  dateToString(dateToFormat: Date): string {
    return this.datePipe.transform(dateToFormat, 'yyyyMMdd');
  }

  dateToCustomString(dateToFormat: Date): string {
    return this.datePipe.transform(dateToFormat, 'YYYY-MM-dd');
  }

  dateStringFormat(dateToFormat: string): string {
    let formattedDate = '';
    if (dateToFormat) {
      formattedDate = dateToFormat.substring(6, 8) + '/' + dateToFormat.substring(4, 6) + '/' + dateToFormat.substring(0, 4);
    }
    return formattedDate;
  }

  isSameOrAfter(fromValue: any, toValue: any) {
    let momentFrom = moment(
      this.ConvertFromDatePicker(fromValue),
      "YYYY-MM-DD",
      false
    );

    let momentmomentTo = moment(
      this.ConvertFromDatePicker(toValue),
      "YYYY-MM-DD",
      false
    );
    return momentmomentTo.isSameOrAfter(momentFrom);
  }

  ConvertFromDatePicker(date: any): string {
    if (date) {
      let dVal = new Date(date);
      let month = dVal.getMonth() + 1;
      return dVal.getFullYear() + "-" + month + "-" + dVal.getDate();
    }
  }
}
