
export class AgendaList{
    id:number;
    nombre:string;
    active:boolean;
    tipo_accion_id:number;
    center_group_id:string;
    delegacion_id:any;
    web_visibility:boolean;
    web_name:string;
    web_comments:string;
    comments:string;
    minutes:any;
    tipo_estadistico_id:any;
    companies_all:boolean;
    eliminado :boolean;
    companies_allowed:any[];  
    week_slots:  any[];
    exception_slots:any[];
}