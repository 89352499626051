import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { VisualizerCitarEditComponent } from './visualizer-citar-edit/visualizer-citar-edit.component';
import { VisualizerCitarsComponent } from './visualizer-citars.component';

const routes: Routes = [
    {
        path: 'view',
        component: VisualizerCitarsComponent
    },
    {
        path: 'edit',
        component: VisualizerCitarEditComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class VisualizerCitarsRoutingModule { }