import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { FooterComponent } from './main-layout/footer/footer.component';
import { HeaderComponent } from './main-layout/header/header.component';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { FeatherModule } from 'angular-feather';
import { FeatherIcons } from 'src/app/model/shared/feather-icons';
import { SpinnerComponent } from './spinner/spinner.component';
import { ConfirmationPopupComponent } from './popups/confirmation-popup/confirmation-popup.component';
import { OwlDateTimeModule } from '@busacca/ng-pick-datetime';
import { ReusableCalendarComponent } from './reusable-component/reusable-calendar/reusable-calendar.component';
import { EmpresaTrabajadorSerchComponent } from './reusable-component/empresa-trabajador-serch/empresa-trabajador-serch.component';
import { AppointmentTypeComponent } from './reusable-component/appointment-type/appointment-type.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'; // a plugin!
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmployeeEditComponent } from './reusable-component/employee-edit/employee-edit.component';
import { VisualizerCitarsPrintDocumentComponent } from './visualizer-citars-print-document/visualizer-citars-print-document.component';
import { SearchEmpresaNotallComponent } from './reusable-component/search-empresa-notall/search-empresa-notall.component';


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin
]);

@NgModule({
  declarations: [
    LoginLayoutComponent,
    MainLayoutComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    SpinnerComponent,
    ConfirmationPopupComponent,
    ReusableCalendarComponent,
    EmpresaTrabajadorSerchComponent,
    AppointmentTypeComponent,
    EmployeeEditComponent,
    VisualizerCitarsPrintDocumentComponent,
    SearchEmpresaNotallComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    FullCalendarModule,
    NgSelectModule,
    FeatherModule.pick(FeatherIcons.icons)
  ],
  exports: [
    FeatherModule,
    SpinnerComponent,
    ReusableCalendarComponent,
    EmpresaTrabajadorSerchComponent,
    AppointmentTypeComponent,
    FullCalendarModule,
    NgSelectModule,
    EmployeeEditComponent,
    VisualizerCitarsPrintDocumentComponent,
    SearchEmpresaNotallComponent
  ]
})
export class SharedModule { }
