import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './auth-service.component';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
    private route: Router,
    private _authService: AuthService
  ) {

  }

  async canActivate(_route: ActivatedRouteSnapshot) {
    const token = await this._authService.getAccessToken();
    if (token != null) return true;

    this.route.navigate(['/signin']).then();
    return false;
  }
}

@Injectable({ providedIn: 'root' })
export class AuthRedirect implements CanActivate {

  constructor(
    private router: Router,
    private _authService: AuthService
  ) {

  }

  async canActivate(route: ActivatedRouteSnapshot) {
    const token = await this._authService.getAccessToken();
    const r = route.routeConfig.path;
    if (token || r === 'signin') return true;

    this.router.navigate(['/']).then();
    return false;
  }
}
