import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReservationTypes } from 'src/app/helpers/enums/reservation-type';
import { AgendaRequestData } from 'src/app/model/agendar/agenda-request-data';
import { RequestData } from 'src/app/model/shared/request-data';
import { SelectOptions } from 'src/app/model/shared/select-options.model';
import { DropdownService } from 'src/app/service/shared/dropdown.service';
import { CommonService } from 'src/app/service/common/common.service';
import { UIService } from 'src/app/service/shared/ui.service';
import { MenuList } from 'src/app/helpers/enums/menu-list';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {Constants} from '../../../../helpers/constant/constant'


@Component({
  selector: 'app-appointment-type',
  templateUrl: './appointment-type.component.html',
  styleUrls: ['./appointment-type.component.scss']
})
export class AppointmentTypeComponent implements OnInit {
  @Output() appoinmentSearchDataEmit: EventEmitter<any> = new EventEmitter();
  @Input() inputAgendaFilteredData: AgendaRequestData;

  @Input() validateInput: Object = null;
  @Input() clearEstado: boolean = false;


  @Input() selectedType: number;
  @Input() hideEstado: boolean = false;
  @Input() requestDataType: string = 'filter';
  
  public reservationTypeEnum = ReservationTypes;
  puestosVSList: SelectOptions[] = [];
  provinciaList: SelectOptions[] = [];
  actionSubTypesList: SelectOptions[] = [];
  townsList: SelectOptions[] = [];
  paymentTypesList: SelectOptions[] = [];
  actionTypesList: SelectOptions[] = [];
  statesActionTypesList: SelectOptions[] = [];
  pagado_sino: number = 0;
  estado: any;
  agendaFilteredData: AgendaRequestData;
  public menuListEnum = MenuList;
  provinceId: number;
  puestovs_id: any;
  accion_subtipo: any;
  puestosCount:number;
  accion_tipo: any;
  solicitante: any;
  provincia_id: any;
  pago_tipo: any;
  poblacion_id: any;
  tarjeta_sanitaria:any;
  pagado_importe:number = 0;
  provinciaRequestData = {
    page_number: 1,
    page_size: 10,
    incl: null,
    type: 'filter'
  }

  townsRequestData = {
    page_number: 1,
    page_size: 10,
    incl: null,
    type: 'filter'
  }

  public isLoading = false;

  private townSubject: Subject<string> = new Subject();
  private provinceSubject: Subject<string> = new Subject();

  isCancelAppointment:boolean;
  appointmentEditData: any;

  constructor(private _visualizerCitarService: CommonService, private dropdownService: DropdownService, public _uiService: UIService) {
    if (this._uiService.selectedMenu === this.menuListEnum.AgendarNuevasCitas) {
      this.selectedType = 1;
   
    } else {
      this.selectedType = 0;
    }
  }

  ngOnInit(): void {

    
    this.agendaFilteredData = new AgendaRequestData();
    this.getProvinciaList();
    this.getActionSubTypesList();
    this.getPaymentTypesList();
    this.getActionTypes();
    this.getTownsList();
    this._visualizerCitarService.companyIdSubject.subscribe((companyId: any) => {
      if (companyId) {
        this.getPuestosVSListCount(companyId);
        // this.getPuestosVSList(companyId);
      } else {
        this.puestosVSList = [];
      }
    })

    this.townSubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe(townName =>  this.loadTopMatchingTowns(townName));

    this.provinceSubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe(provinceName =>  this.loadTopMatchingProvinces(provinceName));


    this.isCancelAppointment=Boolean(localStorage.getItem('cancelStatus'));
    this.appointmentEditData=JSON.parse(localStorage.getItem('cancelNewData'));

    if(this.isCancelAppointment==true)
    {
      this.bindNewDataFromCancel(this.appointmentEditData);    

    }
  }
  bindNewDataFromCancel(data:any)
  {
    if(data)
    {
      this.agendaFilteredData.puestovs_id=data.attribs.puestovs_id;      
      this.agendaFilteredData.accion_subtipo=data.attribs.subtipo_id;
      this.accion_subtipo = data.attribs.subtipo_id;
      this.puestovs_id = data.attribs.puestovs_id;
    }
  }
  ngAfterContentInit(): void{


    this.onSelectRedioButton(this.selectedType);

  }
  onSelectRedioButton(type: number) {
    this.selectedType = Number(type);
    this.agendaFilteredData = new AgendaRequestData();
    if(this.isCancelAppointment==true)
    {
      this.agendaFilteredData.puestovs_id=this.appointmentEditData.attribs.puestovs_id;
      this.agendaFilteredData.accion_subtipo=this.appointmentEditData.attribs.subtipo_id;
      this.accion_subtipo = this.appointmentEditData.attribs.subtipo_id;
      this.puestovs_id = this.appointmentEditData.attribs.puestovs_id;
    }
   
    this.pagado_sino = null;
    this.estado = '';
    this.accion_subtipo = null;
    this.puestovs_id = null;
    this.solicitante = null;
    this.provincia_id = null;
    this.pago_tipo = null;
    this.poblacion_id = null;
    this.tarjeta_sanitaria = null;
    this.pagado_importe = 0;
    this.accion_tipo = this.selectedType;

    this.agendaFilteredData.accion_tipo = this.selectedType; 
    this.agendaFilteredData.accion_subtipo = null;
    this.agendaFilteredData.estados = null;
    this.agendaFilteredData.provincia_id = null;
    this.agendaFilteredData.puestovs_id = null;
    this.agendaFilteredData.solicitante = null;
    this.agendaFilteredData.pago_tipo = null;
    this.agendaFilteredData.poblacion_id = null;
    this.agendaFilteredData.pagado_sino = null;
    this.agendaFilteredData.tarjeta_sanitaria = null;
    this.agendaFilteredData.pagado_importe = 0;
    
    if(this.selectedType == 3) {
      this.pagado_sino = 0;
      this.agendaFilteredData.pagado_sino = this.pagado_sino == 1 ? true : false;
    }
    
    this.appoinmentSearchDataEmit.emit(this.agendaFilteredData);
    if (this.selectedType == this.reservationTypeEnum.Reconocimiento || this.selectedType == this.reservationTypeEnum.PruebaCOVID) {
      this.getStatesActionTypes();
      this.getActionSubTypesList();
    }
  }

  async getPuestosVSList(companyId?: number) {
    this.isLoading = true;
    const requestData = {
      page_number: 1,
      page_size: this.puestosCount,
      type: this.requestDataType,
      relatedId: companyId
    }
   await this._visualizerCitarService.getPuestosVSList(requestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        this.puestosVSList = this.dropdownService.WrappedToDropDownList(res.data, 'descripcion', 'id');
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }
  async getPuestosVSListCount(companyId?: number) {
    this.isLoading = true;
    const requestData = {
      page_number: 1,
      page_size: 10,
      type: this.requestDataType,
      relatedId: companyId
    }
    await this._visualizerCitarService.getPuestosVSList(requestData).toPromise().then(res => {
      this.isLoading = false;
      if (res) {

        this.puestosCount=res.links.rowcount;
        //this.puestosVSList = this.dropdownService.WrappedToDropDownList(res.data, 'descripcion', 'id');
        this.getPuestosVSList(companyId);
      }
    }).catch(error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  getProvinciaList(incl?: string, isScroll = false) {
    this.isLoading = true;
    this.provinciaRequestData.incl = incl ? incl : null;
    this.provinciaRequestData.page_number = isScroll ? (this.provinciaRequestData.page_number + 1) : 1;
    this.provinciaRequestData.page_size = 10;
    this.provinciaRequestData.type  = this.requestDataType;
    this._visualizerCitarService.getProvincesList(this.provinciaRequestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        if (isScroll) {
          this.provinciaList = this.provinciaList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id'));
        } else {
          this.provinciaList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');
        }
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  getActionSubTypesList() {
    this.isLoading = true;
    const requestData = new RequestData();
    requestData.relatedId = this.selectedType;
    requestData.type = this.requestDataType;
    this._visualizerCitarService.getActionSubTypesList(requestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        this.actionSubTypesList = this.dropdownService.WrappedToDropDownList(res.data, 'descripcion', 'id');
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  getTownsList(provinceId?: number, incl?: string, isScroll = false) {
    this.isLoading = true;
    this.townsRequestData.incl = incl ? incl : null;
    this.townsRequestData.page_number = isScroll ? (this.townsRequestData.page_number + 1) : 1;
    this.townsRequestData.type = this.requestDataType;
    let request: any;
    if (provinceId > 0) {
      const requestData = new RequestData();
      requestData.relatedId = provinceId;
      requestData.incl = this.townsRequestData.incl;
      requestData.page_number = this.townsRequestData.page_number;
      requestData.type = this.requestDataType;
      request = requestData;
    } else {
      const requestData = {
        page_number: this.townsRequestData.page_number,
        page_size: 10,
        incl: this.townsRequestData.incl,
        type: this.requestDataType
      }
      request = requestData;
    }
    this._visualizerCitarService.getTownsList(request).subscribe(res => {
      this.isLoading = false;
      if (res) {
        if (isScroll) {
          this.townsList = this.townsList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id'));
        } else {
          this.townsList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id', false, false);
        }
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  getPaymentTypesList() {
    this.isLoading = true;
    const requestData = new RequestData();
    requestData.type = this.requestDataType;
    this._visualizerCitarService.getPaymentTypesList(requestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        this.paymentTypesList = this.dropdownService.WrappedToDropDownList(res.data, 'descripcion', 'id');
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  getActionTypes() {
    this.isLoading = true;
    const requestData = new RequestData();
    requestData.type = this.requestDataType;
    this._visualizerCitarService.getActionTypes(requestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        this.actionTypesList = this.dropdownService.WrappedToDropDownList(res.data, 'descripcion', 'id');
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  getStatesActionTypes() {
    this.isLoading = true;
    const requestData = new RequestData();
    requestData.type = null;
    requestData.relatedId = this.selectedType;
    this._visualizerCitarService.getStatesActionTypes(requestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        this.statesActionTypesList = this.dropdownService.WrappedToDropDownList(res.data, 'descripcion', 'id',false,false,true);
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  onProvinceChange(data: any) {
    if (data) {
      this.getTownsList(Number(data.value));
    }
    this.agendaFilteredData.provincia_id = this.provincia_id;
    this.appoinmentSearchDataEmit.emit(this.agendaFilteredData);

  }

  loadTopMatchingProvinces(provinceName: any) {
    // Trigger the loading only if string length is >= 3
    if (provinceName.length >= 3) { 
      this.getProvinciaList(provinceName);
    } else {
      if (provinceName.length == 0) {
        this.getProvinciaList();
      }
    }
  }

  onKeyUpProvince(provinceTarget){
    this.provinceSubject.next(provinceTarget?.target?.value);
  }

  loadTopMatchingTowns(townName: any) {
    // Trigger the loading only if string length is >= 4
    if (townName.length >= 4) { 
      this.getTownsList(this.agendaFilteredData.provincia_id, townName);
    } else {
      if (townName.length == 0) {
        this.getTownsList();
      }

    }
  }
  onKeyUpTown(townTarget){
    this.townSubject.next(townTarget?.target?.value);
  } 

  onChange() { 
    this.agendaFilteredData.pago_tipo = this.pago_tipo;
    this.agendaFilteredData.solicitante = this.solicitante;
    this.agendaFilteredData.poblacion_id = this.poblacion_id;
    this.agendaFilteredData.tarjeta_sanitaria = this.tarjeta_sanitaria;
    this.agendaFilteredData.pagado_importe = this.pagado_importe;
    this.agendaFilteredData.accion_subtipo = this.accion_subtipo;
    this.agendaFilteredData.puestovs_id = this.puestovs_id;
    this.appoinmentSearchDataEmit.emit(this.agendaFilteredData);
  }

  onPagadoChange() {
    if (this.pagado_sino!= undefined) {
      this.agendaFilteredData.pagado_sino = this.pagado_sino == 1 ? true : false;
    }
    this.appoinmentSearchDataEmit.emit(this.agendaFilteredData);
  }

  onChangeImporte(event:number) { 
    this.agendaFilteredData.pagado_importe=parseInt(event.toString());
    this.appoinmentSearchDataEmit.emit(this.agendaFilteredData);
  }

  onEstadoChange(estadoValues) { 
    if (estadoValues && estadoValues.length > 0) {
      let estadoId = 0;
      for (let index = 0; index < estadoValues.length; index++) {
        const powerValue = Math.pow(2, estadoValues[index].power2_bit);
        estadoId += powerValue;
      }
      this.agendaFilteredData.estados = estadoId;
    } else {
      this.agendaFilteredData.estados = 0;
    }
    this.appoinmentSearchDataEmit.emit(this.agendaFilteredData);
  }

  onScrollProvinces() {
    this.getProvinciaList('', true);
  }

  onScrollTowns() {
    this.getTownsList(this.agendaFilteredData.provincia_id, '', true);
  }

  
  ngOnChanges() {
    this.agendaFilteredData = this.inputAgendaFilteredData;
    if(this.clearEstado){
      this.estado = null;
    }
  }   
   

}