import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddExceptionComponent } from '../add-exception/add-exception.component';
import { EditAppointmentTimeframesComponent } from '../edit-appointment-timeframes/edit-appointment-timeframes.component';
import esLocale from '@fullcalendar/core/locales/es';
import { AgendaException } from '../../../model/agendar/agenda-exception';
import { DatePipe } from '@angular/common';
import { AgendaService } from '../agenda-services/agenda-service'
import { WeekSlots } from '../../../model/agendar/week_slots';
import { AgendaPost } from '../../../model/agendar/agenda-post';
import { AgendaDisplay } from '../../../model/agendar/agenda-display';
import { Router, NavigationEnd } from '@angular/router';
import { UIService } from '../../../service/shared/ui.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { AgendaList } from 'src/app/model/agendar/agenda-list';
import { filter } from 'rxjs/operators';
import { AgendaCopyExceptionService } from '../agenda-copy-exception.service';

@Component({
  selector: 'app-schedule-configuration',
  templateUrl: './schedule-configuration.component.html',
  styleUrls: ['./schedule-configuration.component.scss']
})

export class ScheduleConfigurationComponent implements OnInit {
  @Output() outException = new EventEmitter();
  @Output() outTimeSlot = new EventEmitter();
  agendaExceptions: AgendaException[] = [];
  angendaExcep: AgendaException = new AgendaException();
  weekSlot: WeekSlots[] = [];
  timeFrame: WeekSlots = new WeekSlots();
  agendaShedule: AgendaPost = new AgendaPost();
  angendaDisplay: AgendaDisplay = new AgendaDisplay();
  newAgenda: AgendaList = new AgendaList();
  editAgenda: AgendaList = new AgendaList();
  spaceString: string;
  spaceString1: string;
  centrotxt: string;
  Events = [];
  initialRegularSlots: any;
  public isLoading = false;
  isTabOne: boolean = true;
  currentDateCalender: any;
  OnAcceptObject: any;
  @ViewChild('calendarSpecific') calendarComponent: FullCalendarComponent;

  calendarOptions: CalendarOptions = {
    initialView: 'timeGridWeek',
    locale: esLocale,
    weekNumbers: false,
    dayHeaderFormat: {
      weekday: 'short',
    },
    editable: false,
    headerToolbar: {
      left: '',
      center: '',
      right: ''
    },
    allDaySlot: false,
    eventClick: (arg) => {
      let startSlot = new Date(arg.event.start);
      let endSlot = arg.event.end == null ? startSlot : new Date(arg.event.end);

      const modalRef = this.modalService.open(EditAppointmentTimeframesComponent, { centered: true, size: 'lg' });
      this.timeFrame = new WeekSlots();
      this.timeFrame.slot_desde = this.getTimeFrameValue(startSlot);
      this.timeFrame.slot_hasta = this.getTimeFrameValue(endSlot);
      this.timeFrame.isEdit = true;

      this.timeFrame.slotDate = this.setWeekSlotDate(startSlot);
      if (this.weekSlot.length > 0) {
        let itemIndex = this.weekSlot.findIndex(x => x.slot_desde == this.timeFrame.slot_desde &&
          x.slot_hasta == this.timeFrame.slot_hasta && x.slotDate == this.timeFrame.slotDate);
        this.timeFrame.concurrentes = this.weekSlot[itemIndex].concurrentes;
        this.timeFrame.notas = this.weekSlot[itemIndex].notas;
        this.timeFrame.num_dia = this.weekSlot[itemIndex].num_dia;
        this.timeFrame.aIndex = itemIndex;
      }
      else {
        this.timeFrame.concurrentes = 0;
        this.timeFrame.notas = "";
        this.timeFrame.aIndex = -1;
      }

      modalRef.componentInstance.newTimeFram = this.timeFrame;
      modalRef.componentInstance.minites = this.editAgenda.minutes;

      if (this.weekSlot.length > 0) {
        modalRef.componentInstance.editTimeFrame = this.weekSlot;
      }
      modalRef.componentInstance.onApceptTimeSlot.subscribe((onApceptTimeSlot) => {
        if (onApceptTimeSlot.isRemove) {
          this.removeTimeFrame(onApceptTimeSlot, this.timeFrame);
        }
        else {
          this.editTimeFram(onApceptTimeSlot, this.timeFrame);
        }

        let startOfWeek = moment().startOf('week').toDate();
        this.Events = this.getInitalEvents(startOfWeek, this.weekSlot);
        this.calendarOptions.events = this.Events;
      });
    },
    dateClick: this.handleDateClick.bind(this),
  };


  calendarOptionsSpecific: CalendarOptions = {
    initialView: 'timeGridWeek',
    locale: esLocale,
    weekNumbers: false,

    dayHeaderFormat: {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      omitCommas: true
    },
    titleFormat: {
      month: 'short',
      year: 'numeric'
    },
    customButtons: {
      prevMonth: {
        icon: 'chevrons-left',
        click: this.setPreviousMonth.bind(this),
      },
      nextMonth: {
        icon: 'chevrons-right',
        click: this.setNextMonth.bind(this),
      },
      prev: {
        icon: 'fc-icon-chevron-left',
        click: this.setPreviousWeek.bind(this),
      },
      next: {
        icon: 'fc-icon-chevron-right',
        click: this.setNextWeek.bind(this),
      }
    },

    editable: false,

    headerToolbar: {
      left: 'prevMonth prev',
      center: 'title',
      right: 'next nextMonth',
    },

    allDaySlot: false,

    eventClick: (arg) => {
      const modalRef = this.modalService.open(AddExceptionComponent, { centered: true, size: 'lg' });
      let exceptionItem = new AgendaException();
      exceptionItem = this.setEditDates(exceptionItem, arg);
      modalRef.componentInstance.editException = exceptionItem;
      modalRef.componentInstance.minites = this.editAgenda.minutes;

      exceptionItem.fecha_desde = new Date(arg.event.start);
      exceptionItem.fecha_desde = this.datePipe.transform(exceptionItem.fecha_desde, 'dd/MM/yyyy');
      if (this.agendaExceptions.some(item => item.fecha_desde == exceptionItem.fecha_desde)) {
        this.agendaCopyExceptionService.exceptionAvailability = true;
      } else {
        this.agendaCopyExceptionService.exceptionAvailability = false;
      }
      this.agendaCopyExceptionService.destino = arg.event.start;
      modalRef.componentInstance.onAppecept.subscribe((onAppecept) => {
        this.editExceptionGrid(onAppecept, exceptionItem);
      });
    },
    dateClick: this.handleSpecificDateClick.bind(this),
  };

  constructor(private modalService: NgbModal,
    public agendaService: AgendaService,
    private route: Router,
    private uiService: UIService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private agendaCopyExceptionService: AgendaCopyExceptionService

  ) {
    this.agendaCopyExceptionService.excep.subscribe((onAppecept) => {
      this.OnAcceptObject = onAppecept
    })
    this.bindCalendarData();
  }

  bindCalendarData() {
    this.spaceString = " - ";
    this.spaceString1 = "    ";
    this.route.events.pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (
          event.id === 1 &&
          event.url === event.urlAfterRedirects
        ) {
          let jObjecteditAgenda = this.getLocalStorage("editAgenda");
          let stringeditAgenda = JSON.parse(jObjecteditAgenda);
          this.editAgenda = <AgendaList>stringeditAgenda
          this.agendaExceptions = this.editAgenda.exception_slots;
          let startOfWeek = moment().startOf('week').toDate();
          this.weekSlot = this.setIntialWeekSlot(this.editAgenda.week_slots, startOfWeek);
          this.Events = this.getInitalEvents(startOfWeek, this.weekSlot);
          this.calendarOptions.events = this.Events;
          let days = new Date(startOfWeek)
          this.currentDateCalender = days.setDate(days.getDate() + 1);
          this.createCalendarOptionsSpecificData(startOfWeek, this.weekSlot);
          this.setDefault();
        }
      });
  }

  createCalendarOptionsSpecificData(startOfWeek: any, eventWeekSlot: WeekSlots[]) {
    let initialWeekSlots: any = eventWeekSlot;
    this.weekSlot = this.setIntialExceptionsWeekSlot(this.agendaExceptions);
    let exceptionArray = this.getInitalExceptionEvents(this.weekSlot);

    for (let event of eventWeekSlot) {
      let wDay = this.setInitialEventDate(startOfWeek, event.num_dia);
      for (let exception of this.agendaExceptions) {

        let fecha_desde = exception.fecha_desde.toString().replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3");
        let formattedDate = this.datePipe.transform(fecha_desde, 'EEE MMM d y h:mm:ss zzzz');
        let exceptionDay = new Date(formattedDate).getDate();
        if (wDay.getDate() == exceptionDay) {
          this.Events = this.Events.filter(item => item.num_dia !== event.num_dia)
        }
      }
    }

    let newEvents = this.Events.concat(exceptionArray);

    this.calendarOptionsSpecific.events = newEvents;
    this.weekSlot = initialWeekSlots;
  }
  setEditDates(exceptionItem: AgendaException, arg: any): AgendaException {
    //Set fecha_desde and hora_desde
    exceptionItem.fecha_desde = new Date(arg.event.start);
    exceptionItem.fecha_desde = this.datePipe.transform(exceptionItem.fecha_desde, 'dd/MM/yyyy');
    exceptionItem.hora_desde = this.getTimeFrameValue(arg.event.start);
    //Set fecha_hasta and hora_hasta
    exceptionItem.fecha_hasta = new Date(arg.event.end);
    let fecha_hasta = this.datePipe.transform(exceptionItem.fecha_hasta, 'dd/MM/yyyy');
    if (fecha_hasta == "01/01/1970") {
      exceptionItem.fecha_hasta = exceptionItem.fecha_desde;
    } else {
      exceptionItem.fecha_hasta = fecha_hasta;
    }

    if (arg.event.end == null) {
      exceptionItem.hora_hasta = exceptionItem.hora_desde;
    } else {
      exceptionItem.hora_hasta = this.getTimeFrameValue(arg.event.end);
    }

    return exceptionItem;
  }

  getInitalTestEvents(startOfWeek: Date, _weekDetail: WeekSlots[]): any[] {
    let outputArray = [];

    for (let element in _weekDetail) {
      let intitDate = this.setInitialEventDate(startOfWeek, _weekDetail[element].num_dia);
      let stringDate = this.setWeekSlotDate(intitDate);
      outputArray.push({
        title: _weekDetail[element].concurrentes,
        start: stringDate + "T" + _weekDetail[element].slot_desde,
        end: stringDate + "T" + _weekDetail[element].slot_hasta,
        color: "#5bc2ee",
        daysOfWeek: [_weekDetail[element].num_dia],
        startTime: _weekDetail[element].slot_desde,
        endTime: _weekDetail[element].slot_hasta,
        startRecur: _weekDetail[element].slot_desde,
        endRecur: _weekDetail[element].slot_hasta,
      });
    }
    return outputArray;
  }

  changeTab(tab) {
    if (tab == 1) {
      this.isTabOne = true;
    } else {
      this.isTabOne = false;
      this.getExceptionFromRegularSlot(new Date(this.currentDateCalender), true);
    }
  }

  ngOnInit(): void {
    this.bindCalendarData();
    this.isLoading = true;
    this.agendaService.centroTextSubcription.subscribe((value) => {
      if (value) {
        this.centrotxt = value;
        this.setLocalStorage("centrotxt", this.centrotxt);
      }
      else {
        this.centrotxt = "";
      }
    })
    this.agendaService.agendaNewItem.subscribe((display) => {
      if (display) {
        this.newAgenda = display;
      }
      else {
        this.newAgenda = null;
      }
    });

    this.agendaService.agendaSheduleDetaisCalled.subscribe((agenda) => {
      if (agenda) {
        if (agenda.exception_slots != undefined) {
          this.agendaExceptions = agenda.exception_slots;
        }
        if (agenda.week_slots != undefined) {
          let startOfWeek = moment().startOf('week').toDate();
          this.weekSlot = this.setIntialWeekSlot(agenda.week_slots, startOfWeek);
          this.Events = this.getInitalEvents(startOfWeek, this.weekSlot);
          this.calendarOptions.events = this.Events;
          this.createCalendarOptionsSpecificData(startOfWeek, this.weekSlot);
        }
      }
      else {
      }
    });

    this.agendaService.existsDataSubcrib.subscribe((agenda) => {
      if (agenda) {
        this.editAgenda = agenda;
        this.setLocalStorage("editAgenda", JSON.stringify(this.editAgenda));
      }
    });
    this.setDefault();
  }

  public getExceptionFromRegularSlot(currentDate: Date, isFirstWeek?: boolean, isCopyEx?: boolean) {
    let startOfWeek = moment().startOf('week').toDate();
    if (!isCopyEx) {

      let jObjecteditAgenda = this.getLocalStorage("editAgenda");
      let stringeditAgenda = JSON.parse(jObjecteditAgenda);
      this.editAgenda = <AgendaList>stringeditAgenda
      this.agendaExceptions = this.editAgenda.exception_slots;
      this.weekSlot = this.setIntialWeekSlot(this.editAgenda.week_slots, startOfWeek);
      this.Events = this.getInitalEvents(startOfWeek, this.weekSlot);
    }
    let newWeekSlot = JSON.parse(JSON.stringify(this.weekSlot))

    let initialExceptionWeekSlot = this.setIntialExceptionsWeekSlot(this.agendaExceptions);
    let exceptionArray = this.getInitalExceptionEvents(initialExceptionWeekSlot);

    if (!isFirstWeek) {
      for (let item in newWeekSlot) {
        let newDate = new Date(new Date(newWeekSlot[item].slotDate).setDate(currentDate.getDate() + (newWeekSlot[item].num_dia) - 1));
        let transformedDate = this.datePipe.transform(newDate, 'yyyy-MM-dd');
        newWeekSlot[item].slotDate = transformedDate;
      }
      currentDate = new Date(new Date(currentDate).setDate(currentDate.getDate() - 1));
      this.Events = this.getInitalEvents(currentDate, newWeekSlot);
    }
    for (let item in this.Events) {
      if (exceptionArray.find(item1 => new Date(item1.start).getDate() === new Date(this.Events[item].start).getDate()
        && new Date(item1.start).getMonth() === new Date(this.Events[item].start).getMonth()
        && new Date(item1.start).getFullYear() === new Date(this.Events[item].start).getFullYear())) {
        if (exceptionArray.find(item1 => new Date(item1.start).getDate() === new Date(this.Events[item].start).getDate())) {
          this.Events[item] = [];
        }
      }
    }

    this.Events = this.Events.filter(i => i !== {})
    let newEvents = this.Events.concat(exceptionArray);
    newEvents = newEvents.filter(
      obj => !(obj && Object.keys(obj).length === 0)
    );
    this.calendarOptionsSpecific.events = newEvents;
  }
  public setPreviousWeek(): void {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.prev();
    let currentDate = calendarApi.getDate();
    this.getExceptionFromRegularSlot(currentDate);
  }
  public setNextWeek(): void {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.next();
    let currentDate = calendarApi.getDate();
    this.getExceptionFromRegularSlot(currentDate);
  }
  public setPreviousMonth(): void {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.prev();
    calendarApi.prev();
    calendarApi.prev();
    calendarApi.prev();
    let currentDate = calendarApi.getDate();
    this.getExceptionFromRegularSlot(currentDate);
  }
  public setNextMonth(): void {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.next();
    calendarApi.next();
    calendarApi.next();
    calendarApi.next();
    let currentDate = calendarApi.getDate();
    this.getExceptionFromRegularSlot(currentDate);
  }
  public setCalenderCustom(): void {
    const calendarApi = this.calendarComponent.getApi();
    calendarApi.next();
    calendarApi.prev();
    let currentDate = calendarApi.getDate();
    this.getExceptionFromRegularSlot(currentDate);
  }
  setDefault() {
    if (this.editAgenda == undefined || this.editAgenda == null || this.editAgenda.id == 0) {
      this.newAgenda.week_slots = this.setNewWeekSlot();
    }
  }

  setLocalStorage(storName: string, storObect: any) {
    localStorage.getItem(storName);
    if (storObect != undefined && storObect != null && storObect != "{}") {
      localStorage.setItem(storName, storObect);
    }
  }
  getLocalStorage(storName: string): any {
    return localStorage.getItem(storName);
  }

  removeLocalStorage(storName: string) {
    let data = localStorage.getItem(storName);
    if (data != undefined) {
      localStorage.removeItem(storName);
    }
  }

  setIntialWeekSlot(slotItem: any[], startOfWeek: Date): WeekSlots[] {
    let outputArray: WeekSlots[] = [];
    for (let slot in slotItem) {
      let item = new WeekSlots();
      item.num_dia = slotItem[slot].num_dia;
      let intitDate = this.setInitialEventDate(startOfWeek, slotItem[slot].num_dia);
      item.slot_desde = slotItem[slot].slot_desde;
      item.slot_hasta = slotItem[slot].slot_hasta;
      item.concurrentes = slotItem[slot].concurrentes;
      item.total_concurrentes = slotItem[slot].total_concurrentes;
      item.notas = slotItem[slot].notas;
      item.slotDate = this.setWeekSlotDate(intitDate);
      outputArray.push(item);
    }
    return outputArray;
  }
  setIntialWeekSlotSpecific(slotItem: any[], startOfWeek: Date): WeekSlots[] {
    let outputArray: WeekSlots[] = [];
    for (let slot in slotItem) {
      let item = new WeekSlots();

      let intitDate = this.setInitialEventDate(startOfWeek, slotItem[slot].num_dia);

      item.num_dia = slotItem[slot].num_dia;
      item.slot_desde = slotItem[slot].hora_desde;
      item.slot_hasta = slotItem[slot].hora_hasta;
      item.concurrentes = slotItem[slot].concurrentes;
      item.total_concurrentes = slotItem[slot].total_concurrentes;
      item.notas = slotItem[slot].notas;
      item.slotDate = this.setWeekSlotDate(intitDate);
      outputArray.push(item);
    }
    return outputArray;
  }

  setIntialExceptionsWeekSlot(slotItem: any[]): WeekSlots[] {
    let outputArray: WeekSlots[] = [];
    for (let slot in slotItem) {
      let item = new WeekSlots();

      let fecha_desde;
      let intitDate

      if (slotItem[slot].fecha_desde?._isAMomentObject && slotItem[slot].fecha_desde._isAMomentObject) {
        slotItem[slot].fecha_desde = moment(slotItem[slot].fecha_desde).format("DD/MM/YYYY")
        slotItem[slot].fecha_desde = moment(slotItem[slot].fecha_hasta).format("DD/MM/YYYY")

        fecha_desde = slotItem[slot].fecha_desde.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3");
        intitDate = this.datePipe.transform(fecha_desde, 'EEE MMM d y h:mm:ss zzzz');
      } else {
        fecha_desde = slotItem[slot].fecha_desde.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3");
        intitDate = this.datePipe.transform(fecha_desde, 'EEE MMM d y h:mm:ss zzzz');
      }
      item.slot_desde = slotItem[slot].hora_desde;
      item.slot_hasta = slotItem[slot].hora_hasta;
      item.concurrentes = slotItem[slot].concurrentes;
      item.notas = slotItem[slot].notas;
      item.slotDate = this.setWeekSlotDate(new Date(intitDate));

      outputArray.push(item);
    }
    return outputArray;
  }

  setCopyExceptionsWeekSlot(slotItem: any[]): WeekSlots[] {
    let outputArray: WeekSlots[] = [];
    for (let slot in slotItem) {
      let item = new WeekSlots();
      //Format fecha_desde
      let fecha_desde;
      let intitDate
      fecha_desde = slotItem[slot].fecha_desde.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$2/$1/$3");
      intitDate = this.datePipe.transform(fecha_desde, 'EEE MMM d y h:mm:ss zzzz');
      item.slot_desde = slotItem[slot].hora_desde;
      item.slot_hasta = slotItem[slot].hora_hasta;
      item.concurrentes = slotItem[slot].concurrentes;
      item.notas = slotItem[slot].notas;
      item.slotDate = this.setWeekSlotDate(new Date(intitDate));

      outputArray.push(item);
    }
    return outputArray;
  }

  getInitalEvents(startOfWeek: Date, _weekDetail: WeekSlots[]): any[] {
    let outputArray = [];
    for (let element in _weekDetail) {
      let intitDate = this.setInitialEventDate(startOfWeek, _weekDetail[element].num_dia);
      let stringDate = this.setWeekSlotDate(intitDate);
      outputArray.push({
        title: _weekDetail[element].concurrentes,
        start: stringDate + "T" + _weekDetail[element].slot_desde,
        end: stringDate + "T" + _weekDetail[element].slot_hasta,
        color: "#5bc2ee",
      });
    }
    return outputArray;
  }

  getInitalExceptionEvents(_weekDetail: WeekSlots[]): any[] {
    let outputArray = [];
    for (let element in _weekDetail) {

      let slotDate = _weekDetail[element].slotDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3");
      let intitDate = this.datePipe.transform(slotDate, 'EEE MMM d y h:mm:ss zzzz');
      let stringDate = this.setWeekSlotDate(new Date(intitDate));

      outputArray.push({
        title: _weekDetail[element].concurrentes,
        start: stringDate + "T" + _weekDetail[element].slot_desde,
        end: stringDate + "T" + _weekDetail[element].slot_hasta,
        color: "#0062cc",
      });
    }
    return outputArray;
  }

  setWeekSlotDate(startOfWeek: Date): string {
    let wDay = startOfWeek.getDate();
    let wYear = startOfWeek.getFullYear();
    let wMonth = startOfWeek.getMonth() + 1;
    let sMonth = "";
    let sDay = "";
    if (wMonth < 10) {
      sMonth = "0" + wMonth.toString();
    }
    else {
      sMonth = wMonth.toString();
    }
    if (wDay < 10) {
      sDay = "0" + wDay.toString();
    }
    else {
      sDay = wDay.toString();
    }
    return (wYear + "-" + sMonth + "-" + sDay);
  }
  setInitialEventDate(startOfWeek: Date, dayID: number): Date {
    let daysInMonth = this.getCurrentMonthDays(startOfWeek);
    let tempDay = startOfWeek.getDate() + this.getDayByID(dayID);
    let wDay = startOfWeek.getDate() + this.getDayByID(dayID);
    let wYear = startOfWeek.getFullYear();
    let wMonth = startOfWeek.getMonth() + 1;
    if (tempDay > daysInMonth) {
      if (wMonth == 12) {
        wYear = wYear + 1;
      }
      wMonth = wMonth + 1;
      if (wMonth > 12) {
        wMonth = 1;
      }
      wDay = (tempDay - daysInMonth);
    }

    return new Date(wYear + "-" + wMonth + "-" + wDay);
  }

  getCurrentMonthDays(startOfWeek: Date): number {
    let wYear = startOfWeek.getFullYear();
    let wMonth = startOfWeek.getMonth() + 1;
    return new Date(wYear, wMonth, 0).getDate();
  }

  getDayByID(dayID: number): number {
    if (dayID > 7) {
      dayID = 0;
    }
    return dayID;
  }

  handleSpecificDateClick(arg) {
    const modalRef = this.modalService.open(AddExceptionComponent, { centered: true, size: 'lg' });
    let exceptionItem = new AgendaException();
    let selectDate = new Date(arg.dateStr);

    exceptionItem.fecha_desde = this.datePipe.transform(selectDate, 'dd/MM/yyyy');
    exceptionItem.fecha_hasta = exceptionItem.fecha_desde;
    exceptionItem.hora_desde = this.getTimeFrameValue(selectDate);
    exceptionItem.hora_hasta = exceptionItem.hora_desde;
    this.timeFrame.notas = "";

    modalRef.componentInstance.editException = exceptionItem;
    modalRef.componentInstance.minites = this.editAgenda.minutes;
    modalRef.componentInstance.onAppecept.subscribe((onAppecept) => {
      this.addExceptionToGrid(onAppecept);
    });
    let clickedDate = moment(arg.dateStr).format("DD/MM/YYYY");
    if (this.agendaExceptions.some(item => item.fecha_desde == clickedDate)) {
      this.agendaCopyExceptionService.exceptionAvailability = true;
    } else {
      this.agendaCopyExceptionService.exceptionAvailability = false;
    }
    this.agendaCopyExceptionService.destino = arg.dateStr;
    modalRef.result.then(_dismiss => {
      if (this.OnAcceptObject) {
        if (this.agendaCopyExceptionService.isClicked) {
          let origin = moment(this.OnAcceptObject.día_origen).format("DD/MM/YYYY");

          let dia_destino = this.datePipe.transform(this.OnAcceptObject.día_destino, 'dd/MM/yyyy');
          let destinatedExcep = this.agendaExceptions.filter(item => item.fecha_desde == origin);
          let second = JSON.parse(JSON.stringify(destinatedExcep))
          second.forEach(item => {
            item.fecha_desde = dia_destino;
            item.fecha_hasta = dia_destino
          });
          let destinatedExceptionWeekSlot = this.setCopyExceptionsWeekSlot(second);
          let desinatedExcepArray = this.getInitalExceptionEvents(destinatedExceptionWeekSlot);
          let initialExceptionWeekSlot = this.setIntialExceptionsWeekSlot(this.agendaExceptions);
          let exceptionArray = this.getInitalExceptionEvents(initialExceptionWeekSlot);
          this.Events = this.Events.filter(
            obj => !(obj && Object.keys(obj).length === 0)
          );
          let allExceptions = desinatedExcepArray.concat(exceptionArray);
          let newEvents = this.Events.concat(allExceptions);

          this.calendarOptionsSpecific.events = newEvents;
          second.forEach(i => this.agendaExceptions.push(i));
          this.agendaCopyExceptionService.buttonClickReset();
          this.getExceptionFromRegularSlot(new Date(this.currentDateCalender), true, true);
          this.setLocalStorage("editAgenda", JSON.stringify(this.editAgenda));
        }
      }
    }
    );
  }

  handleDateClick(arg) {
    let selectDate = new Date(arg.dateStr);
    const modalRef = this.modalService.open(EditAppointmentTimeframesComponent, { centered: true, size: 'lg' });
    this.timeFrame = new WeekSlots();
    this.timeFrame.slot_desde = this.getTimeFrameValue(selectDate);
    this.timeFrame.slot_hasta = this.getTimeFrameValue(selectDate);
    this.timeFrame.notas = "";
    this.timeFrame.slotDate = this.setWeekSlotDate(selectDate);
    this.timeFrame.isEdit = false;
    this.timeFrame.aIndex = -1;
    modalRef.componentInstance.newTimeFram = this.timeFrame;
    modalRef.componentInstance.minites = this.editAgenda.minutes;
    modalRef.componentInstance.onApceptTimeSlot.subscribe((onApceptTimeSlot) => {
      if (this.weekSlot == undefined || this.weekSlot == null || this.weekSlot.length == 0) {
        let startOfWeek = moment().startOf('week').toDate();
        if ((this.newAgenda == undefined || this.newAgenda == null) || (this.newAgenda.week_slots == undefined || this.newAgenda.week_slots == null)) {
          this.newAgenda = new AgendaList();
          this.newAgenda.week_slots = this.setNewWeekSlot();
        }
        this.weekSlot = this.setIntialWeekSlot(this.newAgenda.week_slots, startOfWeek);
      }
      this.editTimeFram(onApceptTimeSlot, onApceptTimeSlot);
    });
  }

  setNewWeekSlot(): any[] {
    let outputArray = [];
    for (let i = 1; i < 8; i++) {
      outputArray.push({
        num_dia: i,
        slot_desde: "00.00",
        slot_hasta: "23:59",
        concurrentes: 0,
        total_concurrentes: 0,
        notas: ""
      });
    }
    return outputArray
  }
  getTimeFrameValue(value: Date): any {
    let cHour: any;
    let cMinit: any;
    if (value.getHours() < 10) {
      cHour = "0" + value.getHours();
    }
    else {
      cHour = value.getHours();
    }

    if (value.getMinutes() < 10) {
      cMinit = "0" + value.getMinutes();
    }
    else {
      cMinit = value.getMinutes();
    }
    return cHour + ":" + cMinit;
  }

  addTimeFram(onAppecept: WeekSlots): WeekSlots {
    let outputArray;
    outputArray({
      num_dia: onAppecept.num_dia,
      slot_desde: onAppecept.slot_desde,
      slot_hasta: onAppecept.slot_hasta,
      concurrentes: onAppecept.concurrentes,
      total_concurrentes: onAppecept.total_concurrentes,
      notas: onAppecept.notas
    });

    return outputArray;
  }

  editTimeFram(onAppecept: WeekSlots, _lastValue: WeekSlots) {
    let message = "El registro podría estar superpuesto";

    if (onAppecept.isEdit) {
      let value = this.weekSlot[onAppecept.aIndex];
      this.weekSlot.splice(onAppecept.aIndex, 1);
      let tArray = this.weekSlot.filter(item => item.num_dia == onAppecept.num_dia)
      let arrDesta = tArray.filter((item: any) =>
        item.slot_desde >= onAppecept.slot_desde && item.slot_desde <= onAppecept.slot_hasta);

      let arrhasta = tArray.filter((item: any) =>
        item.slot_hasta >= onAppecept.slot_desde && item.slot_hasta <= onAppecept.slot_hasta);

      if (arrDesta.length > 0 || arrhasta.length > 0) {
        this.uiService.showWarningMessage(message);
        this.weekSlot.push(value);
        return;
      }
      this.weekSlot.push(onAppecept);
      this.Events = this.getCalanderEvent(this.weekSlot);
      this.calendarOptions.events = this.Events;

    }
    else {
      let tArray = this.weekSlot.filter(item => item.num_dia == onAppecept.num_dia)
      let arrDesta = tArray.filter((item: any) =>
        item.slot_desde >= onAppecept.slot_desde && item.slot_desde <= onAppecept.slot_hasta);

      let arrhasta = tArray.filter((item: any) =>
        item.slot_hasta >= onAppecept.slot_desde && item.slot_hasta <= onAppecept.slot_hasta);

      if (arrDesta.length > 0 || arrhasta.length > 0) {
        this.uiService.showWarningMessage(message);
        return;
      }
      this.weekSlot.push(onAppecept);
      this.Events = this.getCalanderEvent(this.weekSlot);
      this.calendarOptions.events = this.Events;
    }
  }

  removeTimeFrame(onAppecept: WeekSlots, _lastValue: WeekSlots) {

    let itemIndex = this.weekSlot.findIndex(x => x.num_dia == onAppecept.num_dia && x.slot_desde == onAppecept.slot_desde && x.slot_hasta == onAppecept.slot_hasta);
    if (itemIndex !== -1) {
      onAppecept.slot_desde = "00:15AM";
      onAppecept.slot_hasta = "11.59PM";
      onAppecept.concurrentes = 0;
      onAppecept.notas = "";
      this.weekSlot[itemIndex] = onAppecept;
      this.Events = this.getCalanderEvent(this.weekSlot);
      this.calendarOptions.events = this.Events;
    }
    else {
      this.uiService.showWarningMessage("No se pudo eliminar el registro");
    }
  }
  getCalanderEvent(timeSlot: WeekSlots[]): any[] {
    let outputArray = [];
    for (let element in timeSlot) {
      if (timeSlot[element].concurrentes > 0) {
        outputArray.push({
          title: timeSlot[element].concurrentes,
          start: timeSlot[element].slotDate + "T" + timeSlot[element].slot_desde,
          end: timeSlot[element].slotDate + "T" + timeSlot[element].slot_hasta,
          color: "#5bc2ee",
        });
      }
    }
    return outputArray;
  }

  onOpenTimeFrameModal() {
    const modalRef = this.modalService.open(EditAppointmentTimeframesComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.minites = this.editAgenda.minutes;
  }

  onEditExceptionMadal(exceptionItem: AgendaException) {
    const modalRef = this.modalService.open(AddExceptionComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.editException = exceptionItem;
    modalRef.componentInstance.minites = this.editAgenda.minutes;
    modalRef.componentInstance.onAppecept.subscribe((onAppecept) => {
      this.editExceptionGrid(onAppecept, exceptionItem);
    });
  }

  onOpenExceptionModal() {
    const modalRef = this.modalService.open(AddExceptionComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.minites = this.editAgenda.minutes;
    modalRef.componentInstance.onAppecept.subscribe((onAppecept) => {
      this.addExceptionToGrid(onAppecept);
    });
  }

  editExceptionGrid(onAppecept: AgendaException, exceptionItem: AgendaException) {
    if (onAppecept.fecha_desde?._isAMomentObject && onAppecept.fecha_hasta._isAMomentObject) {
      onAppecept.fecha_desde = moment(onAppecept.fecha_desde).format("DD/MM/YYYY")
      onAppecept.fecha_hasta = moment(onAppecept.fecha_hasta).format("DD/MM/YYYY")
    }
    let itemIndex = this.agendaExceptions.findIndex(x => x.fecha_hasta == exceptionItem.fecha_hasta &&
      x.fecha_desde == exceptionItem.fecha_desde && x.hora_hasta == exceptionItem.hora_hasta
      && x.hora_desde == exceptionItem.hora_desde);
    this.agendaExceptions[itemIndex] = onAppecept;

    let startOfWeek = moment().startOf('week').toDate();
    this.weekSlot = this.setIntialWeekSlotSpecific(this.agendaExceptions, startOfWeek);
    this.getExceptionFromRegularSlot(new Date(this.currentDateCalender), true, true);
    this.setLocalStorage("editAgenda", JSON.stringify(this.editAgenda));
    this.weekSlot = [];
  }
  addExceptionToGrid(onAppecept: AgendaException) {
    if (onAppecept.fecha_desde?._isAMomentObject && onAppecept.fecha_hasta._isAMomentObject) {
      onAppecept.fecha_desde = moment(onAppecept.fecha_desde).format("DD/MM/YYYY")
      onAppecept.fecha_hasta = moment(onAppecept.fecha_hasta).format("DD/MM/YYYY")
    }
    this.agendaExceptions.push(onAppecept);
    let startOfWeek = moment().startOf('week').toDate();
    this.weekSlot = this.setIntialWeekSlotSpecific(this.agendaExceptions, startOfWeek);
    this.getExceptionFromRegularSlot(new Date(this.currentDateCalender), true, true);
    this.setLocalStorage("editAgenda", JSON.stringify(this.editAgenda));
    this.weekSlot = [];
  }

  deleteException(exceptionItem: AgendaException) {

    Swal.fire({
      title: '',
      text: this.translateService.instant('common.deleteMessage'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('common.yes'),
      cancelButtonText: this.translateService.instant('common.no')
    }).then((result) => {
      if (result.value) {
        let itemIndex = this.agendaExceptions.findIndex(x => x.fecha_hasta == exceptionItem.fecha_hasta &&
          x.fecha_desde == exceptionItem.fecha_desde && x.hora_hasta == exceptionItem.hora_hasta
          && x.hora_desde == exceptionItem.hora_desde);
        if (itemIndex !== -1) {
          this.agendaExceptions.splice(itemIndex, 1);
          let startOfWeek = moment().startOf('week').toDate();
          this.weekSlot = this.setIntialWeekSlotSpecific(this.agendaExceptions, startOfWeek);
          this.createCalendarOptionsSpecificData(startOfWeek, this.weekSlot);
          this.setDefault();
          this.weekSlot = [];
        }
        else {
          this.uiService.showWarningMessage("No se pudo eliminar el registro")
        }
      }
    });
  }

  getCashCentro(): string {
    if (this.centrotxt == undefined || this.centrotxt == "") {
      return this.getLocalStorage("centrotxt")
    }
    else {
      return this.centrotxt;
    }
  }
  onAppceptCalender() {
    this.centrotxt = this.getCashCentro();
    this.agendaService.setCentroText(this.centrotxt);

    if (this.newAgenda != undefined && this.newAgenda == null) {
      this.newAgenda.week_slots = this.weekSlot;
      this.newAgenda.exception_slots = this.agendaExceptions;
    }

    this.agendaService.setNewAgendaDetail(this.newAgenda);
    this.agendaShedule.exception_slots = this.agendaExceptions;
    this.agendaShedule.week_slots = this.weekSlot
    this.agendaService.updateAgendaException(this.agendaShedule);

    if (this.editAgenda != undefined && this.editAgenda == null) {
      this.editAgenda.week_slots = this.weekSlot;
      this.editAgenda.exception_slots = this.agendaExceptions;
    }

    this.editAgenda.week_slots = this.weekSlot
    this.agendaService.updateExistsRevData(this.editAgenda);
    this.removeLocalStorage("editAgenda")
    this.route.navigate(['/agendar-list/save/', '0', '']);
  }

  onConfigAgenda() {
    this.onAppceptCalender();

  }

  onCaneclShedul() {
    this.centrotxt = this.getCashCentro();
    this.agendaService.setCentroText(this.centrotxt);
    this.agendaService.setNewAgendaDetail(this.newAgenda);
    this.route.navigate(['/agendar-list/save/', '0', '']);
  }

  getAgendaName() {
    return localStorage.getItem('listAgendaName')
  }
}

