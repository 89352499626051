<app-spinner *ngIf="isLoading"></app-spinner>
<div class="container-fluid pl-0 pr-0">
    <div class="row">
        <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
          <div class="form-group">
            <label class="mb-0">Empresa</label>
            <ng-select
              [clearAllText]="'limpiar todo'"
              #select
              [virtualScroll]="true"
              (close)="select.blur()"
              (clear)="onCloseCompanies()"
              loadingText="Cargando..."
              notFoundText="No hay resultados"
              id="activity-type"
              name="activity-type"
              bindLabel="label"
              bindValue="value"
              [items]="companyList"
              placeholder="Seleccione"
              (keyup)="onKeyUpCompany($event)"
              (change)="onChangeCompany($event)"
              [(ngModel)]="companyId"
              (scrollToEnd)="onScrollCompany()"
              [class.ng-invalid]="this.validateCompany && companyId == null"
            >
              <ng-template ng-option-tmp let-item="item">
                <div title="{{ item.label }}">{{ item.label }}</div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        
      </div>
</div>
