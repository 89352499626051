import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../service/authentication/auth-service.component';

@Component({
    selector: 'app-signin-callback',
    templateUrl: './signout-redirect-callback.component.html'
})

export class SignoutRedirectCallbackComponent implements OnInit {
    
    constructor(
        private _authService: AuthService,
        private _router: Router) { }

    ngOnInit() {
        this._authService.completeLogout().then(_user => {
          this._router.navigate(['/signin'], {replaceUrl: true});
     })
     
    }
}
