import { PowerbiService } from 'src/app/service/powerbi/powerbi.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/authentication/auth-service.component';
import { CommonService } from 'src/app/service/common/common.service';
import { HeaderService } from 'src/app/service/shared/header.service';
import { UIService } from 'src/app/service/shared/ui.service';
import tippy from 'tippy.js';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  title: string;
  public isLoading = false;

  powerBIReports :any;

  powerBIReportCount:number;
  tooltipData = [
    {
      id: 'analyticMenuItem',
      content: 'Analitica BI',
    },
    {
      id: 'powerBiMenuItem',
      content: 'Analitica BI',
    },
    {
      id: 'contactsMenuItem',
      content: 'Contactos Desde Autocita',
    },
  ];
  notificationCount: number;
  constructor(
    private _authService: AuthService,
    private headerService: HeaderService,
    private _commonService: CommonService, 
    private router : Router,
    public _uiService: UIService,
    private powerbiService : PowerbiService
  ) {
  }

  pendingContactsRequestData = {
    page_number: 1,
    page_size: 25, 
    type: 'PENDING'
  }
  ngOnInit(): void {
    this.headerService.title.subscribe(title => {
      this.title = title;
    });
    this.getPendingContacts();
    this.getAllPowerBIReports();
    this.tooltipData.forEach((element) => {
      tippy(document.getElementById(element.id), {
        content: element.content,
        theme: 'vmd',
        placement: 'bottom',
      });
    });
  }
  getAllPowerBIReports()
  {
    this.powerbiService.getAllReports().subscribe(res=>{
      this.powerBIReports=res;
      this.powerBIReportCount=res.length;
    });
  }

  logout() {
    localStorage.removeItem("centro_id");
    localStorage.removeItem("agendaRelatedId");
    localStorage.removeItem("agendaId");
    localStorage.removeItem("centro_text");    
    this._authService.logout();
    return false;
  }

  getPendingContacts() {
    this.isLoading = true;
    this._commonService.getContacts(this.pendingContactsRequestData).subscribe(res => {
      this.isLoading = false;
      if (res.data) {    
        this.notificationCount = res.links.rowcount;  
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  redirect(link){
    this._uiService.selectedMenu = 6;
    this.router.navigateByUrl(link);
  }
  redirectPBI(link,id){
    this._uiService.selectedMenu = 6;
    this.router.navigate([link,id]);
  }
}
