import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Agenda } from "src/app/model/agendar/agenda";
import { CommonService } from 'src/app/service/common/common.service';
import { UIService } from 'src/app/service/shared/ui.service';
import moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-reschedule-appointment-pop-up',
  templateUrl: './reschedule-appointment-pop-up.component.html',
  styleUrls: ['./reschedule-appointment-pop-up.component.scss']
})
export class RescheduleAppointmentPopUpComponent implements OnInit {
  @Input() agenda: Agenda;
  @Input() intentionId: string;
  editedAgenda: Agenda;
  formData;

  agendaTimeSlotForm = new FormGroup({
    centro_Id: new FormControl(''),
    dateAppointment: new FormControl('', [Validators.required]),
    timeAppointment: new FormControl('', [Validators.required]),
  });
  constructor(public modal: NgbActiveModal, private _commonService: CommonService, private uiService: UIService, private changeDetection: ChangeDetectorRef) {
    this.editedAgenda = new Agenda();

  }
  submitted = false;
  isLoading: boolean = false;
  ngOnInit(): void {
    this.editedAgenda = this.agenda;
  }

  getAgendaTimeSlotData(data) {
    this.formData = data;
    if (data?.attribs) {
      this.agendaTimeSlotForm.controls.dateAppointment.setValue(this.formData.attribs.fecha);
      this.agendaTimeSlotForm.controls.timeAppointment.setValue(this.formData.attribs.hora);
      this.agendaTimeSlotForm.controls.centro_Id.setValue(this.formData.attribs.centro_id);
    }
  }

  validateFormControl(name: string) {
    if (this.agendaTimeSlotForm.get(name) != null) {
      return this.agendaTimeSlotForm.get(name).invalid &&
        (this.submitted ||
          this.agendaTimeSlotForm.get(name).dirty ||
          this.agendaTimeSlotForm.get(name).touched)
    }
  }

  saveTimeSlot() {
    this.submitted = true;
    if (this.agendaTimeSlotForm.valid) {
      this.isLoading = true;
      this.preparePostData()
      const postData = this.editedAgenda;
      postData['intention_id'] = this.intentionId;
      this._commonService.postAppointment(postData).subscribe(res => {
        if (res && res?.webError?.number == 0) {
          this.uiService.showFailToast("Error", res?.webError?.messageToUser);
        }
        else if (res && res?.data != null) {
          this.uiService.showSuccessToast("Guardado con éxito");
          this.prepareBindingData();
          setTimeout(function () {
            window.location.reload();
          }, 1500)
        }
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
      });
    }
  }

  preparePostData() {
    if (this.editedAgenda.attribs != null) {
      this.editedAgenda.attribs.fecha = moment(new Date(this.formData.attribs.fecha)).format('yyyyMMDD');
      const h = this.formData.attribs.hora?.substr(0, 2);
      const m = this.formData.attribs.hora?.substr(3, 2);
      this.editedAgenda.attribs.hora = h + m + '00';
      this.editedAgenda.attribs.centro_id = this.formData.attribs.centro_id;
      this.editedAgenda.attribs.subtipo_id = this.editedAgenda.attribs.subtipo_id?.toString();
    }
  }

  prepareBindingData() {
    if (this.editedAgenda.attribs != null) {
      this.editedAgenda.attribs.subtipo_id = this.editedAgenda.attribs.subtipo_id == "" ? null : (typeof (this.editedAgenda.attribs.subtipo_id) == "string" ? Number(this.editedAgenda.attribs.subtipo_id) : this.editedAgenda.attribs.subtipo_id);
      
      if (this.editedAgenda.attribs.fecha.length == 8) {
        let day = this.editedAgenda.attribs.fecha?.substring(6, 8);
        let month = this.editedAgenda.attribs.fecha?.substring(4, 6);
        let year = this.editedAgenda.attribs.fecha?.substring(0, 4);
        if (day != undefined && month != undefined && year != undefined) {
          let date = day + '/' + month + '/' + year;
          this.editedAgenda.attribs.fecha = date;
        }
      }

      if (this.editedAgenda.attribs.hora.trim().length == 4 || this.editedAgenda.attribs.hora.trim().length == 6) {
        const h = this.editedAgenda.attribs.hora?.substring(0, 2);
        const m = this.editedAgenda.attribs.hora?.substring(2, 2);
        this.editedAgenda.attribs.hora = h + ':' + m;
      }

    }
  }
}
