<app-spinner *ngIf="isLoading"></app-spinner>
<div class="edit-cita-section">
  <form [formGroup]="datosPersonalesForm" (ngSubmit)="saveEmployee()" id="datosPersonalesForm">
    <div class="row mb-1">
      <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
        <div class="form-group">
          <label class="mb-0 font-weight-bold">Datos Personales :</label>
        </div>
      </div>
      <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 text-right">
        <button type="button" (click)="onOpenAddWorker()" [disabled]="editarButtonIsDissable"
          class="btn-custom btn-primary">
          Editar datos personales
        </button>

      </div>
    </div>
    <div class="edit-cita-tbl emp-edit">
      <ng-container *ngIf="datosPersonalesFormIsEditable; else viewBlock">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="nombre" class="mb-0">Nombre</label>
              <div>
                <input type="text" formControlName="nombre" class="form-control form-control-sm" id="nombre" />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="telefono" class="mb-0">Telf</label>
              <div>
                <input type="text" formControlName="tel1" class="form-control form-control-sm" id="telefono" [ngClass]="{
                    'is-valid': datosPersonalesFormControl.tel1.valid,
                    'is-invalid': datosPersonalesFormControl.tel1.invalid
                  }" />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="email" class="mb-0">Email</label>
              <div>
                <input type="email" formControlName="email1" class="form-control form-control-sm" id="email" [ngClass]="{
                    'is-valid': datosPersonalesFormControl.email1.valid,
                    'is-invalid': datosPersonalesFormControl.email1.invalid
                  }" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="apellidos" class="mb-0">Apellidos</label>
              <div>
                <input type="text" formControlName="apellidos" class="form-control form-control-sm" id="apellidos" />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="dni" class="mb-0">DNI</label>
                  <div>
                    <input type="text" formControlName="nip" class="form-control form-control-sm" id="dni" [ngClass]="{
                        'is-valid': datosPersonalesFormControl.nip.valid,
                        'is-invalid': datosPersonalesFormControl.nip.invalid
                      }" />
                    <div *ngIf="datosPersonalesFormControl.nip?.errors" class="invalid-feedback">
                      NIF ERRÓNEO
                    </div>
                    <div *ngIf="!datosPersonalesFormControl.nip?.errors" class="valid-feedback">
                      NIF OK
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="col-form-label font-weight-bold pr-0 pl-2">
                  <input class="form-check-input" type="checkbox" value="chkPassport" [checked]="chkPassportBox"
                    id="chkPassport" [(ngModel)]="chkPassport" (change)="onChangeChkPassport($event)"
                    [ngModelOptions]="{ standalone: true }" [disabled]="isDisabledPassport" />
                  <label for="chkPassport" style="font-weight: 800">
                    Es un pasaporte
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="birth_date" class="mb-0">Fecha De Nacimiento</label>
              <div>
                <input formControlName="fecha_nacimiento" id="birth_date" [owlDateTime]="birth_date"
                  [owlDateTimeTrigger]="birth_date" type="text" class="form-control form-control-sm date-field w-100"
                  [(ngModel)]="EditDate" [class.is-invalid]="
                    (datosPersonalesForm.controls.fecha_nacimiento.dirty ||
                      datosPersonalesForm.controls.fecha_nacimiento.touched ||
                      this.submitted) &&
                    datosPersonalesForm.controls.fecha_nacimiento.invalid
                  " />
                <owl-date-time #birth_date [pickerType]="'calendar'" [firstDayOfWeek]="1"></owl-date-time>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="gender" class="mb-0">Género</label>
              <div>
                <select formControlName="genero" id="gender" class="form-control form-control-sm" [ngClass]="{
                    'is-invalid':
                      (datosPersonalesForm.controls.genero.dirty ||
                        datosPersonalesForm.controls.genero.touched ||
                        this.submitted) &&
                      datosPersonalesForm.controls.genero.invalid
                  }">
                  <option value="H">Hombre</option>
                  <option value="M">Mujer</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="language_txt" class="mb-0">Idioma</label>
              <div>
                <ng-select #selectLanguageTypes (close)="selectLanguageTypes.blur()" loadingText="Cargando..."
                  notFoundText="No hay resultados" id="language_id" name="language_id" bindLabel="label"
                  bindValue="value" [items]="languageList" placeholder="Seleccione" formControlName="language_id">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.label }}">{{ item.label }}</div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="workcenter" class="mb-0">Centro de trabajo</label>
              <div>
                <ng-select #selectCentro [virtualScroll]="true" (close)="selectCentro.blur()" loadingText="Cargando..."
                  notFoundText="No hay resultados" id="id_centro_trabajo" name="id_centro_trabajo" bindLabel="label"
                  bindValue="value" [items]="workCenterList" placeholder="Seleccione"
                  formControlName="id_centro_trabajo">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.label }}">{{ item.label }}</div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="comments" class="mb-0">Notas</label>
              <div>
                <input type="text" formControlName="comentarios" class="form-control form-control-sm" id="comments" />
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #viewBlock>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="nombre" class="mb-0">Nombre</label>
              <div>
                <input type="text" formControlName="nombre" [readonly]="true"
                  class="form-control-plaintext form-control-sm h-auto p-0" id="nombre" />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="telefono" class="mb-0">Telf</label>
              <div>
                <input type="text" formControlName="tel1" [readonly]="true"
                  class="form-control-plaintext form-control-sm h-auto p-0" id="telefono" />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="email" class="mb-0">Email</label>
              <div>
                <input type="text" formControlName="email1" [readonly]="true"
                  class="form-control-plaintext form-control-sm h-auto p-0" id="email" />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="apellidos" class="mb-0">Apellidos</label>
              <div>
                <input type="text" formControlName="apellidos" [readonly]="true"
                  class="form-control-plaintext form-control-sm h-auto p-0" id="apellidos" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="dni" class="mb-0">DNI</label>
              <div>
                <input type="text" formControlName="nip" [readonly]="true"
                  class="form-control-plaintext form-control-sm h-auto p-0" id="dni" />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="birth_date" class="mb-0">Fecha De Nacimiento</label>
              <div>
                <input type="text" formControlName="fecha_nacimiento" [readonly]="true"
                  class="form-control-plaintext form-control-sm h-auto p-0" id="birth_date" />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="gender" class="mb-0">Género</label>
              <div>
                <select id="gender" formControlName="genero" class="form-control-sm h-auto p-0"
                  style="border: none; background: #fff; padding: 0px" [disabled]="true">
                  <option value="H">Hombre</option>
                  <option value="M">Mujer</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="language_txt" class="mb-0">Idioma</label>
              <div>
                <input type="text" value="{{ idioma_txt }}" [readonly]="true"
                  class="form-control-plaintext form-control-sm h-auto p-0" id="idioma_txt" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="workcenter" class="mb-0">Centro de trabajo</label>
              <div>
                <input type="text" formControlName="id_centro_trabajo_name" [readonly]="true"
                  class="form-control-plaintext form-control-sm h-auto p-0" id="workcenter" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="comments" class="mb-0">Notas</label>
              <div>
                <input type="text" formControlName="comentarios" [readonly]="true"
                  class="form-control-plaintext form-control-sm h-auto p-0" id="comments" />
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </form>
</div>