<div class="modal-body pt-0">
    <div class="row covid-report-list-popup-header pl-3">
        <div class="col-md-10 text-left pl-0">
            <h1 class="modal-title font-weight-bold">
                {{formTitle}}
            </h1>
        </div>
        <div class="col-md-2">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                (click)="closeCovidPrint($event)">
                <span aria-hidden="true">×</span>
            </button>
        </div>
    </div>
    <div class="row mt-1">
        <div class="form-group d-grid mb-0">
            <ul class="covid-report-list">
                <li (click)="justificantedeasistenciaReport()">
                    <span class="fa fa-print pr-2"></span>
                    Justificante de asistencia
                </li>
                <li (click)="recibodepagoReport()">
                    <span class="fa fa-print pr-2"></span>
                    Recibo de pago
                </li>
                <li (click)="tomaDeDATOSPruebasReport()">
                    <span class="fa fa-print pr-2"></span>
                    TOMA DE DATOS pruebas COVID-19
                </li>
                <li (click)="castellanoPruebasReport()">
                    <span class="fa fa-print pr-2"></span>
                    CASTELLANO - Consentimiento pruebas COVID-19
                </li>
                <li (click)="catalanConsPruebasReport()">
                    <span class="fa fa-print pr-2"></span>
                    CATALAN - Consentimiento pruebas COVID-19
                </li>
                <li (click)="inglesReport()">
                    <span class="fa fa-print pr-2"></span>
                    INGLÉS - Consentimiento pruebas COVID-19
                </li>
                <li (click)="francePredasReport()">
                    <span class="fa fa-print pr-2"></span>
                    FRANCÉS - Consentimiento pruebas COVID-19
                </li>
                <li (click)="castellanoMenoresPrebasReport()">
                    <span class="fa fa-print pr-2"></span>
                    CASTELLANO - Consentimiento MENORES pruebas COVID-19
                </li>
                <li (click)="catalanMenoresPruebasReport()">
                    <span class="fa fa-print pr-2"></span>
                    CATALAN - Consentimiento MENORES pruebas COVID-19
                </li>
                <li (click)="inglesMenoresPruebas()">
                    <span class="fa fa-print pr-2"></span>
                    INGLÉS - Consentimiento MENORES pruebas COVID-19
                </li>
            </ul>

        </div>
    </div>


    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 text-right pr-0">
        <button class="btn btn-secondary" (click)="closeCovidPrint($event)">Salir</button>
    </div>

</div>