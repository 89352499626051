import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SelectOptions } from 'src/app/model/shared/select-options.model';
import { CommonService } from 'src/app/service/common/common.service';
import { DropdownService } from 'src/app/service/shared/dropdown.service';
import {Constants} from '../../../../helpers/constant/constant'

@Component({
  selector: 'app-search-empresa-notall',
  templateUrl: './search-empresa-notall.component.html',
  styleUrls: ['./search-empresa-notall.component.scss']
})
export class SearchEmpresaNotallComponent implements OnInit {
  companyList: SelectOptions[] = [];
  isLoading=false;
  @Output() searchDataEmit: EventEmitter<any> = new EventEmitter();
  @Input() requestDataType:string="filter";
  @Input() validateCompany: boolean = false;

  companyRequestData = {
    page_number: 1,
    page_size: 10,
    incl: null,
    type: this.requestDataType,
    relatedId : null
  }
  private companySubject: Subject<string> = new Subject();
  companyId: number;
  constructor(private _visualizerCitarService: CommonService, private dropdownService: DropdownService) { }

  ngOnInit(): void {
    this.companyRequestData.type = this.requestDataType;

    this.getCompanyList();
    this.companySubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe(companyName =>  this.loadTopMatchingCompanies(companyName));
  }

  onCloseCompanies() { 
    this.getCompanyList();
    this.companyId = null;
  
    this.searchDataEmit.emit({
      companyId: this.companyId
    });
    this._visualizerCitarService.companyIdSubject.next(null);
  }

  onKeyUpCompany(companyNameTarget: any){
    this.companySubject.next(companyNameTarget?.target?.value);
  }

  onChangeCompany(company: any) {
    if (company != undefined) {
      this.searchDataEmit.emit({
        companyId: this.companyId,
        companyName: company.label
      });

      if (company) {
        this._visualizerCitarService.companyIdSubject.next(this.companyId);

      } else {
        this._visualizerCitarService.companyIdSubject.next(null);
      }
    }
  }

  onScrollCompany() {
    this.getCompanyList('', true);
  }



  getCompanyList(incl?: string, isScroll = false, companyId = 0) {
    this.isLoading = true;
    this.companyRequestData.incl = incl ? incl : null;
    this.companyRequestData.page_number = isScroll ? (this.companyRequestData.page_number + 1) : 1;
    this.companyRequestData.relatedId = companyId > 0 ? Number(companyId)  : null;
    this._visualizerCitarService.getCompanyList(this.companyRequestData).subscribe(res => {
      this.isLoading = false;

      if (res) {
        if (isScroll) {
          this.companyList = this.companyList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id'));
        } else {
          this.companyList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');
          this.companyList.shift();           
        } 
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }



  loadTopMatchingCompanies(companyName) {
    //Trigger the loading only if string length is >= 4
    if (companyName.length >= 4) { 
      this.getCompanyList(companyName);
    } 
    else if (companyName.length == 0) {
        this.getCompanyList();
    }
  }

}
