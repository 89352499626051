<app-spinner *ngIf="isLoading"></app-spinner>
<div class="agendas-breadcrumb">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" aria-current="page">visualizar citas</li>
      <li class="breadcrumb-item active" aria-current="page">
        Editando cita de {{ this.appointmentEditData.persona.apellidos }},
        {{ this.appointmentEditData.persona.nombre }}
      </li>
    </ol>
  </nav>
</div>
<div id="scrollbar" class="agendas-content pt-2">
  <div class="row visual-h-27">
    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
      <div class="form-group">
        <label class="mb-0 mr-1 font-weight-bold">Empresa : </label>
        <label class="mb-0 text-uppercase">{{
          this.appointmentEditData.attribs.empresa_txt
          }}</label>
      </div>
    </div>
    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 text-right">
      <div class="form-group">
        <label class="mb-0 mr-1 font-weight-bold">Programador :</label>
        <label class="mb-0 text-uppercase">{{
          this.appointmentEditData.attribs.programador_txt
          }}</label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-5 col-xs-5 col-md-5 col-sm-12">
      <div class="form-group">
        <label class="mb-0">Comercial</label>
        <ng-select [clearAllText]="'limpiar todo'" #selectCommercial (close)="selectCommercial.blur()"
          loadingText="Cargando..." notFoundText="No hay resultados" id="comercial_id" name="comercial_id"
          bindLabel="label" bindValue="value" [items]="commercialList" placeholder="Seleccione"
          [(ngModel)]="appointmentEditData.attribs.comercial_id">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.label }}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="col-lg-7 col-xs-7 col-md-7 col-sm-12">
      <div class="form-group">
        <label class="mb-0">Comentarios</label>
        <textarea class="form-control" [(ngModel)]="appointmentEditData.attribs.comentarios" rows="1"
          style="min-height: 50px" maxlength="7000"></textarea>
      </div>
    </div>
  </div>
  <div *ngIf="appointmentLoaded | async">
    <app-employee-edit [personId]="personId" [inputCompanyId]="
    this.appointmentEditData.attribs.empresa_id" [tel1]="this.appointmentEditData.persona.telefono"
      [id_centro_trabajo]="this.appointmentEditData.attribs.empresa_sede_id"></app-employee-edit>
  </div>
  <div class="row mt-3 mb-3 d-flex align-items-center">
    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
      <div class="form-group">
        <label class="mb-0 mr-1 font-weight-bold">Tipo Reserva : </label>
        <label class="mb-0 text-uppercase">
          {{ this.appointmentEditData.attribs.tipo_txt }}</label>
      </div>
    </div>
    <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
      <div class="form-inline float-right">
        <label class="mb-0 font-weight-bold">Código Barras :</label>
        <input type="text" class="form-control ml-1" [(ngModel)]="appointmentEditData.attribs.codigo_barras"
          maxlength="29" />
      </div>
    </div>
  </div>

  <!-- Reconocimiento section -->
  <div class="row my-1" *ngIf="selectedType == reservationTypeEnum.Reconocimiento">
    <div class="col-lg-4 col-xl-4 col-md-6 col-sm-12">
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 pl-0">
        <div class="form-group">
          <label class="mb-0">Tipo Reco</label>
          <ng-select [clearAllText]="'limpiar todo'" #selectSubTypes (close)="selectSubTypes.blur()"
            loadingText="Cargando..." notFoundText="No hay resultados" id="activity-type" name="activity-type"
            bindLabel="label" bindValue="value" [items]="actionSubTypesList" placeholder="Seleccione"
            [(ngModel)]="appointmentEditData.attribs.subtipo_id">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 pl-0">
        <div class="form-group">
          <label class="mb-0">Puesto VS</label>
          <ng-select [clearAllText]="'limpiar todo'" #selectPuestoVS (close)="selectPuestoVS.blur()"
            loadingText="Cargando..." notFoundText="No hay resultados" id="puesto" name="puesto" bindLabel="label"
            bindValue="value" [items]="puestosVSList" placeholder="Seleccione"
            [(ngModel)]="appointmentEditData.attribs.puestovs_id">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="col-lg-8 col-xl-8 col-md-6 col-sm-12">
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 pr-0">
        <div class="form-group">
          <label class="mb-0">Descripción puesto</label>
          <textarea class="form-control" [(ngModel)]="this.appointmentEditData.attribs.puestovs_comments"
            maxlength="7000"></textarea>
        </div>
      </div>
    </div>
  </div>
  <!--/ Reconocimiento section -->

  <!-- Prueba COVID section -->
  <div class="row my-3" *ngIf="selectedType == reservationTypeEnum.PruebaCOVID">
    <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12">
      <div class="form-group">
        <label class="mb-0">Tipo Prueba</label>
        <ng-select [clearAllText]="'limpiar todo'" #selectSubTypes (close)="selectSubTypes.blur()"
          loadingText="Cargando..." notFoundText="No hay resultados" id="activity-type2" name="activity-type2"
          bindLabel="label" bindValue="value" [items]="actionSubTypesList" placeholder="Seleccione"
          [(ngModel)]="appointmentEditData.attribs.subtipo_id">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.label }}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12">
      <div class="form-group">
        <label class="mb-0">Solicitante</label>
        <input type="text" class="form-control ml-1" [(ngModel)]="appointmentEditData.attribs.solicitante" />
      </div>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12">
      <div class="form-group">
        <label class="mb-0">Provincia</label>
        <ng-select [clearAllText]="'limpiar todo'" #selectProvincia (close)="selectProvincia.blur()"
          loadingText="Cargando..." notFoundText="No hay resultados" id="province" name="province" bindLabel="label"
          bindValue="value" [items]="provinciaList" placeholder="Seleccione" (change)="onProvinceChange($event)"
          (keyup)="onKeyUpProvince($event)" (scrollToEnd)="onScrollProvinces()"
          [(ngModel)]="appointmentEditData.attribs.provincia_id">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.label }}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12">
      <div class="form-group">
        <label class="mb-0">Tipo de Pago</label>
        <ng-select [clearAllText]="'limpiar todo'" #selectTipoDePago (close)="selectTipoDePago.blur()"
          loadingText="Cargando..." notFoundText="No hay resultados" id="activity-type" name="activity-type"
          bindLabel="label" bindValue="value" [items]="paymentTypesList" placeholder="Seleccione"
          [(ngModel)]="this.appointmentEditData.attribs.tipo_pago_id" (change)="onChange($event)" [class.ng-invalid]="
            this.appointmentEditData.attribs.tipo_pago_id == null ||
            this.appointmentEditData.attribs.tipo_pago_id == 0
          ">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.label }}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row my-3" *ngIf="selectedType == reservationTypeEnum.PruebaCOVID">
    <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12">
      <div class="form-group">
        <label class="mb-0">Población</label>
        <ng-select [clearAllText]="'limpiar todo'" #selectTown (close)="selectTown.blur()" loadingText="Cargando..."
          notFoundText="No hay resultados" id="poblacion" name="poblacion" bindLabel="label" bindValue="value"
          [items]="townsList" placeholder="Seleccione" [(ngModel)]="appointmentEditData.attribs.poblacion_id"
          (keyup)="onKeyUpTown($event)" (change)="onChange($event)" (scrollToEnd)="onScrollTowns()">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.label }}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12">
      <div class="form-group">
        <label class="mb-0">Tarjeta Sanitaria</label>
        <input type="text" maxlength="25" class="form-control"
          [(ngModel)]="appointmentEditData.attribs.tarjeta_sanitaria" />
      </div>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12">
      <div class="form-group">
        <label class="form-check-label"> Pagado</label> <br />
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="pagado_sino" id="rdoPagado" [value]="1"
            [(ngModel)]="pagado_sino" (change)="onPagadoChange($event)" [checked]="pagado_sino == 1" />
          <label class="form-check-label" for="rdoPagado"> Si </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" [value]="0" name="pagado_sino" id="rdoPagadoNo"
            [(ngModel)]="pagado_sino" (change)="onPagadoChange($event)" [checked]="pagado_sino == 0" />
          <label class="form-check-label" for="rdoPagadoNo"> No </label>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12">
      <div class="form-group">
        <label class="mb-0">Importe</label>
        <input type="number" class="form-control ml-1" [(ngModel)]="appointmentEditData.attribs.pagado_importe" />
      </div>
    </div>
  </div>
  <!--/ Prueba COVID section -->

  <!-- citas date time change sections -->
  <div class="row agenda-change-div mb-3">
    <div class="col-lg-10 col-xl-10 col-md-12 col-sm-12">
      <div class="row">
        <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12"></div>
        <div class="col-lg-9 col-xl-9 col-md-12 col-sm-12 d-flex">
          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="mb-0 mr-1 font-weight-bold">Agenda : </label>
              <label class="mb-0 text-uppercase">{{
                this.appointmentEditData.attribs.agenda_txt
                }}</label>
            </div>
          </div>

          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="mb-0 mr-1 font-weight-bold">Centro médico : </label>
              <label class="mb-0 text-uppercase">{{
                this.appointmentEditData.attribs.centro_txt
                }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12"></div>
        <div class="col-lg-9 col-xl-9 col-md-12 col-sm-12">
          <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
            <label class="mb-0 citas-date">
              Fecha / hora :
              <span>{{ this.appointmentEditData.attribs.fecha }}</span>
              a las
              <span>{{ this.appointmentEditData.attribs.hora }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-xl-2 col-md-12 col-sm-12 text-right">
      <button class="btn v-edit-btn" id="btnChange" type="submit" (click)="openRescheduleAppointmentPopup('btnChange')">
        Cambiar
      </button>
    </div>
  </div>
  <!--/ citas date time change sections -->

  <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 justify-content-end mt-2 pr-0 d-flex">
    <button id="btnAddDocsValimed" class="btn v-edit-btn mr-3" type="submit"
      (click)="onOpenAddDocument('btnAddDocsValimed')">
      Añadir Documentos
    </button>
    <button id="btnPrint" class="btn v-edit-btn mr-3" (click)="onPrint('btnPrint')">
      Imprimir
    </button>

    <div class="d-flex">
      <button id="btnFirmaGeseme" class="btn btn-primary mr-1" (click)="onPrintSignature('btnFirmaCas')">
        Firma
      </button>
      <button id="firmaGeseme" (click)="onClickFirmaGesemeArrow()" type="button" class="btn btn-primary">
        <span class="fa fa-arrow-down"></span>
      </button>
    </div>
    <div *ngIf="firmaTypesButtonsGeseme" class="d-flex">
      <button type="button" class="btn btn-primary ml-3" (click)="onPrintSignature('btnFirmaCat')">Catalán</button>
      <button type="button" class="btn btn-primary ml-1" (click)="onPrintSignature('btnFirmaEn')">Inglés</button>
      <button type="button" class="btn btn-primary ml-1" (click)="onPrintSignature('btnFirmaFr')">Francés</button>
    </div>

  </div>
  <div class="row my-2">
    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 text-center">
      <span class="estado-status-span">
        <label class="mb-0 mr-1">Estado : </label>
        <label class="mb-0">{{
          this.appointmentEditData.attribs.estado_txt
          }}</label>
      </span>
    </div>
    <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12"></div>
  </div>
  <!-- status buttons section -->
  <div class="status-btns-div">
    <div class="row">
      <div class="col-lg-1 col-xl-1 col-md-6 col-sm-12"></div>
      <div class="col-lg-2 col-xl-2 col-md-6 col-sm-12"></div>
      <div class="col-lg-2 col-xl-2 col-md-6 col-sm-12">
        <button class="btn v-edit-btn" type="submit" id="btnIniciar" (click)="this.updateAppointment('btnIniciar')">
          Inciar Prueba
        </button>
      </div>
      <div class="col-lg-2 col-xl-2 col-md-6 col-sm-12 d-flex">
        <button class="btn v-edit-btn mr-1" type="submit" id="btnCancelGeseme" (click)="CancelGeseme()">
          Cancela Geseme
        </button>

        <button id="cancelGeseme" (click)="onClickCancelGesemeArrow()" type="button" class="btn btn">
          <span class="fa fa-arrow-down"></span>
        </button>

      </div>

      <button (click)="onClickCancelNew()" *ngIf="cancelNewButtonGeseme" type="button" class="btn btn-primary">CANCELAR
        Y ABRIR NUEVA</button>

      <div class="col-lg-2 col-xl-2 col-md-6 col-sm-12">
        <button class="btn v-edit-btn" type="submit" id="btnNotPresented"
          (click)="this.updateAppointment('btnNotPresented')">
          No Presentado
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-2 col-xl-2 col-md-6 col-sm-12">
        <button class="btn v-edit-btn" type="submit" id="btnCancel" (click)="this.updateAppointment('btnCancel')">
          Salir Sin Guardar
        </button>
      </div>
      <div class="col-lg-2 col-xl-2 col-md-6 col-sm-12">
        <button class="btn v-edit-btn" type="submit" id="btnToGo" (click)="onOpenCovidScreen()">
          Ir a Reco
        </button>
      </div>
      <div class="col-lg-2 col-xl-2 col-md-6 col-sm-12 d-flex">
        <button class="btn v-edit-btn mr-1" type="submit" id="btnCancelCompany" (click)="CancelCompany()">
          cancela empresa
        </button>

        <button id="cancelEmpresa" (click)="onClickCancelEmpresaArrow()" type="button" class="btn btn">
          <span class="fa fa-arrow-down"></span>
        </button>
      </div>

      <button (click)="onClickCancelNew()" *ngIf="cancelNewButtonEmpresa" type="button" class="btn btn-primary">CANCELAR
        Y ABRIR NUEVA</button>

      <div class="col-lg-2 col-xl-2 col-md-6 col-sm-12 text-right">
        <button class="btn v-edit-btn" type="submit" id="btnSave" (click)="this.updateAppointment('btnSave')">
          Guardar
        </button>
      </div>
    </div>
  </div>

  <!--/ status buttons section -->
</div>

<app-visualizer-citars-print-document [hidden]="true" [appointmentData]="appointmentEditData"
  [covidAppoimnet]="appoimentDetail" [Sedes]="sedesName"
  [personTelNo]="personTelNo"></app-visualizer-citars-print-document>