export class AgendaPersona {
    //persona
    persona_id: number;
    apellidos: string;
    nombre: string;
    dni: string;
    birth_date: string;
    telefono: string;
    email: string;
    gender: string;
    comments: string;
    language_txt: string;

}