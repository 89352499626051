export class AgendaAttribute {
    id: string;
    accion_tipo_id: string;
    id_reco: string;
    fecha: string;
    hora: string;
    empresa_id: number;
    empresa_txt: string;
    agenda_txt: string;
    tipo_txt: string;
    subtipo_id: string | number;
    subtipo_txt: string;
    programador_txt: string;
    comercial_txt: string;
    codigo_barras: string;
    solicitante: string;
    puestovs_id: string;
    puestovs_txt: string;
    puestovs_comments: string;
    estado_txt: string;
    comercial_id: number;
    centro_txt: string;
    pagado_sino: boolean;
    centro_id: number;
    observaciones: string
}  