<app-spinner *ngIf="isLoading"></app-spinner>
<div class="modal-body">
  <form [formGroup]="personaForm" (ngSubmit)="saveEmployee()" id="datosPersonalesForm">
    <div class="row pl-3 pb-3">
      <div class="col-md-10 text-left pl-0">
        <h1 id="activity-modal" class="modal-title popup-title-f-s font-weight-bold">
          Datos Personales
        </h1>
      </div>
      <div class="col-md-2">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.close()">
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Empresa</label>
          <ng-select [clearAllText]="'limpiar todo'" #select [virtualScroll]="true" (close)="select.blur()"
            (clear)="onClearCompany()" loadingText="Cargando..." notFoundText="No hay resultados" id="activity-type"
            name="activity-type" bindLabel="label" bindValue="value" [items]="companyList" placeholder="Seleccione"
            formControlName="empresa_id" (keyup)="onKeyUpCompany($event)" (change)="onChangeCompany($event)"
            (scrollToEnd)="onScrollCompany()" [readonly]="isCompanyDissable" [class.ng-invalid]="
            
              (personaForm.controls.empresa_id.dirty ||
                personaForm.controls.empresa_id.touched ||
                this.submitted) &&
              personaFormControl.empresa_id.invalid
            ">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Centro de trabajo</label>
          <ng-select [clearAllText]="'limpiar todo'" #select [virtualScroll]="true" (close)="select.blur()"
            loadingText="Cargando..." notFoundText="No hay resultados" id="activity-type" name="activity-type"
            bindLabel="label" bindValue="value" [items]="workcenterList" placeholder="Seleccione"
            formControlName="id_centro_trabajo" [class.ng-invalid]="
              (personaForm.controls.id_centro_trabajo.dirty ||
                personaForm.controls.id_centro_trabajo.touched ||
                this.submitted) &&
              personaFormControl.id_centro_trabajo.invalid
            " (scrollToEnd)="onScrollCentro()">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-0">Nombre</label>
          <input type="text" class="form-control" formControlName="nombre" [ngClass]="{
            'is-valid': personaForm.controls.nombre.valid,
            'is-invalid':
              (personaForm.controls.nombre.dirty ||
                personaForm.controls.nombre.touched ||
                this.submitted) &&
              personaForm.controls.nombre.invalid
          }" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-0">Apellidos</label>
          <input type="text" class="form-control" formControlName="apellidos" [ngClass]="{
            'is-valid': personaForm.controls.apellidos.valid,
            'is-invalid':
              (personaForm.controls.apellidos.dirty ||
                personaForm.controls.apellidos.touched ||
                this.submitted) &&
              personaForm.controls.apellidos.invalid
          }" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label class="mb-0">NIF</label>
          <input type="text" formControlName="nip" (keypress)="omitSpecialChar($event)" (keydown.space)="$event.preventDefault()" class="form-control form-control-sm" id="dni" [ngClass]="{
              'is-valid': personaFormControl.nip.valid,
              'is-invalid':
                (personaForm.controls.nip.dirty ||
                  personaForm.controls.nip.touched ||
                  this.submitted) &&
                personaFormControl.nip.invalid
            }" />
          <div *ngIf="personaFormControl.nip?.errors" class="invalid-feedback">
            NIF ERRÓNEO
          </div>

          <div *ngIf="!personaFormControl.nip?.errors" class="valid-feedback">
            NIF OK
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-check" style="padding-top: 21px">
          <input class="form-check-input" type="checkbox" value="chkPassport" [checked]="chkPassportBox"
            id="chkPassport" [(ngModel)]="chkPassport" (change)="onChangeChkPassport($event)"
            [ngModelOptions]="{ standalone: true }" [disabled]="isDisabledPassport" />
          <label for="chkPassport" style="font-weight: 800">
            Es un pasaporte
          </label>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="mb-0">Fecha Nacimiento</label>
          <input [owlDateTime]="birth_date" [owlDateTimeTrigger]="birth_date" type="text"
            class="form-control form-control-sm date-field w-100" formControlName="fecha_nacimiento" [class.is-invalid]="
              (personaForm.controls.fecha_nacimiento.dirty ||
                personaForm.controls.fecha_nacimiento.touched ||
                this.submitted) &&
              personaForm.controls.fecha_nacimiento.invalid
            " />
          <owl-date-time #birth_date [firstDayOfWeek]="1" [pickerType]="'calendar'"></owl-date-time>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="mb-0">Género</label>
          <ng-select [clearAllText]="'limpiar todo'" #select (close)="select.blur()" loadingText="Cargando..."
            notFoundText="No hay resultados" bindLabel="name" formControlName="genero" bindValue="gid"
            [items]="genderList" placeholder="Seleccione" [class.ng-invalid]="
                (personaForm.controls.genero.dirty ||
                  personaForm.controls.genero.touched ||
                  this.submitted) &&
                personaForm.controls.genero.invalid
            ">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label class="mb-0">Idioma</label>
          <ng-select [clearAllText]="'limpiar todo'" #selectLanguageTypes (close)="selectLanguageTypes.blur()"
            loadingText="Cargando..." notFoundText="No hay resultados" id="language_id" name="language_id"
            bindLabel="label" bindValue="value" [items]="languageList" placeholder="Seleccione"
            formControlName="language_id" (change)="langChange($event)" [class.ng-invalid]="
            
              (personaForm.controls.language_id.dirty ||
                personaForm.controls.language_id.touched ||
                this.submitted) &&
                !this.isLangSelected
            ">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label class="mb-0">Telf</label>
          <input type="text" class="form-control" formControlName="tel1" [ngClass]="{
              'is-valid':
                (personaForm.controls.tel1.dirty ||
                  personaForm.controls.tel1.touched ||
                  this.submitted) &&
                personaForm.controls.tel1.valid,
              'is-invalid':
                (personaForm.controls.tel1.dirty ||
                  personaForm.controls.tel1.touched ||
                  this.submitted) &&
                personaForm.controls.tel1.invalid
            }" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="mb-0">E-mail</label>
          <input type="email" class="form-control" formControlName="email1" [ngClass]="{
              'is-valid': personaForm.controls.email1.valid,
              'is-invalid':
                (personaForm.controls.email1.dirty ||
                  personaForm.controls.email1.touched ||
                  this.submitted) &&
                personaForm.controls.email1.invalid
            }" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label class="mb-0">Notas</label>
          <textarea class="form-control" formControlName="comentarios"></textarea>
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 text-right mt-2">
      <button class="btn btn-secondary mr-3" type="button" (click)="modal.dismiss()">
        Cancelar
      </button>
      <button class="btn btn-primary" type="submit">Guardar</button>
    </div>
  </form>
</div>