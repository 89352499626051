import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {AgendaList} from '../../../model/agendar/agenda-list';
import {AgendaPost} from '../../../model/agendar/agenda-post';
import {AgendaDisplay}from '../../../model/agendar/agenda-display';

const behaviorSubjectAgendaSechdule = new BehaviorSubject<AgendaList>(
    new AgendaList()
  );
const behaviorSubjectEditException = new BehaviorSubject<AgendaPost>(
  new AgendaPost()
)

const behaviorSubjectAgendaDisplay = new BehaviorSubject<AgendaDisplay>(
    new AgendaDisplay()
  )

  const behaviorSubjectNewAgendaSechdule = new BehaviorSubject<AgendaList>(
    new AgendaList()
  );

  const behaviorSubjectCentroText = new BehaviorSubject<string>(
   ""
  );

  const behaviorSubjectAgendaExists = new BehaviorSubject<AgendaList>(
    new AgendaList()
  );
  
  const behaviorSubjectAgendaExistsRev = new BehaviorSubject<AgendaList>(
    new AgendaList()
  );

@Injectable()
export class AgendaService{
      
    private agendaShecdule = behaviorSubjectAgendaSechdule;
    private exceptionList = behaviorSubjectEditException;
    private agendaDisplay = behaviorSubjectAgendaDisplay;
    private newAgenda = behaviorSubjectNewAgendaSechdule;
    private centroTextSub= behaviorSubjectCentroText;
    private existsData = behaviorSubjectAgendaExists;
    private existsDataRev = behaviorSubjectAgendaExists;
    public agendaSheduleDetaisCalled = this.agendaShecdule.asObservable();
    public agendaExceptions = this.exceptionList.asObservable();
    public agendaDisplayDetail = this.agendaDisplay.asObservable()
    public agendaNewItem = this.newAgenda.asObservable();

    public centroTextSubcription = this.centroTextSub.asObservable();
    public existsDataSubcrib = this.existsData.asObservable();

    public existsDataRevSubcrib = this.existsDataRev.asObservable();

    public isUpdatedException :boolean =false;
    setCentroText(str :string){
      this.centroTextSub.next(str);
    }
  setNewAgendaDetail(agenda: AgendaList){
    this.newAgenda.next(agenda);
  }
  UpdateAgendaSheduleDetail(agenda: AgendaList) {
    this.agendaShecdule.next(agenda);
  }

  updateAgendaException(exception:AgendaPost){
      this.exceptionList.next(exception);
      this.isUpdatedException=true;
  }

  removeAgendaException(){
    this.exceptionList.next(null);
  }

  updateExceptionStatus(status:boolean)
  {
    this.isUpdatedException=status;
  }

  getExceptionStatus()
  {
    return this.isUpdatedException;
  }

  updateAgendaDisplay(display:AgendaDisplay){
    this.agendaDisplay.next(display);  
  }

updateExistsData(exists :AgendaList){
  this.existsData.next(exists);
  }

  updateExistsRevData(exists :AgendaList){
    this.existsDataRev.next(exists);
    }

}