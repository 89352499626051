import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from '../shared/api.service';
import API from '../../helpers/api.routes.json';
import { RequestData } from '../../model/shared/request-data';
import { AgentsRequestData } from '../../model/visualizer-citars/agents-request-data'
import { AgendaRequestData } from 'src/app/model/agendar/agenda-request-data';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  companyIdSubject = new Subject();

  constructor(private apiService: ApiService) { }

  public getAgendaList(requestData: any): Observable<any> {
    const url = `${environment.apiRoot}${API.common.getAgendaList}`;
    return this.apiService.post(url, requestData);
  }

  public getCompanyList(requestData: any): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getCompanyList}`;
    return this.apiService.post(url, requestData);
  }

  public getPersonsList(requestData: RequestData): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getPersonsList}`;
    return this.apiService.post(url, requestData);
  }

  public getCenterList(requestData: RequestData): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getCenterList}`;
    return this.apiService.post(url, requestData);
  }

  public getActionSubTypesList(requestData: RequestData): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getActionSubTypesList}`;
    return this.apiService.post(url, requestData);
  }

  public getTownsList(requestData: RequestData): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getTownsList}`;
    return this.apiService.post(url, requestData);
  }

  public getProvincesList(requestData: any): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getProvincesList}`;
    return this.apiService.post(url, requestData);
  }

  public getPaymentTypesList(requestData: RequestData): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getPaymentTypesList}`;
    return this.apiService.post(url, requestData);
  }

  public getStatesAppointmentList(requestData: RequestData): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getStatesAppointmentList}`;
    return this.apiService.post(url, requestData);
  }

  public getPuestosVSList(requestData: any): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getPuestosVSList}`;
    return this.apiService.post(url, requestData);
  }

  public getAgentsList(requestData: AgentsRequestData): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getAgentsList}`;
    return this.apiService.post(url, requestData);
  }

  public getAppointmentsList(agendaRequestData: AgendaRequestData): Observable<any> {
    const url = `${environment.apiRoot}${API.common.getAppointmentsList}`;
    return this.apiService.post(url, agendaRequestData);
  }

  public getActionTypes(requestData: RequestData): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getActionTypes}`;
    return this.apiService.post(url, requestData);
  }

  public getStatesActionTypes(requestData: RequestData): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getStatesActionTypes}`;
    return this.apiService.post(url, requestData);
  }

  public getAppointments(requestData: any): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getAppointments}`;
    return this.apiService.post(url, requestData);
  }
 
  public postAppointment(requestData: any): Observable<any> {
    let url = `${environment.apiRoot}${API.common.postAppointment}`; 
    return this.apiService.post(url, requestData);
  }
  public getAgendaTimeSlots(requestData: any): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getAgendaTimeSlots}`; 
    return this.apiService.post(url, requestData);
  }

  public getAgendaTimeSlotDetails(requestData: any): Observable<any> {
    let url = `${environment.apiRoot}${API.common.getAgendaTimeSlotDetails}`; 
    return this.apiService.post(url, requestData);
  }
  public getWorkCenters(requestData: any):Observable<any>{
    let url = `${environment.apiRoot}${API.common.getWorkCenters}`; 
    return this.apiService.post(url, requestData);
  }

  public getBookingType(requestData: any):Observable<any> {
    let url = `${environment.apiRoot}${API.common.getBookTypes}`; 
    return this.apiService.post(url, requestData);
  }

  public getStatType(requestData: any):Observable<any> {
    let url = `${environment.apiRoot}${API.common.getStatTypes}`; 
    return this.apiService.post(url, requestData);
  }
  public getPartnerGroup(requestData: any):Observable<any> {
    let url = `${environment.apiRoot}${API.common.getPartnerGroup}`; 
    return this.apiService.post(url, requestData);
  }
  
  public getQuarters(requestData: any):Observable<any> {
    let url = `${environment.apiRoot}${API.common.getQuarters}`; 
    return this.apiService.post(url, requestData);
  }
  
  public getAgendaData(requestData: any):Observable<any> {
    let url = `${environment.apiRoot}${API.listado_de_agenda.agendaData}`; 
    return this.apiService.post(url, requestData);
  }

  public postAgenda(requestData: any): Observable<any> {
    let url = `${environment.apiRoot}${API.listado_de_agenda.agendaUpadate}`; 
    return this.apiService.post(url, requestData);
  }
 
  public getWorkCenterList(requestData: any): Observable<any> {
    const url = `${environment.apiRoot}${API.common.getWorkCenterList}`;
    return this.apiService.post(url, requestData);
  }

  public getCompanyDetails(requestData: any): Observable<any> {
    const url = `${environment.apiRoot}${API.erp.getCompanyDetails}`;
    return this.apiService.post(url, requestData);
  }

  public updatePerson(requestData: any): Observable<any> {
    const url = `${environment.apiRoot}${API.erp.updatePerson}`;
    return this.apiService.post(url, requestData);
  }
  
  public getPerson(requestData: any): Observable<any> {
    const url = `${environment.apiRoot}${API.erp.getPerson}`;
    return this.apiService.post(url, requestData);
  }

  public getLanguages(requestData: any): Observable<any> {
    const url = `${environment.apiRoot}${API.common.getLanguages}`;
    return this.apiService.post(url, requestData);
  }
  public getProviders(requestData: any): Observable<any> {
    const url = `${environment.apiRoot}${API.common.getProviders}`;
    return this.apiService.post(url, requestData);
  }
  public getDiagnostics(requestData: any): Observable<any> {
    const url = `${environment.apiRoot}${API.common.getDiagnostics}`;
    return this.apiService.post(url, requestData);
  }

  public getContacts(requestData: any): Observable<any> {
    const url = `${environment.apiRoot}${API.contacts.getContacts}`;
    return this.apiService.post(url, requestData);
  }

  public updateContact(requestData: any): Observable<any> {
    const url = `${environment.apiRoot}${API.contacts.updateContact}`;
    return this.apiService.post(url, requestData);
  }

  public getDocumentList(requestData: any): Observable<any> {
    const url = `${environment.apiRoot}${API.reception.getDocumentList}`;
    return this.apiService.post(url, requestData);
  }

  public generateReport(requestData: any): Observable<any> {
    const url = `${environment.apiRoot}${API.reception.generateReport}`;
    return this.apiService.post(url, requestData);
  }

  public downloadDocument(requestData: any): Observable<any> {
    const url = `${environment.apiRoot}${API.reception.downloadDocument}`;
    return this.apiService.post(url, requestData);
  }

  public generateMemoriavsReport(requestData: any): Observable<any> {
    const url = `${environment.apiRoot}${API.memoriavs.generateReport}`;
    return this.apiService.post(url, requestData);
  }
}
