import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../service/authentication/auth-service.component';

@Component({
    selector: 'app-signin-callback',
    templateUrl: './signin-redirect-callback.component.html'
})
export class SigninRedirectCallbackComponent implements OnInit {

    constructor(
        private _authService: AuthService,
        private _router: Router) {
    }

    ngOnInit() {
        this._authService.completeLogin().then((user) => {
            if (user != null) {
                const loggedUser = {
                    gesemeApiToken: user.access_token,
                    permissionScope: user.scope,
                    accessLevel: ''
                };
                localStorage.setItem('loggeduser', JSON.stringify(loggedUser));
                this._router.navigate(['/visualizer/view'], { replaceUrl: true }).then();
            }
            else {
                this._router.navigate(['/signin'], { replaceUrl: true }).then();
            }
        }).catch(error => {
            console.error(error);
            this._router.navigate(['/signin'], { replaceUrl: true }).then();
        });
    }
}
