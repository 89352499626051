import { Component } from '@angular/core';
import { Authentication } from 'src/app/model/authentication/authentication';
import { SimpleClaim } from 'src/app/model/authentication/simple-claims';
import { UserContext } from 'src/app/model/authentication/user-context';
import { AuthService } from 'src/app/service/authentication/auth-service.component';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent {

  isLoggedIn = false;
  currentUserContext: UserContext = null;

  constructor(private _authService: AuthService) { }

  public login(): boolean {
    this._authService.login();
    return false;
  }
  public isAdmin(): boolean {
    return this.currentUserContext.auth && this.currentUserContext.auth.isAdmin;
  }

  public userNameInfo(): string {
    let textualInformation: string = '';
    if (!!this.currentUserContext && !!this.currentUserContext.auth) {
      let theClaim: SimpleClaim;
      theClaim = this.currentUserContext.auth.claims.find(x => x.type == 'sub');
      if (!!theClaim)
        textualInformation = theClaim.value.toString();
    }
    return textualInformation
  }


  public levelAccessInfo(): string {

    let textualInformation: string = '';

    if (!!this.currentUserContext && !!this.currentUserContext.auth) {
      textualInformation = this.currentUserContext.auth.userPermissions[0].accessLevelId.toString();
      const id = this._authService.getAuthPermissions().accessLevelId;
      switch (id) {
        case Authentication.AUTH_NO_ACCESS: {
          textualInformation = 'all apps->no access';
          break;
        }
        case Authentication.AUTH_ADMIN: {
          textualInformation = 'all apps->admin access';
          break;
        }
        default: {
          break;
        }
      }
    }
    return textualInformation;
  }
}
