// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	// COMMENT OUT THE 'clientRoot' VARIABLE AS PER THE APPLICATION ENVIRONMENT
	//clientRoot: 'https://uat.valimed.qualitapps.com/',
	//clientRoot: 'https://qa.valimed.qualitapps.com/',
	//clientRoot: 'https://localhost:4200/',
	clientRoot: 'https://agenda.geseme.com/', // Live


	apiRoot: 'https://erpapipre.geseme.com', //'https://erppre.geseme.com/api-integrax',
	stsAuthority: 'https://stspre.geseme.com/.well-known/openid-configuration',//'https://it.geseme.com/' //'https://stspre.geseme.com/.well-known/openid-configuration'
	openCovid: 'https://erppre.geseme.com/vigilancia/edit/',
	
	//powerbiApi:'http://localhost:18731/api',
	//powerbiApi:'https://qa.token.valimed.qualitapps.com/api',
	powerbiApi:'https://powerbiapi.geseme.com/api', //Live
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
