export class Appointments {
    rowNum: number;
    id: number;
    accion_tipo_id: number;
    agenda: string;
    fecha: string;
    hora: string;
    apellidos_nombre_persona: string;
    nip: string;
    empresa: string;
    accion_tipo: string;
    accion_subtipo: string;
    estado_alias: string;
    imprimir: boolean;
    editar: boolean;
    fechaView: Date;
    horaView: string;
}
