<div class="agendas-breadcrumb">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" aria-current="page">Listado de agendas</li>
      <li class="breadcrumb-item" aria-current="page">Editar Agenda</li>
    </ol>
  </nav>
</div>
<div id="scrollbar" class="agendas-content">
  <div class="row">
    <div class="col-lg-8 col-xl-8 col-md-12 col-sm-12 agendaConfig-tab-btns">
      <h2 class="fc-toolbar-title text-center">{{ getAgendaName() }}</h2>

      <button class="btn btn-primary" [ngClass]="isTabOne ? 'active': '' " (click)="changeTab(1)">
        Vista Genérica
      </button>
      <button class="btn btn-primary ml-2" [ngClass]="!isTabOne ? 'active': '' " (click)="changeTab(2)">
        Vista Específica
      </button>

      <div class="agendaConfig-tab-content p-3">
        <div *ngIf="isTabOne">
          <full-calendar #calendar deepChangeDetection="true" [options]="calendarOptions"></full-calendar>
        </div>
        <div id="specific" *ngIf="!isTabOne">
          <full-calendar #calendarSpecific deepChangeDetection="true" [options]="calendarOptionsSpecific">
          </full-calendar>
        </div>
      </div>

    </div>
    <div class="col-lg-4 col-xl-4 col-md-12 col-sm-12">
      <div class="row shedule-excepciones">
        <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12 p-t-6">
          Excepciones
        </div>
        <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12 text-right">
          <button class="btn btn-primary" type="submit" (click)="onOpenExceptionModal()">
            Añadir
          </button>
        </div>
      </div>
      <div class="row shedule-slot-row">
        <table class="table shedule-config-tbl">
          <tbody>
            <tr *ngFor="let item of agendaExceptions">
              <td>
                <span>{{ item.fecha_desde | CustomeDatePipe }} </span>
                <span style="font-weight: bold;"> {{ item.hora_desde }} {{ spaceString }}</span>
                <span>
                  {{ item.fecha_hasta | CustomeDatePipe }}
                </span>
                <span style="font-weight: bold;">
                  {{ item.hora_hasta }} </span>
                <span class="margin-expectation">{{spaceString1}} {{ item.concurrentes }}</span>
              </td>
              <td>
                <a class="pb-2">
                  <span class="fa fa-edit tbl-row-action" (click)="onEditExceptionMadal(item)"></span>
                </a>
              </td>
              <td>
                <span class="fa fa-close tbl-row-action" (click)="deleteException(item)"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 text-right mt-2">
    <button class="btn btn-secondary mr-3" (click)="onCaneclShedul()">
      Cancelar
    </button>
    <button class="btn btn-primary mr-3" (click)="onConfigAgenda()">
      Configurar Agenda
    </button>
    <button class="btn btn-primary" (click)="onAppceptCalender()">
      Aceptar cambios y Cerrar
    </button>
  </div>
</div>