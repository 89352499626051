import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Appointments } from 'src/app/model/agendar/appointments';
import { CommonService } from 'src/app/service/common/common.service';
import { RequestData } from '../../model/shared/request-data';
import { DropdownService } from '../../service/shared/dropdown.service';
import { SelectOptions } from 'src/app/model/shared/select-options.model';
import { NgxPaginationConfig } from 'src/app/model/shared/ngx-pagination-config';
import { AgentsRequestData } from 'src/app/model/visualizer-citars/agents-request-data';
import { AgendaRequestData } from 'src/app/model/agendar/agenda-request-data'
import { MenuList } from 'src/app/helpers/enums/menu-list';
import { AppointmentTypeComponent } from '../shared/reusable-component/appointment-type/appointment-type.component';
import { EmpresaTrabajadorSerchComponent } from '../shared/reusable-component/empresa-trabajador-serch/empresa-trabajador-serch.component';
import moment from 'moment';
import { CommonUiService, Delay } from 'src/app/service/common/common-ui.service';
import { VisualizerCitarService } from 'src/app/service/visualizercitar/visualizer-citar.service';
import { DatePipe } from '@angular/common';
import { UIService } from 'src/app/service/shared/ui.service';
import { RecoService } from 'src/app/service/uploading/reco.service';
import { Agenda } from 'src/app/model/agendar/agenda';
import { EMPTY, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CovidReportPopupComponent } from './covid-report-popup/covid-report-popup/covid-report-popup.component';
import { VisualizerDataService } from './visualizer-citar-report-service/visualizer-citar-report-service'
import { Constants } from 'src/app/helpers/constant/constant';

@Component({
  selector: 'app-visualizer-citars',
  templateUrl: './visualizer-citars.component.html',
  styleUrls: ['./visualizer-citars.component.scss']
})
export class VisualizerCitarsComponent implements OnInit {

  @ViewChild('refreshcode') refreshcodeElement;
  @HostListener('window:focus') onFocus() {
    this.triggerSearch()
  }
  @ViewChild(AppointmentTypeComponent) appointmentTypeComponent: AppointmentTypeComponent;
  @ViewChild(EmpresaTrabajadorSerchComponent) empresaTrabajadorSerchComponent: EmpresaTrabajadorSerchComponent;

  // dropdown list
  agendalist: SelectOptions[] = [];
  centerList: SelectOptions[] = [];
  statesAppointmentList: SelectOptions[] = [];
  commercialList: SelectOptions[] = [];
  programmerList: SelectOptions[] = [];
  appointmentList: Appointments[] = [];
  selectedEstadoCitaItems = [];
  // left pagination
  public paginationConfig = new NgxPaginationConfig();
  rowcount = 0;
  page = 1;
  pageSize = 10;
  agendaFilteredData: AgendaRequestData;
  statesAppointment: any;
  public menuListEnum = MenuList;
  filterFromDate = moment(new Date(), "dd/MM/YYYY", true);
  filterToDate = moment(new Date(), "dd/MM/YYYY", true);
  public isLoading = false;
  public patianDetail: Appointments;
  datePipe = new DatePipe('en-US');
  agendaRequestData = {
    page_number: 1,
    page_size: 25,
    incl: null,
    type: 'filter'
  }
  centerRequestData = {
    page_number: 1,
    page_size: 25,
    incl: null,
    type: 'filter'
  }
  appointmentData: Agenda;
  formValidation: boolean = true;
  covidAppoiment: Appointments;
  private agendaSubject: Subject<string> = new Subject();
  private centerSubject: Subject<string> = new Subject();

  constructor(private _commonService: CommonService,
    private _visualizerCitarService: VisualizerCitarService,
    private router: Router, private dropdownService: DropdownService,
    private _commonUiService: CommonUiService,
    private uiService: UIService,
    public recoService: RecoService,
    private modalService: NgbModal) {
    this.loadDropdownList();
    this.agendaFilteredData = new AgendaRequestData();
    this.agendaFilteredData.fecha_desde = this.filterFromDate;
    this.agendaFilteredData.fecha_hasta = this.filterToDate;
    this.agendaFilteredData.accion_tipo = 0;
    this.appointmentData = new Agenda();
  }

  ngAfterViewInit() {
    this.refreshcodeElement.focus();
  }
  ngOnInit(): void {
    this.paginationConfig.id = 'appointment-list-pagination';
    this.paginationConfig.currentPage = 1;

    this.agendaSubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe(agendaName => this.loadTopMatchingAgenda(agendaName));

    this.centerSubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe(centerName => this.loadTopMatchingCenters(centerName));

  }

  onEdit(appointment: Appointments) {
    window.open("visualizer/edit?id=" + appointment.id + "&accion_tipo_id=" + appointment.accion_tipo_id, "_blank");
  }

  //#region dropdown list
  loadDropdownList() {
    this.getAgendaList();
    this.getCenterList();
    this.getStatesAppointmentList();
    this.getProgrammerList();
    this.getCommercialList();
  }

  getAgendaList(incl?: string, isScroll = false) {
    this.isLoading = true;
    this.agendaRequestData.incl = incl ? incl : null;
    this.agendaRequestData.page_number = isScroll ? (this.agendaRequestData.page_number + 1) : 1;
    this._commonService.getAgendaList(this.agendaRequestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        if (isScroll) {
          this.agendalist = this.agendalist.concat(this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id'));
        } else {
          this.agendalist = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');
        }
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  getCenterList(agendaId?: number, incl?: string, isScroll = false) {
    this.isLoading = true;
    this.centerRequestData.incl = incl ? incl : null;
    this.centerRequestData.page_number = isScroll ? (this.centerRequestData.page_number + 1) : 1;
    let request: any;
    if (agendaId > 0) {
      const requestData = new RequestData();
      requestData.relatedId = agendaId;
      requestData.incl = this.centerRequestData.incl;
      requestData.page_number = this.centerRequestData.page_number;
      requestData.type = 'filter';
      request = requestData;
    } else {
      const requestData = {
        page_number: this.centerRequestData.page_number,
        page_size: 10,
        incl: this.centerRequestData.incl,
        type: 'filter'
      }
      request = requestData;
    }
    this._commonService.getCenterList(request).subscribe(res => {
      this.isLoading = false;
      if (res) {
        if (isScroll) {
          this.centerList = this.centerList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id'));
        } else {
          this.centerList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id', false, false);
        }
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  onClickDownloadExcel() {
    this.onSearch(true).subscribe((res) => {
      if (res?.links?.rowcount > 4000) {
        this.uiService.showFailToast("Error ", "No se puede generar un excel de más de 4000 líneas")
      }
      else if (res?.links?.rowcount <= 4000) {
        let data = this.agendaFilteredData;
        data.excel = true;
        data.check_in = false;
        this._visualizerCitarService.downloadExcelFile(data).subscribe(res => {
          this._visualizerCitarService.exportAsExcelFile(res.data, "appointments");
        })
      }
    });

  }

  getStatesAppointmentList() {
    this.isLoading = true;
    const requestData = new RequestData();
    requestData.type = null;
    this._commonService.getStatesAppointmentList(requestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        this.statesAppointmentList = this.dropdownService.WrappedToDropDownList(res.data, 'descripcion', 'id', false, false, true);
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  getProgrammerList() {
    this.isLoading = true;
    const requestData = new AgentsRequestData();
    requestData.id_rol = 'PROG';
    requestData.rol_alias = 'programador';
    requestData.type = 'filter';
    this._commonService.getAgentsList(requestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        this.programmerList = this.dropdownService.WrappedToDropDownList(res.data, 'apellidos_nombre', 'id');
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });

  }

  getAppointments(agendaId: number, accion_tipo_id: number) {
    const requestData = {
      id: agendaId,
      accion_tipo_id: accion_tipo_id
    }
    return this._commonService.getAppointments(requestData).toPromise();
  }

  getCommercialList() {
    this.isLoading = true;
    const requestData = new AgentsRequestData();
    requestData.id_rol = 'COMERCIAL';
    requestData.rol_alias = 'comercial';
    requestData.type = 'filter';
    this._commonService.getAgentsList(requestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        this.commercialList = this.dropdownService.WrappedToDropDownList(res.data, 'apellidos_nombre', 'id');
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }
  //#endregion

  //#region events
  onChangeAgenda(agenda: any) {
    if (agenda) {
      this.getCenterList(Number(agenda.value));
    } else {
      this.centerList = [];
    }
  }

  loadTopMatchingAgenda(agendaName: any) {
    // Trigger the loading only if string length is >= 4
    if (agendaName && agendaName.length >= 4) {
      this.getAgendaList(agendaName);
    } else {
      if (agendaName.length == 0) {
        this.getAgendaList();
      }
    }
  }

  onKeyUpAgenda(agendaNameTarget) {
    this.agendaSubject.next(agendaNameTarget?.target?.value);
  }

  onCloseAgenda() {
    this.getAgendaList();
    this.getCenterList();
    this.agendaFilteredData.agenda_id = null;
    this.agendaFilteredData.centro_id = null;
  }

  loadTopMatchingCenters(centerName: any) {
    // Trigger the loading only if string length is >= 4
    if (centerName && centerName.length >= 4) {
      this.getCenterList(this.agendaFilteredData.agenda_id, centerName);
    } else {
      if (centerName.length == 0) {
        const agenda_id = this.agendaFilteredData.agenda_id > 0 ? this.agendaFilteredData.agenda_id : undefined;
        this.getCenterList(agenda_id);
      }
    }
  }

  onKeyUpCenter(centerNameTarget) {
    this.centerSubject.next(centerNameTarget?.target?.value);
  }

  onCloseCentro() {
    this.agendaFilteredData.centro_id = null;
  }

  setCompanySearchData(data: any) {
    if (Number(data.companyId) > 0 && this.agendaFilteredData.empresa_id != Number(data.companyId)) {
      this.getCompanyDetails(Number(data.companyId));
    }
    this.agendaFilteredData.empresa_id = Number(data.companyId);
    this.agendaFilteredData.persona_id = Number(data.personId);
    this.agendaFilteredData.workcenter_id = data.workCenterId > 0 ? data.workCenterId : null;

  }

  setAppoinmentSearchData(data: AgendaRequestData) {
    this.agendaFilteredData.accion_tipo = Number(data.accion_tipo);
    this.agendaFilteredData.puestovs_id = Number(data.puestovs_id);
    this.agendaFilteredData.estados = Number(data.estados);
    this.agendaFilteredData.accion_subtipo = Number(data.accion_subtipo);
    this.agendaFilteredData.solicitante = data.solicitante;
    this.agendaFilteredData.provincia_id = Number(data.provincia_id);
    this.agendaFilteredData.pago_tipo = Number(data.pago_tipo);
    this.agendaFilteredData.poblacion_id = Number(data.poblacion_id);
    this.agendaFilteredData.pagado_sino = (data.pagado_sino == undefined && this.agendaFilteredData.accion_tipo == 2) ? false : data.pagado_sino;
    this.agendaFilteredData.tarjeta_sanitaria = data.tarjeta_sanitaria;
    this.agendaFilteredData.pagado_importe = Number(data.pagado_importe);
  }

  onSearch(fromExcel = false) {
    this.paginationConfig.currentPage = 1;
    this.agendaFilteredData.check_in = false;
    this.agendaFilteredData.excel = false;
    this.agendaFilteredData.page_size = 25;
    this.agendaFilteredData.page_number = 1;
    this.onChangeDate();

    if (this.filterFromDate != null) {
      this.agendaFilteredData.fecha_desde = this.convertToDateString(this.filterFromDate);
    } else {
      const filterFromDate = moment(new Date(), "dd/MM/YYYY", true);
      this.agendaFilteredData.fecha_desde = this.convertToDateString(filterFromDate);
    }

    if (this.filterToDate != null) {
      this.agendaFilteredData.fecha_hasta = this.convertToDateString(this.filterToDate);
    } else {
      let from_date = new Date(this.convertToDate(this.filterFromDate));
      from_date.setDate(from_date.getDate() + 30);
      const filterToDate = moment(from_date, "dd/MM/YYYY", true);
      this.agendaFilteredData.fecha_hasta = this.convertToDateString(filterToDate);
    }
    if (this.selectedEstadoCitaItems && this.selectedEstadoCitaItems.length > 0) {
      let estadoId = 0;
      this.selectedEstadoCitaItems.forEach(item => {
        const powerValue = Math.pow(2, item.power2_bit);
        estadoId += powerValue;
      });

      this.agendaFilteredData.estados_cita = estadoId;
    } else {
      this.agendaFilteredData.estados_cita = 0;
    }
    if (this.agendaFilteredData.persona_id != null && this.agendaFilteredData.persona_id > 0) {
      this.agendaFilteredData.fecha_desde = null;
      this.agendaFilteredData.fecha_hasta = null;
    }
    localStorage.removeItem('visualizer-citars');
    localStorage.setItem('visualizer-citars', JSON.stringify(this.agendaFilteredData));
    if (this.formValidation || fromExcel) {
      return this.getAppointmentsList(this.agendaFilteredData);
    }

    return EMPTY;
  }

  triggerSearch() {
    this.onSearch().subscribe()
  }
  getAppointmentsList(filteredData: AgendaRequestData) {
    this.isLoading = true;
    console.log(filteredData);
    filteredData.empresa_id = (filteredData.empresa_id == null || isNaN(filteredData.empresa_id)) ? 0 : filteredData.empresa_id;
    return this._commonService.getAppointmentsList(filteredData).pipe(
      map((res: any) => {
        this.isLoading = false;
        if (res.data) {
          this.rowcount = res.links.rowcount;
          this.paginationConfig.totalItems = res.links.rowcount;
          this.paginationConfig.itemsPerPage = 25;
          this.appointmentList = res.data;
        }
        else if (res.data == null) {
          this.rowcount = 0;
          this.paginationConfig.totalItems = 0;
          this.paginationConfig.itemsPerPage = 25;
          this.appointmentList = [];
        }
        return res;
      }),
      catchError((err) => {
        this.isLoading = false;
        console.log(err);
        return EMPTY;
      })
    );

  }

  public pageChanged(pageNo: number): void {
    if (pageNo > 0 && pageNo <= this.rowcount && this.rowcount !== pageNo) {
      this.paginationConfig.currentPage = pageNo;
      this.agendaFilteredData.page_number = pageNo;
      this.getAppointmentsList(this.agendaFilteredData).subscribe();
    }
  }

  static Paginate(array: any[], page_size: number, page_number: number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  onClearFilters() {
    this.agendaFilteredData = new AgendaRequestData();
    this.filterFromDate = moment(new Date(), "dd/MM/YYYY", true);
    this.filterToDate = moment(new Date(), "dd/MM/YYYY", true);
    this.agendaFilteredData.fecha_desde = this.filterFromDate;
    this.agendaFilteredData.fecha_hasta = this.filterToDate;
    this.appointmentTypeComponent.agendaFilteredData = new AgendaRequestData();
    this.empresaTrabajadorSerchComponent.companyId = null;
    this.empresaTrabajadorSerchComponent.personId = null;
    this.appointmentTypeComponent.estado = '';
    this.appointmentTypeComponent.pagado_sino = null;
    this.statesAppointment = '';
  }

  onScrollAgenda() {
    this.getAgendaList('', true);
  }

  onScrollCenter() {
    this.getCenterList(this.agendaFilteredData.agenda_id, '', true);
  }

  //#endregion

  async onPrint(appointment: Appointments) {
    this.covidAppoiment = appointment;
    const data = await this.getAppointments(appointment.id, appointment.accion_tipo_id);
    this.appointmentData = data.data;
    if (this.agendaFilteredData.accion_tipo == 2) {
      this.printCovid(appointment)
    }
    else {
      await Delay(300);
      this._commonUiService.PrintHtmlReport('visualizer-zitar-report');
    }

  }

  printCovid(appointment: Appointments) {

    const modalRef = this.modalService.open(CovidReportPopupComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.patianDetail = appointment;
    modalRef.componentInstance.formTitle = appointment.apellidos_nombre_persona + " - " + appointment.nip;
    modalRef.result.then(close => {
    }, dismiss => { });
  }



  convertToDateString(date: any) {
    const toDate: Date = date.toDate();
    const actualMonth = toDate.getMonth() + 1;
    //  date in YYYYMMdd format
    return toDate.getFullYear() + ('0' + actualMonth).slice(-2) + ('0' + toDate.getDate()).slice(-2);
  }

  convertToDate(date: any) {
    const toDate: Date = date.toDate();
    const actualMonth = toDate.getMonth() + 1;
    //  date in YYYYMMdd format
    return toDate.getFullYear() + '-' + ('0' + actualMonth).slice(-2) + '-' + ('0' + toDate.getDate()).slice(-2);
  }

  async onChangeDate() {
    this.formValidation = true;
    if (this.filterFromDate && this.filterToDate) {
      const from_date = new Date(this.convertToDate(this.filterFromDate));
      const to_date = new Date(this.convertToDate(this.filterToDate));
      const time = to_date.getTime() - from_date.getTime();
      const days = time / (1000 * 3600 * 24); //Diference in Days
      if (to_date < from_date) {
        const message = '¡La fecha de hasta debe ser posterior a la fecha de fesde!'; //To date must be later than the from date!
        this.uiService.showWarningMessage(message);
        this.formValidation = false;
        return;
      } else if (days > 365) {
        const message = 'Rango de fechas inválido'; // Invalid date range
        this.uiService.showWarningMessage(message);
        this.formValidation = false;
        return;
      }
    }
  }

  getCompanyDetails(companyId) {
    let requestData = {
      "empresa_id": companyId
    };
    this.isLoading = true;
    this._commonService.getCompanyDetails(requestData).subscribe(res => {
      this.isLoading = false;
      if (res && res.data && res.data.advices.length > 0) {
        res.data.advices.forEach(toastMessage => {
          this.uiService.showInfoMessage(toastMessage.texto);
        });
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  onChangeEstadoCita(estado) {
    this.selectedEstadoCitaItems = estado;
  }


}
