import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReservationTypes } from 'src/app/helpers/enums/reservation-type';
import * as Response from "src/app/model/agendar/agenda";
import { RequestData } from 'src/app/model/shared/request-data';
import { SelectOptions } from 'src/app/model/shared/select-options.model';
import { AgentsRequestData } from 'src/app/model/visualizer-citars/agents-request-data';
import { CommonService } from 'src/app/service/common/common.service';
import { DropdownService } from 'src/app/service/shared/dropdown.service';
import { RescheduleAppointmentPopUpComponent } from '../reschedule-appointment-pop-up/reschedule-appointment-pop-up.component';
import { UIService } from 'src/app/service/shared/ui.service';
import { dniValidator } from 'src/app/helpers/validators/dniValidator'
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CovidReportPopupComponent } from '../covid-report-popup/covid-report-popup/covid-report-popup.component';
import { VisualizerDataService } from '../visualizer-citar-report-service/visualizer-citar-report-service'
import { Appointments } from '../../../model/agendar/appointments';
import { CommonUiService, Delay } from 'src/app/service/common/common-ui.service';
import { Constants } from 'src/app/helpers/constant/constant';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-visualizer-citar-edit',
  templateUrl: './visualizer-citar-edit.component.html',
  styleUrls: ['./visualizer-citar-edit.component.scss']
})
export class VisualizerCitarEditComponent implements OnInit {
  selectedType: number;
  datosPersonalesFormIsEditable: boolean = false;
  public reservationTypeEnum = ReservationTypes;
  appointmentEditData: any;
  commercialList: SelectOptions[] = [];
  programmerList: SelectOptions[] = [];
  paymentTypesList: SelectOptions[] = [];
  languageList: SelectOptions[] = [];
  actionSubTypesList: SelectOptions[] = [];
  puestosVSList: SelectOptions[] = [];
  townsList: SelectOptions[] = [];
  provinciaList: SelectOptions[] = [];
  pagado_sino: number = 0;
  requestData: any;
  submitted = false;
  datePipe = new DatePipe('en-US');
  postEmpressaObject = {};
  @Input() id;
  @Input() accion_tipo_id;
  isLoading = false;
  appoimentDetail: Appointments;
  personId = null;
  appointmentLoaded: Promise<boolean>;
  puestosCount: number;
  cancelNewButtonGeseme: boolean = false;
  cancelNewButtonEmpresa: boolean = false;
  firmaTypesButtonsGeseme: boolean = false;
  cancelIdentification: string;
  sedesName: string;
  personTelNo: any;

  datosPersonalesForm = new FormGroup({
    id: new FormControl(''),
    id_trabajador: new FormControl(''),
    apellidos: new FormControl(''),
    nombre: new FormControl(''),
    nip: new FormControl('', [dniValidator()]),
    email1: new FormControl('', Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")),
    tel1: new FormControl('', [Validators.minLength(9), Validators.maxLength(12)]),
    fecha_nacimiento: new FormControl('', Validators.required),
    language_id: new FormControl('', Validators.required),
    genero: new FormControl('', []),
    comentarios: new FormControl(''),
    id_centro_trabajo: new FormControl('')
  });

  provinciaRequestData = {
    page_number: 1,
    page_size: 10,
    incl: null,
    type: null,
    id: null
  }

  townsRequestData = {
    page_number: 1,
    page_size: 10,
    incl: null,
    type: null,
    id: null
  }
  private provinceSubject: Subject<string> = new Subject();
  private townSubject: Subject<string> = new Subject();
  constructor(
    private modalService: NgbModal,
    private _route: ActivatedRoute,
    private route: Router,
    private _visualizerCitarService: CommonService,
    private dropdownService: DropdownService,
    public uiService: UIService,
    private vDataService: VisualizerDataService,
    private _commonUiService: CommonUiService,
    public datepipe: DatePipe
  ) {
    this.appointmentEditData = new Response.Agenda();
  }

  async ngOnInit(): Promise<void> {
    this.vDataService.appoimentDetail.subscribe((appoiment) => {
      if (appoiment != undefined) {
        this.appoimentDetail = appoiment;
      }
    }
    );

    if (this.id && this.accion_tipo_id) {
      this.requestData = { id: this.id, accion_tipo_id: this.accion_tipo_id };
      this.getAppointments(this.id, this.accion_tipo_id);
    }
    else {
      await this.QueryParamsListener();
    }
    this.provinceSubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe(provinceName => this.loadTopMatchingProvinces(provinceName));

    this.townSubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe(townName => this.loadTopMatchingTowns(townName));
  }

  onClickFirmaGesemeArrow() {
    this.firmaTypesButtonsGeseme = !this.firmaTypesButtonsGeseme;
  }
  CancelGeseme() {
    this.vDataService.setIsCancelAppointment(false);
    this.updateAppointment('btnCancelGeseme');
  }
  CancelCompany() {
    this.vDataService.setIsCancelAppointment(false);
    this.updateAppointment('btnCancelCompany');
  }
  onClickCancelEmpresaArrow() {
    this.cancelIdentification = 'btnCancelCompany';
    this.cancelNewButtonGeseme = false;
    this.cancelNewButtonEmpresa = true;
  }
  onClickCancelGesemeArrow() {
    this.cancelIdentification = 'btnCancelGeseme';
    this.cancelNewButtonGeseme = true;
    this.cancelNewButtonEmpresa = false;
  }

  onClickCancelNew() {
    localStorage.setItem('agendaId', this.appointmentEditData.attribs.agenda_id);
    localStorage.setItem('agendaName', this.appointmentEditData.attribs.agenda_txt);
    localStorage.setItem('centro_text', this.appointmentEditData.attribs.centro_txt);
    localStorage.setItem('centro_id', this.appointmentEditData.attribs.centro_id);
    if (this.cancelIdentification == 'btnCancelGeseme') {
      localStorage.setItem('cancelNewData', JSON.stringify(this.appointmentEditData));
      localStorage.setItem('cancelStatus', 'true')
      this.updateAppointment('btnCancelGeseme');
    } else if (this.cancelIdentification == 'btnCancelCompany') {
      localStorage.setItem('cancelNewData', JSON.stringify(this.appointmentEditData));
      localStorage.setItem('cancelStatus', 'true')
      this.updateAppointment('btnCancelCompany');
    }
    window.open('agendar-nuevas/view', "_blank");
  }
  private async QueryParamsListener() {
    this._route.queryParams.subscribe(async params => {
      if (params['id'] && params['accion_tipo_id']) {
        this.requestData = { id: Number(params['id']), accion_tipo_id: Number(params['accion_tipo_id']) }
        this.getAppointments(Number(params['id']), Number(params['accion_tipo_id']));
      }
    });
  }

  public openRescheduleAppointmentPopup(intentionId) {
    const modalRef = this.modalService.open(RescheduleAppointmentPopUpComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.agenda = this.appointmentEditData;
    modalRef.componentInstance.intentionId = intentionId;
    modalRef.result.then(_close => {
      console.log('test');
    }, _dismiss => {
      this.PrepareBindingData();
    });
  }

  async getPuestosVSListCount() {
    const requestData = new RequestData();
    requestData.type = null;
    requestData.relatedId = this.appointmentEditData.attribs.empresa_id;
    await this._visualizerCitarService.getPuestosVSList(requestData).subscribe(res => {
      if (res) {
        this.puestosCount = res.links.rowcount;
        this.getPuestosVSList();
      }
    }, error => {
      console.log(error);
    });
  }
  async getPuestosVSList() {
    const requestData = new RequestData();
    requestData.type = null;
    requestData.relatedId = this.appointmentEditData.attribs.empresa_id;
    requestData.page_size = this.puestosCount;
    await this._visualizerCitarService.getPuestosVSList(requestData).subscribe(res => {
      if (res) {
        this.puestosVSList = this.dropdownService.WrappedToDropDownList(res.data, 'descripcion', 'id');
      }
    }, error => {
      console.log(error);
    });
  }

  getAppointments(agendaId: number, accion_tipo_id: number) {
    this.isLoading = true;
    const requestData = {
      id: agendaId,
      accion_tipo_id: accion_tipo_id
    }
    this._visualizerCitarService.getAppointments(requestData).subscribe(res => {
      if (res) {
        this.appointmentEditData = res.data;
        this.getPerson(this.appointmentEditData.persona.persona_id);
        this.datosPersonalesForm.patchValue(this.appointmentEditData.persona)
        this.selectedType = Number(this.appointmentEditData.attribs.accion_tipo_id);
        this.loadDropdownList();
        this.getActionSubTypesList();
        this.getPuestosVSListCount();
        this.PrepareBindingData();
        this.getProvinciaList(null, false, this.appointmentEditData.attribs.provincia_id);
        this.getTownsList(this.appointmentEditData.attribs.provincia_id, null, false, this.appointmentEditData.attribs.poblacion_id);
        this.isLoading = false;
        this.personId = this.appointmentEditData.persona.persona_id;
        this.appointmentLoaded = Promise.resolve(true);

      }
    }, error => {
      console.log(error);
      this.isLoading = false;
    });
  }
  public getPerson(persona_id: number) {
    const requestData = {
      persona_id: persona_id,
    }
    this._visualizerCitarService.getPerson(requestData).subscribe(res => {
      if (res) {
        this.personTelNo = res.data.tel1;
        this.sedesName = res.data.empresas[0].sedes[0].empresa_sede_txt;
      }
    }, error => {
      console.log(error);
      this.isLoading = false;
    });
  }

  getActionSubTypesList() {
    const requestData = new RequestData();
    requestData.relatedId = this.selectedType;
    this._visualizerCitarService.getActionSubTypesList(requestData).subscribe(res => {
      if (res) {
        this.actionSubTypesList = this.dropdownService.WrappedToDropDownList(res.data, 'descripcion', 'id');
      }
    }, error => {
      console.log(error);
    });
  }

  loadDropdownList() {
    this.getPaymentTypesList();
    this.getProgrammerList();
    this.getCommercialList();
  }
  getCommercialList() {
    const requestData = new AgentsRequestData();
    requestData.id_rol = 'COMERCIAL';
    requestData.rol_alias = 'comercial';
    requestData.type = null;
    this._visualizerCitarService.getAgentsList(requestData).subscribe(res => {
      if (res) {
        this.commercialList = this.dropdownService.WrappedToDropDownList(res.data, 'apellidos_nombre', 'id');
      }
    }, error => {
      console.log(error);
    });
  }
  getProgrammerList() {
    const requestData = new AgentsRequestData();
    requestData.id_rol = 'PROG';
    requestData.rol_alias = 'progrador';
    requestData.type = null;
    this._visualizerCitarService.getAgentsList(requestData).subscribe(res => {
      if (res) {
        this.commercialList = this.dropdownService.WrappedToDropDownList(res.data, 'apellidos_nombre', 'id');
      }
    }, error => {
      console.log(error);
    });
  }

  getPaymentTypesList() {
    this.isLoading = true;
    const requestData = new RequestData();
    requestData.type = null;
    this._visualizerCitarService.getPaymentTypesList(requestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        this.paymentTypesList = this.dropdownService.WrappedToDropDownList(res.data, 'descripcion', 'id');
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  getProvinciaList(incl?: string, isScroll = false, id = null) {
    this.isLoading = true;
    this.provinciaRequestData.incl = incl ? incl : null;
    this.provinciaRequestData.page_number = isScroll ? (this.provinciaRequestData.page_number + 1) : 1;
    this.provinciaRequestData.page_size = 10;
    this.provinciaRequestData.type = null;
    this.provinciaRequestData.id = id;
    this._visualizerCitarService.getProvincesList(this.provinciaRequestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        if (isScroll) {
          this.provinciaList = this.provinciaList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id'));
        } else {
          this.provinciaList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');
        }
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  onProvinceChange(data: any) {
    if (data) {
      this.getTownsList(Number(data.value));
    }
  }

  loadTopMatchingProvinces(provinceName: any) {
    // Trigger the loading only if string length is >= 3
    if (provinceName.length >= 3) {
      this.getProvinciaList(provinceName);
    } else {
      if (provinceName.length == 0) {
        this.getProvinciaList();
      }
    }
  }

  onKeyUpProvince(provinceTarget) {
    this.provinceSubject.next(provinceTarget?.target?.value);
  }

  loadTopMatchingTowns(townName: any) {
    // Trigger the loading only if string length is >= 4
    if (townName.length >= 4) {
      this.getTownsList(this.appointmentEditData.attribs.provincia_id, townName);
    } else {
      if (townName.length == 0) {
        this.getTownsList();
      }
    }
  }

  onKeyUpTown(townTarget) {
    this.townSubject.next(townTarget?.target?.value);
  }

  getTownsList(provinceId?: number, incl?: string, isScroll = false, id = null) {
    this.isLoading = true;
    this.townsRequestData.incl = incl ? incl : null;
    this.townsRequestData.page_number = isScroll ? (this.townsRequestData.page_number + 1) : 1;
    this.townsRequestData.type = null;
    this.townsRequestData.id = id;
    let request: any;
    if (provinceId > 0) {
      const requestData = {
        relatedId: provinceId,
        incl: this.townsRequestData.incl,
        page_number: this.townsRequestData.page_number,
        type: null,
        id: this.townsRequestData.id
      }
      request = requestData;
    } else {
      const requestData = {
        page_number: this.townsRequestData.page_number,
        page_size: 10,
        incl: this.townsRequestData.incl,
        type: null,
        id: this.townsRequestData.id
      }
      request = requestData;
    }
    this._visualizerCitarService.getTownsList(request).subscribe(res => {
      this.isLoading = false;
      if (res) {
        if (isScroll) {
          this.townsList = this.townsList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id'));
        } else {
          this.townsList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id', false, false);
        }
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  onScrollProvinces() {
    this.getProvinciaList('', true);
  }

  onScrollTowns() {
    this.getTownsList(this.appointmentEditData.attribs.provincia_id, '', true);
  }

  onOpenAddDocument(intentionId) {
    this.updateAppointment(intentionId);
    const url = this.route.serializeUrl(this.route.createUrlTree(['/uploader/view'], { queryParams: { id: this.requestData.id, accion_tipo_id: this.requestData.accion_tipo_id } }));
    window.open(url, '_blank');
  }

  onPagadoChange() {
    if (this.pagado_sino != null) {
      const pagado = this.pagado_sino == 1 ? true : false;
      if (pagado != this.appointmentEditData.attribs.pagado_sino) {
        this.appointmentEditData.attribs.pagado_sino = pagado
      }
    }
  }

  PrepareBindingData() {
    if (this.appointmentEditData.attribs != null) {
      this.appointmentEditData.attribs.subtipo_id = this.appointmentEditData.attribs.subtipo_id == "" ? null : Number(this.appointmentEditData.attribs.subtipo_id)
      if (this.appointmentEditData.attribs.fecha.length == 8) {
        let day = this.appointmentEditData.attribs.fecha?.substr(6, 8);
        let month = this.appointmentEditData.attribs.fecha?.substr(4, 2);
        let year = this.appointmentEditData.attribs.fecha?.substr(0, 4);
        if (day != undefined && month != undefined && year != undefined) {
          let date = day + '/' + month + '/' + year;
          this.appointmentEditData.attribs.fecha = date;
        }
      }
      if (this.appointmentEditData.attribs.hora.trim().length == 4 || this.appointmentEditData.attribs.hora.trim().length == 6) {
        const h = this.appointmentEditData.attribs.hora?.substr(0, 2);
        const m = this.appointmentEditData.attribs.hora?.substr(2, 2);
        this.appointmentEditData.attribs.hora = h + ':' + m;
      }
      this.renderButtons();
      this.pagado_sino = this.appointmentEditData.attribs.pagado_sino ? 1 : 0;
    }
  }

  renderButtons() {
    this.appointmentEditData.options.forEach((option) => {
      const btnName = String(option.id);
      const element = document.getElementById(btnName) as HTMLInputElement;
  
      if (element !== null) {
        element.style.backgroundColor = String(option.backcolor);
        element.disabled = !(option.enabled ?? false);
        element.classList?.add(option.enabled ? 'inline-block' : 'none');
        element.innerText = String(option.caption ?? element.innerText);
  
        if (btnName === 'btnCancelCompany') {
          const cancelEmpresaElement = document.getElementById('cancelEmpresa') as HTMLInputElement;
          cancelEmpresaElement.style.backgroundColor = String(option.backcolor);
          cancelEmpresaElement.disabled = !(option.enabled ?? false);
          cancelEmpresaElement.classList?.add(option.enabled ? 'inline-block' : 'none');
        } else if (btnName === 'btnCancelGeseme') {
          const cancelGesemeElement = document.getElementById('cancelGeseme') as HTMLInputElement;
          cancelGesemeElement.style.backgroundColor = String(option.backcolor);
          cancelGesemeElement.disabled = !(option.enabled ?? false);
          cancelGesemeElement.classList?.add(option.enabled ? 'inline-block' : 'none');
        }
      }
    });
  }  

  onOpenCovidScreen(_intentionId = null) {
    let idReserva = this.appointmentEditData.attribs.id_reserva;
    window.open(`${environment.openCovid}${idReserva}`);
  }

  mapAppointmentEditData(appointmentEditData: any) {
    this.appoimentDetail.id = appointmentEditData.attribsid
    this.appoimentDetail.accion_tipo_id = appointmentEditData.attribs.accion_tipo_id;
    this.appoimentDetail.agenda = appointmentEditData.attribs.agenda_txt;
    this.appoimentDetail.fecha = appointmentEditData.attribs.fecha
    this.appoimentDetail.hora = appointmentEditData.attribs.hora;
    this.appoimentDetail.apellidos_nombre_persona = appointmentEditData.persona.apellidos + " " + appointmentEditData.persona.nombre
    this.appoimentDetail.nip = appointmentEditData.persona.dni;
    this.appoimentDetail.empresa = appointmentEditData.attribs.empresa_txt;
    this.appoimentDetail.accion_tipo = appointmentEditData.attribs.tipo_txt;
    this.appoimentDetail.accion_subtipo = appointmentEditData.attribs.tipo_pago_txt;
    this.appoimentDetail.fechaView = appointmentEditData.attribs.fecha;
    this.appoimentDetail.horaView = appointmentEditData.attribs.hora;
  }

  async onPrint(intentionId) {
    const PrintData = this.appointmentEditData;
    this.updateAppointment(intentionId);
    if (this.appointmentEditData != undefined) {
      if (Number(this.appointmentEditData?.attribs?.accion_tipo_id) == 2) {
        this.mapAppointmentEditData(PrintData)
        this.printCovid(this.appoimentDetail)
      }
      else {
        await Delay(300);
        this._commonUiService.PrintHtmlReport('visualizer-zitar-report');
      }
    }
  }

  async onPrintSignature(intentionId) {
    await Delay(300);
    if (intentionId == 'btnFirmaCas') {
      this._commonUiService.PrintHtmlReport('castellano_rgpd');
    } else if (intentionId == 'btnFirmaCat') {
      this._commonUiService.PrintHtmlReport('catalan_rgpd');
    } else if (intentionId == 'btnFirmaEn') {
      this._commonUiService.PrintHtmlReport('english_rgpd');
    } else if (intentionId == 'btnFirmaFr') {
      this._commonUiService.PrintHtmlReport('french_rgpd');
    }
  }

  printCovid(appointment: any) {
    const modalRef = this.modalService.open(CovidReportPopupComponent, { centered: true, size: 'lg' });
    modalRef.componentInstance.patianDetail = appointment;
    modalRef.componentInstance.formTitle = appointment.persona.nombre + " - " + appointment.persona.dni;
  }

  updateAppointment(intentionId) {
    this.submitted = true;
    this.isLoading = true;
    const postData = this.preparePostData();
    postData["intention_id"] = intentionId;
    this._visualizerCitarService.postAppointment(postData).subscribe(res => {
      if (res && res?.webError?.number == 0) {
        this.uiService.showFailToast("Error", res?.webError?.messageToUser);
      }
      else if (res && res?.data != null) {
        if (intentionId == 'btnCancel' || intentionId == 'btnIniciar' || intentionId == 'btnNotPresented' || intentionId == 'btnCancelCompany' || intentionId == 'btnCancelGeseme') {
          this.uiService.showSuccessToast("Guardado con éxito");
          window.close();
        } else {
          this.uiService.showSuccessToast("Guardado con éxito");
        }
      }
      this.PrepareBindingData();
      this.isLoading = false;
    }, error => {
      console.log(error);
      this.isLoading = false;
    });
  }

  preparePostData() {
    const appointmentData = this.appointmentEditData;
    if (appointmentData.attribs != null) {
      appointmentData.attribs.fecha = moment(appointmentData.attribs.fecha?.toString(), "DD/MM/yyyy").format('yyyyMMDD');
      const h = appointmentData.attribs.hora?.substr(0, 2);
      const m = appointmentData.attribs.hora?.substr(3, 2);
      appointmentData.attribs.hora = h + m;
      appointmentData.attribs.subtipo_id = appointmentData.attribs.subtipo_id?.toString();
      if (this.appointmentEditData.attribs.comercial_id == null) {
        appointmentData.attribs.comercial_id = 0;
      }
    }
    return appointmentData;
  }

  reloadPageAfterCovid() {
    if (localStorage.getItem('CovidScreenUpdated')?.toString() == "1") {
      window.location.reload();
      localStorage.setItem('CovidScreenUpdated', "0");
    }
  }

  @HostListener('window:focus', ['$event'])
  onFocused(_event) {
    this.reloadPageAfterCovid();
  }

}

