import { Component } from '@angular/core';
import { AuthService } from 'src/app/service/authentication/auth-service.component';

@Component({
    selector: 'app-unauthorized',
    templateUrl: 'unauthorized.component.html'
})

export class UnauthorizedComponent {
    constructor( private _authService: AuthService ) { }

    logout() {
        localStorage.removeItem("centro_id");
        localStorage.removeItem("agendaRelatedId");
        localStorage.removeItem("agendaId");
        localStorage.removeItem("centro_text");
        this._authService.logout();
    }

}