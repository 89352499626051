import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonUiService } from 'src/app/service/common/common-ui.service';
import { Agenda } from 'src/app/model/agendar/agenda';
import { Appointments } from 'src/app/model/agendar/appointments';

@Component({
  selector: 'app-covid-report-popup',
  templateUrl: './covid-report-popup.component.html',
  styleUrls: ['./covid-report-popup.component.scss']
})
export class CovidReportPopupComponent implements OnInit {
  @Input() public patianDetail;
  @Input() public formTitle;
  appointmentData: Agenda;
  reportData: Appointments;
  constructor(private modalService: NgbModal,
    private _commonUiService: CommonUiService,) {
    this.reportData = new Appointments();
  }

  ngOnInit(): void {
    this.reportData = this.patianDetail;
  }

  closeCovidPrint(event: Event) {
    event.preventDefault();
    this.modalService.dismissAll();
  }

  justificantedeasistenciaReport() {
    this._commonUiService.justificantedeasistenciaReport('justificant_edeasistencia');
  }
  recibodepagoReport() {
    this._commonUiService.recibodepagoReport('recibode_pago_Report');
  }

  tomaDeDATOSPruebasReport() {
    this._commonUiService.tomaDeDATOSPruebasReport('tomaDeDATOS_Pruebas_Report');
  }

  castellanoPruebasReport() {
    this._commonUiService.castellanoPruebasReport('castellano_Pruebas_Report');
  }
  catalanConsPruebasReport() {
    this._commonUiService.catalanConsPruebasReport('catalanCons_Pruebas_Report');
  }
  inglesReport() {
    this._commonUiService.inglesReport('ingles_Report');
  }
  francePredasReport() {
    this._commonUiService.francePredasReport('france_Predas_Report');
  }
  castellanoMenoresPrebasReport() {
    this._commonUiService.castellanoMenoresPrebasReport('castellano_MenoresPrebas_Report');
  }
  catalanMenoresPruebasReport() {
    this._commonUiService.catalanMenoresPruebasReport('catalan_MenoresPruebas_Report');
  }
  inglesMenoresPruebas() {
    this._commonUiService.inglesMenoresPruebas('ingles_Menores_Pruebas');
  }

}
