<div>
    <section class="row login-card m-0">
        <div class="col-xl-12 col-12 d-flex justify-content-center divcenter">
            <div class="card p-5">
                <div class="row m-0">
                    <div class="col-lg-12 col-12 p-0">
                        <div class="login-logo">ERP - AGENDA</div>
                        <p class="px-2">Bienvenido, inicie sesión en su cuenta.</p>
                        <div class="card-content">
                            <form>

                                <button type="submit" class="btn btn-primary float-right btn-inline mb-1 w-100"
                                    (click)="login()">Iniciar Sesión
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</div>