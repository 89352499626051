export class AgendaDisplay{
    rowNum:number;
    id: number;
    nombre: string;
    accion_tipo_id: number;
    centro_txt:string;
    accion_tipo_txt : string;
    accion_tipo_comment: string;
    editar: boolean;
    eliminar: boolean;    
}