<app-spinner *ngIf="isLoading"></app-spinner>
<div class="agendas-breadcrumb">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" aria-current="page">Listado de agendas</li>
      <li class="breadcrumb-item" aria-current="page">{{ title }}</li>
    </ol>
  </nav>
</div>
<div id="scrollbar" class="agendas-content">
  <form [formGroup]="agendaListForm">
    <div class="row">
      <div class="col-md-4 col-xl-4 col-lg-4 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Nombre :</label>
          <input type="text" class="form-control" [(ngModel)]="agendaListItem.nombre" formControlName="nombre" />
          <span *ngIf="
              agendaListForm.get('nombre').touched &&
              agendaListForm.get('nombre').errors?.required &&
              agendaListForm.get('nombre').errors != null
            " class="is-invalid red-clr">
            requerida
          </span>
        </div>
      </div>
      <div class="col-md-4 col-xl-4 col-lg-4 col-sm-12">
        <div class="form-group">
          <label class="mb-0" for="tipo_action_id">Tipo De Reservas :</label>

          <ng-select [clearAllText]="'limpiar todo'" #selectBookingTypes [virtualScroll]="true"
             loadingText="Cargando..." notFoundText="No hay resultados" id="id"
            bindLabel="label" bindValue="value" [items]="bookTypesList" placeholder="Seleccione"
            (change)="onChangeBookTypes($event)" [(ngModel)]="agendaListItem.tipo_accion_id"
            formControlName="tipo_accion_id">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>

          <span *ngIf="
              agendaListForm.get('tipo_accion_id').touched &&
              agendaListForm.get('tipo_accion_id').errors?.required &&
              agendaListForm.get('tipo_accion_id').errors != null
            " class="is-invalid red-clr">
            requerida
          </span>
        </div>
      </div>
      <div class="col-md-4 col-xl-4 col-lg-4 col-sm-12">
        <div class="form-group">
          <label class="mb-0" for="tipo_action_id">Tipo de Agenda :</label>
          <ng-select [clearAllText]="'limpiar todo'" #selectBookingTypes [virtualScroll]="true"
             loadingText="Cargando..." notFoundText="No hay resultados" id="id"
            bindLabel="label" bindValue="value" [items]="statTypesList" placeholder="Seleccione"
            (change)="onChangeStatTypes($event)" [(ngModel)]="agendaListItem.tipo_estadistico_id"
            formControlName="tipo_estadistico_id">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>

          <span *ngIf="
              agendaListForm.get('tipo_estadistico_id').touched &&
              agendaListForm.get('tipo_estadistico_id').errors?.required &&
              agendaListForm.get('tipo_estadistico_id').errors != null
            " class="is-invalid red-clr">
            requerida
          </span>
        </div>
      </div>
      <div class="col-md-4 col-xl-4 col-lg-4 col-sm-12">
        <div class="form-inline mb-2">
          <label class="mb-0">Centro asociado :</label>

          <ng-select [clearAllText]="'limpiar todo'" #selectCentroAso [virtualScroll]="true"
            (clear)="onClosePartnerCenter()" loadingText="Cargando..." notFoundText="No hay resultados" id="id"
            bindLabel="label" bindValue="value" [items]="partnerCenterList" placeholder="Seleccione"
            (change)="onChangePartnerCenter($event)" (keyup)="onKeyUpCenter($event)"
            (scrollToEnd)="onScrollPartnerCenter()" [(ngModel)]="agendaListItem.center_group_id"
            formControlName="center_group_id">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>

          <span *ngIf="
              agendaListForm.get('center_group_id').touched &&
              agendaListForm.get('center_group_id').errors?.required &&
              agendaListForm.get('center_group_id').errors != null
            " class="is-invalid red-clr">
            requerida
          </span>
        </div>
      </div>
      <div class="col-md-4 col-xl-4 col-lg-4 col-sm-12">
        <div class="form-inline mb-2">
          <label class="mb-0">Delegación gestora :</label>
          <ng-select [clearAllText]="'limpiar todo'" #selectBookingTypes [virtualScroll]="true"
             loadingText="Cargando..." notFoundText="No hay resultados" id="id"
            bindLabel="label" bindValue="value" [items]="quaterList" placeholder="Seleccione"
            (change)="onChangeQuarters($event)" [(ngModel)]="agendaListItem.delegacion_id"
            formControlName="delegacion_id">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>

          <span *ngIf="
              agendaListForm.get('delegacion_id').touched &&
              agendaListForm.get('delegacion_id').errors?.required &&
              agendaListForm.get('delegacion_id').errors != null
            " class="is-invalid red-clr">
            requerida
          </span>
        </div>
      </div>
      <div class="col-md-4 col-xl-4 col-lg-4 col-sm-12 pt-4">
        <span> Inactiva</span>
        <label class="switch mx-3">

          <input type="checkbox" formControlName="active" [(ngModel)]="agendaListItem.active" />
          <span class="slider round"></span>
        </label>
        <span> Activa</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-xl-12 col-lg-12 col-sm-12">
        <div class="row">
          <div class="col-md-12 col-xl-6 col-lg-6 col-sm-12 mt-2 mb-3">
            <label class="mb-0">¿Visible desde la web de Autocita? </label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-xl-4 col-lg-4 col-sm-12">
            <div class="form-inline">
              <div class="form-check pl-2">

                <input class="form-check-input" type="radio" formControlName="web_visibility" [value]="true"
                  (click)="onChangeWebVisiYes($event)" />
                <label class="form-check-label" for="exampleRadios1">
                  Si
                </label>
              </div>
            </div>
            <div class="form-inline">
              <div class="form-check pl-2">
                <input class="form-check-input" type="radio" formControlName="web_visibility" [value]="false"
                  (click)="onChangeWebVisiNo($event)" />
                <label class="form-check-label"> No </label>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-xl-8 col-lg-8 col-sm-12">
            <div class="form-group">
              <label class="mb-0" for="web_name">Nombre para la web :</label>
              <input type="text" class="form-control w-50" formControlName="web_name"
                [(ngModel)]="agendaListItem.web_name" />

              <span *ngIf="
                  agendaListForm.get('web_name').touched &&
                  agendaListForm.get('web_name').errors?.required &&
                  agendaListForm.get('web_name').errors != null
                " class="is-invalid red-clr">
                requerida
              </span>
            </div>
            <div class="form-group">
              <label class="mb-0" for="web_comments">Mensaje para la web :</label>
              <textarea class="form-control w-100" formControlName="web_comments"
                [(ngModel)]="agendaListItem.web_comments"></textarea>

              <span *ngIf="
                  agendaListForm.get('web_comments').touched &&
                  agendaListForm.get('web_comments').errors?.required &&
                  agendaListForm.get('web_comments').errors != null
                " class="is-invalid red-clr">
                requerida
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-xl-12 col-lg-12 col-sm-12">
        <div class="row">
          <div class="col-md-12 col-xl-6 col-lg-6 col-sm-12 mt-1 mb-3">
            <label class="mb-0">¿Visible para todas las empresas?</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-xl-4 col-lg-4 col-sm-12">
            <div class="form-inline">
              <div class="form-check pl-2">
                <input class="form-check-input" type="radio" formControlName="companies_all" [value]="true"
                  (click)="onChangeEmpresasYes($event)" />
                <label class="form-check-label"> Si </label>
              </div>
            </div>
            <div class="form-inline">
              <div class="form-check pl-2">
                <input class="form-check-input" type="radio" formControlName="companies_all" [value]="false"
                  (click)="onChangeEmpresasNo($event)" />
                <label class="form-check-label"> No </label>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-xl-8 col-lg-8 col-sm-12">
            <div class="form-inline">
              <label class="mb-0">Lista de empresas:</label>

              <ng-select [clearAllText]="'limpiar todo'" #select [virtualScroll]="true" (close)="select.blur()"
                (clear)="onCloseCompanies()" loadingText="Empresas..." notFoundText="No hay resultados"
                id="activity-type" bindLabel="label" bindValue="value" [items]="companyList" placeholder="Seleccione"
                (keyup)="onKeyUpCompany($event)" (change)="onChangeCompany($event)" (scrollToEnd)="onScrollCompany()"
                [multiple]="true" [(ngModel)]="modelChangeCompy" formControlName="companies_allowed" [disabled]="true">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.label }}">{{ item.label }}</div>
                </ng-template>
              </ng-select>

              <span *ngIf="
                  agendaListForm.get('companies_allowed').touched &&
                  agendaListForm.get('companies_allowed').errors?.required &&
                  agendaListForm.get('companies_allowed').errors != null
                " class="is-invalid red-clr">
                requerida
              </span>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 col-xl-6 col-lg-6 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Duración de cada cita en minutos :</label>
          <label class="mb-0">{{ agendaListItem.minutes }}</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-xl-12 col-lg-12 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Notas :</label>
          <textarea class="form-control" formControlName="comments" [(ngModel)]="agendaListItem.comments"></textarea>
        </div>
      </div>
    </div>
  </form>
  <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 text-right mt-2">
    <button class="btn btn-secondary mr-3" (click)="onCancel()">
      Cancelar
    </button>
    <button class="btn btn-primary mr-3" (click)="onSaveEditDetail($event, agendaListForm)">
      Guardar Cambios Y Cerrar
    </button>
    <button class="btn btn-primary" (click)="onSelectSchedule()">
      Configurar Horarios
    </button>
  </div>
</div>