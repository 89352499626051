export class AgendaRequestData {
    check_in: boolean;
    excel: boolean;
    page_size: number;
    page_number: number;
    centro_id: string;
    agenda_id: number;
    empresa_id: number;
    persona_id: number;
    fecha_desde: any;
    fecha_hasta: any;
    accion_tipo: number;
    accion_subtipo: number;
    puestovs_id: number;
    provincia_id: number;
    poblacion_id: number;
    pago_tipo: number;
    pagado_sino: boolean;
    solicitante: string;
    tarjeta_sanitaria: string;
    programador_id: number;
    comercial_id: number;
    estados: number;
    estados_cita: number;
    barcode: string;
    pagado_importe:number; 
    workcenter_id:number;
    constructor() {
        this.check_in = false;
        this.excel = false;
        this.solicitante = "";
        this.tarjeta_sanitaria = "";
        this.barcode = "";
        this.fecha_hasta = null;
        this.workcenter_id = null;
        this.pagado_importe=0;
    }
}