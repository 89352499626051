import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  public title = new BehaviorSubject('');

  public setTitle(sTitle: string): void {
    this.title.next(sTitle);
  }
}
