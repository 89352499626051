<app-spinner *ngIf="isLoading"></app-spinner>

<header>
  <div class="row user-log-row">
    <div class="col-sm-6 col-md-7 col-lg-8 col-xl-8 s-w-50">
      <nav class="navbar navbar-expand-md navbar-dark fixed-top">
        <div class="logo mr-15">
          <a routerLink="/visualizer/view">
            <img alt="logo" class="logo-icon" src="assets\images\geseme-logo.png" />
          </a>
        </div>
      </nav>
    </div>
    <div class="col-sm-6 col-md-5 col-lg-4 col-xl-4 s-w-50 cerrar-btn text-right">

      <span class="dropdown" id="powerBiMenuItem">

        <span *ngIf="powerBIReportCount == 1" class="analytic-BI-icon"
          (click)="redirectPBI('powerbi/view',powerBIReports[0].id)">
          <img alt="analytic" src="assets\images\menu\icon_powerbi.png" />
        </span>

        <button *ngIf="powerBIReportCount > 1" class="btn dropdown-toggle" type="button" id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span class="power-BI-icon">
            <img alt="contact" src="assets\images\menu\icon_powerbi.png" />
          </span>
        </button>
        <div *ngIf="powerBIReportCount > 1" class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a *ngFor="let report of powerBIReports" class="dropdown-item"
            (click)="redirectPBI('powerbi/view',report.id)">{{report.name}}</a>

        </div>
      </span>
      <span class="pending-contacts" (click)="redirect('contactos/view')" id="contactsMenuItem">
        <img alt="contact" src="assets\images\menu\Icon_Contact.png" />
        <div class="contact-bottomright" *ngIf="notificationCount > 0">{{notificationCount}}</div>
      </span>
      <a (click)="logout()" class="link-btn">
        <span class="fa fa-sign-out" aria-hidden="true"></span>
        Cerrar Sesión
      </a>
    </div>
  </div>
</header>