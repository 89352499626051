import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiService } from '../shared/api.service';
import API from '../../helpers/api.routes.json';
@Injectable({
  providedIn: 'root'
})
export class PowerbiService {

  companyIdSubject = new Subject();
  constructor(private apiService: ApiService) { }

  public getEmbededInfo(reportId:number,userId:string): Observable<any> {
    const url = `${environment.powerbiApi}${API.powerbi.getEmbededInfo+"/"+userId+"/" + reportId}`;
    return this.apiService.get(url);
  }
  public getEmbededInfoReport2(): Observable<any> {
    const url = `${environment.powerbiApi}${API.powerbi.getEmbededInfoReport2}`;
    return this.apiService.get(url);
  }

  public getAllReports(): Observable<any> {
    const url = `${environment.powerbiApi}${API.powerbi.getAllReports}`;
    return this.apiService.get(url);
  }
 
}
