<app-spinner *ngIf="isLoading"></app-spinner>
<div class="agendas-breadcrumb">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" aria-current="page">Listado de agendas</li>
    </ol>
  </nav>
</div>
<div id="scrollbar" class="agendas-content">
  <div class="row">
    <div class="col-md-7 col-xl-8 col-lg-8 col-sm-12"></div>
    <div class="col-lg-4 col-xl-4 col-md-5 col-sm-12 text-right pr-1">
      <button
        class="btn btn-primary mr-3"
        type="submit"
        (click)="onSaveAgenda()"
      >
        Añadir Agenda Nueva
      </button>
    </div>
  </div>
  <div class="mt-3">
    <table class="table tbl-visualizer-citars">
      <thead class="thead-light">
        <tr>
          <th>Nombre</th>
          <th>Centro</th>
          <th>Tipo de Agenda</th>
          <th></th>
          <th>Editar</th>
          <th>Eliminar</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of angendaDetails">
          <td (click)="onEdit(item)">{{ item.nombre }}</td>
          <td (click)="onEdit(item)">{{ item.centro_txt }}</td>
          <td (click)="onEdit(item)">{{ item.accion_tipo_txt }}</td>
          <td (click)="onEdit(item)">{{ item.accion_tipo_comment }}</td>
          <td class="text-center">
            <a class="pb-2" [hidden]="!item.editar" (click)="onEdit(item)">
              <span class="fa fa-edit tbl-row-action"></span>
            </a>
          </td>
          <td>
            <a
              class="pb-2"
              [hidden]="!item.eliminar"
              (click)="onDeleteAgenda(item)"
            >
              <span class="fa fa-close tbl-row-action"></span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
