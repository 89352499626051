<app-spinner *ngIf="isLoading"></app-spinner>
<form [formGroup]="agendaTimeSlotForm">
  <div class="row">
    <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12 pr-2">
      <div class="form-group">
        <label class="mb-0">Centro médico</label>
        <ng-select [clearAllText]="'limpiar todo'" (clear)="onCloseCentro()" [virtualScroll]="true"
          loadingText="Cargando..." notFoundText="No hay resultados" id="centro_Id" name="centro_Id" [items]="centerList"
          bindLabel="label" bindValue="value" placeholder="Seleccione" formControlName="centro_Id" [searchable]="true"
          [readonly]="isCentroDissable" [class.ng-invalid]="
              this.validateProp?.centro_Id &&
              agendaTimeSlotForm.controls.centro_Id.value == null
            " [(ngModel)]="selectedValue" (keyup)="onKeyUpCenter($event)" (scrollToEnd)="onScrollCenter()"
          (change)="changeLeagueOwner($event)">
          <ng-template ng-option-tmp let-item="item">
            <div title="{{ item.label }}">{{ item.label }}</div>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="col-lg-4 col-xl-4 col-md-3 col-sm-12 date-div">
      <div class="form-group">
        <label class="mb-0">Fecha</label>
        <input [owlDateTime]="dateAppointment" [owlDateTimeTrigger]="dateAppointment" type="text"
          class="form-control form-control-sm date-field" formControlName="dateAppointment" id="dateAppointment"
          name="dateAppointment" [class.is-invalid]="
              this.validateProp?.dateAppointment &&
              (agendaTimeSlotForm.controls.dateAppointment.value == null ||
                agendaTimeSlotForm.controls.dateAppointment.value == '')
            " />
        <owl-date-time #dateAppointment [pickerType]="'calendar'" [firstDayOfWeek]="1"></owl-date-time>
      </div>
    </div>
    <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12">
      <div class="form-group d-grid">
        <label class="mb-0">Hora</label>
        <input class="time-input" type="time" name="timeAppointment" formControlName="timeAppointment" [class.ng-invalid]="
              this.validateProp?.timeAppointment &&
              (agendaTimeSlotForm.controls.timeAppointment.value == null ||
                agendaTimeSlotForm.controls.timeAppointment.value == '')
            " />
      </div>
    </div>
    <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12 text-right mt-3 saveButton">
      <div class="form-group d-grid">
        <ng-content select="[saveButton]"></ng-content>      
      </div>
    </div>
    <div class="col-lg-3 col-xl-3 col-md-3 col-sm-12 text-right mt-3 savenewButton">
      <div class="form-group d-grid">
        <ng-content select="[savenewButton]"></ng-content>
      </div>
    </div>

  </div>
  <ng-content></ng-content>
  <div class="row">
    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12" *ngIf="comments">
      <p>Notas : {{ comments }}</p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 position-relative">
      <div class="position-absolute left-btn">
        <button [disabled]="monthIdentity==0" class="btn pl-0 mr-2" (click)="onWeekChange(1)">
          <i class="fa fa-backward"></i>
        </button>
        <button [disabled]="weekIdentity==0"class="btn mr-2" (click)="onWeekChange(0)">
          <i class="fa fa-caret-left"></i>
        </button>
      </div>
      <div class="position-absolute right-btn">
        <button class="btn mr-2" (click)="onWeekChange(2)">
          <i class="fa fa-caret-right"></i>
        </button>
        <button class="btn mr-2" (click)="onWeekChange(3)">
          <i class="fa fa-forward"></i>
        </button>
      </div>

      <full-calendar
        #calendar
        deepChangeDetection="true"
        [options]="calendarOptions"
      ></full-calendar>
    </div>
  </div>
</form>
