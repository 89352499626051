import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup,FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import { EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { dniValidator } from 'src/app/helpers/validators/dniValidator';
import { emailPattern } from 'src/app/helpers/validators/emailPattern';
import { RequestData } from 'src/app/model/shared/request-data';
import { SelectOptions } from 'src/app/model/shared/select-options.model';
import { AddNewWorkerComponent } from 'src/app/modules/agendar-nuevas-citas/add-new-worker/add-new-worker.component';
import { CommonService } from 'src/app/service/common/common.service';
import { DropdownService } from 'src/app/service/shared/dropdown.service';
import { UIService } from 'src/app/service/shared/ui.service';


@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.scss']
})
export class EmployeeEditComponent {
  datosPersonalesFormIsEditable: boolean = false;
  postEmpressaObject = {};
  submitted: boolean = false;
  @Input() personId: number;
  @Input() inputCompanyId: number;
  @Input() id_centro_trabajo: number;
  @Input() tel1: string;
  @Output() personDataEmit: EventEmitter<any> = new EventEmitter();
  isLoading: boolean = false;
  editarButtonIsDissable: boolean =false;  
  EditDate: any;
 datosPersonalesForm = new FormGroup({
    id: new FormControl(''),
    id_trabajador: new FormControl(''),
    apellidos: new FormControl(''),
    nombre: new FormControl(''),
    nip: new FormControl('',[dniValidator()]),
    email1: new FormControl('',[Validators.required,Validators.pattern(emailPattern())]),
    tel1: new FormControl('',[Validators.minLength(9),Validators.maxLength(12)]),
    fecha_nacimiento: new FormControl('',Validators.required),
    language_id: new FormControl('',Validators.required),
    genero: new FormControl('',[Validators.required]),
    comentarios: new FormControl(''),
    id_centro_trabajo: new FormControl(null),
    empresa_id: new FormControl(''),
    is_edit:new FormControl(0),
    id_centro_trabajo_name: new FormControl(null),
  });

  chkPassport = false;
  workCentersRequestData = {
    page_number: 1,
    page_size: 25,
    type: null,
    relatedId: null,
    incl: null
  }

  workCenterList: SelectOptions[] = [];  
  actionSubTypesList: SelectOptions[] = [];
  puestosVSList: SelectOptions[] = [];  
  languageList: SelectOptions[] = [];  
  idioma_txt = '';
  isDisabledPassport : boolean =true;
  chkPassportBox=false;  

  constructor(  private modalService: NgbModal,
    private _visualizerCitarService: CommonService,
    public uiService: UIService,
    public frmBuild :FormBuilder,
    private dropdownService: DropdownService,
    ) {

    }
  onOpenAddWorker() {
    const requestData = {
      persona_id: this.datosPersonalesForm.get('id').value,
    }
    this._visualizerCitarService.getPerson(requestData).subscribe(res => {
      if (res) {
        let empresaobj =res.data?.empresas[0];

        this.datosPersonalesForm.patchValue({
            empresa_id:this.inputCompanyId,
            id_centro_trabajo:empresaobj?.sedes[0].empresa_sede_id,
            is_edit:1,
          })
        const modalRef = this.modalService.open(AddNewWorkerComponent, { centered: true, size: 'lg' });
        modalRef.componentInstance.presonaData = this.datosPersonalesForm.value;
        modalRef.componentInstance.selectedCompanyRec = this.datosPersonalesForm.value.empresa_id;
        modalRef.result.then(_close => { 
          this.isLoading = false;
          this.getPerson(this.datosPersonalesForm.get('id').value)
          this.datosPersonalesForm.updateValueAndValidity();
        });
      }
    });

  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.personId.currentValue > 0) {
      this.editarButtonIsDissable = false;
      this.getPerson(this.personId);
    }
    else {
      this.editarButtonIsDissable = true;
    }
  }   

  public getPerson(persona_id: number) {
    this.isLoading = true;
    this.getLanguageList().subscribe(()=>{
      const requestData = {
        persona_id: persona_id,
      }
      this._visualizerCitarService.getPerson(requestData).subscribe(res => {
        if (res) {
          this.personDataEmit.emit(res.data);
          this.datosPersonalesForm.patchValue(res.data);
          this.preparePersonaBindingData(res.data);
          this.postEmpressaObject = res.data.empresas;

          this.languageList.forEach(element => {
            if(element.value == res.data.language_id){
              this.idioma_txt = element.label;
            }
          });
          this.isLoading = false;
        }
      }, error => {
        console.log(error);
        this.isLoading = false;
      });
    })
  }

  
  preparePersonaBindingData(personData) {
    if (personData != null) {
      if (personData.fecha_nacimiento?.length == 8) {
        const day = personData.fecha_nacimiento?.substr(6, 8);
        const month = personData.fecha_nacimiento?.substr(4, 2);
        const year = personData.fecha_nacimiento?.substr(0, 4);
        
        if (day != undefined && month != undefined && year != undefined) {
          let date = day + '/' + month + '/' + year;
          personData.fecha_nacimiento = date;
        }
        this.datosPersonalesForm.controls.fecha_nacimiento.setValue(personData.fecha_nacimiento);
      }
      if (personData?.empresas[0]?.sedes?.length > 0 && !this.datosPersonalesFormIsEditable) {
        this.datosPersonalesForm.controls.id_centro_trabajo.setValue(personData?.empresas[0]?.sedes[0].empresa_sede_txt);
        this.datosPersonalesForm.controls.id_centro_trabajo_name.setValue(personData?.empresas[0]?.sedes[0].empresa_sede_txt);
      }
      if (personData?.empresas[0]?.sedes?.length > 0 && this.datosPersonalesFormIsEditable) {
        this.datosPersonalesForm.controls.id_centro_trabajo.setValue(personData?.empresas[0]?.id);
        this.datosPersonalesForm.controls.id_centro_trabajo_name.setValue(personData?.empresas[0]?.id);
      }
      if (personData?.language_id == 0) {
        personData.language_id = null;
        this.datosPersonalesForm.controls.language_id.setValue(personData.language_id);
      }
      else if (personData?.language_id > 0) {
        this.languageList.forEach(element => {
          if (element.value == personData.language_id) {
            this.idioma_txt = element.label;
          }
        });
      }
    }
  }

  getLanguageList() {
    const requestData = new RequestData();
    requestData.type = null;
    return this._visualizerCitarService.getLanguages(requestData).pipe(map((res) => {
      if (res) {
        this.languageList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');
      }
    }),
      catchError((_err, _caught) => {
        this.isLoading = false;
        return EMPTY;
      }));
  }

  onEditarDatosClick() {
    this.datosPersonalesFormIsEditable = !this.datosPersonalesFormIsEditable;
    if (this.postEmpressaObject[0]?.sedes?.length > 0 && !this.datosPersonalesFormIsEditable) {
      this.datosPersonalesForm.controls.id_centro_trabajo.setValue(this?.postEmpressaObject[0]?.sedes[0].empresa_sede_txt);
      this.datosPersonalesForm.controls.id_centro_trabajo_name.setValue(this?.postEmpressaObject[0]?.sedes[0].empresa_sede_txt);
    }
    if (this?.postEmpressaObject[0]?.sedes?.length > 0 && this.datosPersonalesFormIsEditable) {
      this.datosPersonalesForm.controls.id_centro_trabajo.setValue(this.postEmpressaObject[0]?.sedes[0].empresa_sede_id);
      this.datosPersonalesForm.controls.id_centro_trabajo_name.setValue(this.postEmpressaObject[0]?.sedes[0].empresa_sede_id);
    }
    if (this.datosPersonalesFormIsEditable) {
      this.getRelatedWorkCentersList(this.postEmpressaObject[0].id);
      this.chkPassport = false;
      this.datosPersonalesForm.controls.nip.setValidators(dniValidator());
      this.datosPersonalesForm.controls.nip.updateValueAndValidity();
    }
    if (this.datosPersonalesForm.controls.fecha_nacimiento.value.length == 10) {
      this.EditDate = moment(this.datosPersonalesForm.controls.fecha_nacimiento.value, "DD/MM/yyyy");
    }
    this.handlePasaporteCheckBox();
  }
  
  prepareEmployeePostData(personData) {
    if (personData != null) {
      personData.fecha_nacimiento = personData.fecha_nacimiento.format('yyyyMMDD').toString();
    }
    if (this?.postEmpressaObject[0]?.sedes?.length > 0 && this.datosPersonalesFormIsEditable) {
      this.postEmpressaObject[0].sedes[0].empresa_sede_id = this.datosPersonalesForm.controls.id_centro_trabajo.value;
    }
    personData["empresas"] = this.postEmpressaObject;
    return personData;
  }

  saveEmployee() {
    this.submitted = true;
    if (this.datosPersonalesForm.valid) {
      this.chkPassport = false;
      this.isLoading = true;
      let postData = this.datosPersonalesForm.value;
      postData = this.prepareEmployeePostData(postData);
      this._visualizerCitarService.updatePerson(postData).subscribe(res => {
        if (res && res?.webError?.number == 0) {
          this.uiService.showFailToast("Error", res?.webError?.messageToUser);
        }
        else if (res && res?.data != null) {
          this.datosPersonalesFormIsEditable = !this.datosPersonalesFormIsEditable;
          this.datosPersonalesForm.patchValue(res.data)
          this.preparePersonaBindingData(res.data);
          this.uiService.showSuccessToast("Guardado con éxito");
        }
        this.isLoading = false;
      }, error => {
        console.log(error);
        this.isLoading = false;
      });
    }
  }

  get datosPersonalesFormControl() {
    return this.datosPersonalesForm.controls;
  }

  getRelatedWorkCentersList(companyId?: number, isScroll = false) {
    this.isLoading = true;
    this.workCentersRequestData.page_number = isScroll ? (this.workCentersRequestData.page_number + 1) : 1;
    if (companyId > 0) {
      this.workCentersRequestData.relatedId = companyId;
      this._visualizerCitarService.getWorkCenterList(this.workCentersRequestData).subscribe(res => {
        this.isLoading = false;
        if (res) {
          if (isScroll) {
            this.workCenterList = this.workCenterList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'empresa_sede', 'id', false, false));
          } else {
            this.workCenterList = this.dropdownService.WrappedToDropDownList(res.data, 'empresa_sede', 'id', false, false);
          }
        }
      }, error => {
        this.isLoading = false;
        console.log(error);
      });
    }
  }

  onChangeChkPassport($event) {
    this.chkPassport = $event.target.checked; 
    if(this.chkPassport){
      this.datosPersonalesForm.controls.nip.clearValidators();
      this.datosPersonalesForm.controls.nip.updateValueAndValidity();
    }
    else if(!this.chkPassport){
      this.datosPersonalesForm.controls.nip.setValidators(dniValidator);
      this.datosPersonalesForm.controls.nip.updateValueAndValidity();
    }
  }

  handlePasaporteCheckBox() {
    const selectedCompany = this.inputCompanyId;
    this.chkPassportBox = false;
    this.chkPassport = false;
    this.isDisabledPassport = true;

    if (selectedCompany == 1) {
      this.chkPassportBox = false;
      this.isDisabledPassport = false;
    }
  }
  
}
