import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuList } from 'src/app/helpers/enums/menu-list';
import { UIService } from 'src/app/service/shared/ui.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {
  public menuListEnum = MenuList;

  public menus = [
    {name : '/visualizer/view', value : 0},
    {name : '/agendar-nuevas/view', value : 1},
    {name : '/reception/view', value : 2},
    {name : '/agendar-list/view', value : 3},
    {name : '/contactos/view', value : 5},
    {name : '/agendar-list/schedule', value : 3},
    {name : '/memoria-vs/view', value : 6},
    {name : '/puestosvs', value : 7},
  ]

  constructor(private router: Router, public _uiService: UIService) {
    let locationPathName = location.pathname;
    this.menus.forEach(menu => {
      if(menu.name == locationPathName){
        this._uiService.selectedMenu = menu.value;
      }
    });

    if(this._uiService.selectedMenu == null){
      this._uiService.selectedMenu = 0;
    }
  }

  onSelectMenu(menuId: number) {
    this._uiService.selectedMenu = menuId;
    if (this.menuListEnum.VisualizarCitas == menuId) {
      this.router.navigate(['/visualizer/view']);
    }
    else if (this.menuListEnum.AgendarNuevasCitas == menuId) {
      localStorage.removeItem('cancelStatus')
      localStorage.removeItem('cancelNewData')
      this.router.navigate(['/agendar-nuevas/view']);
    }
    else if (this.menuListEnum.Recepcion == menuId) {
      this.router.navigate(['/reception/view']);
    }
    else if (this.menuListEnum.ListadoDeAgendas == menuId) {
      this.router.navigate(['/agendar-list/view']);
    }
    else if (this.menuListEnum.Valimed == menuId) {
      window.open('validator/view', '_blank');
    }
    else if (this.menuListEnum.Contactos == menuId) {
      this.router.navigate(['/contactos/view']);
    }
    else if (this.menuListEnum.Memoria == menuId) {
      this.router.navigate(['/memoria-vs/view']);
    }
    else if (this.menuListEnum.PuestoVS == menuId) {
      this.router.navigate(['/puestosvs']);
    }
  }

}
