import { Component, EventEmitter, OnInit, Output ,Input} from '@angular/core';
import {AgendaException} from '../../../model/agendar/agenda-exception'
import {AgendaCopyException} from '../../../model/agendar/agenda-copy-exception';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {UIService} from '../../../service/shared/ui.service';
import moment from 'moment';
import { AgendaService } from '../agenda-services/agenda-service';
import { DatePipe } from '@angular/common';
import {AgendaCopyExceptionService} from '../agenda-copy-exception.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-add-exception',
  templateUrl: './add-exception.component.html',
  styleUrls: ['./add-exception.component.scss']
})
export class AddExceptionComponent implements OnInit {
  @Input() public editException;
  @Input() public minites;
  @Output() onAppecept = new EventEmitter();
   angendaExcep: AgendaException =new AgendaException();
   agendaCopyExcep: AgendaCopyException = new AgendaCopyException();
   exceptionForm :FormGroup;
   copyExcepForm :FormGroup;
   showException : boolean;
   isEdit :boolean;
   concurrentText :string

   hasException:boolean;

   hrefvalue:string;

   copyException:boolean= false;
  constructor( 
    public _formBuilder: FormBuilder,
    private modalService: NgbModal,
    private uiService :UIService,
    public agendaService:AgendaService,
    private agendaCopyExceptionService:AgendaCopyExceptionService,
    private datePipe: DatePipe,
    private translateService :TranslateService
    ) 
    {
      this.isEdit =false;
     }
    
    dia_destino= new FormControl('');
  ngOnInit(): void {
    this.hrefvalue = "#nav-copiar";
    this.hasException = this.agendaCopyExceptionService.exceptionAvailability;

    if (this.hasException) {
      this.hrefvalue = "agendar-list/schedule";
    }
    this.agendaCopyExcep.día_destino = this.agendaCopyExceptionService.destino;
    this.agendaCopyExcep.día_destino = new Date(this.agendaCopyExcep.día_destino).toDateString();

    if (this.agendaCopyExcep.día_destino != 'Invalid Date') {
      let result = this.customSpanishDateFormater(this.datePipe.transform(new Date(this.agendaCopyExcep.día_destino), 'EEE d MMM y', 'es-ES'))
      this.dia_destino.setValue(result);
    }

    this.copyExcepForm = this._formBuilder.group({
      día_origen: [""],
      dia_destino: [this.agendaCopyExceptionService.destino != "" ? new Date(this.agendaCopyExceptionService.destino).toISOString() : ""]
    });

    this.exceptionForm = this._formBuilder.group({
      fecha_desde: ["", [Validators.required]],
      hora_desde: ["", [Validators.required]],
      fecha_hasta: ["", [Validators.required]],
      hora_hasta: ["", [Validators.required]],
      concurrentes: ["", [Validators.required, Validators.min(0)]],
      notas: [""],
    });
    this.concurrentText = "Citas concurrentes cada " + this.minites + " minutos";
    this.showException = true;
    if (this.editException != null) {
      this.isEdit = true;
      this.setEditValues(this.editException);
    }

  }

  customSpanishDateFormater(date:any):any
  {
    let spanishDay: string[] = ['LUN', 'MAR', 'MIE','JUE','VIE','SAB','DOM'];
    let spanishMonth: string[] = ['ENE', 'FEB', 'MAR','ABR','MAY','JUN','JUL','AGO','SEPT','OCT','NOV','DIC'];
    let displayDay="";
    let displayMonth = "";
    let splitted = date.split(" "); 
    
    switch (splitted[0]) {
      case 'Sun':
        displayDay = spanishDay[6];
        break;
      case 'Mon':
        displayDay = spanishDay[0];
        break;
      case 'Tue':
        displayDay = spanishDay[1];
        break;
      case 'Wed':
        displayDay = spanishDay[2];
        break;
      case 'Thu':
        displayDay = spanishDay[3];
        break;
      case 'Fri':
        displayDay = spanishDay[4];
        break;
      case 'Sat':
        displayDay = spanishDay[5];
        break;
    }
    switch (splitted[2]) {
      case 'Jan':
        displayMonth = spanishMonth[0];
        break;
      case 'Feb':
        displayMonth = spanishMonth[1];
        break;
      case 'Mar':
        displayMonth = spanishMonth[2];
        break;
      case 'Apr':
        displayMonth = spanishMonth[3];
        break;
      case 'May':
        displayMonth = spanishMonth[4];
        break;
      case 'Jun':
        displayMonth = spanishMonth[5];
        break;
      case 'Jul':
        displayMonth = spanishMonth[6];
        break;
      case 'Aug':
        displayMonth = spanishMonth[7];
        break;
      case 'Sep':
        displayMonth = spanishMonth[8];
        break;
      case 'Oct':
        displayMonth = spanishMonth[9];
        break;
      case 'Nov':
        displayMonth = spanishMonth[10];
        break;
      case 'Dec':
        displayMonth = spanishMonth[11];
        break;
    }
    
    let dateString = "";
    dateString = dateString.concat(displayDay).concat(" ").concat(splitted[1]).concat(" ").concat(displayMonth).concat(" ").concat(splitted[3]); 
    return dateString;
  }
  setEditValues(value:AgendaException){

    this.angendaExcep =new AgendaException();
    let dFecha_hasta = new Date(value.fecha_hasta); 
    let dDayFecha_hasta = dFecha_hasta.getDate();

    let dFecha_desde= new Date(value.fecha_desde); 
    let dDayFecha_desde =dFecha_desde.getDate();
    
    if(dDayFecha_hasta){
      let sSub =  String(value.fecha_hasta);
      let sSplitStr =  sSub.split("/");     
      let nDate = sSplitStr[2]+"-"+sSplitStr[1]+"-"+sSplitStr[0];
      this.angendaExcep.fecha_hasta=  new Date(nDate);
    }    

    if(dDayFecha_desde){
      let sSub =  String(  value.fecha_desde);
      let sSplitStr =  sSub.split("/");     
      let nDate = sSplitStr[2]+"-"+sSplitStr[1]+"-"+sSplitStr[0];
      this.angendaExcep.fecha_desde=  new Date(nDate);
    }
   
    this.angendaExcep.hora_hasta=value.hora_hasta;
    this.angendaExcep.hora_desde=value.hora_desde;
    this.angendaExcep.concurrentes=value.concurrentes;
    this.angendaExcep.notas=value.notas;
  }
  closeExceptionModal(event:Event) {
    event.preventDefault();
    this.showException = false;
    this.hasException = false;
    this.modalService.dismissAll();
  }

  appceptException(event:Event , form){
      if (event || form) {
        if (this.exceptionForm.invalid) {
          Object.keys(this.exceptionForm.controls).forEach(field => {
            const control = this.exceptionForm.get(field);
            control.markAsDirty({ onlySelf: true });
            control.markAsTouched({ onlySelf: true });
          });        
          return;
        }
      }
      
        let isValidateRange= this.uiService.isSameOrAfter(form.value.fecha_desde, form.value.fecha_hasta )
        if(!isValidateRange){
          this.uiService.showWarningMessage("desde la fecha debe ser menor que hasta la fecha");
        return;
        }

        if(form.value.hora_desde>form.value.hora_hasta){
          this.uiService.showWarningMessage("de vez en cuando debe ser menor que a tiempo");
          return
        }  

        let fecha_desde =  moment(form.value.fecha_desde, "DD/MM/YYYY");
        let fecha_hasta =  moment(form.value.fecha_hasta, "DD/MM/YYYY");
        this.angendaExcep.fecha_desde = fecha_desde;
        this.angendaExcep.fecha_hasta= fecha_hasta;

        this.onAppecept.next(this.angendaExcep);
        event.preventDefault();
        this.showException = false;
        this.modalService.dismissAll();
         
              
  }

  appceptCopyException(event: Event, form) {
    this.agendaCopyExceptionService.buttonClicked();
    if (event || form) {
      if (this.copyExcepForm.invalid) {
        Object.keys(this.copyExcepForm.controls).forEach(field => {
          const control = this.copyExcepForm.get(field);
          control.markAsDirty({ onlySelf: true });
          control.markAsTouched({ onlySelf: true });
        });
        return;
      }
    }

    let día_origen = new Date(form.value.día_origen);
    let día_destino = form.value.dia_destino;
    this.agendaCopyExcep.día_origen = día_origen;
    this.agendaCopyExcep.día_destino = día_destino;
    this.agendaCopyExceptionService.setException(this.agendaCopyExcep);
    event.preventDefault();
    this.showException = false;
    this.modalService.dismissAll();
  }

  closeCopyExceptionModal(event: Event) {
    event.preventDefault();
    this.hasException = false;
    this.modalService.dismissAll();
  }

  
}
