import { Component, Input, OnInit } from '@angular/core';
import { Agenda } from 'src/app/model/agendar/agenda';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Component({
  selector: 'app-visualizer-citars-print-document',
  templateUrl: './visualizer-citars-print-document.component.html',
  styleUrls: ['./visualizer-citars-print-document.component.scss']
})

export class VisualizerCitarsPrintDocumentComponent {
  datePipe = new DatePipe('en-US');
  @Input() appointmentData:Agenda;
  @Input() allAppointmentData;
  @Input() covidAppoimnet:any;
  @Input() testType:any;
  @Input() Sedes:string;
  @Input() personTelNo:string;

  
  convertDate(covidAppoimnetDate: any) {
    if (covidAppoimnetDate !== undefined && covidAppoimnetDate != null) {
      if (covidAppoimnetDate.length == 8) {
        let day = covidAppoimnetDate?.substr(6, 8);
        let month = covidAppoimnetDate?.substr(4, 2);
        let year = covidAppoimnetDate?.substr(0, 4);
        if (day != undefined && month != undefined && year != undefined) {
          return day + '/' + month + '/' + year;
        }
      }
      else {
        return covidAppoimnetDate;
      }
    }
  }

  getDayOfDate(dateValue :any):any{
    if (dateValue!=null) {
      //let newDate = new Date(dateValue);
      return dateValue?.substr(0, 2);
    }
  }

  getMonthOfDate(dateValue :any):any {
    return dateValue?.substr(3, 2);
  }

  getYearOfDate(dateValue :any):any {
    return dateValue?.substr(6, 4);
  }

  getDateCovidReport(dateValue :any):any {
    let month = dateValue?.substr(3, 2);
    let year = dateValue?.substr(6, 4);
    let day = dateValue?.substr(0, 2);
    return day + '/' + month + '/' + year;
  }

  convertDateToReadableFormat(date: any) {
    if (date != null) {

      if (date.length == 8) {
        let day = date?.substr(6, 8);
        let month = date?.substr(4, 2);
        let year = date?.substr(0, 4);
        if (day != undefined && month != undefined && year != undefined) {
          return day + '/' + month + '/' + year;
        }
      } else if (date.length == 10) {
        return date?.substr(0, 10);
      }
    }
  }

  calculateAge(birthday: any) {
    if (birthday != null) {
      let year = birthday.substring(0, 4);
      let month = birthday.substring(4, 6);
      let day = birthday.substring(6, 8);

      let birthDayFormat = year + '/' + month + '/' + day;
      let today = new Date();
      let birthDate = new Date(birthDayFormat);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return isNaN(age) ? '' : age;
    }
  }

  getHora(hora:any){ 
    if(hora?.trim().length == 4 || hora?.trim().length == 6){
      const h = hora?.substr(0, 2);
      const m = hora?.substr(2, 2);
      return h+':'+m;
    }
  }
  
  getFormattedTodayDate(){
    return moment(new Date()).format('DD/MM/yyyy');
  }
}
