import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisualizerCitarsComponent } from './visualizer-citars.component';
import { VisualizerCitarsRoutingModule } from './visualizer-citars-routing.module'
import { SharedModule } from '../shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { OwlDateTimeModule } from '@busacca/ng-pick-datetime';
import { VisualizerCitarEditComponent } from '../visualizer-citars/visualizer-citar-edit/visualizer-citar-edit.component';
import { RescheduleAppointmentPopUpComponent } from './reschedule-appointment-pop-up/reschedule-appointment-pop-up.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CovidScreenComponent } from './covid-screen/covid-screen.component';
import { CovidReportPopupComponent } from './covid-report-popup/covid-report-popup/covid-report-popup.component';
import {VisualizerDataService } from './visualizer-citar-report-service/visualizer-citar-report-service';


@NgModule({
  declarations: [
    VisualizerCitarsComponent,
    VisualizerCitarEditComponent,
    RescheduleAppointmentPopUpComponent,
    CovidScreenComponent,
    CovidReportPopupComponent],
  imports: [
    CommonModule,
    VisualizerCitarsRoutingModule,
    SharedModule,
    NgxPaginationModule,
    OwlDateTimeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers:[
    VisualizerDataService
  ]

})
export class VisualizerCitarsModule { }
