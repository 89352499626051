import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Appointments } from '../../../model/agendar/appointments';

const behaviorSubjectAppoiment = new BehaviorSubject<Appointments>(
  new Appointments()
);

@Injectable()
export class VisualizerDataService {
  public appointmentEditData: any[];
  public isCancelAppointment: boolean = false;

  private appoiment = behaviorSubjectAppoiment;
  public appoimentDetail = this.appoiment.asObservable();
  setAppoiment(appoimentParam: Appointments) {
    this.appoiment.next(appoimentParam);
  }

  public setAppointmentEditData(appointmentData) {
    this.appointmentEditData = appointmentData;
  }

  public setIsCancelAppointment(status: boolean) {
    this.isCancelAppointment = status;
  }

}