import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { UIService } from '../../../service/shared/ui.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WeekSlots } from '../../../model/agendar/week_slots'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-edit-appointment-timeframes',
  templateUrl: './edit-appointment-timeframes.component.html',
  styleUrls: ['./edit-appointment-timeframes.component.scss']
})
export class EditAppointmentTimeframesComponent implements OnInit {
  @Input() public newTimeFram;
  @Input() public editTimeFrame;
  @Input() public minites;
  @Output() onApceptTimeSlot = new EventEmitter();
  timeSlotForm: FormGroup;
  weekTimeSlot: WeekSlots = new WeekSlots();
  days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  concurrentText: string;

  constructor(public _formBuilder: FormBuilder,
    private modalService: NgbModal,
    private uiService: UIService,
    private translateService: TranslateService) {
    this.timeSlotForm = this._formBuilder.group({
      num_dia: [""],
      slot_desde: ["", [Validators.required]],
      slot_hasta: ["", [Validators.required]],
      concurrentes: ["", [Validators.required, Validators.min(0)]],
      notas: [""],
    });
  }

  ngOnInit(): void {

    this.concurrentText = "Citas concurrentes cada " + this.minites + " minutos";
    if (this.newTimeFram != null) {

      this.setNewTimeSlot(this.newTimeFram)
    }
  }

  setNewTimeSlot(value: WeekSlots) {

    this.weekTimeSlot = new WeekSlots();
    this.weekTimeSlot.num_dia = value.num_dia;
    this.weekTimeSlot.slot_desde = value.slot_desde;
    this.weekTimeSlot.slot_hasta = value.slot_hasta;
    if (value.concurrentes != undefined) {
      this.weekTimeSlot.concurrentes = value.concurrentes;
    }
    this.weekTimeSlot.notas = value.notas;
    this.weekTimeSlot.slotDate = value.slotDate;
    this.weekTimeSlot.isEdit = value.isEdit;
    this.weekTimeSlot.aIndex = value.aIndex;
    this.weekTimeSlot.isRemove = false;

  }
  closeTimeFrameModal(event: Event) {
    event.preventDefault();
    this.modalService.dismissAll();
  }

  removeTimeFrame(event: Event) { 

    if (!this.weekTimeSlot.isEdit) {
      this.uiService.showWarningMessage("El espacio de la semana no existe en la lista")
      return;
    }
    Swal.fire({
      title: '',
      text: this.translateService.instant('common.deleteMessage'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('common.yes'),
      cancelButtonText: this.translateService.instant('common.no')
    }).then((result) => {
      if (result.value) {
        this.weekTimeSlot.isRemove = true;
        this.onApceptTimeSlot.next(this.weekTimeSlot);
        event.preventDefault();
        this.modalService.dismissAll();
      } 
    })


  }
  aceptTimeFame(event: Event, form) {
    if (event || form) {
      if (this.timeSlotForm.invalid) {
        Object.keys(this.timeSlotForm.controls).forEach(field => {
          const control = this.timeSlotForm.get(field);
          control.markAsDirty({ onlySelf: true });
          control.markAsTouched({ onlySelf: true });
        });
        return;
      }

      if (this.weekTimeSlot == undefined || this.weekTimeSlot == null) {
        return;
      }


      if (form.value.slot_desde > form.value.slot_hasta) {
        this.uiService.showWarningMessage("de hora debe ser menor que a hora");
        return
      }


      this.weekTimeSlot.num_dia = this.getDayID(this.weekTimeSlot.slotDate);
      if (!this.weekTimeSlot.isEdit) {
        if (this.isOverLapTimeSlot(this.weekTimeSlot, this.editTimeFrame)) {
          return;
        }
      }

      this.onApceptTimeSlot.next(this.weekTimeSlot);
      event.preventDefault();
      this.modalService.dismissAll();
    }
  }

  isOverLapTimeSlot(current: WeekSlots, exists: WeekSlots[]): boolean {
    if (exists != undefined) {
      let itemIndex = exists.findIndex(x => x.slot_desde == current.slot_desde &&
        x.slot_hasta == current.slot_hasta);

      let item = exists[itemIndex];
      if (item) {
        return true;
      }
      return false
    }
    else {
      return false;
    }
  }

  getDayID(aDate: any): number {
    let currentDate = new Date(aDate);
    let dayID: number;
    let dayName = this.days[currentDate.getDay()];
    switch (dayName) {
      case "Sunday":
        dayID = 7;
        break;
      case "Monday":
        dayID = 1;
        break;
      case "Tuesday":
        dayID = 2;
        break;
      case "Wednesday":
        dayID = 3;
        break;
      case "Thursday":
        dayID = 4;
        break;
      case "Friday":
        dayID = 5;
        break;
      case "Saturday":
        dayID = 6;
        break;
      default:
        dayID = 0;
        break;
    }
    return dayID;
  }



}
