<app-spinner *ngIf="isLoading"></app-spinner>
<div class="modal-body">
  <div class="row pl-3 pb-3">
    <div class="col-md-10 text-left pl-0"></div>
    <div class="col-md-2">
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="modal.dismiss()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
  <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 pb-3">
    <div class="row">
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
        <div class="form-group">
          <label class="mb-0 mr-1">Agenda :</label>
          <label class="mb-0 text-uppercase">{{
            this.agenda?.attribs?.agenda_txt
          }}</label>
        </div>
      </div>
    </div>

    <div class="calender-box px-0">
      <app-reusable-calendar
        [agendaId]="this.agenda?.attribs?.agenda_id"
        [isEdit]="1"
        [centroId]="this.agenda?.attribs?.centro_id"
        [timeAppointment]="this.agenda?.attribs?.hora"
        [dateAppointment]="this.agenda?.attribs?.fecha"
        (editedAgenda)="getAgendaTimeSlotData($event)"
        [validateProp]="{
          centro_Id: validateFormControl('centro_Id'),
          dateAppointment: validateFormControl('dateAppointment'),
          timeAppointment: validateFormControl('timeAppointment')
        }"
      ></app-reusable-calendar>
    </div>
  </div>

  <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 text-right mt-2">
    <button
      class="btn btn-secondary mr-3"
      type="reset"
      (click)="modal.dismiss()"
    >
      Cancelar
    </button>
    <button class="btn btn-primary" (click)="saveTimeSlot()" type="button">
      OK
    </button>
  </div>
</div>
