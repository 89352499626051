<app-spinner *ngIf="isLoading"></app-spinner>
<div id="covid-screen-comp" class="container-fluid px-0">
  <div class="header-div text-center">
    <label
      >{{ this.appointmentEditData?.attribs?.tipo_txt }} de
      {{ this.appointmentEditData.persona.apellidos }},
      {{ this.appointmentEditData.persona.nombre }}, fecha
      <span>{{ this.fecha }}</span></label
    >
  </div>
  <div id="scrollbar" class="agendas-content pt-2">
    <div class="row">
      <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
        <label class="mb-1"
          >Tipo Prueba :
          {{ this.appointmentEditData?.attribs?.subtipo_txt }}</label
        >
      </div>
      <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 text-right">
        <button class="btn btn-primary" (click)="saveCovidDetails(true)">
          Guardar y salir
        </button>
      </div>
    </div>

    <div class="edit-cita-section">
      <form
        [formGroup]="covidForm"
        (ngSubmit)="saveCovidDetails()"
        id="datosPersonalesForm"
      >
        <!-- Datos Personales Section -->
        <div class="row mb-1">
          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="mb-0 font-weight-bold">Datos Personales : </label>
            </div>
          </div>
          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 text-right"></div>
        </div>
        <table class="edit-cita-tbl">
          <tbody>
            <tr>
              <td>
                <span class="font-weight-bold">Nombre : </span>
                {{ this.appointmentEditData.persona.nombre }}
              </td>
              <td>
                <span class="font-weight-bold">Telf : </span>
                {{ this.appointmentEditData.persona.telefono }}
              </td>
              <td>
                <span class="font-weight-bold">Email : </span>
                {{ this.appointmentEditData.persona.email }}
              </td>
            </tr>
            <tr>
              <td>
                <span class="font-weight-bold">Apellidos : </span
                >{{ this.appointmentEditData.persona.apellidos }}
              </td>
              <td>
                <span class="font-weight-bold">DNI : </span
                >{{ this.appointmentEditData.persona.dni }}
              </td>
              <td>
                <span class="font-weight-bold">Fecha Nacimiento : </span
                >{{ this.appointmentEditData.persona.birth_date }}
              </td>
            </tr>
            <tr>
              <td>
                <span class="font-weight-bold">Género : </span>
                {{
                  this.appointmentEditData.persona.gender == "H"
                    ? "Hombre"
                    : this.appointmentEditData.persona.gender == "M"
                    ? "Mujer"
                    : ""
                }}
              </td>
              <td rowspan="0">
                <span class="font-weight-bold">Notas : </span>
                {{ this.appointmentEditData.persona.comments }}
              </td>
            </tr>
            <tr>
              <td>
                <span class="font-weight-bold">Idioma : </span
                >{{ this.appointmentEditData.persona.language_txt }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row mt-3">
          <!-- left panel -->
          <div class="col-lg-5 col-xl-5 col-md-12 col-sm-12">
            <!-- PCR section -->
            <div class="covid-test-div" *ngIf="pcr">
              <div class="header-div">
                <label>PCR</label>
                <button
                  class="btn covid-test-print-btn"
                  type="button"
                  (click)="onPrint('PR05A57_RAPID_ANT_CAS', 'pcr')"
                >
                  Imprimir
                  <span class="fa fa-print"></span>
                </button>
              </div>
              <div class="row pt-3">
                <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12">
                  <label class="mb-0">Proveedor</label>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12 p-l-19">
                  <div class="form-group">
                    <ng-select
                      #selectPCRProveedor
                      (close)="selectPCRProveedor.blur()"
                      loadingText="Cargando..."
                      notFoundText="No hay resultados"
                      id="poblacion"
                      name="poblacion"
                      bindLabel="label"
                      bindValue="value"
                      [items]="PCRProveedorList"
                      placeholder="Seleccione"
                      formControlName="pcr_proveedor_id"
                    >
                      <ng-template ng-option-tmp let-item="item">
                        <div title="{{ item.label }}">{{ item.label }}</div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Lote"
                    formControlName="pcr_lote"
                  />
                </div>
              </div>
              <div class="row px-3">
                <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12">
                  <label class="mb-0">Resultado</label>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12 pl-0">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="pcr_resultado"
                  />
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12 text-right">
                  <button class="btn btn-primary" type="button">
                    Cargar de Laboratorio
                  </button>
                </div>
              </div>
              <div class="row px-3 pt-3">
                <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12">
                  <label class="mb-0">Diagnóstico</label>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12 pl-0">
                  <div class="form-group">
                    <ng-select
                      #selectPCRDiagnostics
                      (close)="selectPCRDiagnostics.blur()"
                      loadingText="Cargando..."
                      notFoundText="No hay resultados"
                      id="poblacion"
                      name="poblacion"
                      bindLabel="label"
                      bindValue="value"
                      [items]="PCRDiagnosticsList"
                      placeholder="Seleccione"
                      formControlName="pcr_diagnostico_id"
                    >
                      <ng-template ng-option-tmp let-item="item">
                        <div title="{{ item.label }}">{{ item.label }}</div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12"></div>
              </div>
            </div>
            <!-- Trag section -->
            <div class="covid-test-div mt-2" *ngIf="trag">
              <div class="header-div">
                <label>TRAG</label>
                <button
                  class="btn covid-test-print-btn"
                  type="button"
                  (click)="onPrint('PR05A57_RAPID_ANT_CAS', 'trag')"
                >
                  Imprimir
                  <span class="fa fa-print"></span>
                </button>
                <button
                  class="btn covid-test-print-btn"
                  type="button"
                  (click)="tragExternalPrint()"
                  [hidden]="!this.tragPrintObj.isPrint"
                >
                  {{ this.tragPrintObj.buttonText }}
                  <span class="fa fa-print"></span>
                </button>
              </div>
              <div class="row pt-3">
                <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12">
                  <label class="mb-0">Proveedor</label>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12 p-l-19">
                  <div class="form-group">
                    <ng-select
                      #selectTRAGProveedor
                      (close)="selectTRAGProveedor.blur()"
                      loadingText="Cargando..."
                      notFoundText="No hay resultados"
                      id="poblacion"
                      name="poblacion"
                      bindLabel="label"
                      bindValue="value"
                      [items]="TRAGProveedorList"
                      placeholder="Seleccione"
                      formControlName="trag_proveedor_id"
                    >
                      <ng-template ng-option-tmp let-item="item">
                        <div title="{{ item.label }}">{{ item.label }}</div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Lote"
                    formControlName="trag_lote"
                  />
                </div>
              </div>
              <div class="row px-3">
                <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12">
                  <label class="mb-0">Diagnóstico</label>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12 pl-0">
                  <div class="form-group">
                    <ng-select
                      #selectTRAGDiagnostics
                      (close)="selectTRAGDiagnostics.blur()"
                      loadingText="Cargando..."
                      notFoundText="No hay resultados"
                      id="poblacion"
                      name="poblacion"
                      bindLabel="label"
                      bindValue="value"
                      [items]="TRAGDiagnosticsList"
                      placeholder="Seleccione"
                      formControlName="trag_diagnostico_id"
                    >
                      <ng-template ng-option-tmp let-item="item">
                        <div title="{{ item.label }}">{{ item.label }}</div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12"></div>
              </div>
            </div>
            <!-- Serología Section -->
            <div class="covid-test-div mt-2" *ngIf="sero">
              <div class="header-div">
                <label>Serología</label>
                <button
                  class="btn covid-test-print-btn"
                  type="button"
                  (click)="onPrint('PR05A57_RAPID_ANT_CAS', 'sero')"
                >
                  Imprimir
                  <span class="fa fa-print"></span>
                </button>
              </div>
              <div class="row pt-3">
                <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12">
                  <label class="mb-0">Proveedor</label>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12 p-l-19">
                  <div class="form-group">
                    <ng-select
                      #selectSEROProveedor
                      (close)="selectSEROProveedor.blur()"
                      loadingText="Cargando..."
                      notFoundText="No hay resultados"
                      id="poblacion"
                      name="poblacion"
                      bindLabel="label"
                      bindValue="value"
                      [items]="SEROProveedorList"
                      placeholder="Seleccione"
                      formControlName="sero_proveedor_id"
                    >
                      <ng-template ng-option-tmp let-item="item">
                        <div title="{{ item.label }}">{{ item.label }}</div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Lote"
                    formControlName="sero_lote"
                  />
                </div>
              </div>
              <div class="row px-3">
                <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12">
                  <label class="mb-0">IgG </label>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12 pl-0">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="igG"
                  />
                </div>
                <div class="col-lg-1 col-xl-1 col-md-1 col-sm-12">
                  <label class="mb-0">IgM </label>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="igM"
                  />
                </div>
              </div>
              <div class="row px-3 pt-3">
                <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12">
                  <label class="mb-0">IgA </label>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12 pl-0">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="igA"
                  />
                </div>
                <div class="col-lg-1 col-xl-1 col-md-1 col-sm-12">
                  <label class="mb-0">IgM_S1 </label>
                </div>
                <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="igS1"
                  />
                </div>
              </div>
              <div class="row px-3 pt-3">
                <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12">
                  <label class="mb-0">Diagnóstico</label>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12 pl-0">
                  <div class="form-group">
                    <ng-select
                      #selectSERODiagnostics
                      (close)="selectSERODiagnostics.blur()"
                      loadingText="Cargando..."
                      notFoundText="No hay resultados"
                      id="poblacion"
                      name="poblacion"
                      bindLabel="label"
                      bindValue="value"
                      [items]="SERODiagnosticsList"
                      placeholder="Seleccione"
                      formControlName="sero_diagnostico_id"
                    >
                      <ng-template ng-option-tmp let-item="item">
                        <div title="{{ item.label }}">{{ item.label }}</div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12"></div>
              </div>
            </div>

            <!-- Otro section -->
            <div class="covid-test-div mt-2" *ngIf="otro">
              <div class="header-div">
                <label>Otros</label>
                <button
                  class="btn covid-test-print-btn"
                  type="button"
                  (click)="onPrint('PR05A57_RAPID_ANT_CAS', 'otro')"
                >
                  Imprimir
                  <span class="fa fa-print"></span>
                </button>
              </div>
              <div class="row px-3 pt-3">
                <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12 pl-0">
                  <label class="mb-0">Descripción</label>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12">
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      formControlName="description"
                    ></textarea>
                  </div>
                </div>
                <div class="col-lg-5 col-xl-5 col-md-5 col-sm-12"></div>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 px-3">
                <div class="form-group">
                  <label class="mb-0">Observaciones</label>
                  <textarea
                    class="form-control"
                    formControlName="observaciones"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <!-- right side panel -->
          <div class="col-lg-7 col-xl-7 col-md-12 col-sm-12">
            <div class="row covid-test-div p-3 min-h-100">
              <div class="col-lg-8 col-xl-8 col-md-12 col-sm-12">
                <label>Resultados de tests anteriores :</label>
              </div>
              <div
                class="col-lg-4 col-xl-4 col-md-12 col-sm-12 text-right pr-0"
              >
                <button
                  class="btn v-edit-btn"
                  disabled
                  type="button"
                  style="background-color: rgb(205, 203, 203)"
                >
                  Cargar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
            <button class="btn btn-primary" type="button" (click)="close()">
              Salir sin guardar
            </button>
          </div>
          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12 text-right">
            <button class="btn btn-primary" (click)="saveCovidDetails()">
              Guardar
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<app-visualizer-citars-print-document
  [hidden]="true"
  [appointmentData]="appointmentEditData"
  [testType]="testType"
></app-visualizer-citars-print-document>
