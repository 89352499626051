<app-spinner *ngIf="isLoading"></app-spinner>

<div class="container-fluid px-0">
  <div class="agendas-breadcrumb">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item" aria-current="page">visualizar citas</li>
      </ol>
    </nav>
  </div>
  <div id="scrollbar" class="agendas-content">
    <div class="row">
      <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Agenda</label>
          <ng-select #refreshcode [clearAllText]="'limpiar todo'" #selectAgenda [virtualScroll]="true"
            (close)="selectAgenda.blur()" (clear)="onCloseAgenda()" loadingText="Cargando..."
            notFoundText="No hay resultados" id="activity-type" name="activity-type" bindLabel="label" bindValue="value"
            [items]="agendalist" placeholder="Seleccione" (change)="onChangeAgenda($event)"
            [(ngModel)]="agendaFilteredData.agenda_id" (keyup)="onKeyUpAgenda($event)" (scrollToEnd)="onScrollAgenda()">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-lg-4 col-xl-4 col-md-4 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Centro Médico</label>
          <ng-select [clearAllText]="'limpiar todo'" #selectCentro [virtualScroll]="true" (close)="selectCentro.blur()"
            (clear)="onCloseCentro()" loadingText="Cargando..." notFoundText="No hay resultados" id="activity-type"
            name="activity-type" bindLabel="label" bindValue="value" [items]="centerList" placeholder="Seleccione"
            [(ngModel)]="agendaFilteredData.centro_id" (keyup)="onKeyUpCenter($event)" (scrollToEnd)="onScrollCenter()">
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>

      <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12 date-div pr-0">
        <div class="form-group">
          <label class="mb-0">Desde</label>
          <input [owlDateTime]="filterFromDatePicker1" [owlDateTimeTrigger]="filterFromDatePicker1" type="text"
            class="form-control form-control-sm date-field" [(ngModel)]="filterFromDate"
            (dateTimeChange)="onChangeDate()" [disabled]="this.agendaFilteredData.persona_id > 0" />
          <owl-date-time #filterFromDatePicker1 [pickerType]="'calendar'" [firstDayOfWeek]="1"></owl-date-time>
        </div>
      </div>
      <div class="col-lg-2 col-xl-2 col-md-2 col-sm-12 date-div l-pl-9">
        <div class="form-group">
          <label class="mb-0">Hasta</label>
          <input [owlDateTime]="filterFromDatePicker2" [owlDateTimeTrigger]="filterFromDatePicker2" type="text"
            class="form-control form-control-sm date-field" [(ngModel)]="filterToDate" (dateTimeChange)="onChangeDate()"
            [disabled]="this.agendaFilteredData.persona_id > 0" />
          <owl-date-time #filterFromDatePicker2 [pickerType]="'calendar'" [firstDayOfWeek]="1"></owl-date-time>
        </div>
      </div>
    </div>

    <div class="row">
      <!-- Trabajador search start -->
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
        <app-empresa-trabajador-serch (searchDataEmit)="setCompanySearchData($event)" [isShowWorkCenter]="true"
          [inputCompanyId]="this.agendaFilteredData.empresa_id" [inputPersonId]="this.agendaFilteredData.persona_id">
        </app-empresa-trabajador-serch>
      </div>
      <!--/ Trabajador search end -->
    </div>
    <!-- Reservation Type -->
    <div>
      <app-appointment-type (appoinmentSearchDataEmit)="setAppoinmentSearchData($event)"></app-appointment-type>
    </div>
    <!--/ Reservation Type -->
    <div class="row mt-2">
      <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label class="mb-0">Programador</label>
              <ng-select [clearAllText]="'limpiar todo'" #selectProgramador (close)="selectProgramador.blur()"
                loadingText="Cargando..." notFoundText="No hay resultados" id="activity-type" name="activity-type"
                bindLabel="label" bindValue="value" [items]="programmerList" placeholder="Seleccione"
                [(ngModel)]="agendaFilteredData.programador_id">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.label }}">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="mb-0">Comercial</label>
              <ng-select [clearAllText]="'limpiar todo'" #selectComercial (close)="selectComercial.blur()"
                loadingText="Cargando..." notFoundText="No hay resultados" id="activity-type" name="activity-type"
                bindLabel="label" bindValue="value" [items]="commercialList" placeholder="Seleccione"
                [(ngModel)]="agendaFilteredData.comercial_id">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.label }}">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="col-lg-6 col-xl-6 col-md-6 col-sm-12">
            <div class="form-group">
              <label class="mb-0">Código Barras</label>
              <input type="text" class="form-control" [(ngModel)]="agendaFilteredData.barcode" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-xl-6 col-md-12 col-sm-12">
        <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 float-right pr-0 m-pl-0">
          <div class="form-group">
            <label class="mb-0">Estado Citas</label>
            <div class="input-group mb-3">
              <ng-select [clearAllText]="'limpiar todo'" #selectEstado (close)="selectEstado.blur()"
                loadingText="Cargando..." notFoundText="No hay resultados" id="activity-type" name="activity-type"
                bindLabel="label" bindValue="value" [multiple]="true" [items]="statesAppointmentList"
                placeholder="Seleccione" [(ngModel)]="statesAppointment" (change)="onChangeEstadoCita($event)">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.label }}">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 text-right mt-2">
        <button class="btn btn-primary mr-3" type="submit" (click)="onClearFilters()">
          Limpiar Filtros
        </button>
        <button class="btn btn-primary mr-3" type="submit" [disabled]="
            !(agendaFilteredData?.barcode.length > 0) &&
            !(filterFromDate && filterToDate) &&
            !(agendaFilteredData.empresa_id > 0)
          " (click)="triggerSearch()">
          Actualizar Resultados
        </button>
        <button class="btn btn-primary" type="submit" (click)="onClickDownloadExcel()">
          Descargar Excel
        </button>
      </div>
    </div>

    <div class="row">
      <!-- pagination -->
      <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 pagination-div">
        <div class="d-flex pl-0">
          <pagination-controls previousLabel="Anterior" nextLabel="Siguiente" id="appointment-list-pagination"
            (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)">
          </pagination-controls>
          <span class="float-left pt-1">Hay {{ paginationConfig.totalItems }} registros encontrados</span>
        </div>
      </div>
    </div>

    <!-- visualizer-citars table -->
    <div class="mt-1">
      <table class="table tbl-visualizer-citars">
        <thead class="thead-light">
          <tr>
            <th>Fecha</th>
            <th>Hora</th>
            <th>Apellidos, Nombre</th>
            <th>DNI</th>
            <th>Empresa</th>
            <th>Tipo</th>
            <th>Subtipo</th>
            <th>Estado</th>
            <th>Imprimir</th>
            <th>Editar</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="
              let appointment of appointmentList | paginate: paginationConfig
            ">
            <td (click)="onEdit(appointment)">
              {{ appointment.fecha ? appointment.fecha : "" }}
            </td>
            <td (click)="onEdit(appointment)">{{ appointment.hora }}</td>
            <td (click)="onEdit(appointment)">
              {{ appointment.apellidos_nombre_persona }}
            </td>
            <td (click)="onEdit(appointment)">{{ appointment.nip }}</td>
            <td (click)="onEdit(appointment)">{{ appointment.empresa }}</td>
            <td (click)="onEdit(appointment)">{{ appointment.accion_tipo }}</td>
            <td (click)="onEdit(appointment)">
              {{ appointment.accion_subtipo }}
            </td>
            <td (click)="onEdit(appointment)">
              {{ appointment.estado_alias }}
            </td>
            <td>
              <a class="pb-2" (click)="onPrint(appointment)">
                <span class="fa fa-print tbl-row-action"></span>
              </a>
            </td>
            <td>
              <a class="pb-2" (click)="onEdit(appointment)">
                <span class="fa fa-edit tbl-row-action"></span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-visualizer-citars-print-document [hidden]="true" [appointmentData]="appointmentData"
  [covidAppoimnet]="covidAppoiment"></app-visualizer-citars-print-document>