<app-spinner *ngIf="isLoading"></app-spinner>
<div class="container-fluid pl-0 visualizar-reserv-type">
  <!-- <form> -->
  <div class="row reserva-checkbox-row">
    <div class="col-lg-2 col-xs-2 col-md-4 col-sm-12 pl-2">
      <label class="mb-0">Tipo Reserva :</label>
    </div>
    <div
      *ngIf="_uiService.selectedMenu != menuListEnum.AgendarNuevasCitas"
      class="col-lg-2 col-xs-2 col-md-4 col-sm-12"
    >
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="exampleRadios"
          id="rdoCualquiera"
          value="option1"
          [checked]="selectedType == 0"
          (change)="onSelectRedioButton(0)"
          for=""
        />
        <label class="form-check-label" for="rdoCualquiera"> Cualquiera </label>
      </div>
    </div>
    <div class="col-lg-2 col-xs-2 col-md-4 col-sm-12">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="exampleRadios"
          id="rdoReco"
          value="option1"
          (change)="onSelectRedioButton(1)"
          [checked]="selectedType == 1"
        />
        <label class="form-check-label" for="rdoReco"> Reconocimiento </label>
      </div>
    </div>
    <div class="col-lg-2 col-xs-2 col-md-4 col-sm-12">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="exampleRadios"
          id="rdoPrueba"
          value="option1"
          (change)="onSelectRedioButton(2)"
          [checked]="selectedType == 2"
        />
        <label class="form-check-label" for="rdoPrueba"> Prueba COVID </label>
      </div>
    </div>
    <div class="col-lg-2 col-xs-2 col-md-4 col-sm-12">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="exampleRadios"
          id="rdoOtro"
          value="option1"
          (change)="onSelectRedioButton(3)"
          [checked]="selectedType == 3"
        />
        <label class="form-check-label" for="rdoOtro"> Otro </label>
      </div>
    </div>
  </div>

  <!-- Reconocimiento -->
  <div class="row pt-3 px-3" *ngIf="selectedType == reservationTypeEnum.Reconocimiento">

    <ng-container *ngIf="hideEstado; else elseBlock">
      <div class="col-lg-6 col-xs-6 col-md-6 col-sm-6">
        <div class="form-group">
          <label class="mb-0">Tipo Reco</label>
          <ng-select
            [clearAllText]="'limpiar todo'"
            #selectSubTypes
            (close)="selectSubTypes.blur()"
            loadingText="Cargando..."
            notFoundText="No hay resultados"
            id="activity-type"
            name="activity-type"
            bindLabel="label"
            bindValue="value"
            [items]="actionSubTypesList"
            placeholder="Seleccione"
            [(ngModel)]="accion_subtipo"
            (change)="onChange($event)"
            [class.ng-invalid]="
              this.validateInput?.accion_subtipo &&
              agendaFilteredData.accion_subtipo == null
            "
          >
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-lg-6 col-xs-6 col-md-6 col-sm-6">
        <div class="form-group">
          <label class="mb-0">Puesto VS</label>
          <ng-select
            [clearAllText]="'limpiar todo'"
            #selectPuestoVS
            (close)="selectPuestoVS.blur()"
            loadingText="Cargando..."
            notFoundText="No hay resultados"
            id="activity-type"
            name="activity-type"
            bindLabel="label"
            bindValue="value"
            [items]="puestosVSList"
            placeholder="Seleccione"
            [(ngModel)]="puestovs_id"
            (change)="onChange($event)"
            [class.ng-invalid]="
              this.validateInput?.puestovs_id &&
              agendaFilteredData.puestovs_id == null
            "
          >
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </ng-container>

    <ng-template #elseBlock>
      <div class="col-lg-4 col-xs-4 col-md-7 col-sm-12">
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12 pl-0">
          <div class="form-group">
            <label class="mb-0">Tipo Reco</label>
            <ng-select
              [clearAllText]="'limpiar todo'"
              #selectSubTypes
              (close)="selectSubTypes.blur()"
              loadingText="Cargando..."
              notFoundText="No hay resultados"
              id="activity-type"
              name="activity-type"
              bindLabel="label"
              bindValue="value"
              [items]="actionSubTypesList"
              placeholder="Seleccione"
              [(ngModel)]="accion_subtipo"
              (change)="onChange($event)"
              [class.ng-invalid]="
                this.validateInput?.accion_subtipo &&
                agendaFilteredData.accion_subtipo == null
              "
            >
              <ng-template ng-option-tmp let-item="item">
                <div title="{{ item.label }}">{{ item.label }}</div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12 pl-0">
          <div class="form-group">
            <label class="mb-0">Puesto VS</label>
            <ng-select
              [clearAllText]="'limpiar todo'"
              #selectPuestoVS
              (close)="selectPuestoVS.blur()"
              loadingText="Cargando..."
              notFoundText="No hay resultados"
              id="activity-type"
              name="activity-type"
              bindLabel="label"
              bindValue="value"
              [items]="puestosVSList"
              placeholder="Seleccione"
              [(ngModel)]="puestovs_id"
              (change)="onChange($event)"
              [class.ng-invalid]="
                this.validateInput?.puestovs_id &&
                agendaFilteredData.puestovs_id == null
              "
            >
              <ng-template ng-option-tmp let-item="item">
                <div title="{{ item.label }}">{{ item.label }}</div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-xs-8 col-md-12 col-sm-12">
        <div class="col-lg-10 col-xs-10 col-md-12 col-sm-12 float-right">
          <div class="form-group">
            <label class="mb-0">Estado</label>
            <ng-select
              [clearAllText]="'limpiar todo'"
              #selectEstado
              (close)="selectEstado.blur()"
              loadingText="Cargando..."
              notFoundText="No hay resultados"
              id="activity-type"
              name="activity-type"
              bindLabel="label"
              bindValue="value"
              [multiple]="true"
              [items]="statesActionTypesList"
              placeholder="Seleccione"
              [(ngModel)]="estado"
              (change)="onEstadoChange($event)"
              [class.ng-invalid]="
                this.validateInput?.estado && agendaFilteredData.estado == null
              "
            >
              <ng-template ng-option-tmp let-item="item">
                <div title="{{ item.label }}">{{ item.label }}</div>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <!-- Prueba COVID -->
  <ng-container *ngIf="selectedType == reservationTypeEnum.PruebaCOVID">
    <div class="row pt-3 px-3">
      <div class="col-lg-3 col-xs-3 col-md-5 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Tipo Prueba</label>
          <ng-select
            [clearAllText]="'limpiar todo'"
            #selectTipoPrueba
            (close)="selectTipoPrueba.blur()"
            loadingText="Cargando..."
            notFoundText="No hay resultados"
            id="activity-type"
            name="activity-type"
            bindLabel="label"
            bindValue="value"
            [items]="actionSubTypesList"
            placeholder="Seleccione"
            [(ngModel)]="accion_subtipo"
            (change)="onChange($event)"
            [class.ng-invalid]="
              this.validateInput?.accion_subtipo &&
              agendaFilteredData.accion_subtipo == null
            "
          >
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-lg-3 col-xs-3 col-md-5 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Solicitante</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="solicitante"
            (blur)="onChange($event)"
            (ngModelChange)="onChange($event)"
            [class.is-invalid]="
              this.validateInput?.solicitante &&
              agendaFilteredData.solicitante == null
            "
          />
        </div>
      </div>
      <div class="col-lg-3 col-xs-3 col-md-5 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Província</label>
          <ng-select
            [clearAllText]="'limpiar todo'"
            #selectProvincia
            [virtualScroll]="true"
            (close)="selectProvincia.blur()"
            loadingText="Cargando..."
            notFoundText="No hay resultados"
            id="activity-type"
            name="activity-type"
            bindLabel="label"
            bindValue="value"
            [items]="provinciaList"
            placeholder="Seleccione"
            [(ngModel)]="provincia_id"
            (keyup)="onKeyUpProvince($event)"
            (change)="onProvinceChange($event)"
            (scrollToEnd)="onScrollProvinces()"
            [class.ng-invalid]="
              this.validateInput?.provincia_id &&
              (agendaFilteredData.provincia_id == null ||
                agendaFilteredData.provincia_id == 0)
            "
          >
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="col-lg-3 col-xs-3 col-md-5 col-sm-12">
        <div class="form-group">
          <label class="mb-0">Tipo de pago</label>
          <ng-select
            [clearAllText]="'limpiar todo'"
            #selectTipoDePago
            (close)="selectTipoDePago.blur()"
            loadingText="Cargando..."
            notFoundText="No hay resultados"
            id="activity-type"
            name="activity-type"
            bindLabel="label"
            bindValue="value"
            [items]="paymentTypesList"
            placeholder="Seleccione"
            [(ngModel)]="pago_tipo"
            (change)="onChange($event)"
            [class.ng-invalid]="
              this.validateInput?.pago_tipo &&
              (agendaFilteredData.pago_tipo == null ||
                agendaFilteredData.pago_tipo == 0)
            "
          >
            <ng-template ng-option-tmp let-item="item">
              <div title="{{ item.label }}">{{ item.label }}</div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="row px-3">
      <ng-container *ngIf="hideEstado; else elseBlock">
        <div class="col-lg-3 col-xs-3 col-md-3 col-sm-12">
          <div class="form-group">
            <label class="mb-0">Población</label>
            <ng-select
              [clearAllText]="'limpiar todo'"
              #selectTown
              [virtualScroll]="true"
              (close)="selectTown.blur()"
              loadingText="Cargando..."
              notFoundText="No hay resultados"
              id="activity-type"
              name="activity-type"
              bindLabel="label"
              bindValue="value"
              [items]="townsList"
              placeholder="Seleccione"
              [(ngModel)]="poblacion_id"
              (keyup)="onKeyUpTown($event)"
              (change)="onChange($event)"
              (scrollToEnd)="onScrollTowns()"
              [class.ng-invalid]="
                this.validateInput?.poblacion_id &&
                (agendaFilteredData.poblacion_id == null ||
                  agendaFilteredData.poblacion_id == 0)
              "
            >
              <ng-template ng-option-tmp let-item="item">
                <div title="{{ item.label }}">{{ item.label }}</div>
              </ng-template>
            </ng-select>
          </div>
        </div>
        <div class="col-lg-3 col-xs-3 col-md-3 col-sm-12">
          <div class="form-group">
            <label class="form-check-label"> Pagado </label> <br />
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="pagado_sino"
                id="rdoPagado"
                [(ngModel)]="pagado_sino"
                (change)="onPagadoChange($event)"
                [checked]="pagado_sino == 1"
                [value]="1"
              />
              <label class="form-check-label" for="rdoPagado"> Si </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="pagado_sino"
                id="rdoPagadoNo"
                [(ngModel)]="pagado_sino"
                (change)="onPagadoChange($event)"
                [checked]="pagado_sino == 0"
                [value]="0"
              />
              <label class="form-check-label" for="rdoPagadoNo"> No </label>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-xs-3 col-md-3 col-sm-12">
          <div class="form-group">
            <label class="mb-0">Tarjeta Sanitaria</label>
            <input
              type="text"
              maxlength="25"
              class="form-control"
              [(ngModel)]="tarjeta_sanitaria"
              (blur)="onChange($event)"
              (ngModelChange)="onChange($event)"
              [class.is-invalid]="
                this.validateInput?.tarjeta_sanitaria &&
                (agendaFilteredData.tarjeta_sanitaria == null ||
                  agendaFilteredData.tarjeta_sanitaria == 0)
              "
            />
          </div>
        </div>
        <div class="col-lg-3 col-xs-3 col-md-3 col-sm-12">
          <div class="form-group">
            <label class="mb-0">Importe</label>
            <input
            value="0"
              type="number"
              class="form-control"
              [(ngModel)]="pagado_importe"
              (input)="onChangeImporte($event.target.value)"
            />
          </div>
        </div>
      </ng-container>

      <ng-template #elseBlock>
        <div class="col-lg-6 col-xs-6 col-md-8 col-sm-12">
          <div class="row">
            <div class="col-lg-6 col-xs-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="mb-0">Población</label>
                <ng-select
                  [clearAllText]="'limpiar todo'"
                  #selectTown
                  [virtualScroll]="true"
                  (close)="selectTown.blur()"
                  loadingText="Cargando..."
                  notFoundText="No hay resultados"
                  id="activity-type"
                  name="activity-type"
                  bindLabel="label"
                  bindValue="value"
                  [items]="townsList"
                  placeholder="Seleccione"
                  [(ngModel)]="poblacion_id"
                  (keyup)="onKeyUpTown($event)"
                  (change)="onChange($event)"
                  (scrollToEnd)="onScrollTowns()"
                  [class.ng-invalid]="
                    this.validateInput?.poblacion_id &&
                    (agendaFilteredData.poblacion_id == null ||
                      agendaFilteredData.poblacion_id == 0)
                  "
                >
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.label }}">{{ item.label }}</div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="col-lg-6 col-xs-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="form-check-label"> Pagado </label> <br />
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="pagado_sino"
                    id="rdoPagado"
                    [(ngModel)]="pagado_sino"
                    (change)="onPagadoChange($event)"
                    [checked]="pagado_sino == 1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="rdoPagado"> Si </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="pagado_sino"
                    id="rdoPagadoNo"
                    [(ngModel)]="pagado_sino"
                    (change)="onPagadoChange($event)"
                    [checked]="pagado_sino == 0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="rdoPagadoNo"> No </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-xs-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label class="mb-0">Tarjeta Sanitaria</label>
                <input
                  type="text"
                  maxlength="25"
                  class="form-control"
                  [(ngModel)]="tarjeta_sanitaria"
                  (blur)="onChange($event)"
                  (ngModelChange)="onChange($event)"
                  [class.is-invalid]="
                    this.validateInput?.tarjeta_sanitaria &&
                    (agendaFilteredData.tarjeta_sanitaria == null ||
                      agendaFilteredData.tarjeta_sanitaria == 0)
                  "
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xs-6 col-md-12 col-sm-12 px-0">
          <div class="col-lg-12 col-xs-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label class="mb-0">Estado</label>
              <ng-select
                [clearAllText]="'limpiar todo'"
                #selectEstado
                (close)="selectEstado.blur()"
                loadingText="Cargando..."
                notFoundText="No hay resultados"
                id="activity-type"
                name="activity-type"
                bindLabel="label"
                bindValue="value"
                [multiple]="true"
                [items]="statesActionTypesList"
                placeholder="Seleccione"
                [(ngModel)]="estado"
                (change)="onEstadoChange($event)"
                [class.ng-invalid]="
                  this.validateInput?.estado &&
                  agendaFilteredData.estado == null
                "
              >
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.label }}">{{ item.label }}</div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>
