import { Injectable } from '@angular/core';
import { ApiService } from '../shared/api.service';
import API from '../../helpers/api.routes.json';
import { BehaviorSubject, Observable } from 'rxjs';
import { Reco } from '../../model/uploading/reco.model';
import { RecoResponse } from '../../model/uploading/reco-response.model';
import { DocumentRequet } from '../../model/uploading/document-request.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecoService {

  public isLoading = false;
  public selectedReco = new Reco();
  public recoCompleted = new BehaviorSubject(false);

  constructor(private apiService: ApiService) { }

  public getRandomReco(): Observable<RecoResponse> {
    const url = `${environment.apiRoot}${API.uploading.getRandomReco}`;
    return this.apiService.get(url);
  }

  public saveRecoDocument(document: DocumentRequet): Observable<RecoResponse> {
    const formData: FormData = new FormData();
    formData.append('file', document.file, document.file.name);
    formData.append('doc', JSON.stringify(document.doc));
    const url = `${environment.apiRoot}${API.uploading.addDocPart}`;
    return this.apiService.post(url, formData);
  }
}
