import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectOptions } from 'src/app/model/shared/select-options.model';
import { DropdownService } from 'src/app/service/shared/dropdown.service';
import { CommonService } from 'src/app/service/common/common.service';
import { RequestData } from '../../../../model/shared/request-data';
import { of, Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged } from 'rxjs/operators';
import {Constants} from '../../../../helpers/constant/constant'

@Component({
  selector: 'app-empresa-trabajador-serch',
  templateUrl: './empresa-trabajador-serch.component.html',
  styleUrls: ['./empresa-trabajador-serch.component.scss']
})
export class EmpresaTrabajadorSerchComponent implements OnInit {
  @Output() searchDataEmit: EventEmitter<any> = new EventEmitter();
  @Input() validateCompany: Boolean = false;
  @Input() validateEmployee: Boolean = false;
  @Input() isShowWorkCenter: Boolean = false;
  @Input() isShowEmpresa: Boolean = false;
  @Input() requestDataType: any = 'filter';
  companyList: SelectOptions[] = [];
  personsList: SelectOptions[] = [];
  workcenterList: SelectOptions[] = [];
  companyId: number;
  personId: number;
  personValue : String;  
  companyNameVal  : string; 
  workCenterId: number;
  workCenterDisable: boolean = true;
  isLableFound:boolean=false;
  @Input() inputCompanyId:number;
  @Input() inputPersonId:number;
  @Input() inputCompanyName:string;
    @Input() inputPersonName:string;

  isCancelAppointment:boolean;
  appointmentEditData: any;
  companyRequestData = {
    page_number: 1,
    page_size: 10,
    incl: null,
    type: this.requestDataType,
    relatedId : null
  }

  personsRequestData = {
    page_number: 1,
    page_size: 10,
    incl: null,
    type: this.requestDataType,
    relatedId: null
  }

  workCentersRequestData = {
    page_number: 1,
    page_size: 25,
    type: "filter",
    relatedId: null
  }

  selectedPresonEvent ={
    companyId: "",
    companyText: "",
    label: "",
    value: null,
  }

  selectedCompanyEvent ={
    label: "",
    value: null
  }
  public isLoading = false;
  fakeObservable = of('dummy').pipe(delay(3000));  
  private companySubject: Subject<string> = new Subject();
  private employeeSubject: Subject<string> = new Subject();
  constructor(private _visualizerCitarService: CommonService, private dropdownService: DropdownService) {

  }

  ngOnInit(): void {
    this.personsRequestData.type = this.requestDataType;
    this.companyRequestData.type = this.requestDataType;

    this.isCancelAppointment = Boolean(localStorage.getItem('cancelStatus'))
    this.appointmentEditData = JSON.parse(localStorage.getItem('cancelNewData'))

    if (this.isCancelAppointment == true) {
      this.bindNewDataFromCancel(this.appointmentEditData)
      this.getCompanyList("", false, this.companyId);
      this.getPersonsList(this.companyId, "", false);
    } else {
      this.getCompanyList();
      this.getPersonsList();
    }
    setTimeout(() => {
      this.inputCompanyId ? this.companyId = this.inputCompanyId : this.companyId = null;
      this.inputPersonId ? this.personId = this.inputPersonId : this.personId = null;
    }, 2000);

    this.companySubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe(companyName => this.loadTopMatchingCompanies(companyName));

    this.employeeSubject.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      distinctUntilChanged(),
    ).subscribe(employeeName => this.loadTopMatchingPersons(employeeName));

  }
  bindNewDataFromCancel(data:any)
  {
    if(data)
    {
      this.companyId=data.attribs.empresa_id;
      this.inputCompanyId=this.companyId;
      this.personId=data.persona.persona_id;
      this.inputPersonId=data.persona.persona_id;
      this.selectedCompanyEvent.value=this.companyId;
      this.selectedCompanyEvent.label=data.attribs.empresa_txt;
      //set data temp to person list since person list loading get more time 
      this.personsList = this.dropdownService.WrappedToDropDownList([data.persona], 'nombre', 'id', true, false);
      this.personsList[0].value=data.persona.persona_id;
    }
  }
   
  public reloadWithSelectedValues(){ 
    if(this.inputCompanyName && this.inputPersonName){
      this.isLoading = true;
       this.fakeObservable.subscribe(_data => {
        this.isLoading = false;
        this.getCompanyList(this.inputCompanyName,false);
        this.getPersonsList(this.inputCompanyId,this.inputPersonName);    
       });
    } 
  }


  getCompanyList(incl?: string, isScroll = false, companyId = 0) {
    this.isLoading = true;
    this.companyRequestData.incl = incl ? incl : null;
    this.companyRequestData.page_number = isScroll ? (this.companyRequestData.page_number + 1) : 1;
    this.companyRequestData.relatedId = companyId > 0 ? Number(companyId) : null;
    this._visualizerCitarService.getCompanyList(this.companyRequestData).subscribe(res => {
      this.isLoading = false;
      if (res) {
        if (isScroll) {
          this.companyList = this.companyList.concat(this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id'));
        } else {
          this.companyList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id');
        }
        if (this.isCancelAppointment == true) {
          this.onChangeCompany(this.selectedCompanyEvent)
        }
      }
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

  getPersonsList(companyId?: number, incl?: string, isScroll = false) {
    this.isLoading = true;
    this.personsRequestData.incl = incl || null;
    this.personsRequestData.page_number = isScroll ? (this.personsRequestData.page_number + 1) : 1;
  
    let request: any;
    if (companyId > 0) {
      const requestData = new RequestData();
      requestData.relatedId = companyId;
      requestData.incl = this.personsRequestData.incl;
      requestData.page_number = this.personsRequestData.page_number;
      requestData.type = this.requestDataType;
      request = requestData;
    } else {
      const requestData = {
        type: this.requestDataType,
        page_number: this.personsRequestData.page_number,
        page_size: 10,
        incl: this.personsRequestData.incl,
        relatedId: null
      };
      request = requestData;
    }
  
    setTimeout(() => {
      this._visualizerCitarService.getPersonsList(request).subscribe(
        (res) => {
          this.isLoading = false;
          if (res) {
            if (this.isCancelAppointment) {
              request.page_size = res.links.rowcount;
              this._visualizerCitarService.getPersonsList(request).subscribe(
                (resParticular) => {
                  this.isLoading = false;
                  if (resParticular) {
                    if (isScroll) {
                      const newData = resParticular.data.filter(newLoadedData =>
                        !this.personsList.some(value => value.label === newLoadedData.nombre)
                      );
                      this.personsList = this.personsList.concat(this.dropdownService.WrappedToDropDownList(newData, 'nombre', 'id', true, false));
                      const map = new Map(this.personsList.map(person => [person.label, person]));
                      this.personsList = [...map.values()];
                    } else {
                      this.personsList = this.dropdownService.WrappedToDropDownList(resParticular.data, 'nombre', 'id', true, false);
                    }
                    this.selectedPresonEvent = {
                      companyId: this.companyId.toString(),
                      companyText: "",
                      label: "",
                      value: this.personId,
                    };
                    if (this.selectedPresonEvent) {
                      this.onChange(this.selectedPresonEvent);
                    }
                  }
                },
                (error) => {
                  this.isLoading = false;
                  console.log(error);
                }
              );
            } else {
              if (isScroll) {
                const newData = res.data.filter(newLoadedData =>
                  !this.personsList.some(value => value.label === newLoadedData.nombre)
                );
                this.personsList = this.personsList.concat(this.dropdownService.WrappedToDropDownList(newData, 'nombre', 'id', true, false));
                const map = new Map(this.personsList.map(person => [person.label, person]));
                this.personsList = [...map.values()];
              } else {
                this.personsList = this.dropdownService.WrappedToDropDownList(res.data, 'nombre', 'id', true, false);
              }
            }
          }
        },
        (error) => {
          this.isLoading = false;
          console.log(error);
        }
      );
    }, 2500);
  }
  

  getRelatedWorkCentersList(companyId?: number, isScroll = false) {
    this.isLoading = true;
    this.workCentersRequestData.page_number = isScroll ? (this.workCentersRequestData.page_number + 1) : 1;
    
    if (companyId > 0) {
      this.workCenterDisable = false;
      this.workCentersRequestData.relatedId = companyId;
  
      this._visualizerCitarService.getWorkCenterList(this.workCentersRequestData).subscribe(
        (res) => {
          this.isLoading = false;
          if (res) {
            if (isScroll) {
              const newData = res.data.filter(newLoadedData =>
                !this.workcenterList.some(value => value.label === newLoadedData.empresa_sede)
              );
              this.workcenterList = this.workcenterList.concat(this.dropdownService.WrappedToDropDownList(newData, 'empresa_sede', 'id', false, false));
            } else {
              this.workcenterList = this.dropdownService.WrappedToDropDownList(res.data, 'empresa_sede', 'id', false, false);
            }
          }
        },
        (error) => {
          this.isLoading = false;
          console.log(error);
        }
      );
    }
  }
  

  loadTopMatchingCompanies(companyName) {
    //Trigger the loading only if string length is >= 4
    if (companyName.length >= 4) { 
      this.getCompanyList(companyName);
    } 
    else if (companyName.length == 0) {
        this.getCompanyList();
    }
  }

  onKeyUpCompany(companyNameTarget: any){
    this.companyNameVal = companyNameTarget?.target?.value;
    this.companySubject.next(companyNameTarget?.target?.value);
  }
  loadSpecificCompany(companyId: any) {
    this.companyId=Number(companyId)
    let isFound= false;
    this.companyList.forEach(x=>{
      if(x.value == companyId){
        isFound = true;
        return;
      }
    });

    if (!isFound && companyId > 0) { 
      this.getCompanyList(null,false,companyId);
    }  
  }

  onCloseCompanies() {
    this.selectedPresonEvent=null
    this.getCompanyList();
    this.companyId = null;
    this.personId = null;
    this.workCenterId= null;
    this.workCenterDisable = true;
    this.personValue = null;
    this.searchDataEmit.emit({
      companyId: this.companyId,
      personId: this.personId,
      personValue : this.personValue 
    });
    this._visualizerCitarService.companyIdSubject.next(null);
  }

  onCloseWorkCenters() { 
    
    this.searchDataEmit.emit({
      companyId: this.companyId,
      personId: this.personId,
      workCenterId : this.workCenterId
    });
  }


  loadTopMatchingPersons(personsName: any) {
    // Trigger the loading only if string length is >= 4
    if (personsName.length >= 4) {
      this.getPersonsList(this.companyId, personsName);
    } else {
      if (personsName.length == 0) {
        this.companyId = this.companyId > 0 ? this.companyId : undefined;
        this.getPersonsList(this.companyId);
      }

    }
  }

  onKeyUpPerson(personsNameTarget: any){
    this.employeeSubject.next(personsNameTarget?.target?.value);
  }

  onClosePersons() {
    this.companyId = this.companyId > 0 ? this.companyId : undefined;
    this.getPersonsList(this.companyId);
  }

  onChangeCompany(company: any) {
    this.personId = null;
    this.workCenterId = null;
    this.personValue =null;
    this.searchDataEmit.emit({
      companyId: this.companyId,
      personId: this.personId,
      personValue : this.personValue,
      companyName: company?.label
    });
    if(this.inputPersonName == null){
      if (company) {
        this._visualizerCitarService.companyIdSubject.next(this.companyId);
        this.getPersonsList(Number(company.value));
        this.getRelatedWorkCentersList(Number(company.value));
      } else {
        this._visualizerCitarService.companyIdSubject.next(null);
        this.getPersonsList();
      }
    }
  }

  onChangeWorkCenter(_company: any) {
    this.searchDataEmit.emit({
      companyId: this.companyId,
      personId: this.personId,
      workCenterId : this.workCenterId
    });
  }

  
  onChange(event:any) {    
    ////fix auto change company when select the person
    if(event?.companyId){
      this.companyId = event?.companyId;
      this.loadSpecificCompany(event?.companyId);
    }
    this.personValue = event?.label;
    this.searchDataEmit.emit({
      companyId: this?.companyId,
      personId: this?.personId,
      workCenterId : this?.workCenterId
    });
  }

  onScrollCompany() {
    this.getCompanyList(this.companyNameVal, true);
  }

  onScrollPerson() {
   
    this.getPersonsList(this.companyId, '', true);
  }

  onScrollCentro(){
    this.getRelatedWorkCentersList(this.companyId,true);
  }

  ngOnChanges() {
    this.inputCompanyId ?  this.companyId = this.inputCompanyId : this.companyId = null;  
    this.inputPersonId ?  this.personId = this.inputPersonId : this.personId = null;  
  }   
   
}
